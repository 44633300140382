// contexts/CartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getCartsItemCount } from '../api/carts';
import PropTypes from 'prop-types';

import useContextHook from './useContextHook';

const Context = createContext();
Context.displayName = 'Cart';

export const Provider = ({ children }) => {
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    // Fetch the initial cart item count when the component mounts
    getCartsItemCount()
      .then((response) => {
        const count = response.result.count; // Extract the count from the response
        setCartItemCount(count);
    
      })
      .catch((error) => {});
  }, []);
  
  

  const updateCartItemCount = (count) => {
    setCartItemCount(count !== undefined ? count : 0);
  };

  return (
    <Context.Provider value={{ cartItemCount, updateCartItemCount }}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useCart = () => useContextHook(Context);
