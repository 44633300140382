import { forwardRef, memo, Fragment } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import {
  // HAIRLINE_DIVIDER_CLASSES,
  MENU_BUTTON_CLASSES
} from './ProfileMenuTheme';
import { app as $appContent } from '../../content';
import { clsm } from '../../utils';
import { createAnimationProps } from '../../helpers/animationPropsHelper';
import { Logout, SettingsBlue } from '../../assets/icons';
import { useUser } from '../../contexts/User';
import Button from '../Button';
import UserAvatar from '../UserAvatar';
import withPortal from '../withPortal';

const $content = $appContent.navbar;

const commonMenuButtonProps = (toggleProfileMenu, onClick) => ({
  variant: 'tertiaryText',
  className: clsm(
    MENU_BUTTON_CLASSES,
    'flex items-center gap-3 px-4 py-3 transition-colors duration-200 ease-in-out',
    'hover:bg-gray-200 dark:hover:bg-gray-700',
    'focus:outline-none focus:ring-none'
  ),
  onClick: () => {
    if (onClick) onClick();
    toggleProfileMenu(false);
  },
  'aria-live': 'polite'
});

const Popup = forwardRef(
  ({ navData = [], toggleProfileMenu, menuClassName = '' }, ref) => {
    const { userData, logOut } = useUser();
    const {
      avatarSrc,
      color: profileColor,
      username,
      isSeller
    } = userData || {};

    const menuClassNames = clsm(
      'w-96 max-w-md bg-white dark:bg-zinc-900 rounded-xl shadow-lg overflow-y-auto max-h-screen ring-1 ring-gray-700 ring-opacity-5',
      menuClassName
    );

    return (
      <motion.nav
        {...createAnimationProps({ animations: ['fadeIn-half', 'scale'] })}
        className={menuClassNames}
        ref={ref}
        aria-label="Profile menu"
        role="navigation"
      >
        <div className="flex flex-col py-5">
          {/* User Info */}
          <div className="px-5 pb-5 border-b border-gray-200 dark:border-gray-700">
            <Button
              {...commonMenuButtonProps(toggleProfileMenu)}
              className="flex items-center gap-3 px-0 py-0 hover:bg-transparent focus:ring-0"
              to={isSeller ? `/${username}` : '/settings'}
              type="nav"
              aria-label={username ? `Profile of ${username}` : 'Profile'}
              data-testid="profileMenu-avatar"
            >
              <UserAvatar
                avatarSrc={avatarSrc}
                profileColor={profileColor}
                username={username}
                alt={`Avatar of ${username}`}
                className="w-12 h-12 shadow-md rounded-full"
              />
              <div className="flex flex-col">
                {/* <p className="text-lg font-semibold text-gray-900 dark:text-white">
                  {username || $content.profile}
                </p> */}
                <p className="text-base font-semibold text-gray-900 dark:text-white">
                  {isSeller ? 'View Shop' : 'View Profile'}
                </p>
              </div>
            </Button>
          </div>

          {/* Navigation Buttons */}
          <ul className="mt-3">
            {navData.map(({ icon, label, to, hasDivider, pageName }) => (
              <Fragment key={label}>
                <li>
                  <Button
                    {...commonMenuButtonProps(toggleProfileMenu)}
                    data-testid={`${pageName}-button`}
                    type="nav"
                    to={to}
                    aria-label={label}
                  >
                    {icon}
                    <span className="text-sm font-medium text-zinc-800 dark:text-gray-200">
                      {label}
                    </span>
                  </Button>
                </li>
                {/* {hasDivider && (
                  <li className="my-2">
                    <hr className={clsm(HAIRLINE_DIVIDER_CLASSES)} />
                  </li>
                )} */}
              </Fragment>
            ))}
          </ul>

          {/* Settings and Logout */}
          <div className="mt-3 border-t border-gray-200 dark:border-gray-700 pt-3">
            <Button
              {...commonMenuButtonProps(toggleProfileMenu)}
              type="nav"
              to="/settings"
              aria-label={$content.settings}
            >
              <SettingsBlue />
              <span className="text-sm font-medium text-gray-800 dark:text-gray-200">
                {$content.settings}
              </span>
            </Button>
            <Button
              {...commonMenuButtonProps(toggleProfileMenu, () => logOut('logOut'))}
              className="w-full flex items-center justify-center pl-4"
              aria-label={$content.log_out}
            >
              <Logout className="h-7 w-7" />
              <span className="text-sm font-medium text-gray-800 dark:text-gray-200">
                {$content.log_out}
              </span>
            </Button>
          </div>
        </div>
      </motion.nav>
    );
  }
);

const MemoizedPopup = memo(Popup);

const PortalPopup = withPortal(MemoizedPopup, 'profile-menu-popup', {
  baseContainerClasses: clsm('absolute top-0 right-0 z-50')
});

const MenuPopup = forwardRef(
  ({ asPortal = false, isOpen = false, ...restProps }, ref) =>
    asPortal ? (
      <PortalPopup isOpen={isOpen} ref={ref} {...restProps} />
    ) : (
      isOpen && <MemoizedPopup ref={ref} {...restProps} />
    )
);

MenuPopup.propTypes = {
  asPortal: PropTypes.bool,
  isOpen: PropTypes.bool
};

MenuPopup.defaultProps = {
  asPortal: false,
  isOpen: false
};

Popup.propTypes = {
  navData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      hasDivider: PropTypes.bool,
      pageName: PropTypes.string
    })
  ),
  menuClassName: PropTypes.string,
  toggleProfileMenu: PropTypes.func.isRequired
};

Popup.defaultProps = {
  navData: [],
  menuClassName: ''
};

export default MenuPopup;
