import PropTypes from 'prop-types';
import { productDirectory as $productDirectoryContent } from '../../../content';
import ProductCard from './ProductCard/components/ProductCard';
import GridLayout from './GridLayout';

const $content = $productDirectoryContent.product_section;
const $productPageNotifications = $productDirectoryContent.notification;

const ProductsSection = ({
  products = [],
  isLoading = false,
  channelArn = '',
  username = ''
}) => {
  const hasAvailableProducts = products.length > 0;

  return (
    <GridLayout
      className={hasAvailableProducts ? 'pb-12' : 'pb-0'}
      hasData={hasAvailableProducts}
      isLoading={isLoading}
      loadingError={$productPageNotifications.error.error_loading_products}
      noDataText={$content.no_products_available}
    >
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          channelArn={channelArn}
          username={username}
        />
      ))}
    </GridLayout>
  );
};

ProductsSection.propTypes = {
  products: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  channelArn: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default ProductsSection;
