import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiMapPin, FiShoppingCart, FiMail, FiCalendar, FiArrowLeft, FiPackage, FiDollarSign } from 'react-icons/fi';
// import Button from '../../components/Button';
import { getCustomerOrder } from '../../api/order';
import Spinner from '../../components/Spinner';

const FALLBACK_IMAGE_URL = '/no-image.png';

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

const formatOrderID = (id) => `#${id}`;

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');



  const fetchOrderDetails = useCallback(async () => {
    try {
      const data = await getCustomerOrder(orderId);
      setOrder(data);
    } catch (err) {
      const errorMessage =
        err.response?.data.message ||
        'Failed to fetch order details. Please try again later.';
      setError(`Error: ${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  if (isLoading) return <Spinner message="Loading order details..." />;
  if (error)
    return <div className="text-center text-red-500 py-10">{error}</div>;
  if (!order)
    return (
      <div className="text-center py-10">
        Order details are currently unavailable.
      </div>
    );

  const formattedOrderID = formatOrderID(order.orderId);
  const formattedDate = formatDate(order.transactionDate);
  const capitalizedStatus = capitalize(order.orderStatus);

  const subtotal = order.itemsPurchased.reduce((acc, item) => {
    const itemTotal = (item.unitAmount / 100) * item.quantity;
    return acc + itemTotal;
  }, 0);

  const totalTax = order.itemsPurchased.reduce((acc, item) => {
    const itemTax = item.amount / 100 - (item.unitAmount / 100) * item.quantity;
    return acc + itemTax;
  }, 0);

  return (
    <div className="bg-white dark:bg-black min-h-screen py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-2">
          <Link
            to="/orders"
            className="group inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-300 transition-colors duration-200"
          >
            <FiArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" /> 
            Back to Orders
          </Link>
          {order.orderStatus === 'delivered' && (
            <Link
              to={`/orders/${orderId}/review`}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Leave Review
            </Link>
          )}
        </div>
        
        <main className="bg-white dark:bg-black rounded-2xl overflow-hidden">
          <header className="bg-gray-800 dark:bg-gray-800 text-white px-6 py-4">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-2xl font-bold tracking-tight">Order Summary</h1>
                <p className="text-indigo-200 mt-1">{formattedOrderID} • {formattedDate}</p>
              </div>
              <div className="px-4 py-2 rounded-lg bg-gray-50 bg-opacity-20 backdrop-blur-sm">
                <span className="text-sm font-semibold">{capitalizedStatus}</span>
              </div>
            </div>
          </header>

          <div className="p-6 space-y-8">
            <section className="grid grid-cols-2 sm:grid-cols-1 gap-6">
              <OrderInfoSection
                title="Order Information"
                icon={<FiShoppingCart className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />}
              >
                <InfoItem icon={<FiCalendar />} label="Order Date" value={formattedDate} />
                <InfoItem icon={<FiPackage />} label="Status" value={capitalizedStatus} />
                <InfoItem icon={<FiDollarSign />} label="Total Amount" value={`$${(subtotal + totalTax).toFixed(2)} USD`} />
              </OrderInfoSection>
              <OrderInfoSection
                title="Shipping Information"
                icon={<FiMapPin className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />}
              >
                <InfoItem label="Name" value={order.customerName} />
                <InfoItem icon={<FiMail />} label="Email" value={order.customerEmail} />
                <InfoItem
                  label="Address"
                  value={`${order.shippingAddress.line1}, ${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postalCode}, ${order.shippingAddress.country}`}
                />
              </OrderInfoSection>
            </section>

            <section>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4 flex items-center">
                <FiPackage className="mr-2" /> Items Purchased
              </h2>
              <div className="space-y-3">
                {order.itemsPurchased.map((item, index) => (
                  <ItemCard key={index} item={item} />
                ))}
              </div>
            </section>

            <section className="bg-gray-50 dark:bg-gray-700/50 p-6 rounded-xl border border-gray-100 dark:border-gray-600">
              <OrderSummaryItem label="Subtotal" value={`$${subtotal.toFixed(2)} USD`} />
              <OrderSummaryItem label="Tax" value={`$${totalTax.toFixed(2)} USD`} />
              <OrderSummaryItem
                label="Total Amount"
                value={`$${(subtotal + totalTax).toFixed(2)} USD`}
                isTotal
              />
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

const OrderInfoSection = ({ title, icon, children }) => (
  <div className="bg-gray-50 dark:bg-gray-700/50 p-5 rounded-xl border border-gray-100 dark:border-gray-600">
    <h2 className="text-base font-semibold flex items-center text-gray-900 dark:text-white mb-4">
      {icon}
      <span className="ml-2">{title}</span>
    </h2>
    <div className="space-y-3">
      {children}
    </div>
  </div>
);

OrderInfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired
};

const InfoItem = ({ icon = null, label, value }) => (
  <div className="flex items-center text-sm">
    {icon && <span className="text-gray-500 dark:text-gray-400 mr-2">{icon}</span>}
    <span className="text-gray-600 dark:text-gray-300 mr-2">{label}:</span>
    <span className="font-medium text-gray-900 dark:text-white">{value}</span>
  </div>
);

InfoItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const ItemCard = ({ item }) => (
  <div className="flex items-center bg-gray-50 dark:bg-gray-700/50 p-4 rounded-xl border border-gray-100 dark:border-gray-600 hover:border-indigo-200 dark:hover:border-indigo-500 transition-colors duration-200">
    <img
      src={item.image || FALLBACK_IMAGE_URL}
      alt={item.name}
      className="w-16 h-16 object-cover rounded-lg mr-4 border border-gray-200 dark:border-gray-600"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = FALLBACK_IMAGE_URL;
      }}
    />
    <div className="flex-grow">
      <p className="text-base font-medium text-gray-900 dark:text-white">
        {item.name}
      </p>
      <div className="flex justify-between items-center mt-2">
        <p className="text-sm text-gray-600 dark:text-gray-400">
          ${(item.unitAmount / 100).toFixed(2)} {item.currency.toUpperCase()} × {item.quantity}
        </p>
        <p className="text-base font-semibold text-gray-900 dark:text-white">
          ${((item.unitAmount / 100) * item.quantity).toFixed(2)} {item.currency.toUpperCase()}
        </p>
      </div>
    </div>
  </div>
);

ItemCard.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    unitAmount: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired
  }).isRequired
};

const OrderSummaryItem = ({ label, value, isTotal = false }) => (
  <div className={`flex justify-between items-center py-3 ${isTotal ? 'border-t-2' : 'border-t'} border-gray-200 dark:border-gray-600`}>
    <p className={`font-medium ${isTotal ? 'text-lg font-semibold' : 'text-base'} text-gray-800 dark:text-white`}>
      {label}
    </p>
    <p className={`font-semibold ${isTotal ? 'text-lg text-indigo-600 dark:text-indigo-400' : 'text-base text-gray-800 dark:text-white'}`}>
      {value}
    </p>
  </div>
);

OrderSummaryItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isTotal: PropTypes.bool
};

export default OrderDetails;
