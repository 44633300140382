import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clsm } from '../../../../../utils';
import { PROFILE_COLORS } from '../../../../../constants';
import { useResponsiveDevice } from '../../../../../contexts/ResponsiveDevice';
import LivePill from '../../../../../components/LivePill';
import UserAvatar from '../../../../../components/UserAvatar';

const FollowedUserButton = forwardRef(
  ({ avatarSrc, color, isLive, username, isSidebarOpen }, ref) => {
    const { isTouchscreenDevice } = useResponsiveDevice();

    return (
      <Link
        data-testid="followed-user"
        aria-label={`Profile of ${username}`}
        className={clsm([
          'flex',
          'items-center',
       
          'rounded-lg',
          'transition-all',
          'duration-200',
          'ease-in-out',
          'hover:bg-gray-100',
          'dark:hover:bg-gray-800',
          'group',
          isSidebarOpen ? 'justify-start w-full' : '',
        ])}
        to={`/${username}`}
        ref={ref}
      >
        <div className="relative">
          <UserAvatar
            avatarSrc={avatarSrc}
            className={clsm([
              'transition-transform',
              'duration-200',
              !isTouchscreenDevice && 'group-hover:scale-110',
              isSidebarOpen ? 'w-10 h-10' : 'w-9 h-9'
            ])}
            profileColor={color}
            alt={`Avatar of ${username}`}
            username={username}
          />
          {isLive && (
            <div className={clsm([
              'absolute',
              'bottom-0',
              'right-0',
              'transform',
              'translate-x-1/4',
              'translate-y-1/4',
              'w-3',
              'h-3',
              'bg-red-500',
              'rounded-full',
              'border-2',
              'border-white',
              'dark:border-gray-800'
            ])} />
          )}
        </div>
        {isSidebarOpen && (
          <div className="ml-3 flex-grow overflow-hidden">
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 truncate">
              {username}
            </h3>
            {isLive && (
              <div className="mt-1">
                <LivePill size="xs" />
              </div>
            )}
          </div>
        )}
      </Link>
    );
  }
);

FollowedUserButton.defaultProps = {
  avatarSrc: '',
  color: 'default',
  isLive: false,
  username: '',
  isSidebarOpen: true
};

FollowedUserButton.propTypes = {
  avatarSrc: PropTypes.string,
  color: PropTypes.oneOf([...PROFILE_COLORS, 'default', 'white']),
  isLive: PropTypes.bool,
  username: PropTypes.string,
  isSidebarOpen: PropTypes.bool
};

export default FollowedUserButton;
