import { useState, useRef, useEffect, useMemo } from 'react';
import { Combobox } from '@headlessui/react';
import { Search as SearchIcon } from '../../../../assets/icons';
import { searchChannels } from '../../../../api/channels';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../../../components/UserAvatar';
import { getAvatarSrc } from '../../../../helpers';
import { clsm } from '../../../../utils';
import Spinner from '../../../../components/Spinner';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const Searchbar = () => {
  const [query, setQuery] = useState('');
  const [channels, setChannels] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const comboboxRef = useRef(null);
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (!debouncedQuery) {
      setChannels([]);
      return;
    }

    const fetchChannels = async () => {
      setIsLoading(true);
      try {
        const response = await searchChannels(debouncedQuery);
        setChannels(response.result.channels);
        setError(null);
      } catch {
        setError('An error occurred while fetching the data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchChannels();
  }, [debouncedQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setQuery('');
        setChannels([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredChannels = useMemo(() => channels.slice(0, 10), [channels]);

  return (
    <Combobox
      as="div"
      className="relative mx-auto flex w-full max-w-2xl"
      onChange={(channel) => {
        if (channel) {
          navigate(`/${channel.username}`);
          setQuery('');
          setChannels([]);
        }
      }}
      ref={comboboxRef}
    >
      <div className="relative w-full">
        <Combobox.Input
          className="h-11 w-full rounded-full border-2 border-gray-200 bg-white px-6 py-2 text-sm text-black placeholder-gray-400 shadow-sm transition-all duration-200 ease-in-out focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 dark:border-gray-700 dark:bg-black dark:text-white dark:placeholder-gray-500 dark:focus:border-indigo-400 dark:focus:ring-indigo-400"
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search by shop name..."
          aria-label="Search shops"
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center pr-4 focus:outline-none"
          aria-label="Search"
        >
          {isLoading ? (
            <Spinner className="h-5 w-5 animate-spin text-gray-400 dark:text-gray-500" />
          ) : (
            <SearchIcon className="h-5 w-5 text-gray-400 dark:text-gray-500 fill-current" />
          )}
        </Combobox.Button>

        {query && (
          <Combobox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900">
            {filteredChannels.length > 0 ? (
              filteredChannels.map((channel) => (
                <Combobox.Option
                  key={channel.id}
                  value={channel}
                  className={({ active }) =>
                    clsm(
                      'relative cursor-pointer select-none py-3 px-4 transition-colors duration-150',
                      active ? 'bg-indigo-100 dark:bg-indigo-900' : 'text-gray-900 dark:text-white'
                    )
                  }
                >
                  {({ active }) => (
                    <div className="flex items-center">
                      <UserAvatar
                        avatarSrc={getAvatarSrc(channel)}
                        className="h-8 w-8"
                        size="sm"
                        profileColor={channel.color}
                        username={channel.username}
                      />
                      <div className="ml-3 flex-1">
                        <p className={clsm('font-medium', active ? 'text-indigo-800 dark:text-indigo-200' : 'text-gray-900 dark:text-white')}>
                          {channel.username}
                        </p>
                        <p className={clsm('text-sm truncate', active ? 'text-indigo-600 dark:text-indigo-300' : 'text-gray-500 dark:text-gray-400')}>
                          {channel.description}
                        </p>
                      </div>
                    </div>
                  )}
                </Combobox.Option>
              ))
            ) : (
              <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                No results found{query ? ` for "${query}"` : ''}.
              </div>
            )}
          </Combobox.Options>
        )}

        {error && (
          <div className="mt-2 px-4 py-2 text-sm text-red-600 dark:text-red-400 bg-red-100 dark:bg-red-900 rounded-md">
            {error}
          </div>
        )}
      </div>
    </Combobox>
  );
};

export default Searchbar;
