import React, { useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';
import { FaChevronDown, FaChevronUp, FaGavel, FaShippingFast, FaUser } from 'react-icons/fa';
import Button from '../../../../components/Button';
import AuctionContainer from './AuctionContainer';
import PropTypes from 'prop-types';

const formatPrice = (price, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);

const AuctionDetailItem = React.memo(({ icon, label, value }) => (
  <div className="flex items-center space-x-2 p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
    {icon}
    <div className="flex flex-col">
      <span className="text-sm text-gray-500 dark:text-gray-400">{label}</span>
      <span className="font-semibold text-gray-900 dark:text-gray-100">{value}</span>
    </div>
  </div>
));

AuctionDetailItem.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

const StreamerAuction = () => {
  const auctionRef = useRef(null);

  const {
    dispatchAuctionState,
    isExpanded,
    auctionTitle,
    auctionPrice,
    auctionShippingRate,
    auctionBidCount,
    currentBidderUsername,
  } = useAuction();

  const handleExpandClick = useCallback(() => {
    dispatchAuctionState({ isExpanded: !isExpanded });
  }, [dispatchAuctionState, isExpanded]);

  useEffect(() => {
    if (auctionRef.current) {
      dispatchAuctionState({ auctionRef: auctionRef.current });
    }
  }, [dispatchAuctionState]);

  const displayedBidderUsername = currentBidderUsername || 'No bids yet';

  return (
    <AuctionContainer ref={auctionRef} className="w-full max-w-md mx-auto">
      <Button
        variant="primaryText"
        className="flex items-center justify-between w-full px-6 py-4 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
        onClick={handleExpandClick}
        aria-expanded={isExpanded}
        aria-controls="StreamerAuctionContainer"
      >
        <span className="text-lg font-semibold text-gray-800 dark:text-gray-200">
          {isExpanded ? 'Hide Auction Details' : 'View Auction Details'}
        </span>
        {isExpanded ? (
          <FaChevronUp className="w-5 h-5 text-gray-600 dark:text-gray-400" />
        ) : (
          <FaChevronDown className="w-5 h-5 text-gray-600 dark:text-gray-400" />
        )}
      </Button>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key="StreamerAuctionContainer"
            id="StreamerAuctionContainer"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-6">
              {auctionTitle || 'Auction Details'}
            </h2>
            <div className="grid sm:grid-cols-1 grid-cols-2 gap-4">
              {auctionPrice && (
                <AuctionDetailItem
                  icon={<FaGavel className="w-6 h-6 text-blue-500" />}
                  label="Current Bid"
                  value={formatPrice(auctionPrice)}
                />
              )}
              {auctionShippingRate && (
                <AuctionDetailItem
                  icon={<FaShippingFast className="w-6 h-6 text-green-500" />}
                  label="Shipping Rate"
                  value={formatPrice(auctionShippingRate)}
                />
              )}
              <AuctionDetailItem
                icon={<FaGavel className="w-6 h-6 text-purple-500" />}
                label="Total Bids"
                value={auctionBidCount.toLocaleString()}
              />
              <AuctionDetailItem
                icon={<FaUser className="w-6 h-6 text-orange-500" />}
                label="Current Bidder"
                value={displayedBidderUsername}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </AuctionContainer>
  );
};

export default React.memo(StreamerAuction);
