import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clsm } from '../../../../utils';
import StreamerFlashSale from './StreamerFlashSale';
import ViewerFlashSale from './ViewerFlashSale';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useChat } from '../../../../contexts/Chat';
import { useFlashSale } from '../../../../contexts/StreamManagerActions/FlashSale';

const FlashSale = ({ shouldRenderInTab = false }) => {
  const { pathname } = useLocation();
  const { isActiveFlashSale } = useFlashSale();
  const { isModerator } = useChat();
  const { isDesktopView, isLandscape } = useResponsiveDevice();
  const isStreamManagerPage = pathname === '/manager';

  return (
    <div
    className={clsm([
      !isStreamManagerPage && [
        'no-scrollbar',
        'overflow-y-auto',
        'supports-overlay:overflow-y-overlay',
        !isDesktopView && ['pb-20', 'h-full']
      ],
      'w-full',
      'absolute',
      isDesktopView && 'pt-3',
      isLandscape && 'mb-[110px]',
      !isActiveFlashSale ? 'z-0' : 'z-50'
    ])}
    >
      {isModerator && isStreamManagerPage && <StreamerFlashSale />}
      {!isStreamManagerPage && (
        <ViewerFlashSale shouldRenderInTab={shouldRenderInTab} />
      )}
    </div>
  );
};

FlashSale.propTypes = {
  shouldRenderInTab: PropTypes.bool
};

export default FlashSale;
