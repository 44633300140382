import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import { channelAPI } from '../../../../api';
import { userManagement as $content } from '../../../../content';

const ResetPasswordRequestForm = ({ onSuccess }) => (
  <div className="rounded-lg">
    <div className=" w-96 dark:bg-darkMode-gray-dark p-10 sm:px-8 xs:px-8 rounded-lg">
      <Button
        to="/"
        type="nav"
        className="shadow-none mb-8 bg-transparent hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent  dark:bg-darkMode-gray-dark"
      >
        <img
          className="w-52 h-auto dark:hidden "
          src="/logo-ls.svg"
          alt="logo light"
        />
        <img
          className="w-52 h-auto hidden dark:block  dark:bg-darkMode-gray-dark"
          src="/logo-ls-dark.svg"
          alt="logo dark"
        />
      </Button>

      <Form
        inputsData={{ email: {} }}
        onSuccess={onSuccess}
        submitHandler={channelAPI.sendResetPasswordRequest}
        submitText={$content.continue}
        title={$content.reset_password_page.title}
        footer={<Link to="/login">{$content.return_to_login}</Link>}
      />
    </div>
  </div>
);

ResetPasswordRequestForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default ResetPasswordRequestForm;
