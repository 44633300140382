import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import useContextHook from '../useContextHook';
import useLocalStorage from '../../hooks/useLocalStorage';
import { extractChannelIdfromChannelArn } from '../../utils';
import { pack, unpack } from '../../helpers/streamActionHelpers';
import { useChannel } from '../Channel';
import { useUser } from '../User';
import { useLocation } from 'react-router-dom';
import {
  NUM_MILLISECONDS_TO_SHOW_FLASH_SALE_RESULTS,
  EXTRA_TIME_TO_WAIT_FOR_END_FLASH_SALE_EVENT
} from '../../constants';

const COMPOSER_HEIGHT = 92;
const SPACE_BETWEEN_COMPOSER_AND_FLASH_SALE = 100;

const Context = createContext(null);
Context.displayName = 'FlashSale';

const FLASHSALE_TAB_LABEL = 'Live flash sale';

const initialFlashSaleProps = {
  purchases: [],
  isActiveFlashSale: false,
  flashSaleTitle: null,
  flashSaleDuration: 0,
  flashSaleExpiry: null,
  flashSaleStartTime: null,
  flashSaleDelay: 0,
  flashSaleTerms: null,
  flashSaleDescription: null,
  flashSaleDiscountPrice: null,
  flashSalePrice: null,
  flashSaleQuantity: null,
  flashSaleShippingRate: null,
  flashSalePurchaseCount: 0
};

const initialFlashSaleState = {
  isSubmitting: false,
  isPurchasing: true,
  isExpanded: true,
  flashSaleHeight: 0,
  flashSaleRef: undefined,
  hasListReordered: false,
  showFinalResults: false,
  hasFlashSaleEnded: false,
  noPurchasesCaptured: false,
  tieFound: false,
  hasScrollbar: false
};

const localStorageInitialState = {
  ...initialFlashSaleProps,
  purchasers: {}
};

export const Provider = ({ children }) => {
  const forceResetFlashSalePropsTimerRef = useRef();
  const stopFlashSaleTimerRef = useRef();
  const [composerRefState, setComposerRefState] = useState();
  const shouldAnimateListRef = useRef(false);
  const [selectedOption, setSelectedOption] = useState();
  const { channelData } = useChannel();
  const { username, channelArn = '', isViewerBanned } = channelData || {};
  const { userData, isSessionValid } = useUser();
  const channelId = extractChannelIdfromChannelArn(channelArn);
  const isModerator = channelId === userData?.trackingId;
  const { pathname } = useLocation();
  const isStreamManagerPage = pathname === '/manager';

  // FlashSale UI states
  const [flashSaleState, dispatchFlashSaleState] = useReducer(
    (prevState, nextState) => ({ ...prevState, ...nextState }),
    initialFlashSaleState
  );
  // Active flash sale props
  const [flashSaleProps, dispatchFlashSaleProps] = useReducer(
    (prevState, nextState) => ({ ...prevState, ...nextState }),
    initialFlashSaleProps
  );

  const flashSaleHasEnded = useCallback(() => {
    dispatchFlashSaleState({ hasFlashSaleEnded: true });
  }, []);

  const {
    purchases,
    isActiveFlashSale,
    flashSaleTitle,
    flashSaleDuration,
    flashSaleExpiry,
    flashSaleStartTime,
    flashSaleDelay,
    flashSaleTerms,
    flashSaleDescription,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount
  } = flashSaleProps;

  const {
    isSubmitting,
    isPurchasing,
    isExpanded,
    flashSaleHeight,
    flashSaleRef,
    showFinalResults,
    hasListReordered,
    hasFlashSaleEnded,
    noPurchasesCaptured,
    tieFound,
    hasScrollbar
  } = flashSaleState;

  const { value: savedFlashSaleData, set: saveFlashSaleDataToLocalStorage } =
    useLocalStorage({
      key: username,
      initialValue: localStorageInitialState,
      options: {
        keyPrefix: 'flash_sale',
        serialize: pack,
        deserialize: unpack
      }
    });

  const showFinalResultActionButton = () => ({
    flashSaleDuration: 10,
    flashSaleExpiry: new Date(Date.now() + 10 * 1000).toISOString()
  });

  const updateFlashSaleData = ({
    purchases,
    flashSaleTitle,
    flashSaleDuration,
    flashSaleExpiry,
    flashSaleStartTime,
    isActiveFlashSale,
    flashSaleDelay = 0,
    flashSaleTerms,
    flashSaleDescription,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount
  }) => {
    const props = {
      ...(flashSaleDuration && { flashSaleDuration }),
      ...(purchases && { purchases }),
      ...(flashSaleExpiry && { flashSaleExpiry }),
      ...(isActiveFlashSale && { isActiveFlashSale }),
      ...(flashSaleStartTime && { flashSaleStartTime }),
      ...(flashSaleDelay && { flashSaleDelay }),
      ...(flashSaleTitle && { flashSaleTitle }),
      ...(flashSaleTerms && { flashSaleTerms }),
      ...(flashSaleDescription && { flashSaleDescription }),
      ...(flashSaleDiscountPrice && { flashSaleDiscountPrice }),
      ...(flashSalePrice && { flashSalePrice }),
      ...(flashSaleQuantity && { flashSaleQuantity }),
      ...(flashSaleShippingRate && { flashSaleShippingRate }),
      ...(flashSalePurchaseCount && { flashSalePurchaseCount })
    };

    dispatchFlashSaleProps(props);
  };

  const clearFlashSaleLocalStorage = useCallback(() => {
    saveFlashSaleDataToLocalStorage(localStorageInitialState);
  }, [saveFlashSaleDataToLocalStorage]);

  const resetFlashSaleProps = useCallback(() => {
    if (stopFlashSaleTimerRef.current)
      clearTimeout(stopFlashSaleTimerRef.current);
    clearFlashSaleLocalStorage();
    dispatchFlashSaleProps(initialFlashSaleProps);
    dispatchFlashSaleState(initialFlashSaleState);
    setSelectedOption();
    shouldAnimateListRef.current = false;
    stopFlashSaleTimerRef.current = undefined;
    forceResetFlashSalePropsTimerRef.current = undefined;
  }, [clearFlashSaleLocalStorage]);

  const hasMounted = useRef(false);

  useEffect(() => {
    if (!channelData || hasMounted.current) return;

    if (
      isModerator &&
      isStreamManagerPage &&
      savedFlashSaleData?.isActiveFlashSale
    ) {
      hasMounted.current = true;
      const {
        flashSaleDuration,
        flashSaleStartTime,
        purchases: options,
        flashSaleExpiry,
        flashSaleTitle,
        flashSaleTerms,
        flashSaleDescription,
        flashSaleDiscountPrice,
        flashSalePrice,
        flashSaleQuantity,
        flashSaleShippingRate,
        flashSalePurchaseCount
      } = savedFlashSaleData;

      updateFlashSaleData({
        flashSaleExpiry,
        flashSaleStartTime,
        flashSaleDuration,
        isActiveFlashSale: true,
        purchases: options,
        flashSaleDelay,
        flashSaleTitle,
        flashSaleTerms,
        flashSaleDescription,
        flashSaleDiscountPrice,
        flashSalePrice,
        flashSaleQuantity,
        flashSaleShippingRate,
        flashSalePurchaseCount
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelData, savedFlashSaleData]);

  useEffect(() => {
    let timeout;

    if (savedFlashSaleData?.hasFlashSaleEnded && !hasFlashSaleEnded) {
      flashSaleHasEnded();
      return;
    }

    if (
      flashSaleDuration &&
      !hasFlashSaleEnded &&
      !savedFlashSaleData?.hasFlashSaleEnded
    ) {
      const flashSaleTime = flashSaleDuration * 1000 - flashSaleDelay * 1000;

      timeout = setTimeout(() => {
        dispatchFlashSaleState({ hasFlashSaleEnded: true });
      }, flashSaleTime);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    flashSaleDelay,
    flashSaleDuration,
    hasFlashSaleEnded,
    flashSaleHasEnded,
    savedFlashSaleData?.hasFlashSaleEnded
  ]);

  useEffect(() => {
    if (showFinalResults) {
      dispatchFlashSaleState({ hasListReordered: true });
    }
  }, [showFinalResults]);

  const checkForTie = (purchases) => {
    const maxPurchase = Math.max(
      ...purchases.map((purchase) => purchase.count)
    );
    const count = purchases.filter(
      (purchase) => purchase.count === maxPurchase
    ).length;

    return count > 1;
  };

  useEffect(() => {
    if (
      hasFlashSaleEnded &&
      !noPurchasesCaptured &&
      !showFinalResults &&
      !tieFound
    ) {
      const noPurchases = purchases.every(
        (purchase) => purchase.count === 0
      );
      const hasTie = checkForTie(purchases);

      if (noPurchases) {
        dispatchFlashSaleState({ noPurchasesCaptured: true });
      } else {
        if (hasTie) {
          dispatchFlashSaleState({ tieFound: true });
        } else {
          dispatchFlashSaleState({ showFinalResults: true });
        }
        const sortedPurchases = purchases.sort((a, b) =>
          a.count < b.count ? 1 : a.count > b.count ? -1 : 0
        );
        dispatchFlashSaleProps({
          purchases: sortedPurchases
        });
      }
    }
  }, [
    hasFlashSaleEnded,
    noPurchasesCaptured,
    showFinalResults,
    tieFound,
    purchases
  ]);

  const containerMinHeight = `${
    flashSaleHeight + SPACE_BETWEEN_COMPOSER_AND_FLASH_SALE + COMPOSER_HEIGHT
  }px`;

  useEffect(() => {
    if (flashSaleRef) {
      dispatchFlashSaleState({ flashSaleHeight: flashSaleRef.offsetHeight });
    }
  }, [flashSaleRef, isExpanded]);

  const getFlashSaleDetails = (purchases) => {
    return purchases.reduce(
      (acc, purchase) => {
        if (!acc.highestCountOption) {
          acc.highestCountOption = purchase;
        } else {
          if (purchase.count > acc.highestCountOption.count) {
            acc.highestCountOption = purchase;
          }
        }

        acc.totalPurchases += purchase.count;
        return acc;
      },
      { totalPurchases: 0, highestCountOption: null }
    );
  };

  const { highestCountOption, totalPurchases } = getFlashSaleDetails(purchases);

  const savePurchasesToLocalStorage = useCallback(
    (currentPurchases, purchaser) => {
      saveFlashSaleDataToLocalStorage({
        ...savedFlashSaleData,
        purchases: currentPurchases,
        purchasers: {
          ...savedFlashSaleData.purchasers,
          ...purchaser
        }
      });
    },
    [saveFlashSaleDataToLocalStorage, savedFlashSaleData]
  );

  const updateSavedFlashSalePropsOnTimerExpiry = useCallback(() => {
    const { flashSaleDuration, flashSaleExpiry } =
      showFinalResultActionButton();
    saveFlashSaleDataToLocalStorage({
      ...savedFlashSaleData,
      flashSaleDuration,
      flashSaleExpiry,
      hasFlashSaleEnded: true
    });
  }, [saveFlashSaleDataToLocalStorage, savedFlashSaleData]);

  const isAbleToPurchase =
    isPurchasing &&
    !showFinalResults &&
    !noPurchasesCaptured &&
    !isViewerBanned;

  const shouldRenderRadioInput =
    isAbleToPurchase && isSessionValid && !isStreamManagerPage;

  const shouldRenderPurchaseButton = isAbleToPurchase && !!userData;

  const shouldRenderProgressbar =
    !showFinalResults &&
    !noPurchasesCaptured &&
    !tieFound &&
    flashSaleStartTime;

  const endFlashSaleAndResetFlashSaleProps = useCallback(() => {
    clearTimeout(forceResetFlashSalePropsTimerRef.current);
    dispatchFlashSaleProps({ isActiveFlashSale: false });
    setTimeout(resetFlashSaleProps, 100);
    hasMounted.current = false;
  }, [resetFlashSaleProps]);

  useEffect(() => {
    if (hasFlashSaleEnded) {
      if (forceResetFlashSalePropsTimerRef.current) {
        clearTimeout(forceResetFlashSalePropsTimerRef.current);
      } else {
        forceResetFlashSalePropsTimerRef.current = setTimeout(() => {
          if (isActiveFlashSale) endFlashSaleAndResetFlashSaleProps();
        }, NUM_MILLISECONDS_TO_SHOW_FLASH_SALE_RESULTS + EXTRA_TIME_TO_WAIT_FOR_END_FLASH_SALE_EVENT);
      }
    }
  }, [
    endFlashSaleAndResetFlashSaleProps,
    isActiveFlashSale,
    hasFlashSaleEnded
  ]);

  useEffect(() => {
    const isFlashSaleExpired =
      flashSaleStartTime +
        flashSaleDuration * 1000 +
        NUM_MILLISECONDS_TO_SHOW_FLASH_SALE_RESULTS +
        EXTRA_TIME_TO_WAIT_FOR_END_FLASH_SALE_EVENT <
      Date.now();

    if (isFlashSaleExpired && isActiveFlashSale) {
      endFlashSaleAndResetFlashSaleProps();
    }
  }, [
    flashSaleDuration,
    flashSaleStartTime,
    isActiveFlashSale,
    endFlashSaleAndResetFlashSaleProps
  ]);

  const value = useMemo(
    () => ({
      isExpanded,
      flashSaleHeight,
      containerMinHeight,
      showFinalResults,
      purchases,
      highestCountOption,
      totalPurchases,
      hasListReordered,
      shouldAnimateListRef,
      isActiveFlashSale,
      flashSaleStartTime,
      flashSaleDuration,
      selectedOption,
      setSelectedOption,
      isSubmitting,
      isPurchasing,
      updateFlashSaleData,
      flashSaleExpiry,
      resetFlashSaleProps,
      noPurchasesCaptured,
      tieFound,
      flashSaleDelay,
      clearFlashSaleLocalStorage,
      flashSaleTabLabel: FLASHSALE_TAB_LABEL,
      showFinalResultActionButton,
      hasFlashSaleEnded,
      stopFlashSaleTimerRef,
      flashSaleHasEnded,
      savePurchasesToLocalStorage,
      savedFlashSaleData,
      saveFlashSaleDataToLocalStorage,
      updateSavedFlashSalePropsOnTimerExpiry,
      hasScrollbar,
      composerRefState,
      setComposerRefState,
      dispatchFlashSaleState,
      shouldRenderRadioInput,
      shouldRenderPurchaseButton,
      endFlashSaleAndResetFlashSaleProps,
      hasPurchases: purchases.length > 0,
      shouldRenderProgressbar,
      flashSaleTitle,
      flashSaleTerms,
      flashSaleDescription,
      flashSaleDiscountPrice,
      flashSalePrice,
      flashSaleQuantity,
      flashSaleShippingRate,
      flashSalePurchaseCount
    }),
    [
      isExpanded,
      flashSaleHeight,
      containerMinHeight,
      showFinalResults,
      purchases,
      highestCountOption,
      totalPurchases,
      hasListReordered,
      isActiveFlashSale,
      flashSaleStartTime,
      flashSaleDuration,
      selectedOption,
      isSubmitting,
      isPurchasing,
      flashSaleExpiry,
      resetFlashSaleProps,
      noPurchasesCaptured,
      tieFound,
      flashSaleDelay,
      clearFlashSaleLocalStorage,
      hasFlashSaleEnded,
      flashSaleHasEnded,
      savePurchasesToLocalStorage,
      savedFlashSaleData,
      saveFlashSaleDataToLocalStorage,
      updateSavedFlashSalePropsOnTimerExpiry,
      hasScrollbar,
      composerRefState,
      shouldRenderRadioInput,
      shouldRenderPurchaseButton,
      endFlashSaleAndResetFlashSaleProps,
      shouldRenderProgressbar,
      flashSaleTitle,
      flashSaleTerms,
      flashSaleDescription,
      flashSaleDiscountPrice,
      flashSalePrice,
      flashSaleQuantity,
      flashSaleShippingRate,
      flashSalePurchaseCount
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useFlashSale = () => useContextHook(Context);
