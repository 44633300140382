import React, { useState, useEffect } from 'react';
import { OpenInNew } from '../../assets/icons';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import {
  createStripeLoginLink,
  hasStripeConnectId,
  getLastPayout
} from '../../api/payment';
import { useNotif } from '../../contexts/Notification';
import PropTypes from 'prop-types';

function PayoutInfoBox({ title, content, isLoading }) {
  return (
    <div className="bg-indigo-50 dark:bg-gray-800 rounded-lg p-4 shadow-md flex flex-col justify-between h-full">
      <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">
        {title}
      </h2>
      <p className="text-lg font-bold text-blue-600 dark:text-blue-400">
        {isLoading ? <Spinner size="small" aria-label={`Loading ${title}`} /> : content}
      </p>
    </div>
  );
}

PayoutInfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

function SellerPayments() {
  const { notifyError } = useNotif();
  const [isLoading, setIsLoading] = useState(false);
  const [lastPayout, setLastPayout] = useState(null);
  // const [nextPayoutDate, setNextPayoutDate] = useState('');
  const [isLastPayoutLoading, setIsLastPayoutLoading] = useState(false);

  useEffect(() => {
    const fetchLastPayout = async () => {
      setIsLastPayoutLoading(true);
      try {
        const response = await getLastPayout();
        setLastPayout(response.result);
      } catch (error) {
        console.error('Error fetching last payout:', error);
      } finally {
        setIsLastPayoutLoading(false);
      }
    };
    fetchLastPayout();
  }, []);

  useEffect(() => {
    const checkStripeAccount = async () => {
      try {
        await hasStripeConnectId();
      } catch (error) {
        console.error('Error checking Stripe account ID:', error);
      }
    };

    // const calculateNextPayoutDate = () => {
    //   const today = new Date();
    //   const dayOfWeek = today.getDay();
    //   const daysUntilNextFriday = (12 - dayOfWeek) % 7 || 7;
    //   const nextFriday = new Date(today);
    //   nextFriday.setDate(today.getDate() + daysUntilNextFriday);
    //   const formattedDate = nextFriday.toLocaleDateString('en-US', {
    //     year: 'numeric',
    //     month: 'short',
    //     day: 'numeric'
    //   });
    //   // setNextPayoutDate(formattedDate);
    // };

    checkStripeAccount();
    // calculateNextPayoutDate();
  }, []);

  const lastPayoutAmount = lastPayout
    ? (lastPayout.lastPayoutAmount / 100).toFixed(2)
    : '0.00';

  const lastPayoutDate = lastPayout
    ? new Date(lastPayout.lastPayoutDate * 1000).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
    : '---';

  const handleStripeLogin = async () => {
    setIsLoading(true);
    try {
      const response = await createStripeLoginLink();
      if (response.result?.loginLinkUrl) {
        window.open(response.result.loginLinkUrl, '_blank', 'noopener,noreferrer');
      } else {
        notifyError('Login link not available. Please contact support.');
      }
    } catch (error) {
      notifyError('Something went wrong while logging in. Please try again.');
      console.error('Error during seller login:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-black min-h-screen">
      <div className="max-w-2xl mx-auto px-4 py-24">
        <header className="text-start mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800 dark:text-white mb-0">
            Payment Dashboard
          </h1>
          <Button
            onClick={handleStripeLogin}
            variant="primary"
            className="rounded-full px-6 py-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner size="small" aria-label="Loading dashboard" />
            ) : (
              <>
                Access Stripe Dashboard
                <OpenInNew className="w-5 h-5 ml-2 inline-block" />
              </>
            )}
          </Button>
        </header>
        
        <div className="bg-white dark:bg-black rounded-xl shadow p-6">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
              Payout Summary
            </h2>
            <div className="grid sm:grid-cols-1 grid-cols-1 gap-6">
              <PayoutInfoBox
                title="Last Payout"
                content={`$${lastPayoutAmount} received on ${lastPayoutDate}`}
                isLoading={isLastPayoutLoading}
              />
            </div>
          </section>
          
          <section className="text-gray-700 dark:text-gray-300 mb-6">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-3">
              Payout Details
            </h2>
            <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4">
              <p className="mb-4">
                Your earnings are automatically sent to your bank account every Friday.
                Note that there is a 7-day security hold on all transactions.
              </p>
              <p>
                You can view detailed payout information, track your earnings, and update
                your payment details in your Stripe dashboard.
              </p>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default SellerPayments;
