import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { channelAPI } from '../../../../api';
import { useNotif } from '../../../../contexts/Notification';
import { userManagement as $content } from '../../../../content';
import Button from '../../../../components/Button';

const ResetPasswordRequestConfirmation = ({ email }) => {
  const { notifySuccess, notifyError } = useNotif();

  const resend = async () => {
    const userData = { email };
    const { result, error } =
      await channelAPI.sendResetPasswordRequest(userData);

    if (result) {
      notifySuccess($content.notification.success.resent_confirmation);
    }

    if (error) {
      notifyError($content.notification.error.unexpected_error_occurred);
    }
  };

  return (
    <div className="rounded-lg">
      <div className=" sm:p-6 md:p-8 p-10 rounded-xl max-w-lg mx-auto text-center space-y-6 ">
        <Link to="/">
          <img
            className="w-40 h-auto mx-auto dark:hidden"
            src="/logo-ls.svg"
            alt="Light Logo"
          />
          <img
            className="w-40 h-auto mx-auto hidden dark:block dark:bg-darkMode-gray-dark"
            src="/logo-ls-dark.svg"
            alt="Dark Logo"
          />
        </Link>
        <h2 className="sm:text-2xl text-3xl font-bold text-gray-800 dark:text-white">
          {$content.reset_password_page.title}
        </h2>
        <p className="text-base text-gray-600 dark:text-gray-300">
          {$content.reset_password_page.email_link_sent}
        </p>
        <div className="flex flex-col items-center space-y-4">
          <span className="text-sm text-gray-600 dark:text-gray-400 font-semibold">
            {$content.did_not_receive_email}
          </span>
          <Button onClick={resend} type="button" variant="primary">
            {$content.resend}
          </Button>
          <Link
            to="/login"
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600 hover:underline transition duration-200 ease-in-out"
          >
            {$content.return_to_login}
          </Link>
        </div>
      </div>
    </div>
  );
};

ResetPasswordRequestConfirmation.propTypes = {
  email: PropTypes.string.isRequired
};

export default ResetPasswordRequestConfirmation;
