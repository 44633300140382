import { apiBaseUrl, authFetch } from './utils';

export const getCustomerOrders = async () => {
  try {
    const { result, error } = await authFetch({
      url: `${apiBaseUrl}/orders/customer`,
      method: 'GET'
    });
    if (error) {
      throw new Error('Failed to fetch customer orders: ' + error.message);
    }
    return result;
  } catch (error) {
    console.error('Error in getCustomerOrders:', error);
    throw error;
  }
};

export const getSellerOrders = async () => {
  try {
    const { result, error } = await authFetch({
      url: `${apiBaseUrl}/orders/seller`,
      method: 'GET'
    });
    if (error) {
      throw new Error('Failed to fetch seller orders: ' + error.message);
    }
    return result;
  } catch (error) {
    console.error('Error in getSellerOrders:', error);
    throw error;
  }
};
