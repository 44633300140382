import { clsm } from '../../../utils';
import Chat from '../../Channel/Chat';
import Poll from '../../Channel/Chat/Poll/Poll';
import Auction from '../../Channel/Chat/Auction/Auction';
import FlashSale from '../../Channel/Chat/FlashSale/FlashSale';
import { usePoll } from '../../../contexts/StreamManagerActions/Poll';
import { useAuction } from '../../../contexts/StreamManagerActions/Auction';
import { useFlashSale } from '../../../contexts/StreamManagerActions/FlashSale';

const StreamManagerChat = () => {
  const { isActive, containerMinHeight, hasVotes } = usePoll();
  const { isActiveAuction, containerMinHeightAuction, hasBids } = useAuction();
  const { isActiveFlashSale, containerMinHeightFlashSale, hasPurchases } =
    useFlashSale();

  return (
    <section
      style={{
        ...((isActive || isActiveAuction || isActiveFlashSale) && {
          minHeight:
            containerMinHeight ||
            containerMinHeightAuction ||
            containerMinHeightFlashSale
        })
      }}
      className={clsm([
        'relative',
        'bg-lightMode-gray-extraLight',
        'dark:bg-darkMode-gray-dark',
        'flex-1',
        'flex',
        'overflow-hidden',
        'rounded-3xl',
        'w-full'
      ])}
    >
      {hasPurchases && <FlashSale />}
      {hasBids && <Auction />}
      {hasVotes && <Poll />}
      <Chat />
    </section>
  );
};

export default StreamManagerChat;
