import { createContext, useMemo } from 'react';
import { Tab } from '@headlessui/react';
import { FiUser, FiVideo, FiSettings } from 'react-icons/fi'; // Import react-icons
import { AccountSettings, ChannelSettings, StreamSettings } from './settingsSections';
import { clsm } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';
import withVerticalScroller from '../../components/withVerticalScroller';
import useContextHook from '../../contexts/useContextHook';

const Context = createContext(null);
Context.displayName = 'SettingsOrientation';
export const useSettingsOrientation = () => useContextHook(Context);
export const SETTINGS_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const Settings = () => {
  const { userData } = useUser();
  const isSeller = userData?.isSeller || false;
  const { isDefaultResponsiveView } = useResponsiveDevice();
  const settingsFormOrientation = useMemo(
    () => isDefaultResponsiveView ? SETTINGS_ORIENTATION.VERTICAL : SETTINGS_ORIENTATION.HORIZONTAL,
    [isDefaultResponsiveView]
  );

  const tabConfig = [
    { name: 'Profile', icon: FiUser },
    ...(isSeller ? [{ name: 'Stream', icon: FiVideo }] : []),
    { name: 'Account', icon: FiSettings }
  ];

  return (
    userData && (
      <article className="bg-white dark:bg-black min-h-screen py-24">
        <div className="max-w-7xl mx-auto sm:px-4 md:px-6 px-8">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-8">Settings</h1>
          <Context.Provider value={settingsFormOrientation}>
            <Tab.Group>
              <div className="grid grid-cols-12 gap-x-5 sm:gap-x-0 sm:gap-y-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                <aside className="sm:py-6 sm:px-2 md:py-6 md:px-2 lg:py-6 lg:px-2 px-0 py-0 col-span-3 sm:col-span-1 md:grid-cols-1 lg:grid-cols-1">
                  <Tab.List className="space-y-1">
                    {tabConfig.map(({ name, icon: Icon }) => (
                      <Tab
                        key={name}
                        className={({ selected }) =>
                          clsm([
                            'group flex items-center px-3 py-2 text-sm font-medium rounded-md w-full',
                            selected
                              ? 'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white'
                              : 'text-gray-600 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800'
                          ])
                        }
                      >
                        <Icon className="mr-3 h-5 w-5" />
                        {name}
                      </Tab>
                    ))}
                  </Tab.List>
                </aside>

                <main className="space-y-6 px-6 sm:px-0 col-span-9">
                  <Tab.Panels>
                    <Tab.Panel>
                      <ChannelSettings userIsSeller={isSeller} />
                    </Tab.Panel>
                    {isSeller && (
                      <Tab.Panel>
                        <StreamSettings />
                      </Tab.Panel>
                    )}
                    <Tab.Panel>
                      <AccountSettings />
                    </Tab.Panel>
                  </Tab.Panels>
                </main>
              </div>
            </Tab.Group>
          </Context.Provider>
        </div>
      </article>
    )
  );
};

export default withVerticalScroller(Settings);