import { useCallback, useEffect, useState } from 'react';

import { channelDirectory as $channelDirectoryContent } from '../../../../../content';
import { getAvatarSrc } from '../../../../../helpers';
import { getLiveChannels } from '../../../../../api/channels';
import { useNotif } from '../../../../../contexts/Notification';
import SidebarChannelCard from './SidebarChannelCard';
import GridLayout from './SidebarGridLayout';
import useForceLoader from '../../../../../hooks/useForceLoader';
import PropTypes from 'prop-types';

const $content = $channelDirectoryContent.live_streams_section;
const $channelPageNotifications = $channelDirectoryContent.notification;

const LiveStreamsSection = ({ isSidebarOpen, toggleSidebar }) => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const { notifyError } = useNotif();
  const isLoadingForced = useForceLoader();

  const { channels: liveChannels } = data || {};
  const hasLiveChannels = !!liveChannels?.length;
  const isLoading = (!error && !data) || isLoadingForced;

  const getSectionData = useCallback(async () => {
    setData(undefined);
    setError(undefined);

    const { result, error: fetchError } = await getLiveChannels();

    setData(result);
    setError(fetchError);

    if (fetchError)
      notifyError(
        $channelDirectoryContent.notification.error.error_loading_streams
      );
  }, [notifyError]);

  useEffect(() => {
    getSectionData();
  }, [getSectionData]);

  return (
    <GridLayout
      className={hasLiveChannels ? 'pb-24' : 'min-h-[280px] space-y-3'}
      hasError={!!error}
      hasData={hasLiveChannels}
      isLoading={isLoading}
      loadingError={$channelPageNotifications.error.error_loading_streams}
      noDataText={$content.no_streams_available}
      title={$content.title}
      tryAgainFn={getSectionData}
      tryAgainText={$content.try_again}
      isSidebarOpen={isSidebarOpen}
      toggleSidebar={toggleSidebar}
    >
      {hasLiveChannels &&
        liveChannels.map((data) => {
          const { color, username } = data;

          return (
            <SidebarChannelCard
              avatarSrc={getAvatarSrc(data)}
              bannerSrc={data?.channelAssetUrls?.banner}
              color={color}
              key={data.username}
              username={username}
              isSidebarOpen={isSidebarOpen}
            />
          );
        })}
    </GridLayout>
  );
};

LiveStreamsSection.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default LiveStreamsSection;
