import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-br from-blue-200 to-purple-300">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold text-gray-800 mb-4">
          Coming Soon
        </h1>
        <p className="text-xl text-gray-600">
          We're working hard to finish the development of this page.
        </p>
        <p className="text-xl text-gray-600">
          Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
