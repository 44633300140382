import { motion } from 'framer-motion';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  defaultViewerStreamActionTransition,
  defaultSlideUpVariant
} from '../../../pages/Channel/ViewerStreamActions/viewerStreamActionsTheme';
import { clsm, range } from '../../../utils';
import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import { usePlayerContext } from '../contexts/Player';
import useResizeObserver from '../../../hooks/useResizeObserver';

const PARAGRAPH_BASE_CLASSES = [
  'font-medium',
  'pr-8',
  'py-2.5',
  'uppercase',
  'whitespace-nowrap',
  'text-[0.9rem]',
  'leading-none',
  'tracking-widest',
  'text-white',
  'transition-all',
  'duration-300',
  'opacity-90',
  'hover:opacity-100',
  'flex',
  'items-center',
  'h-[24px]'
];
const DEFAULT_ANIMATION_DURATION = 8;

const Notice = ({
  message = '',
  onClickPlayerHandler,
  shouldShowStream = false,
  title = ''
}) => {
  const messageRef = useRef();
  const marqueeRef = useRef();
  const [maxMessages, setMaxMessages] = useState(0);
  const { isOverlayVisible } = usePlayerContext();

  const animationDuration = `${DEFAULT_ANIMATION_DURATION * maxMessages}s`;

  const updateMaxMessages = useCallback(() => {
    const { width: messageWidth } = messageRef.current.getBoundingClientRect();
    const { width: marqueeWidth } = marqueeRef.current.getBoundingClientRect();
    const maxMessages = Math.ceil(marqueeWidth / messageWidth);

    setMaxMessages(maxMessages);
  }, []);

  useResizeObserver(marqueeRef, updateMaxMessages);

  useLayoutEffect(() => {
    updateMaxMessages();
  }, [updateMaxMessages]);

  return (
    <motion.div
      {...createAnimationProps({
        animations: ['fadeIn-full'],
        customVariants: {
          visible: { 
            ...defaultSlideUpVariant.visible, 
            x: '-50%',
            scale: 1,
            opacity: 1
          },
          hidden: { 
            ...defaultSlideUpVariant.hidden, 
            x: '-50%',
            scale: 0.95,
            opacity: 0
          }
        },
        transition: { 
          ...defaultViewerStreamActionTransition, 
          duration: 0.5,
          ease: 'easeOut'
        }
      })}
      className={clsm([
        'absolute',
        'bottom-10',
        'left-1/2',
        'transform',
        '-translate-x-1/2',
        'lg:w-full',
        'max-w-5xl',
        'px-4',
        'transition-all',
        'w-[90vw]',
        'hover:scale-[1.02]',
        'active:scale-[0.98]',
        'transition-transform',
        'duration-300',
        'cursor-pointer',
        isOverlayVisible && shouldShowStream && ['mb-20', 'lg:mb-[52px]']
      ])}
      onClick={onClickPlayerHandler}
    >
      <div
        className={clsm([
          'flex',
          'items-center',
          'pl-4',
          'h-16',
          'rounded-[2rem]',
          'shadow-[0_8px_32px_rgba(0,0,0,0.2)]',
          'bg-gradient-to-r',
          'from-indigo-600/95',
          'via-purple-600/95',
          'to-fuchsia-600/95',
          'backdrop-blur-lg',
          'border',
          'border-white/20',
          'hover:border-white/30',
          'hover:shadow-[0_12px_40px_rgba(0,0,0,0.25)]',
          'transition-all',
          'duration-300',
          'group'
        ])}
      >
        <div className="relative rounded-full h-10 flex items-center">
          <p
            className={clsm([
              'px-6',
              'py-2',
              'font-bold',
              'rounded-full',
              'shrink-0',
              'truncate',
              'uppercase',
              'xs:max-w-[80%]',
              'text-base',
              'shadow-md',
              'backdrop-blur-md',
              'bg-white',
              'text-indigo-700',
              'ring-2',
              'ring-white/40',
              'group-hover:ring-white/50',
              'group-hover:shadow-lg',
              'transition-all',
              'duration-300',
              'select-none',
              'flex',
              'items-center',
              'h-8'
            ])}
          >
            {title}
          </p>
        </div>

        <div
          className={clsm([
            'flex',
            'overflow-x-hidden',
            'relative',
            'text-white',
            'w-full',
            'ml-8',
            'mr-6',
            'h-full',
            'items-center',
            'group-hover:ml-10',
            'transition-all',
            'duration-300'
          ])}
          ref={marqueeRef}
        >
          <div
            className={clsm([
              'flex',
              'items-center',
              'h-full',
              'animate-marquee-first-part',
              'absolute',
              'left-0',
              'top-0'
            ])}
            style={{ animationDuration }}
          >
            <p
              aria-live="polite"
              className={clsm([PARAGRAPH_BASE_CLASSES])}
              ref={messageRef}
            >
              {message}
            </p>
            {range(maxMessages - 1).map((index) => (
              <p
                className={clsm([PARAGRAPH_BASE_CLASSES])}
                key={`marquee-first-part-message-${index}`}
              >
                {message}
              </p>
            ))}
          </div>
          <div
            className={clsm([
              'flex',
              'items-center',
              'h-full',
              'animate-marquee-second-part',
              'absolute',
              'left-0',
              'top-0'
            ])}
            style={{ animationDuration }}
          >
            {range(maxMessages).map((index) => (
              <p
                className={clsm([PARAGRAPH_BASE_CLASSES])}
                key={`marquee-second-part-message-${index}`}
              >
                {message}
              </p>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

Notice.propTypes = {
  message: PropTypes.string,
  onClickPlayerHandler: PropTypes.func.isRequired,
  shouldShowStream: PropTypes.bool,
  title: PropTypes.string
};

export default Notice;
