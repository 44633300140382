import PropTypes from 'prop-types';

import { clsm, noop } from '../../utils';
import DataUnavailable from './DataUnavailable';
import Spinner from '../../components/Spinner';
import useCurrentPage from '../../hooks/useCurrentPage';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { BREAKPOINTS } from '../../constants';
import { useUser } from '../../contexts/User';



const GridLayout = ({
  children,
  className = '',
  hasError = false,
  hasData = false,
  isLoading = false,
  noDataText = '',
  title = '',
  tryAgainFn = noop,
  tryAgainText = ''
}) => {
  const { currentBreakpoint } = useResponsiveDevice();
  const currentPage = useCurrentPage();
  const isFollowingPage = currentPage === 'following';
  const { isSessionValid } = useUser();

  const PAGE_CENTERED_CONTENT_BASE_CLASSES = [
    'flex-col',
    'flex',
    'items-center',
    'justify-center',
    'w-full',
    'h-full',
    'text-center',
    // 'max-w-7xl',
    'mx-auto',
  ];

  let gridColsClass = 'grid-cols-1'; // Default for xxs to xs
  if (currentBreakpoint >= BREAKPOINTS.sm) gridColsClass = 'grid-cols-1';
  if (currentBreakpoint >= BREAKPOINTS.md) gridColsClass = 'grid-cols-2';
  if (currentBreakpoint >= BREAKPOINTS.lg) gridColsClass = 'grid-cols-3';
  // Adjusting grid columns for xl, xxl, xxxl based on session validity
  if (currentBreakpoint >= BREAKPOINTS.xl) {
    gridColsClass = isSessionValid ? 'grid-cols-4' : 'grid-cols-3';
  }
  if (currentBreakpoint >= BREAKPOINTS.xxl) {
    gridColsClass = isSessionValid ? 'grid-cols-4' : 'grid-cols-3';
  }
  if (currentBreakpoint >= BREAKPOINTS.xxxl) {
    gridColsClass = isSessionValid ? 'grid-cols-4' : 'grid-cols-3';
  }


  return (
    <section
      className={clsm([
        'flex-col',
        'flex',
        'grow',
        // 'max-w-7xl',
        'sm:px-0',
        'w-full',
        'sm:w-full',
        // 'pt-16',
        isFollowingPage && 'h-full', 
        isFollowingPage && 'pt-4',
        hasData && 'space-y-8',
        className
      ])}
    >
      {/* <h2 className={clsm(['text-zinc-900', 'dark:text-white', 'text-3xl', 'sm:text-2xl', 'text-center'])}>{title}</h2> */}
      {!isLoading && hasData && (
        <div
          className={clsm([
            'gap-6',
            'sm:gap-0',
            'grid',
            gridColsClass
          ])}
        >
          {children}
        </div>
      )}
      {!isLoading && !hasData && (
        <div className="flex-grow flex items-center justify-center">
          <DataUnavailable
            className={clsm(PAGE_CENTERED_CONTENT_BASE_CLASSES)}
            noDataText={noDataText}
            hasError={hasError}
            tryAgainFn={tryAgainFn}
            tryAgainText={tryAgainText}
          />
        </div>
      )}
      {isLoading && (
        <div className={clsm([PAGE_CENTERED_CONTENT_BASE_CLASSES])}>
          <Spinner size="large" variant="light" />
        </div>
      )}
    </section>
  );
};

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  hasData: PropTypes.bool,
  isLoading: PropTypes.bool,
  noDataText: PropTypes.string,
  title: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string
};

export default GridLayout;
