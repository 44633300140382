import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../../../../../../utils';
import Input from '../../../../../../components/Input';

const NumberInput = forwardRef(({ dataKey, onChange, className, value, min, max, ...inputProps }, ref) => {
  const handleOnChange = ({ target }) => {
    let newValue = target.value;

    if (/^\d*$/.test(newValue)) {
      if (min !== undefined && newValue < min) {
        newValue = min;
      }
      if (max !== undefined && newValue > max) {
        newValue = max;
      }
      onChange({ [dataKey]: newValue }, dataKey);
    }
  };

  return (
    <Input
      className={clsm(['dark:bg-darkMode-gray-dark', className])}
      onChange={handleOnChange}
      ref={ref}
      value={value}
      type="text"
      inputMode="numeric"
      {...inputProps}
    />
  );
});

NumberInput.propTypes = {
  className: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberInput.defaultProps = {
  className: '',
  value: '',
  min: undefined,
  max: undefined,
};

export default NumberInput;
