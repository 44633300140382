import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';
import { FaGavel, FaUserCircle, FaClock, FaShippingFast, FaDollarSign } from 'react-icons/fa';

import {
  STREAM_ACTION_NAME,
  CHAT_MESSAGE_EVENT_TYPES,
} from '../../../../constants';
import { createAnimationProps } from '../../../../helpers/animationPropsHelper';
import { streamManager as $streamManagerContent } from '../../../../content';
import { useChat } from '../../../../contexts/Chat';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';
import { useUser } from '../../../../contexts/User';
import AnimatedBidItems from './AnimatedBidItems';
import AuctionContainer from './AuctionContainer';
import Button from '../../../../components/Button/Button';
import CountdownTimer from '../../ViewerStreamActions/CountdownTimer';
import Spinner from '../../../../components/Spinner';
import { ChevronDown, ChevronUp } from '../../../../assets/icons';

const $content =
  $streamManagerContent.stream_manager_actions[STREAM_ACTION_NAME.AUCTION];

const ViewerAuction = ({ shouldRenderInTab }) => {
  const { SUBMIT_BID } = CHAT_MESSAGE_EVENT_TYPES;
  const {
    actions: { sendMessage },
  } = useChat();
  const { isTouchscreenDevice } = useResponsiveDevice();
  const { userData = {} } = useUser();
  const { trackingId = '', username = '' } = userData;

  const {
    isSubmitting,
    selectedOptionAuction,
    auctionStartTime,
    auctionDuration,
    dispatchAuctionState,
    showFinalResults,
    hasScrollbar,
    hasAuctionEnded,
    shouldRenderBidButton,
    shouldRenderProgressbar,
    isActiveAuction,
    auctionTitle,
    auctionShippingRate,
    auctionPrice,
    auctionBidCount,
    setSelectedOptionAuction,
    currentBidderUsername,
  } = useAuction();

  const buttonDivControls = useAnimationControls();
  const radioBoxControls = useAnimationControls();
  const auctionRef = useRef();
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (auctionRef.current) {
      dispatchAuctionState({ auctionRef: auctionRef.current });
    }
  }, [dispatchAuctionState]);

  const submitBid = useCallback(async () => {
    dispatchAuctionState({ isSubmitting: true });

    const result = await sendMessage(SUBMIT_BID, {
      bidder: trackingId,
      eventType: SUBMIT_BID,
      option: JSON.stringify(selectedOptionAuction),
      auctionDuration: JSON.stringify(auctionDuration),
      auctionStartTime: JSON.stringify(auctionStartTime),
      auctionPrice: JSON.stringify(auctionPrice),
      auctionBidCount: JSON.stringify(auctionBidCount),
      username,
    });

    if (result) {
      dispatchAuctionState({ isSubmitting: false });
      setSelectedOptionAuction(null);
    }
  }, [
    dispatchAuctionState,
    sendMessage,
    SUBMIT_BID,
    trackingId,
    selectedOptionAuction,
    auctionDuration,
    auctionStartTime,
    auctionPrice,
    auctionBidCount,
    username,
    setSelectedOptionAuction,
  ]);

  const showBidAndProgress = !hasAuctionEnded && !showFinalResults;
  const showBidAndProgressAsFooter = hasScrollbar && !shouldRenderInTab;

  return (
    <>
      <AuctionContainer ref={auctionRef}>
        {/* Header */}
        <div className="flex items-center justify-between p-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-t-lg">
          <h3 className="text-lg font-semibold">{auctionTitle}</h3>
          <button
            className="text-white hover:text-gray-200 focus:outline-none transition duration-300"
            onClick={() => setIsExpanded((prev) => !prev)}
            aria-label={isExpanded ? 'Collapse auction details' : 'Expand auction details'}
          >
            {isExpanded ? (
              <ChevronUp className="w-5 h-5" />
            ) : (
              <ChevronDown className="w-5 h-5" />
            )}
          </button>
        </div>

        {/* Content */}
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="px-3 py-2 bg-white dark:bg-gray-800"
            >
              {/* Current Bid and Shipping */}
              <div className="flex justify-between items-center mb-3 p-2 bg-gray-100 dark:bg-gray-700 rounded-md">
                <div className="text-base font-semibold text-gray-800 dark:text-gray-200">
                  Current Bid: <span className="text-green-600 dark:text-green-400">${auctionPrice}</span>
                </div>
                <div className="flex items-center text-xs text-gray-600 dark:text-gray-400">
                  <FaShippingFast className="w-3 h-3 mr-1" />
                  <span>${auctionShippingRate} Shipping</span>
                </div>
              </div>

              {/* Bid Options */}
              <AnimatePresence>
                <AnimatedBidItems
                  textColor="text-gray-800 dark:text-gray-200"
                  radioBoxControls={radioBoxControls}
                  showBidPercentage
                  currentBidderUsername={currentBidderUsername}
                  auctionTitle={auctionTitle}
                />
              </AnimatePresence>

              {/* Bid Button and Info */}
              {shouldRenderProgressbar && (
                <motion.div
                  {...createAnimationProps({
                    animations: ['fadeIn-full', 'fadeOut-full'],
                    transition: 'bounce',
                    customVariants: {
                      hidden: {
                        height: 0,
                        padding: 0,
                        transition: { duration: 0.1 },
                      },
                      visible: {
                        height: 'auto',
                      },
                    },
                    options: {
                      isVisible: shouldRenderProgressbar,
                    },
                  })}
                  className="mt-3"
                >
                  {/* Bid Button */}
                  {shouldRenderBidButton && (
                    <motion.div animate={buttonDivControls} className="w-full">
                      <Button
                        isDisabled={!selectedOptionAuction || isSubmitting}
                        disableHover={isTouchscreenDevice}
                        ariaLabel={$content.bid}
                        variant="primary"
                        className="w-full py-2 text-sm font-medium rounded-md shadow-md transition-all duration-300 hover:bg-blue-600"
                        onClick={submitBid}
                      >
                        {!isSubmitting ? (
                          <>
                            <FaDollarSign className="inline-block mr-1" />
                            {$content.bid}
                          </>
                        ) : (
                          <Spinner variant="light" />
                        )}
                      </Button>
                    </motion.div>
                  )}

                  {/* Info Row */}
                  <div className="mt-3 grid grid-cols-3 gap-2 text-xs text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 p-2 rounded-md">
                    <div className="flex flex-col items-center">
                      <FaGavel className="w-4 h-4 mb-1 text-blue-500" />
                      <span className="font-medium">{auctionBidCount}</span>
                      <span>Bids</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <FaClock className="w-4 h-4 mb-1 text-green-500" />
                      <CountdownTimer
                        color="gray"
                        duration={auctionDuration}
                        startTime={auctionStartTime}
                      />
                      <span>Remaining</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <FaUserCircle className="w-4 h-4 mb-1 text-purple-500" />
                      <span className="font-medium truncate w-full text-center">
                        {currentBidderUsername || 'N/A'}
                      </span>
                      <span>Top Bidder</span>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </AuctionContainer>

      {/* Footer (if needed) */}
      {showBidAndProgress && showBidAndProgressAsFooter && isActiveAuction && (
        <>
          <div className="w-full h-px bg-gray-300 dark:bg-gray-600 my-2" />
          <footer className="w-full bg-gray-100 dark:bg-gray-800 rounded-b-lg p-2">
            <div className="flex justify-around items-center text-xs text-gray-700 dark:text-gray-300">
              <div className="flex flex-col items-center">
                <FaGavel className="w-4 h-4 mb-1" />
                <span className="font-medium">{auctionBidCount}</span>
                <span>Bids</span>
              </div>
              <div className="flex flex-col items-center">
                <FaClock className="w-4 h-4 mb-1" />
                <CountdownTimer
                  color="gray"
                  duration={auctionDuration}
                  startTime={auctionStartTime}
                />
                <span>Remaining</span>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

ViewerAuction.propTypes = {
  shouldRenderInTab: PropTypes.bool.isRequired,
};

export default ViewerAuction;