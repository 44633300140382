import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from './formElements/TextArea';
import Button from '../../../components/Button';

const EditChannelDescription = ({
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  aboutChannel = ''
}) => {
  const [description, setDescription] = useState(aboutChannel);
  const [errorMessage, setErrorMessage] = useState('');

  const MAX_CHAR_COUNT = 500;

  const handleSubmit = () => {
    // Reset error message
    setErrorMessage('');

    // Perform validation
    if (description.trim() === '') {
      setErrorMessage('Description cannot be empty');
      return;
    }

    if (description.length > MAX_CHAR_COUNT) {
      setErrorMessage('Description cannot exceed 500 characters');
      return;
    }

    // Perform validation
    if (description.trim() === '') {
      alert('Description cannot be empty!');
      return;
    }

    // Perform sanitization (for example, remove any HTML tags)
    const sanitizedDescription = description.replace(/(<([^>]+)>)/gi, '');

    // Then submit the sanitized and validated description
    onSubmit({ description: sanitizedDescription });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="bg-gray-50 dark:bg-zinc-800 rounded-lg p-6">
      <TextArea
        dataKey="channeldescription"
        name="streamManagerActionFormDescription"
        onChange={(e) => setDescription(e.channeldescription)}
        placeholder="Enter channel description"
        value={description}
        label="Channel Description"
      />
      {errorMessage && (
        <div className="text-red-500 mt-2 p-2 bg-red-100 dark:bg-red-900 rounded">
          {errorMessage}
        </div>
      )}
      <div className="text-right text-sm text-gray-500 mt-2">
        {description.length}/{MAX_CHAR_COUNT}
      </div>

      <div className="flex justify-end space-x-4 mt-6">
        <Button
          onClick={onClose}
          variant="secondary"
          className="hover:bg-gray-200 dark:hover:bg-zinc-700"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="bg-blue-600 hover:bg-blue-700 text-white"
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

EditChannelDescription.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  aboutChannel: PropTypes.string
};

export default EditChannelDescription;
