import { useCallback, useState } from 'react';

import { channelAPI } from '../../../../../api';
import { dashboard as $content } from '../../../../../content';
import {
  MaximumSizeExceededError,
  UnsupportedFileFormatError
} from '../../../../../hooks/useImageUpload';
import { useUser } from '../../../../../contexts/User';
import { isS3Url } from '../../../../../utils';
import ImageUploader from '../ImageUploader';
import SettingContainer from '../../SettingContainer';
import useStateWithCallback from '../../../../../hooks/useStateWithCallback';

const Banner = () => {
  const { userData, fetchUserData } = useUser();
  const [message, setMessage] = useState({ type: '', text: '' });

  const { channelAssetUrls } = userData || {};
  const [bannerUrl, setBannerUrl] = useStateWithCallback(
    channelAssetUrls?.banner || ''
  );

  const handleChangeBanner = useCallback(
    async ({ previewUrl, uploadDateTime }) => {
      const { result, error } = await channelAPI.changeUserPreferences({
        banner: { previewUrl, uploadDateTime }
      });

      if (result) {
        let newUserData = await fetchUserData();
        while (isS3Url(newUserData.channelAssetUrls.banner)) {
          newUserData = await fetchUserData();
        }
        newUserData = await fetchUserData();
        setBannerUrl(newUserData.channelAssetUrls.banner);
      }

      if (error) {
        const { banner } = channelAssetUrls;
        setBannerUrl(banner);
        setMessage({ type: 'error', text: $content.notification.error.banner_failed_to_save });
      }
    },
    [channelAssetUrls, fetchUserData, setBannerUrl]
  );

  const onUpload = useCallback(
    ({ result, error }) => {
      if (result) handleChangeBanner(result);

      if (error) {
        switch (true) {
          case error instanceof UnsupportedFileFormatError:
            setMessage({ type: 'error', text: $content.notification.error.cant_select_file });
            break;
          case error instanceof MaximumSizeExceededError:
            setMessage({ type: 'error', text: $content.notification.error.image_exceeded_max_size });
            break;
          default:
            setMessage({ type: 'error', text: $content.notification.error.banner_failed_to_upload });
        }
      }
    },
    [handleChangeBanner]
  );

  const onImageDownload = useCallback(
    () => setMessage({ type: 'success', text: $content.notification.success.banner_uploaded }),
    []
  );

  const onDelete = useCallback(
    ({ error }) => {
      if (error) {
        setMessage({ type: 'error', text: $content.notification.error.banner_failed_to_delete });
        return;
      }

      setBannerUrl('', () => {
        fetchUserData();
        setMessage({ type: 'success', text: $content.notification.success.banner_deleted });
      });
    },
    [fetchUserData, setBannerUrl]
  );

  return (
    <SettingContainer label={$content.settings_page.banner}>
      {message.text && (
        <div className={`mb-4 p-4 rounded ${message.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message.text}
        </div>
      )}
      <ImageUploader
        previewShape="16/9"
        assetType="banner"
        shouldAnimate={false}
        onDelete={onDelete}
        onImageDownload={onImageDownload}
        onUpload={onUpload}
        uploadUrl={bannerUrl}
      />
    </SettingContainer>
  );
};

export default Banner;
