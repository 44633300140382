import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../../../utils';
import Input from '../../../components/Input';

const StreamManagerActionInput = forwardRef(
  ({ dataKey, onChange, className, maxLength, value, ...inputProps }, ref) => {
    const handleOnChange = ({ target }) =>
      onChange({ [dataKey]: target.value }, dataKey);

    return (
      <div className="flex flex-col">
        <Input
          className={clsm(['dark:bg-darkMode-gray-medium', className])}
          onChange={handleOnChange}
          ref={ref}
          value={value}
          maxLength={maxLength}
          {...inputProps}
        />
        {maxLength && (
          <span className="text-sm text-gray-500 mt-1 self-end">
            {value.length}/{maxLength}
          </span>
        )}
      </div>
    );
  }
);

StreamManagerActionInput.propTypes = {
  className: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  value: PropTypes.string
};

StreamManagerActionInput.defaultProps = { className: '', maxLength: null, value: '' };

export default StreamManagerActionInput;
