import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getCarts } from '../../api/carts';
import { removeProductFromCart } from '../../api/cart';
import {
  createCheckoutSession,
  getStripePublishableKey
} from '../../api/payment';
import { useCart } from '../../contexts/Cart';
import Button from '../../components/Button';
import DataUnavailable from './DataUnavailable';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from '../../components/Spinner';
import AdjustQuantity from './AdjustQuantity';

const Cart = () => {
  const [carts, setCarts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { updateCartItemCount } = useCart();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [cartsResponse, stripeResponse] = await Promise.all([
          getCarts(),
          getStripePublishableKey()
        ]);
        if (cartsResponse.message) {
          setError(cartsResponse.message);
          return;
        }
        setCarts(
          Array.isArray(cartsResponse.result.carts)
            ? cartsResponse.result.carts
            : []
        );
        if (stripeResponse.result.stripePublishableKey) {
          setStripePromise(
            loadStripe(stripeResponse.result.stripePublishableKey)
          );
        } else {
          console.error('Stripe publishable key not found:', stripeResponse);
        }
      } catch (error) {
        // Handle errors
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRemoveProduct = useCallback(
    async (cartId, productId, cartIndex) => {
      try {
        await removeProductFromCart(cartId, productId);
        setCarts((prevCarts) => {
          const newCarts = [...prevCarts];
          const updatedCartItems = newCarts[cartIndex].items.filter(
            (item) => item.productId !== productId
          );
          newCarts[cartIndex].items = updatedCartItems;
          updateCartItemCount(
            updatedCartItems.reduce((total, item) => total + item.quantity, 0)
          );
          return newCarts;
        });
      } catch (error) {
        console.error('Error removing product from cart:', error);
        setError('Failed to remove product. Please try again.');
      }
    },
    [updateCartItemCount]
  );

  const handleCheckout = useCallback(
    async (cartId) => {
      setIsCheckoutLoading(true);
      try {
        const sessionId = await createCheckoutSession(cartId);
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe Checkout error:', error);
          setError('Failed to proceed to checkout. Please try again.');
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
        setError('Failed to create checkout session. Please try again.');
      } finally {
        setIsCheckoutLoading(false);
      }
    },
    [stripePromise]
  );

  const truncateString = useCallback(
    (str, num) => (str && str.length > num ? str.slice(0, num) + '...' : str),
    []
  );

  const canCheckout = useMemo(
    () => (cart) =>
      cart.items.every(
        (item) =>
          item.productDetails &&
          item.quantity <= item.productDetails.quantity &&
          item.productDetails.isActive
      ),
    []
  );

  const hasNoItems = useMemo(
    () => carts.every((cart) => cart.items.length === 0),
    [carts]
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner variant="dark" size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  return (
    <main className="bg-white dark:bg-black min-h-screen">
      <div className="max-w-4xl mx-auto pt-20 px-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white text-center">
          {carts.filter((cart) => cart.items.length > 0).length <= 1
            ? 'Shopping Cart'
            : `Review Your ${carts.filter((cart) => cart.items.length > 0).length} Carts`}
        </h1>
        <section className="mt-6 space-y-6">
          {hasNoItems ? (
            <DataUnavailable
              noDataText="Your cart is currently empty."
              className="mt-24"
            />
          ) : (
            carts
              .filter((cart) => cart.items.length > 0)
              .map((cart, index) => (
                <CartItem
                  key={cart.id}
                  cart={cart}
                  index={index}
                  handleRemoveProduct={handleRemoveProduct}
                  handleCheckout={handleCheckout}
                  isCheckoutLoading={isCheckoutLoading}
                  canCheckout={canCheckout}
                  truncateString={truncateString}
                />
              ))
          )}
        </section>
      </div>
    </main>
  );
};

const CartItem = React.memo(
  ({
    cart,
    index,
    handleRemoveProduct,
    handleCheckout,
    isCheckoutLoading,
    canCheckout,
    truncateString
  }) => (
    <article className="bg-white dark:bg-black rounded-lg shadow-md overflow-hidden transition-all duration-300">
      <div className="flex sm:flex-col flex-row">
        <div className="w-full p-4">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
            Items from {cart.sellerName}
          </h2>
          <div className="space-y-3">
            {cart.items.map((item) => (
              <CartItemDetails
                key={item.productId}
                item={item}
                cartId={cart.id}
                handleRemoveProduct={handleRemoveProduct}
                index={index}
                truncateString={truncateString}
              />
            ))}
          </div>
          <Button
            type="nav"
            variant="primaryText"
            to={`/${cart.sellerName.replace(' ', '-')}`}
            className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-500 mt-6 inline-block"
          >
            View {cart.sellerName}'s Shop
          </Button>
        </div>
        <OrderSummary
          cart={cart}
          handleCheckout={handleCheckout}
          isCheckoutLoading={isCheckoutLoading}
          canCheckout={canCheckout}
        />
      </div>
    </article>
  )
);

const CartItemDetails = React.memo(
  ({ item, cartId, handleRemoveProduct, index, truncateString }) => (
    <div
      className={`flex items-center p-3 rounded-lg shadow-sm ${
        item.unavailable || item.outOfStock
          ? 'bg-red-50 dark:bg-red-900'
          : 'bg-gray-50 dark:bg-gray-700'
      }`}
    >
      {item.productDetails ? (
        <>
          <img
            className="h-16 w-16 object-cover rounded-md"
            src={
              item.productDetails.productAssets?.mainImage?.url ||
              '/no-image.png'
            }
            alt={item.productDetails.productName}
            loading="lazy"
          />
          <div className="ml-3 flex-grow">
            <h3 className="text-base font-semibold text-gray-900 dark:text-gray-100">
              {truncateString(item.productDetails.productName, 40)}
            </h3>
            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
              ${Number(item.productDetails.price).toFixed(2)}
            </p>
            <p className="text-xs text-gray-700 dark:text-gray-400">
              Shipping:{' '}
              {item.productDetails.shippingRate > 0
                ? `$${item.productDetails.shippingRate}`
                : 'Free Shipping'}
            </p>
            <div className="flex items-center space-x-2 mt-1">
              <p className="text-xs text-gray-700 dark:text-gray-400">
                Quantity:
              </p>
              <AdjustQuantity
                item={item.productDetails}
                cartId={cartId}
                quantityInCart={item.quantity}
              />
            </div>
            {item.productDetails.quantity < item.quantity &&
              item.productDetails.quantity > 0 && (
                <p className="text-xs text-red-600 mt-1">
                  Limited stock available
                </p>
              )}
            {(!item.productDetails.isActive ||
              item.productDetails.quantity <= 0) && (
              <p className="text-xs text-red-600 mt-1">Out of Stock</p>
            )}
          </div>
        </>
      ) : (
        <div className="ml-3 flex-grow">
          <h3 className="text-base font-semibold text-gray-900 dark:text-gray-100">
            {item.unavailable
              ? 'Product not available'
              : 'Product out of stock'}
          </h3>
        </div>
      )}
      <button
        className="ml-3 text-gray-500 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 focus:outline-none"
        onClick={() => handleRemoveProduct(cartId, item.productId, index)}
        aria-label="Remove product"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  )
);

const OrderSummary = React.memo(
  ({ cart, handleCheckout, isCheckoutLoading, canCheckout }) => (
    <div className="w-full bg-gray-50 dark:bg-gray-800 p-4 rounded-lg ml-4 sm:ml-0">
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
        Order Summary
      </h3>
      <div className="space-y-2">
        <div className="flex justify-between text-gray-700 dark:text-gray-300">
          <span>
            Subtotal ({cart.items.reduce((total, item) => total + item.quantity, 0)} items)
          </span>
          <span>
            $
            {cart.items
              .reduce(
                (total, item) =>
                  total +
                  Number(item.productDetails?.price || 0) *
                    (item.quantity || 1),
                0
              )
              .toFixed(2)}
          </span>
        </div>

        <div className="flex justify-between text-gray-700 dark:text-gray-300">
          <span>Shipping</span>
          <span>
            $
            {cart.items
              .reduce(
                (total, item) =>
                  total +
                  Number(item.productDetails?.shippingRate || 0) *
                    item.quantity,
                0
              )
              .toFixed(2)}
          </span>
        </div>

        <div className="mt-3 border-t border-gray-200 dark:border-gray-700 pt-3">
          <div className="flex justify-between text-lg font-bold text-gray-900 dark:text-white">
            <span>Total</span>
            <span>
              $
              {cart.items
                .reduce(
                  (total, item) =>
                    total +
                    Number(item.productDetails?.price || 0) * item.quantity +
                    Number(item.productDetails?.shippingRate || 0) *
                      item.quantity,
                  0
                )
                .toFixed(2)}
            </span>
          </div>
          <Button
            onClick={() => handleCheckout(cart.id)}
            variant="primary"
            className="mt-4 w-full py-2 rounded-lg transition duration-300 ease-in-out flex justify-center items-center"
            isDisabled={isCheckoutLoading || !canCheckout(cart)}
          >
            {isCheckoutLoading ? (
              <Spinner size="small" />
            ) : (
              'Proceed to Checkout'
            )}
          </Button>
          {!canCheckout(cart) && (
            <p className="text-xs text-red-600 mt-2 text-center">
              To proceed, please remove out-of-stock items from your cart or
              reduce the quantity of limited stock items.
            </p>
          )}
        </div>
      </div>
    </div>
  )
);

CartItem.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sellerName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        productId: PropTypes.string.isRequired,
        productDetails: PropTypes.shape({
          productAssets: PropTypes.shape({
            mainImage: PropTypes.shape({
              url: PropTypes.string
            })
          }),
          productName: PropTypes.string.isRequired,
          shippingRate: PropTypes.number.isRequired,
          price: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
          isActive: PropTypes.bool.isRequired
        }),
        quantity: PropTypes.number.isRequired,
        unavailable: PropTypes.bool,
        outOfStock: PropTypes.bool
      })
    ).isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  handleCheckout: PropTypes.func.isRequired,
  isCheckoutLoading: PropTypes.bool.isRequired,
  canCheckout: PropTypes.func.isRequired,
  truncateString: PropTypes.func.isRequired
};

CartItemDetails.propTypes = {
  item: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    productDetails: PropTypes.shape({
      productAssets: PropTypes.shape({
        mainImage: PropTypes.shape({
          url: PropTypes.string
        })
      }),
      productName: PropTypes.string.isRequired,
      shippingRate: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired
    }),
    quantity: PropTypes.number.isRequired,
    unavailable: PropTypes.bool,
    outOfStock: PropTypes.bool
  }).isRequired,
  cartId: PropTypes.string.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  truncateString: PropTypes.func.isRequired
};

OrderSummary.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        productDetails: PropTypes.shape({
          price: PropTypes.number.isRequired,
          shippingRate: PropTypes.number.isRequired
        })
      })
    ).isRequired
  }).isRequired,
  handleCheckout: PropTypes.func.isRequired,
  isCheckoutLoading: PropTypes.bool.isRequired,
  canCheckout: PropTypes.func.isRequired
};

export default Cart;
