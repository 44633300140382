import { useCallback, useRef, useState } from 'react';

import { channelAPI } from '../../../api';
import { clsm } from '../../../utils';
import { dashboard as $content } from '../../../content';
import { getInputErrorData } from '../utils';
import { SETTINGS_SECTION_CLASSES } from '../SettingsTheme';
import { useModal } from '../../../contexts/Modal';
import { useNotif } from '../../../contexts/Notification';
import { useSettingsOrientation } from '../Settings';
import { useUser } from '../../../contexts/User';
import Button from '../../../components/Button';
import Form from '../../../components/Form';

const defaultFormProps = (settingsFormOrientation) => ({
  formVariant: 'horizontal',
  inputVariant: settingsFormOrientation,
  submitBtnVariant: 'primary',
  submitText: $content.settings_page.save,
});

const AccountSettings = () => {
  const [isDeleteAccountLoading, setIsDeleteAccountLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotif();
  const { openModal } = useModal();
  const { logOut } = useUser();
  const settingsFormOrientation = useSettingsOrientation();
  const deleteAccountButtonRef = useRef();

  const handleDeleteAccount = useCallback(() => {
    if (isDeleteAccountLoading) return;

    const deleteAccount = async () => {
      setIsDeleteAccountLoading(true);
      const { result, error } = await channelAPI.deleteAccount();

      if (result) logOut('accountDeletion');
      if (error) notifyError($content.notification.error.account_deletion_failed);

      setIsDeleteAccountLoading(false);
    };

    openModal({
      content: {
        confirmText: $content.modal.account_deletion_modal.delete_account,
        isDestructive: true,
        message: $content.modal.account_deletion_modal.confirm_intent_message,
      },
      onConfirm: deleteAccount,
      lastFocusedElement: deleteAccountButtonRef,
    });
  }, [isDeleteAccountLoading, logOut, notifyError, openModal]);

  const handleSavePasswordError = useCallback(
    (error) => {
      notifyError($content.notification.error.password_save_failed);
      const { contentKey, message } = getInputErrorData(error);
      const errorType = 'input_error';
      const errors = [];
      if (contentKey === 'incorrect_password') {
        errors.push({ errorType, inputName: 'currentPassword', message });
      } else {
        errors.push(
          { errorType, inputName: 'currentPassword', message: '' },
          { errorType, inputName: 'newPassword', message: '' },
          { errorType, inputName: 'confirmNewPassword', message }
        );
      }

      return errors;
    },
    [notifyError]
  );

  return (
    <div className={clsm(["space-y-6 ", SETTINGS_SECTION_CLASSES])}>
      <div className="bg-white dark:bg-black shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {$content.settings_page.account_settings}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
            <p>Update your password to enhance security.</p>
          </div>
          <Form
            {...defaultFormProps(settingsFormOrientation)}
            className="mt-5 space-y-4"
            data-testid="change-password-form"
            errorHandler={handleSavePasswordError}
            inputsData={{
              'current password': { type: 'password', skipValidation: true },
              'new password': {
                type: 'password',
                confirmedBy: 'confirmNewPassword',
                placeholder: $content.settings_page.create_your_new_password,
              },
            }}
            onSuccess={() => {
              notifySuccess($content.notification.success.password_saved);
              openModal({
                content: {
                  isInformational: true,
                  confirmText: $content.modal.password_updated_modal.okay,
                  message: $content.modal.password_updated_modal.password_updated,
                  subMessage: $content.modal.password_updated_modal.confirmation_message,
                },
              });
            }}
            submitHandler={channelAPI.changePassword}
            validationCheck={({ currentPassword, newPassword }) => {
              if (currentPassword.value === newPassword.value) {
                return {
                  newPassword: $content.settings_page.input_error.same_as_old_password,
                };
              }
            }}
          />
        </div>
      </div>
      
      <div className="bg-white dark:bg-black shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {$content.settings_page.account_deletion}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
            <p>Warning: This action is irreversible. All your data will be permanently deleted.</p>
          </div>
          <div className="mt-5">
            <Button
              className="sm:w-full w-auto"
              isLoading={isDeleteAccountLoading}
              onClick={handleDeleteAccount}
              ref={deleteAccountButtonRef}
              type="button"
              variant="destructive"
            >
              {$content.settings_page.delete_my_account}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;