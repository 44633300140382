import PropTypes from 'prop-types';
import { ChevronDown } from '../../assets/icons';
import { clsm } from '../../utils';
import Label from '../../components/Input/InputLabel';

const Dropdown = ({
  id,
  label = '',
  selected,
  options = [],
  onChange = () => {},
  placeholder = ''
}) => (
  <div className={clsm('space-y-2')}>
    {label && <Label label={label} htmlFor={id} />}
    <div className="relative">
      <select
        id={id}
        name={id}
        onChange={onChange}
        value={selected}
        className={clsm([
          'w-full',
          'h-11',
          'pl-5',
          'pr-10',
          'py-2',
          'bg-white',
          'border',
          'border-zinc-300',
          'rounded-full',
          'text-zinc-700',
          'focus:outline-none',
          'focus:ring-2',
          'focus:ring-blue-500',
          'focus:border-blue-500',
          'hover:bg-zinc-50',
          'dark:bg-zinc-800',
          'dark:border-zinc-700',
          'dark:text-zinc-300',
          'dark:hover:bg-zinc-700',
          'dark:focus:ring-blue-500',
          'dark:focus:border-blue-500',
          'cursor-pointer',
          'appearance-none'
        ])}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ value, label: optionLabel }) => (
          <option key={value} value={value}>
            {optionLabel || value}
          </option>
        ))}
      </select>
      <ChevronDown
        className={clsm([
          'absolute',
          'pointer-events-none',
          'right-5',
          'top-1/2',
          '-translate-y-1/2',
          'w-5',
          'h-5',
          'text-zinc-500',
          'dark:text-zinc-400'
        ])}
      />
    </div>
  </div>
);

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  selected: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default Dropdown;
