import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Spinner from '../../../../components/Spinner';
import UserAvatar from '../../../../components/UserAvatar';
import Button from '../../../../components/Button';
import { useUser } from '../../../../contexts/User';
import { clsm } from '../../../../utils';
import { app as appContent } from '../../../../content';
import {
  HAIRLINE_DIVIDER_CLASSES,
  MENU_BUTTON_CLASSES
} from './ProfileMenuTheme';
import {
  Logout,
  SettingsBlue,
  GoLive,
  ListAlt,
  Following,
  ShoppingBag,
  ListOrders,
  Paid,
  ChannelOrders,
  Home,
  QuestionFill
} from '../../../../assets/icons';

const content = appContent.navbar;

// Extracted NavItem component for better organization
const NavItem = ({
  to,
  icon: IconComponent,
  label,
  hasDivider = false,
  onClick = null
}) => (
  <>
    <Button
      variant="tertiaryText"
      className={clsm(
        MENU_BUTTON_CLASSES,
        'transition-colors duration-200 ease-in-out',
        'hover:bg-gray-100 dark:hover:bg-gray-800',
        'focus:bg-gray-200 dark:focus:bg-gray-700',
        'rounded-lg'
      )}
      onClick={onClick}
      type="nav"
      to={to}
      aria-label={label}
    >
      <IconComponent aria-hidden="true" className="w-5 h-5 mr-3 text-gray-600 dark:text-gray-300" />
      <p className="truncate text-sm font-medium text-gray-700 dark:text-gray-200">{label}</p>
    </Button>
    {hasDivider && <span className={clsm(HAIRLINE_DIVIDER_CLASSES, 'my-2')} />} 
  </>
);

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  hasDivider: PropTypes.bool,
  onClick: PropTypes.func
};

const SidebarNavigation = memo(
  forwardRef(({ toggleProfileMenu = () => {}, menuClassName = '' }, ref) => {
    const { userData, logOut } = useUser();
    const {
      avatarSrc,
      color: profileColor,
      username,
      isSeller
    } = userData || {};
    const isLoading = !userData;

    const navItems = [
      { label: content.home, icon: Home, to: '/' },
      { label: content.following_list, icon: Following, to: '/following' },
      { label: content.orders, icon: ListAlt, to: '/orders' },
      ...(isSeller
        ? [
            {
              label: content.live_stream_management,
              icon: GoLive,
              to: '/manager'
            },
            {
              label: content.shop_products,
              icon: ShoppingBag,
              to: '/products'
            },
            {
              label: content.my_channels_orders,
              icon: ListOrders,
              to: '/channel-orders'
            },

            {
              label: content.manage_payments,
              icon: Paid,
              to: '/seller-payments'
            }
          ]
        : [])
    ];

    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      );
    }

    const handleMenuClose = () => {
      toggleProfileMenu(false);
    };

    return (
      <motion.nav
        className={clsm(
          'flex flex-col justify-between w-64 h-full px-4 py-6',
          'bg-white dark:bg-black',
          'shadow-lg',
          'transition-all duration-300 ease-in-out',
          menuClassName
        )}
        ref={ref}
        aria-label="Sidebar Navigation"
      >
        <div className="flex flex-col w-full space-y-6 overflow-y-auto">
          <Button
            variant="tertiaryText"
            className="flex flex-col items-center justify-center h-auto mb-4 space-y-2 hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent dark:focus:bg-transparent"
            data-testid="profileMenu-avatar"
            to={isSeller ? `/${username}` : '/'}
            type="nav"
            target="_blank"
            aria-label={
              isSeller ? `View profile of ${username}` : 'Go to settings'
            }
            onClick={handleMenuClose}
          >
            <UserAvatar
              avatarSrc={avatarSrc}
              profileColor={profileColor}
              className="w-20 h-20 rounded-full shadow-md"
              size="xl"
              username={username}
              alt={`Avatar of ${username}`}
            />
            <p className="pt-2 pr-2 text-lg font-semibold text-gray-900 dark:text-white">
              {username}
            </p>
          </Button>

          <ul aria-label="Navigation items" className="space-y-0">
            {navItems.map(({ icon: IconComponent, label, to }) => (
              <NavItem
                key={label}
                to={to}
                icon={IconComponent}
                label={label}
                onClick={handleMenuClose}
              />
            ))}
          </ul>
        </div>

        <div className="flex flex-col w-full space-y-0 mt-6">
          {!isSeller && (
            <NavItem
              to="/start-selling"
              icon={ChannelOrders}
              label="Become a Seller"
              onClick={handleMenuClose}
            />
          )}

          <NavItem
            to="/settings"
            icon={SettingsBlue}
            label="Settings"
            onClick={handleMenuClose}
          />
          <NavItem
            to="https://help.livescapeshops.com/en"
            icon={QuestionFill}
            label="Help Center"
          />
          <Button
            variant="tertiaryText"
            className={clsm(
              MENU_BUTTON_CLASSES,
              'mt-4',
              'bg-red-100 dark:bg-red-900',
              'hover:bg-red-200 dark:hover:bg-red-800',
              'focus:bg-red-300 dark:focus:bg-red-700',
              'text-red-600 dark:text-red-200',
              'rounded-lg'
            )}
            onClick={() => {
              handleMenuClose();
              logOut('logOut');
            }}
            aria-label="Log out"
          >
            <Logout aria-hidden="true" className="w-5 h-5 mr-3 text-red-600 dark:text-red-200" />
            <p className="truncate text-sm font-medium">Log Out</p>
          </Button>
        </div>
      </motion.nav>
    );
  })
);

SidebarNavigation.defaultProps = {
  menuClassName: '',
  toggleProfileMenu: () => {}
};

SidebarNavigation.propTypes = {
  menuClassName: PropTypes.string,
  toggleProfileMenu: PropTypes.func
};

export default SidebarNavigation;
