import PropTypes from 'prop-types';
import { Avatar, Banner } from './preferenceComponents';
import { clsm } from '../../../../utils';
import { dashboard as $content } from '../../../../content';
import { SETTINGS_SECTION_CLASSES } from '../../SettingsTheme';

const ChannelSettings = ({ userIsSeller }) => (
  <div
    className={clsm([
      'bg-white dark:bg-black shadow sm:rounded-lg ',
      SETTINGS_SECTION_CLASSES
    ])}
  >
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
        {$content.settings_page.profile_settings}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
        <p>
          Customize your profile settings by updating your avatar and
          personalizing your experience.
          {userIsSeller
            ? ' As a seller, your profile banner is always visible in the marketplace, whether you are live or offline.'
            : ''}
        </p>
      </div>
      <div className="mt-5 space-y-8">
        <Avatar />
        {userIsSeller && <Banner />}
      </div>
    </div>
  </div>
);

ChannelSettings.propTypes = {
  userIsSeller: PropTypes.bool.isRequired
};

export default ChannelSettings;
