import { apiBaseUrl, authFetch } from './utils';

export const getCustomerOrder = async (orderId) => {
  const { result: orderDetails } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/order/customer/${orderId}`
  });

  return orderDetails;
};

export const getSellerOrder = async (orderId) => {
  const { result: orderDetails } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/order/seller/${orderId}`
  });

  return orderDetails;
};

export const updateOrderStatus = async (orderId, status) => {
  const response = await authFetch({
    method: 'PUT',
    url: `${apiBaseUrl}/order/${orderId}/status`,
    body: { status }
  });

  return response;
};

export const checkOrderReviewStatus = async (orderId) => {
  const { result } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/order/${orderId}/can-review`
  });

  return result;
};

export const submitOrderReview = async (orderId, reviewData) => {
  const response = await authFetch({
    method: 'POST',
    url: `${apiBaseUrl}/order/${orderId}/review`,
    body: reviewData
  });

  return response;
};

export const getSellerReviews = async (sellerId) => {
  const { result } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/reviews/seller/${sellerId}`
  });

  return result;
};

export const getCustomerReviews = async (customerId) => {
  const { result } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/reviews/customer/${customerId}`
  });

  return result;
};
