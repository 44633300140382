import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import useContextHook from '../../contexts/useContextHook';
import useLocalStorage from '../../hooks/useLocalStorage';
import { extractChannelIdfromChannelArn } from '../../utils';
import { pack, unpack } from '../../helpers/streamActionHelpers';
import { useChannel } from '../Channel';
import { useUser } from '../User';
import { useLocation } from 'react-router-dom';
import {
  NUM_MILLISECONDS_TO_SHOW_AUCTION_RESULTS,
  EXTRA_TIME_TO_WAIT_FOR_END_AUCTION_EVENT
} from '../../constants';

const COMPOSER_HEIGHT = 92;
const SPACE_BETWEEN_COMPOSER_AND_AUCTION = 100;

const Context = createContext(null);
Context.displayName = 'Auction';

const AUCTION_TAB_LABEL = 'Live auction';

const initialAuctionProps = {
  bids: [],
  isActiveAuction: false,
  auctionDuration: 0,
  auctionExpiry: null,
  auctionStartTime: null,
  auctionDelay: 0,
  auctionTitle: null,
  auctionTerms: null,
  auctionPrice: null,
  auctionReserve: null,
  auctionShippingRate: null,
  auctionBuyNow: null,
  auctionDescription: null,
  auctionBidCount: 0,
  currentBidder: null,
  currentBidderUsername: null
};

const initialAuctionState = {
  isSubmitting: false,
  isBidding: true,
  isExpanded: true,
  auctionHeight: 0,
  auctionRef: undefined,
  hasListReordered: false,
  showFinalResults: false,
  hasAuctionEnded: false,
  noBidsCaptured: false,
  tieFound: false,
  hasScrollbar: false
};

const localStorageInitialState = {
  ...initialAuctionProps,
  bidders: {}
};

export const Provider = ({ children }) => {
  const forceResetAuctionPropsTimerRef = useRef();
  const stopAuctionTimerRef = useRef();
  const [composerRefState, setComposerRefState] = useState();
  const shouldAnimateListRef = useRef(false);
  const [selectedOptionAuction, setSelectedOptionAuction] = useState();
  const { channelData } = useChannel();
  const { username, channelArn = '', isViewerBanned } = channelData || {};
  const { userData, isSessionValid } = useUser();
  const channelId = extractChannelIdfromChannelArn(channelArn);
  const isModerator = channelId === userData?.trackingId;
  const { pathname } = useLocation();
  const isStreamManagerPage = pathname === '/manager';

  // Auction UI states
  const [auctionState, dispatchAuctionState] = useReducer(
    (prevState, nextState) => ({ ...prevState, ...nextState }),
    initialAuctionState
  );

  // Active auction props
  const [auctionProps, dispatchAuctionProps] = useReducer(
    (prevState, nextState) => ({ ...prevState, ...nextState }),
    initialAuctionProps
  );

  const auctionHasEnded = useCallback(() => {
    dispatchAuctionState({ hasAuctionEnded: true });
  }, []);

  const {
    bids,
    isActiveAuction,
    auctionDuration,
    auctionExpiry,
    auctionStartTime,
    auctionDelay,
    auctionTitle,
    auctionTerms,
    auctionPrice,
    auctionReserve,
    auctionShippingRate,
    auctionBuyNow,
    auctionDescription,
    auctionBidCount,
    currentBidder,
    currentBidderUsername
  } = auctionProps;

  const {
    isSubmitting,
    isBidding,
    isExpanded,
    auctionHeight,
    auctionRef,
    showFinalResults,
    hasListReordered,
    hasAuctionEnded,
    noBidsCaptured,
    tieFound,
    hasScrollbar
  } = auctionState;

  const { value: savedAuctionData, set: saveAuctionDataToLocalStorage } =
    useLocalStorage({
      key: username,
      initialValue: localStorageInitialState,
      options: {
        keyPrefix: 'auction',
        serialize: pack,
        deserialize: unpack
      }
    });

  const showFinalResultActionButton = () => ({
    auctionDuration: 10,
    auctionExpiry: new Date(Date.now() + 10 * 1000).toISOString()
  });

  const updateAuctionData = ({
    bids,
    auctionDuration,
    auctionExpiry,
    auctionStartTime,
    isActiveAuction,
    auctionDelay = 0,
    auctionTitle,
    auctionTerms,
    auctionPrice,
    auctionReserve,
    auctionShippingRate,
    auctionBuyNow,
    auctionDescription,
    auctionBidCount,
    currentBidder,
    currentBidderUsername
  }) => {
    const props = {
      ...(auctionDuration && { auctionDuration }),
      ...(bids && { bids }),
      ...(auctionExpiry && { auctionExpiry }),
      ...(isActiveAuction && { isActiveAuction }),
      ...(auctionStartTime && { auctionStartTime }),
      ...(auctionDelay && { auctionDelay }),
      ...(auctionTitle && { auctionTitle }),
      ...(auctionTerms && { auctionTerms }),
      ...(auctionPrice && { auctionPrice }),
      ...(auctionReserve && { auctionReserve }),
      ...(auctionShippingRate && { auctionShippingRate }),
      ...(auctionBuyNow && { auctionBuyNow }),
      ...(auctionDescription && { auctionDescription }),
      ...(auctionBidCount && { auctionBidCount }),
      ...(currentBidder && { currentBidder }),
      ...(currentBidderUsername && { currentBidderUsername })
    };

    dispatchAuctionProps(props);
  };

  const clearAuctionLocalStorage = useCallback(() => {
    saveAuctionDataToLocalStorage(localStorageInitialState);
  }, [saveAuctionDataToLocalStorage]);

  const resetAuctionProps = useCallback(() => {
    if (stopAuctionTimerRef.current) clearTimeout(stopAuctionTimerRef.current);
    clearAuctionLocalStorage();
    dispatchAuctionProps(initialAuctionProps);
    dispatchAuctionState(initialAuctionState);
    setSelectedOptionAuction();
    shouldAnimateListRef.current = false;
    stopAuctionTimerRef.current = undefined;
    forceResetAuctionPropsTimerRef.current = undefined;
  }, [clearAuctionLocalStorage]);

  const hasMounted = useRef(false);

  useEffect(() => {
    if (!channelData || hasMounted.current) return;

    if (
      isModerator &&
      isStreamManagerPage &&
      savedAuctionData?.isActiveAuction
    ) {
      hasMounted.current = true;
      const {
        auctionDuration,
        auctionStartTime,
        bids: options,
        auctionExpiry,
        auctionTitle,
        auctionTerms,
        auctionPrice,
        auctionReserve,
        auctionShippingRate,
        auctionBuyNow,
        auctionDescription,
        auctionBidCount,
        currentBidder,
        currentBidderUsername
      } = savedAuctionData;

      updateAuctionData({
        auctionExpiry,
        auctionStartTime,
        auctionDuration,
        isActiveAuction: true,
        bids: options,
        auctionDelay,
        auctionTitle,
        auctionTerms,
        auctionPrice,
        auctionReserve,
        auctionShippingRate,
        auctionBuyNow,
        auctionDescription,
        auctionBidCount,
        currentBidder,
        currentBidderUsername
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelData, savedAuctionData]);

  useEffect(() => {
    let timeout;

    if (savedAuctionData?.hasAuctionEnded && !hasAuctionEnded) {
      auctionHasEnded();
      return;
    }

    if (
      auctionDuration &&
      !hasAuctionEnded &&
      !savedAuctionData?.hasAuctionEnded
    ) {
      const auctionTime = auctionDuration * 1000 - auctionDelay * 1000;

      timeout = setTimeout(() => {
        dispatchAuctionState({ hasAuctionEnded: true });
      }, auctionTime);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    auctionDelay,
    auctionDuration,
    hasAuctionEnded,
    auctionHasEnded,
    savedAuctionData?.hasAuctionEnded
  ]);

  useEffect(() => {
    if (showFinalResults) {
      dispatchAuctionState({ hasListReordered: true });
    }
  }, [showFinalResults]);

  const checkForTie = (bids) => {
    const maxBid = Math.max(...bids.map((bid) => bid.count));
    const count = bids.filter((bid) => bid.count === maxBid).length;

    return count > 1;
  };

  useEffect(() => {
    if (hasAuctionEnded && !noBidsCaptured && !showFinalResults && !tieFound) {
      const noBids = bids.every((bid) => bid.count === 0);
      const hasTie = checkForTie(bids);

      if (noBids) {
        dispatchAuctionState({ noBidsCaptured: true });
      } else {
        if (hasTie) {
          dispatchAuctionState({ tieFound: true });
        } else {
          dispatchAuctionState({ showFinalResults: true });
        }
        const sortedBids = bids.sort((a, b) =>
          a.count < b.count ? 1 : a.count > b.count ? -1 : 0
        );
        dispatchAuctionProps({
          bids: sortedBids
        });
      }
    }
  }, [hasAuctionEnded, noBidsCaptured, showFinalResults, tieFound, bids]);

  const containerMinHeight = `${
    auctionHeight + SPACE_BETWEEN_COMPOSER_AND_AUCTION + COMPOSER_HEIGHT
  }px`;

  useEffect(() => {
    if (auctionRef) {
      dispatchAuctionState({ auctionHeight: auctionRef.offsetHeight });
    }
  }, [auctionRef, isExpanded]);

  const getAuctionDetails = (bids) => {
    return bids.reduce(
      (acc, bid) => {
        if (!acc.highestCountOption) {
          acc.highestCountOption = bid;
        } else {
          if (bid.count > acc.highestCountOption.count) {
            acc.highestCountOption = bid;
          }
        }

        acc.totalBids += bid.count;
        return acc;
      },
      { totalBids: 0, highestCountOption: null }
    );
  };

  const { highestCountOption, totalBids } = getAuctionDetails(bids);

  const saveBidsToLocalStorage = useCallback(
    (currentBids, bidder) => {
      saveAuctionDataToLocalStorage({
        ...savedAuctionData,
        bids: currentBids,
        bidders: {
          ...savedAuctionData.bidders,
          ...bidder
        }
      });
    },
    [saveAuctionDataToLocalStorage, savedAuctionData]
  );

  const updateSavedAuctionPropsOnTimerExpiry = useCallback(() => {
    const { auctionDuration, auctionExpiry } = showFinalResultActionButton();
    saveAuctionDataToLocalStorage({
      ...savedAuctionData,
      auctionDuration,
      auctionExpiry,
      hasAuctionEnded: true
    });
  }, [saveAuctionDataToLocalStorage, savedAuctionData]);

  const isAbleToBid =
    isBidding && !showFinalResults && !noBidsCaptured && !isViewerBanned;

  const shouldRenderRadioInput =
    isAbleToBid && isSessionValid && !isStreamManagerPage;

  const shouldRenderBidButton = isAbleToBid && !!userData;

  const shouldRenderProgressbar =
    !showFinalResults && !noBidsCaptured && !tieFound && auctionStartTime;

  const endAuctionAndResetAuctionProps = useCallback(() => {
    clearTimeout(forceResetAuctionPropsTimerRef.current);
    dispatchAuctionProps({ isActiveAuction: false });
    setTimeout(resetAuctionProps, 100);
    hasMounted.current = false;
  }, [resetAuctionProps]);

  useEffect(() => {
    if (hasAuctionEnded) {
      if (forceResetAuctionPropsTimerRef.current) {
        clearTimeout(forceResetAuctionPropsTimerRef.current);
      } else {
        forceResetAuctionPropsTimerRef.current = setTimeout(() => {
          if (isActiveAuction) endAuctionAndResetAuctionProps();
        }, NUM_MILLISECONDS_TO_SHOW_AUCTION_RESULTS + EXTRA_TIME_TO_WAIT_FOR_END_AUCTION_EVENT);
      }
    }
  }, [endAuctionAndResetAuctionProps, isActiveAuction, hasAuctionEnded]);

  useEffect(() => {
    const isAuctionExpired =
      auctionStartTime +
        auctionDuration * 1000 +
        NUM_MILLISECONDS_TO_SHOW_AUCTION_RESULTS +
        EXTRA_TIME_TO_WAIT_FOR_END_AUCTION_EVENT <
      Date.now();

    if (isAuctionExpired && isActiveAuction) {
      endAuctionAndResetAuctionProps();
    }
  }, [
    auctionDuration,
    auctionStartTime,
    isActiveAuction,
    endAuctionAndResetAuctionProps
  ]);

  const value = useMemo(
    () => ({
      isExpanded,
      auctionHeight,
      containerMinHeight,
      showFinalResults,
      bids,
      highestCountOption,
      totalBids,
      hasListReordered,
      shouldAnimateListRef,
      isActiveAuction,
      auctionStartTime,
      auctionDuration,
      selectedOptionAuction,
      setSelectedOptionAuction,
      isSubmitting,
      isBidding,
      updateAuctionData,
      auctionExpiry,
      resetAuctionProps,
      noBidsCaptured,
      tieFound,
      auctionDelay,
      clearAuctionLocalStorage,
      auctionTabLabel: AUCTION_TAB_LABEL,
      showFinalResultActionButton,
      hasAuctionEnded,
      stopAuctionTimerRef,
      auctionHasEnded,
      saveBidsToLocalStorage,
      savedAuctionData,
      saveAuctionDataToLocalStorage,
      updateSavedAuctionPropsOnTimerExpiry,
      hasScrollbar,
      composerRefState,
      setComposerRefState,
      dispatchAuctionState,
      shouldRenderRadioInput,
      shouldRenderBidButton,
      endAuctionAndResetAuctionProps,
      hasBids: bids.length > 0,
      shouldRenderProgressbar,
      auctionTitle,
      auctionTerms,
      auctionPrice,
      auctionReserve,
      auctionShippingRate,
      auctionBuyNow,
      auctionDescription,
      auctionBidCount,
      currentBidder,
      currentBidderUsername
    }),
    [
      isExpanded,
      auctionHeight,
      containerMinHeight,
      showFinalResults,
      bids,
      highestCountOption,
      totalBids,
      hasListReordered,
      isActiveAuction,
      auctionStartTime,
      auctionDuration,
      selectedOptionAuction,
      isSubmitting,
      isBidding,
      auctionExpiry,
      resetAuctionProps,
      noBidsCaptured,
      tieFound,
      auctionDelay,
      clearAuctionLocalStorage,
      hasAuctionEnded,
      auctionHasEnded,
      saveBidsToLocalStorage,
      savedAuctionData,
      saveAuctionDataToLocalStorage,
      updateSavedAuctionPropsOnTimerExpiry,
      hasScrollbar,
      composerRefState,
      shouldRenderRadioInput,
      shouldRenderBidButton,
      endAuctionAndResetAuctionProps,
      shouldRenderProgressbar,
      auctionTitle,
      auctionTerms,
      auctionPrice,
      auctionReserve,
      auctionShippingRate,
      auctionBuyNow,
      auctionDescription,
      auctionBidCount,
      currentBidder,
      currentBidderUsername
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuction = () => useContextHook(Context);
