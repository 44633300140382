import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../utils';
import { PROFILE_COLORS } from '../constants';

const UserAvatar = ({
  avatarSrc = '',
  className = '',
  profileColor = 'default',
  size = 'lg',
  isOffline = false,
  username = ''
}) => {
  const [hasAvatarError, setHasAvatarError] = useState(false);
  const onError = useCallback(() => {
    setHasAvatarError(true);
  }, []);

  // Extract the first letter of the username and capitalize it
  const initial = username ? username.charAt(0).toUpperCase() : '';

  // Check if the avatar is not a custom avatar based on the source URL
  const isNotCustomAvatar = avatarSrc && (avatarSrc.startsWith('/static/media/') || avatarSrc.startsWith('data:image/png'));

  let dimensions;
  let textSize;
  if (size === 'md') {
    dimensions = ['h-8', 'w-8'];
    textSize = 'text-md'; // Medium text size
  }
  if (size === 'sm') {
    dimensions = ['h-6', 'w-6'];
    textSize = 'text-xs'; // Small text size
  }
  if (size === 'lg') {
    dimensions = ['h-10', 'w-10'];
    textSize = 'text-lg'; // Large text size
  }
  if (size === 'xl') {
    dimensions = ['h-16', 'w-16'];
    textSize = 'text-3xl'; // Extra large text size
  }

  const avatarClassNames = clsm([
    'bg-lightMode-gray-extraLight',
    'dark:bg-darkMode-gray-medium',
    'hover:bg-lightMode-gray-extraLight-hover',
    'hover:dark:bg-darkMode-gray-medium-hover',
    'shrink-0',
    'rounded-[50%]',
    'transition-all',
    'shadow',
    'relative', // Add relative positioning for the overlay
    profileColor === 'white' && 'ring-white',
    dimensions,
    className
  ]);

  return !hasAvatarError && !!avatarSrc ? (
    <>
      <div className={clsm([avatarClassNames, isOffline && 'grayscale'])}>
        <img
          className="absolute top-0 left-0 w-full h-full rounded-[50%]"
          src={avatarSrc}
          onError={onError}
          alt=""
          draggable={false}
        />
        {isNotCustomAvatar && (
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-[50%]">
            <span className={`text-white font-bold ${textSize}`}>{initial}</span>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className={avatarClassNames} />
  );
};

UserAvatar.propTypes = {
  avatarSrc: PropTypes.string,
  className: PropTypes.string,
  isOffline: PropTypes.bool,
  profileColor: PropTypes.oneOf([...PROFILE_COLORS, 'default', 'white']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  username: PropTypes.string
};

export default UserAvatar;
