import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import DataUnavailable from './DataUnavailable';
import Dropdown from '../../components/Dropdown/Dropdown';
import Spinner from '../../components/Spinner';
import {
  FaSort,
  FaChevronDown,
  FaChevronRight,
  FaBoxOpen,
  FaTruck,
  FaCheckCircle,
  FaTimesCircle,
  FaSearch,
} from 'react-icons/fa';

const FILTER_OPTIONS = [
  { value: 'all', label: 'All Orders' },
  { value: 'last7days', label: 'Last 7 Days' },
  { value: 'last30days', label: 'Last 30 Days' },
  { value: 'last90days', label: 'Last 3 Months' },
  { value: 'last180days', label: 'Last 6 Months' },
  { value: 'lastYear', label: 'Last Year' },
];

const statusStyles = {
  delivered: 'text-green-700 bg-green-100',
  shipped: 'text-blue-700 bg-blue-100',
  processing: 'text-yellow-700 bg-yellow-100',
  cancelled: 'text-red-700 bg-red-100',
};

const statusIcons = {
  delivered: <FaCheckCircle className="mr-1" />,
  shipped: <FaTruck className="mr-1" />,
  processing: <FaBoxOpen className="mr-1" />,
  cancelled: <FaTimesCircle className="mr-1" />,
};

const StatusBadge = ({ status }) => {
  const statusText = status.charAt(0).toUpperCase() + status.slice(1);
  const classes = statusStyles[status] || 'text-gray-700 bg-gray-100';
  const icon = statusIcons[status] || null;

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${classes}`}
    >
      {icon}
      {statusText}
    </span>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

const OrderRow = ({ order, isExpanded, toggleExpand }) => {
  const {
    id,
    orderId,
    orderStatus,
    totalProductQuantity,
    totalAmount,
    transactionDate,
    sellerName,
    itemsPurchased,
  } = order;

  const FALLBACK_IMAGE_URL = '/no-image.png';

  const formatOrderDate = (dateString) =>
    new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
      new Date(dateString)
    );

  const formatAmount = (amount) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount / 100);

  return (
    <>
      <tr
        className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-200"
        onClick={() => toggleExpand(id)}
      >
        <td className="px-4 py-2 whitespace-nowrap">
          <div className="flex items-center">
            {isExpanded ? (
              <FaChevronDown className="text-gray-500 dark:text-gray-400 transition-transform duration-200 mr-2" />
            ) : (
              <FaChevronRight className="text-gray-500 dark:text-gray-400 transition-transform duration-200 mr-2" />
            )}
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {formatOrderDate(transactionDate)}
            </span>
          </div>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <span className="text-sm font-medium text-blue-600 dark:text-blue-400">
            {orderId}
          </span>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <StatusBadge status={orderStatus} />
        </td>
        <td className="px-4 py-2 whitespace-nowrap text-center">
          <span className="text-sm text-gray-900 dark:text-white">
            {totalProductQuantity}
          </span>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            {formatAmount(totalAmount)}
          </span>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <span className="text-sm text-gray-900 dark:text-white">
            {sellerName}
          </span>
        </td>
        <td className="px-4 py-2 whitespace-nowrap text-right">
          <Button
            variant="primary"
            type="nav"
            to={`/orders/${id}`}
            className="px-3 py-1 text-sm font-medium"
            onClick={(e) => e.stopPropagation()}
          >
            View Details
          </Button>
        </td>
      </tr>
      {isExpanded && itemsPurchased && itemsPurchased.length > 0 && (
        <tr>
          <td colSpan="7" className="px-4 py-2 bg-gray-50 dark:bg-gray-900">
            <div className="p-2">
              <h4 className="text-base font-semibold text-gray-900 dark:text-white mb-2">
                Items Purchased
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                {itemsPurchased.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center p-2 bg-white dark:bg-gray-800 rounded-lg shadow-sm"
                  >
                    <div className="w-10 h-10 bg-gray-200 dark:bg-gray-700 rounded-md mr-2 flex-shrink-0">
                      <img
                        src={item.image || FALLBACK_IMAGE_URL}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-md"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = FALLBACK_IMAGE_URL;
                        }}
                      />
                    </div>
                    <div>
                      <p className="text-xs font-medium text-gray-900 dark:text-white">
                        {item.name}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Qty: {item.quantity}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

OrderRow.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    orderId: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    totalProductQuantity: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    transactionDate: PropTypes.string.isRequired,
    sellerName: PropTypes.string.isRequired,
    itemsPurchased: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        image: PropTypes.string,
      })
    ),
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
};

function useFilteredOrders(orders, filter, searchTerm) {
  return useMemo(() => {
    let filtered = orders;

    if (filter !== 'all') {
      const today = new Date();
      const pastDate = new Date();

      const filterDays = {
        last7days: 7,
        last30days: 30,
        last90days: 90,
        last180days: 180,
        lastYear: 365,
      };

      pastDate.setDate(today.getDate() - (filterDays[filter] || 90));

      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.transactionDate);
        return orderDate >= pastDate && orderDate <= today;
      });
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (order) =>
          order.orderId.toLowerCase().includes(lowerSearchTerm) ||
          order.sellerName.toLowerCase().includes(lowerSearchTerm)
      );
    }

    return filtered;
  }, [orders, filter, searchTerm]);
}

function OrderHistory({ orders }) {
  const [filter, setFilter] = useState('last90days');
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'transactionDate',
    direction: 'desc',
  });
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredOrders = useFilteredOrders(orders, filter, searchTerm);

  const sortedOrders = useMemo(() => {
    const sortableOrders = [...filteredOrders];
    sortableOrders.sort((a, b) => {
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortableOrders;
  }, [filteredOrders, sortConfig]);

  const totalItems = sortedOrders.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const displayedOrders = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedOrders.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedOrders, currentPage, itemsPerPage]);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(timer);
  }, []);

  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return {
          key,
          direction: prevState.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        return { key, direction: 'asc' };
      }
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key)
      return <FaSort className="inline-block ml-1 text-gray-400" />;
    return sortConfig.direction === 'asc' ? (
      <FaSort className="inline-block ml-1 text-gray-600" />
    ) : (
      <FaSort className="inline-block ml-1 transform rotate-180 text-gray-600" />
    );
  };

  const toggleExpand = (orderId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(orderId)
        ? prevExpandedRows.filter((id) => id !== orderId)
        : [...prevExpandedRows, orderId]
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () =>
    totalPages > 1 && (
      <div className="flex justify-between items-center mt-6">
        <span className="text-sm text-gray-700 dark:text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            variant="secondary"
            size="small"
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            variant="secondary"
            size="small"
          >
            Next
          </Button>
        </div>
      </div>
    );

  return (
    <div className="mx-auto max-w-7xl pt-4 sm:px-4 md:px-6 px-8">
      <div className="bg-white dark:bg-black rounded-lg overflow-hidden">
        <div className="sm:p-4 p-6">
          <div className="flex sm:flex-col flex-row justify-between sm:items-start items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 sm:mb-0">
              Order History
            </h2>
            <div className="flex sm:flex-col flex-row sm:items-start items-center gap-2 sm:w-full w-auto">
              <div className="relative sm:w-full w-64">
                <input
                  type="text"
                  className="pl-8 pr-3 py-2 w-full text-sm rounded-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-black text-gray-900 dark:text-white focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Search orders by ID or seller"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaSearch className="w-3 h-3 text-gray-400" />
                </span>
              </div>
              <Dropdown
                id="order-filter"
                selected={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setCurrentPage(1);
                }}
                options={FILTER_OPTIONS}
                placeholder="Filter orders"
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner size="large" />
            </div>
          ) : displayedOrders.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 text-sm">
                  <thead className="bg-gray-100 dark:bg-gray-800">
                    <tr>
                      <th
                        className="px-4 py-2 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('transactionDate')}
                      >
                        Date {getSortIcon('transactionDate')}
                      </th>
                      <th
                        className="px-4 py-2 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('orderId')}
                      >
                        Order # {getSortIcon('orderId')}
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                        Status
                      </th>
                      <th
                        className="px-4 py-2 text-center text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('totalProductQuantity')}
                      >
                        Items {getSortIcon('totalProductQuantity')}
                      </th>
                      <th
                        className="px-4 py-2 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('totalAmount')}
                      >
                        Total {getSortIcon('totalAmount')}
                      </th>
                      <th
                        className="px-4 py-2 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('sellerName')}
                      >
                        Seller {getSortIcon('sellerName')}
                      </th>
                      <th className="px-4 py-2"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-black divide-y divide-gray-200 dark:divide-gray-700">
                    {displayedOrders.map((order) => (
                      <OrderRow
                        key={order.id}
                        order={order}
                        isExpanded={expandedRows.includes(order.id)}
                        toggleExpand={toggleExpand}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              {renderPagination()}
            </>
          ) : (
            <div className="flex justify-center items-center h-64">
              <DataUnavailable
                noDataText="No orders found"
                hasError={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

OrderHistory.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      orderId: PropTypes.string.isRequired,
      orderStatus: PropTypes.string.isRequired,
      totalProductQuantity: PropTypes.number.isRequired,
      totalAmount: PropTypes.number.isRequired,
      transactionDate: PropTypes.string.isRequired,
      sellerName: PropTypes.string.isRequired,
      itemsPurchased: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
          image: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default OrderHistory;