import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLocation
} from 'react-router-dom';
import { MotionConfig } from 'framer-motion';
import { useEffect } from 'react';

// Context Providers
import { Provider as ChannelProvider } from './contexts/Channel';
import { Provider as LastFocusedElementProvider } from './contexts/LastFocusedElement';
import { Provider as ModalProvider } from './contexts/Modal';
import { Provider as NotificationProvider } from './contexts/Notification';
import { Provider as ResponsiveDeviceProvider } from './contexts/ResponsiveDevice';
import { Provider as StreamsProvider } from './contexts/Streams';
import { Provider as TooltipsProvider } from './contexts/Tooltips';
import { Provider as UserProvider } from './contexts/User';
import { Provider as ViewerStreamActionsProvider } from './contexts/ViewerStreamActions';
import { Provider as CartProvider } from './contexts/Cart';
import { Provider as AppSyncProvider } from './contexts/AppSync/AppSync';
import { GlobalStageProvider } from './contexts/Stage';

// Pages
import {
  Channel,
  ChannelDirectory,
  Following,
  Settings,
  StreamManager,
  UserManagement,
  Products,
  ChannelOrders,
  Orders,
  StartSelling,
  SellerPayments,
  Cart,
  TermsOfService,
  PrivacyPolicy,
  // ContactUs,
  ComingSoon
} from './pages';

// Ecommerce Subpages
import {
  AddProductForm,
  EditProductForm,
  ProductDigitalAssets
} from './pages/Products';
import { OrderDetails, OrderReview } from './pages/Orders';
import { ChannelOrderDetails } from './pages/ChannelOrders';

// UserManagement Subpages
import {
  RegisterUser,
  ResetPassword,
  SigninUser
} from './pages/UserManagement/subpages';

import ErrorBoundary from './components/ErrorBoundary';

// Page Layouts
import { AppLayoutWithNavbar, RequireAuth, RequireSeller } from './layouts';

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if gtag is available
    if (window.gtag) {
      window.gtag('config', 'G-LJS973XQ7E', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ErrorBoundary>
          <MotionConfig reducedMotion="user">
            <LastFocusedElementProvider>
              <ResponsiveDeviceProvider>
                <NotificationProvider>
                  <ModalProvider>
                    <TooltipsProvider>
                      <UserProvider />
                    </TooltipsProvider>
                  </ModalProvider>
                </NotificationProvider>
              </ResponsiveDeviceProvider>
            </LastFocusedElementProvider>
          </MotionConfig>
        </ErrorBoundary>
      }
    >
      <Route
        element={
          <StreamsProvider>
            <GlobalStageProvider>
              <ChannelProvider>
                <CartProvider>
                  <AppSyncProvider>
                    <AppLayoutWithNavbar />
                    <AppContent />
                  </AppSyncProvider>
                </CartProvider>
              </ChannelProvider>
            </GlobalStageProvider>
          </StreamsProvider>
        }
      >
        {/* PUBLIC PAGES - Livescape */}
        <Route index element={<ChannelDirectory />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsOfService />} />
        {/* <Route path="contact" element={<ContactUs />} /> */}
        <Route path="coming-soon" element={<ComingSoon />} />
        {/* PUBLIC PAGES - User */}
        <Route path=":username">
          <Route element={<ViewerStreamActionsProvider />}>
            <Route index element={<Channel />} />
            <Route path="profile" element={<Channel />} />
            <Route
              path="*"
              element={<Navigate replace to="/:username" />}
            />
          </Route>
        </Route>
        {/* PRIVATE PAGES */}
        <Route element={<RequireAuth />}>
          <Route path="following" element={<Following />} />
          <Route path="settings" element={<Settings />} />
          <Route path="start-selling" element={<StartSelling />} />
          <Route path="orders">
            <Route index element={<Orders />} />
            <Route path=":orderId" element={<OrderDetails />} />
            <Route path=":orderId/review" element={<OrderReview />} />
          </Route>
          <Route path="cart" element={<Cart />} />
          <Route element={<RequireSeller />}>
            <Route path="manager" element={<StreamManager />} />
            <Route path="channel-orders">
              <Route index element={<ChannelOrders />} />
              <Route path=":orderId" element={<ChannelOrderDetails />} />
            </Route>
            <Route path="seller-payments" element={<SellerPayments />} />
            <Route path="products">
              <Route index element={<Products />} />
              <Route path="new" element={<AddProductForm />} />
              <Route path=":productId" element={<EditProductForm />} />
              <Route
                path=":productId/assets"
                element={<ProductDigitalAssets />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>

      {/* PUBLIC PAGES - User Management */}
      <Route element={<UserManagement />}>
        <Route path="login" element={<SigninUser />} />
        <Route path="register" element={<RegisterUser />} />
        <Route path="reset" element={<ResetPassword />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
