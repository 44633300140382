import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal, MODAL_TYPE } from '../../../../../contexts/Modal';
import { useResponsiveDevice } from '../../../../../contexts/ResponsiveDevice';
import { useCart } from '../../../../../contexts/Cart';
import { useUser } from '../../../../../contexts/User';
import Button from '../../../../../components/Button';
import Spinner from '../../../../../components/Spinner';
import ProductDescription from './ProductDescription';
import { addProductToCart } from '../../../../../api/cart';
import { getCartsItemCount } from '../../../../../api/carts';

function formatPrice(price) {
  const parsedPrice = parseFloat(price);
  return isNaN(parsedPrice) ? '$0.00' : `$${parsedPrice.toFixed(2)}`;
}

function ProductImage({ url = '/no-image.png', alt, isOutOfStock = false }) {
  return (
    <div className="relative w-full h-0 pb-[100%] overflow-hidden bg-gray-100">
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src={url || '/no-image.png'}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = '/no-image.png';
        }}
        loading="lazy"
      />
      {isOutOfStock && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <span className="text-white font-bold">Out of Stock</span>
        </div>
      )}
    </div>
  );
}

ProductImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string.isRequired,
  isOutOfStock: PropTypes.bool,
};

function ProductCard({ product, username }) {
  const {
    id,
    productDescription,
    price,
    productAssetId,
    productAssets,
    productName,
    quantity,
    shippingRate,
  } = product;

  const navigate = useNavigate();
  const location = useLocation();
  const { isSessionValid } = useUser();
  const { isMobileView } = useResponsiveDevice();
  const { openModal, closeModal } = useModal();
  const { updateCartItemCount } = useCart();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const learnMoreButtonRef = useRef();

  const handleNavigateToLogin = useCallback(() => {
    navigate('/login', {
      state: { from: location, locationStoredUsername: username },
    });
  }, [navigate, location, username]);

  const handleAddToCart = useCallback(async () => {
    if (isSessionValid && quantity > 0) {
      setIsLoading(true);
      setError(null);
      try {
        await addProductToCart(id);
        const response = await getCartsItemCount();
        const count = response.result.count;
        closeModal();
        updateCartItemCount(count);
      } catch (err) {
        console.error('Error adding product to cart:', err);
        setError('Failed to add product to cart. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    } else {
      handleNavigateToLogin();
    }
  }, [
    isSessionValid,
    quantity,
    id,
    closeModal,
    updateCartItemCount,
    handleNavigateToLogin,
  ]);

  const handleOpenProductDetails = useCallback(() => {
    openModal({
      content: {
        productDescriptionContent: (
          <ProductDescription
            productId={id}
            productName={productName}
            productDescription={productDescription}
            price={price}
            shippingRate={shippingRate}
            productAssetId={productAssetId}
            productAssets={productAssets}
            addToCart={handleAddToCart}
          />
        ),
      },
      type: MODAL_TYPE.PRODUCT_DESCRIPTION,
      lastFocusedElement: !isMobileView && learnMoreButtonRef.current,
    });
  }, [
    openModal,
    id,
    productName,
    productDescription,
    price,
    shippingRate,
    productAssetId,
    productAssets,
    handleAddToCart,
    isMobileView,
  ]);

  return (
    <div className="flex flex-col rounded-lg overflow-hidden bg-white dark:bg-black shadow-md dark:shadow-gray-900 hover:shadow-lg transition-shadow duration-200 sm:m-8 md:m-6">
      <button
        onClick={handleOpenProductDetails}
        className="focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-opacity-50"
        aria-label={`View details of ${productName}`}
        ref={learnMoreButtonRef}
      >
        <ProductImage
          url={productAssets?.mainImage?.url}
          alt={productName}
          isOutOfStock={quantity <= 0}
        />
      </button>
      <div className="flex flex-col flex-1 p-4">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2 line-clamp-2">
          {productName}
        </h2>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-3 line-clamp-3">
          {productDescription}
        </p>
        <div className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-2">
          {formatPrice(price)}
        </div>
        <div className="text-sm text-gray-700 dark:text-gray-300 mb-4">
          Shipping:{' '}
          <span className="font-medium">
            {shippingRate > 0 ? formatPrice(shippingRate) : 'Free Shipping'}
          </span>
        </div>
        {error && <div className="text-sm text-red-500 mb-2">{error}</div>}
        <div className="mt-auto">
          {quantity > 0 ? (
            <Button
              variant="primary"
              onClick={handleAddToCart}
              className="w-full py-2 text-sm rounded-md transition-colors duration-200"
              disabled={isLoading}
              aria-busy={isLoading}
              aria-label="Add to cart"
            >
              {isLoading ? (
                <Spinner size="small" variant="light" />
              ) : (
                'Add to Cart'
              )}
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="w-full py-2 text-sm rounded-md cursor-not-allowed opacity-60"
              disabled
            >
              Out of Stock
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productDescription: PropTypes.string,
    price: PropTypes.string.isRequired,
    productAssetId: PropTypes.string,
    productAssets: PropTypes.shape({
      mainImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
    shippingRate: PropTypes.string,
    productName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  username: PropTypes.string.isRequired,
};

export default ProductCard;