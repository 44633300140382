import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { BREAKPOINTS, STREAM_ACTION_NAME } from '../../../../constants';
// import { BUTTON_OUTLINE_CLASSES } from '../../../../components/Button/ButtonTheme';
import { clsm } from '../../../../utils';
import { streamManager as $streamManagerContent } from '../../../../content';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useStreamManagerActions } from '../../../../contexts/StreamManagerActions';
import { useUser } from '../../../../contexts/User';
import useCountdown from '../../../../hooks/useCountdown';
import { usePoll } from '../../../../contexts/StreamManagerActions/Poll';
import { useStreamManagerStage } from '../../../../contexts/Stage';
import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';
import { useFlashSale } from '../../../../contexts/StreamManagerActions/FlashSale';

const $content = $streamManagerContent.stream_manager_actions;
const PROGRESS_PIE_RADIUS = 20;
// const PROGRESS_PIE_DIAMETER = PROGRESS_PIE_RADIUS * 2;
const STROKE_DASHARRAY_MAX = Math.round(2 * Math.PI * PROGRESS_PIE_RADIUS);
// const DEFAULT_TRANSITION_CLASSES = [
//   'duration-[0.15s]',
//   'ease-in-out',
//   'transition'
// ];

const getColorScheme = (actionName) => {
  switch (actionName) {
    case STREAM_ACTION_NAME.FLASH_SALE:
      return {
        gradient: 'from-blue-500 to-indigo-600',
        icon: 'text-blue-300',
        ring: 'ring-blue-500'
      };
    case STREAM_ACTION_NAME.AUCTION:
      return {
        gradient: 'from-fuchsia-500 to-purple-600',
        icon: 'text-fuchsia-300',
        ring: 'ring-fuchsia-500'
      };
    case STREAM_ACTION_NAME.POLL:
      return {
        gradient: 'from-teal-500 to-emerald-600',
        icon: 'text-teal-300',
        ring: 'ring-teal-500'
      };
    case STREAM_ACTION_NAME.CELEBRATION:
      return {
        gradient: 'from-rose-500 to-pink-600',
        icon: 'text-rose-300',
        ring: 'ring-rose-500'
      };
    case STREAM_ACTION_NAME.NOTICE:
      return {
        gradient: 'from-amber-500 to-orange-600',
        icon: 'text-amber-300',
        ring: 'ring-amber-500'
      };
    case STREAM_ACTION_NAME.QUIZ:
      return {
        gradient: 'from-violet-500 to-purple-600',
        icon: 'text-violet-300',
        ring: 'ring-violet-500'
      };
    case STREAM_ACTION_NAME.AMAZON_PRODUCT:
      return {
        gradient: 'from-cyan-500 to-sky-600',
        icon: 'text-cyan-300',
        ring: 'ring-cyan-500'
      };
    case STREAM_ACTION_NAME.PRODUCT:
      return {
        gradient: 'from-emerald-500 to-green-600',
        icon: 'text-emerald-300',
        ring: 'ring-emerald-500'
      };
    default:
      return {
        gradient: 'from-slate-500 to-gray-600',
        icon: 'text-slate-300',
        ring: 'ring-slate-500'
      };
  }
};

const StreamManagerActionButton = forwardRef(
  ({ ariaLabel, icon, label, name, onClick }, ref) => {
    const Icon = icon;
    const { hasAuctionEnded, savedAuctionData } = useAuction();
    const { hasPollEnded, savedPollData } = usePoll();
    const { hasFlashSaleEnded, savedFlashSaleData } = useFlashSale();
    const { hasFetchedInitialUserData } = useUser();
    const { currentBreakpoint } = useResponsiveDevice();
    const isSmallBreakpoint = currentBreakpoint < BREAKPOINTS.sm;
    const {
      activeStreamManagerActionData,
      stopStreamAction,
      endPollOnExpiry,
      cancelActivePoll,
      endAuctionOnExpiry,
      cancelActiveAuction,
      endFlashSaleOnExpiry,
      cancelActiveFlashSale
    } = useStreamManagerActions();
    const { isStageActive } = useStreamManagerStage();

    let activeStreamManagerActionDuration;
    let activeStreamManagerActionName;
    let activeStreamManagerActionExpiry;

    if (
      name !== STREAM_ACTION_NAME.POLL &&
      name !== STREAM_ACTION_NAME.AUCTION &&
      name !== STREAM_ACTION_NAME.FLASH_SALE
    ) {
      const { duration, name, expiry } = activeStreamManagerActionData || {};

      activeStreamManagerActionDuration = duration;
      activeStreamManagerActionName = name;
      activeStreamManagerActionExpiry = expiry;
    } else if (name === STREAM_ACTION_NAME.AUCTION) {
      const { auctionDuration, name, auctionExpiry } =
        (savedAuctionData?.isActiveAuction && savedAuctionData) || {};
      activeStreamManagerActionDuration = auctionDuration;
      activeStreamManagerActionName = name;
      activeStreamManagerActionExpiry = auctionExpiry;
    } else if (name === STREAM_ACTION_NAME.FLASH_SALE) {
      const { flashSaleDuration, name, flashSaleExpiry } =
        (savedFlashSaleData?.isActiveFlashSale && savedFlashSaleData) || {};
      activeStreamManagerActionDuration = flashSaleDuration;
      activeStreamManagerActionName = name;
      activeStreamManagerActionExpiry = flashSaleExpiry;
    } else {
      const { duration, name, expiry } =
        (savedPollData?.isActive && savedPollData) || {};

      activeStreamManagerActionDuration = duration;
      activeStreamManagerActionName = name;
      activeStreamManagerActionExpiry = expiry;
    }

    const isActive = name === activeStreamManagerActionName;
    const isPerpetual = isActive && !activeStreamManagerActionExpiry;
    const isCountingDown = isActive && !isPerpetual;

    const [textFormattedTimeLeft, currentProgress] = useCountdown({
      expiry: activeStreamManagerActionExpiry,
      formatter: (timeLeft) => [
        `${Math.ceil(timeLeft / 1000)}${$content.unit_seconds}`,
        (timeLeft / (activeStreamManagerActionDuration * 1000)) *
          STROKE_DASHARRAY_MAX
      ],
      isEnabled: isCountingDown,
      onExpiry:
        name === STREAM_ACTION_NAME.AUCTION && !hasAuctionEnded
          ? endAuctionOnExpiry
          : name === STREAM_ACTION_NAME.POLL && !hasPollEnded
            ? endPollOnExpiry
            : name === STREAM_ACTION_NAME.FLASH_SALE && !hasFlashSaleEnded
              ? endFlashSaleOnExpiry
              : stopStreamAction
    });

    const handleClick = () => {
      if (isActive)
        name === STREAM_ACTION_NAME.AUCTION
          ? cancelActiveAuction()
          : name === STREAM_ACTION_NAME.POLL
            ? cancelActivePoll()
            : name === STREAM_ACTION_NAME.FLASH_SALE
              ? cancelActiveFlashSale()
              : stopStreamAction();
      else onClick();
    };

    const currentLabel = (
      <>
        {!isActive && label.default}
        {isActive &&
          !hasPollEnded &&
          !hasAuctionEnded &&
          !hasFlashSaleEnded &&
          label.active}
        {isActive && hasPollEnded && $content.poll.showing_results}
        {isActive && hasAuctionEnded && $content.auction.showing_results}
        {isActive && hasFlashSaleEnded && $content.flash_sale.showing_results}
        <br />
        {![
          STREAM_ACTION_NAME.AMAZON_PRODUCT,
          STREAM_ACTION_NAME.POLL,
          STREAM_ACTION_NAME.AUCTION,
          STREAM_ACTION_NAME.FLASH_SALE
        ].includes(name) && `a ${name}`}
      </>
    );

    let statusLabel =
      isActive && (isPerpetual ? $content.on : textFormattedTimeLeft);

    if (!isActive) statusLabel = $content.off;

    const colorScheme = getColorScheme(name);

    if (!hasFetchedInitialUserData) return null;

    const isButtonDisabled =
      isStageActive && ![STREAM_ACTION_NAME.POLL].includes(name);

    return (
      <button
        disabled={isButtonDisabled}
        data-testid={`stream-manager-${name}-action-button`}
        ref={ref}
        onClick={handleClick}
        aria-label={ariaLabel}
        className={clsm([
          isButtonDisabled && 'opacity-[.3]',
          'focus:outline-none',
          'focus:ring-2',
          'focus:ring-offset-2',
          `focus:${colorScheme.ring}`,
          'group',
          'h-full',
          'w-full',
          'rounded-xl',
          'shadow-md',
          'hover:shadow-lg',
          'transition-all',
          'duration-300',
          'ease-in-out',
          'text-white',
          `bg-gradient-to-br ${colorScheme.gradient}`,
          !isButtonDisabled && 'hover:opacity-95',
          !isButtonDisabled && 'active:opacity-100'
        ])}
      >
        <div
          className={clsm([
            'flex',
            'flex-col',
            'items-center',
            'justify-center',
            'p-4',
            'space-y-3',
            'h-full'
          ])}
        >
          {icon && !isCountingDown && (
            <div
              className={clsm([
                'h-12',
                'w-12',
                'p-2.5',
                'rounded-full',
                'bg-white bg-opacity-15',
                'transition-colors',
                'duration-300',
                isActive && 'bg-opacity-25'
              ])}
            >
              <Icon
                className={clsm([
                  'fill-current',
                  'h-full',
                  'w-full',
                  'transition-opacity',
                  'duration-300',
                  colorScheme.icon,
                  isActive ? 'opacity-100' : 'opacity-90'
                ])}
              />
            </div>
          )}
          {isCountingDown && (
            <div className="relative h-16 w-16">
              <svg className="h-full w-full" viewBox="0 0 44 44">
                <circle
                  className="fill-transparent stroke-white stroke-opacity-25"
                  r="20"
                  cx="22"
                  cy="22"
                  strokeWidth="4"
                />
                <circle
                  className={clsm([
                    'fill-transparent',
                    'stroke-current',
                    'transition-all',
                    'duration-1000',
                    'ease-linear',
                    'stroke-white'
                  ])}
                  r="20"
                  cx="22"
                  cy="22"
                  strokeWidth="4"
                  strokeDasharray={STROKE_DASHARRAY_MAX}
                  strokeDashoffset={STROKE_DASHARRAY_MAX - currentProgress}
                  transform="rotate(-90 22 22)"
                  strokeLinecap="round"
                />
              </svg>
              <div
                className={clsm([
                  'absolute',
                  'flex',
                  'h-full',
                  'w-full',
                  'items-center',
                  'justify-center',
                  'left-0',
                  'top-0',
                  'font-bold',
                  'text-sm',
                  'text-white'
                ])}
              >
                {textFormattedTimeLeft}
              </div>
            </div>
          )}
          {!isSmallBreakpoint && currentLabel && (
            <p className="text-center font-medium text-sm mt-2">
              {currentLabel}
            </p>
          )}
          <p
            className={clsm([
              'text-xs',
              'font-semibold',
              'transition-opacity',
              'duration-300',
              isActive ? 'opacity-100' : 'opacity-70',
              'mt-1'
            ])}
          >
            {!isSmallBreakpoint && statusLabel}
          </p>
        </div>
      </button>
    );
  }
);

StreamManagerActionButton.defaultProps = {
  icon: null,
  label: { default: '', active: '' }
};

StreamManagerActionButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  label: PropTypes.shape({
    default: PropTypes.string,
    active: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default StreamManagerActionButton;
