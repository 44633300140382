import { useEffect } from 'react';

import { MAX_AVATAR_COUNT } from '../../../../../constants';
import { channelDirectory as $channelDirectoryContent } from '../../../../../content';
import { getAvatarSrc } from '../../../../../helpers';
import { useNotif } from '../../../../../contexts/Notification';
import { useUser } from '../../../../../contexts/User';
import DataUnavailable from './DataUnavailable';
import FollowedUserButton from './SidebarFollowedUserButton';
import Spinner from '../../../../../components/Spinner';
import useForceLoader from '../../../../../hooks/useForceLoader';
import { ChevronLeft, ChevronRight } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import PropTypes from 'prop-types';
import { clsm } from '../../../../../utils';

const $content = $channelDirectoryContent.following_section;
const $channelDirectoryNotifications = $channelDirectoryContent.notification;

const FollowingSection = ({ isSidebarOpen, toggleSidebar }) => {
  const {
    fetchUserFollowingList: tryAgainFn,
    hasErrorFetchingFollowingList: hasFetchError,
    isSessionValid,
    userData
  } = useUser();

  const isLoadingForced = useForceLoader();
  const { notifyError } = useNotif();

  const { followingList } = userData || {};
  const hasFollowingListData = !!followingList?.length;
  const isLoading =
    (followingList === undefined && !hasFetchError) || isLoadingForced;

  let sectionList = followingList;

  if (followingList?.length > MAX_AVATAR_COUNT)
    sectionList = followingList?.slice(0, MAX_AVATAR_COUNT);

  useEffect(() => {
    if (hasFetchError)
      notifyError($channelDirectoryNotifications.error.error_loading_channels);
  }, [hasFetchError, notifyError]);

  /**
   * If the user is not logged hide the following summary section.
   */
  if (!isSessionValid) return null;

  return (
    <section className="flex-col flex max-w-full mb-6 space-y-4 w-full">
      <div className="flex justify-between items-center">
        {isSidebarOpen && (
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{$content.title}</h3>
        )}
        <Button
          className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
          variant="icon"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <ChevronLeft className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
        </Button>
      </div>

      {hasFollowingListData && (
        <div className={clsm([
          'grid',
          'gap-2',
          isSidebarOpen ? 'grid-cols-1' : 'grid-cols-1',
        ])}>
          {sectionList.map((channelData) => {
            const { color, username, isLive } = channelData;

            return (
              <FollowedUserButton
                avatarSrc={getAvatarSrc(channelData)}
                color={color}
                isLive={isLive}
                key={username}
                username={username}
                isSidebarOpen={isSidebarOpen}
              />
            );
          })}
        </div>
      )}

      {!isLoading && !hasFollowingListData && (
        <DataUnavailable
          className="flex-col flex items-center justify-center text-center"
          noDataText={
            hasFetchError
              ? $content.failed_to_load_channels
              : $content.no_channels_followed
          }
          hasError={hasFetchError}
          tryAgainFn={tryAgainFn}
          tryAgainText={$content.try_again}
          isSidebarOpen={isSidebarOpen}
        />
      )}

      {isLoading && (
        <div className="flex-col flex grow items-center justify-center left-0 h-full relative top-0 w-full min-h-[204px] md:min-h-[174px] sm:min-h-[146px] space-y-8">
          <Spinner size="medium" variant="light" />
        </div>
      )}
    </section>
  );
};

FollowingSection.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default FollowingSection;
