import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { clsm, isElementsOverlapping } from '../../../utils';

import FollowButton from './FollowButton';
import UserAvatar from '../../../components/UserAvatar';
// import ChannelStatusBar from '../../../components/ChannelStatusBar';
import ChannelMenu from './ChannelMenu';
import { useUser } from '../../../contexts/User';

const getHeaderButtonClasses = (shouldRemoveZIndex = false) => {
  return clsm([
    'flex',
    'items-center',
    'sm:space-x-2', // Adjust spacing for small screens
    'space-x-6', // Larger spacing for medium and up screens
    'pointer-events-all',
    !shouldRemoveZIndex && 'z-10'
  ]);
};

const ChannelHeader = ({
  avatarSrc = '',
  color = 'default',
  username = ''
}) => {
  const [followButtonRefState, setFollowButtonRefState] = useState();
  const [shouldRemoveFollowButtonZIndex, setShouldRemoveFollowButtonZIndex] =
    useState(false);
  const { isSessionValid } = useUser();

  useEffect(() => {
    if (followButtonRefState) {
      if (isElementsOverlapping(followButtonRefState)) {
        setShouldRemoveFollowButtonZIndex(true);
      }
    }
  }, [followButtonRefState, shouldRemoveFollowButtonZIndex]);

  return (
    <div
      className={clsm(
        'w-full',
        'flex',
        'py-6', // Increased vertical padding
        'px-6', // Increased horizontal padding
        'justify-between',
        'items-center',
        'max-w-7xl',
        'mx-auto',
        'bg-white', // Added background color
        'dark:bg-black'
      )}
    >
      <div
        className={clsm(
          'flex',
          'items-center',
          'space-x-4', // Consistent spacing
          'sm:flex-col',
          'sm:items-start'
        )}
      >
        <div className="flex flex-row items-center space-x-4 sm:space-x-6">
          <button
            className={clsm([
              'group',
              'flex',
              'rounded-full',
              'focus:outline-none',
              'focus:ring-2',
              'focus:ring-blue-500',
              'transition-all'
            ])}
          >
            <UserAvatar
              className={clsm([
                'duration-300',
                'group-hover:scale-105', // Subtle hover effect
                'w-16',
                'h-16',
                'sm:w-12',
                'sm:h-12'
              ])}
              avatarSrc={avatarSrc}
              profileColor={color}
              size={'xl'}
              username={username}
            />
          </button>
          <div className="flex flex-row sm:ml-4">
            <h3
              className={clsm([
                'text-xl', // Increased font size
                'sm:text-sm',
                'font-semibold', // Bolder font weight
                'text-gray-900',
                'dark:text-white',
                'truncate',
                'max-w-[200px]', // Limit width to prevent overflow
                'sm:max-w-[300px]'
              ])}
            >
              {username}
            </h3>
          </div>
        </div>
        {/* {isSessionValid && (
          <ChannelStatusBar className="sm:mt-4 mt-0 ml-4 bg-gray-100 dark:bg-gray-800 rounded-full px-4 py-2 flex items-center space-x-4" />
        )} */}
      </div>
      <div className={clsm(getHeaderButtonClasses(), 'flex-none', 'space-x-3')}>
        <ChannelMenu
          channelUsername={username}
          isSessionValid={isSessionValid}
        />
        <FollowButton
          isExpandedView={null}
          setFollowButtonRefState={setFollowButtonRefState}
        />
      </div>
    </div>
  );
};

ChannelHeader.propTypes = {
  avatarSrc: PropTypes.string,
  color: PropTypes.string,
  username: PropTypes.string
};

export default ChannelHeader;
