import PropTypes from 'prop-types';

import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import useCurrentPage from '../../../hooks/useCurrentPage';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import Sidebar from './Sidebar';
import SidebarSettings from './SidebarSettings';

const Navbar = ({ isSidebarOpen = false, toggleSidebar = () => {}, isSessionValid = false }) => {
  const { isMobileView } = useResponsiveDevice();
  const currentPage = useCurrentPage();

  const pages = {
    channel: 'channel',
    settings: 'settings',
    streamManager: 'stream_manager',
    channelOrders: 'channel_orders',
    channelOrderDetails: 'channel_order_details',
    orders: 'orders',
    orderDetails: 'order_details',
    orderReview: 'order_review',
    startSelling: 'start_selling',
    sellerPayments: 'seller_payments',
    cart: 'cart',
    channelDirectory: 'channel_directory',
    following: 'following',
    products: 'products',
    addProduct: 'add_product',
    productAssets: 'product_assets',
    editProduct: 'edit_product',
    termsAndConditions: 'terms_and_conditions',
    contact: 'contact',
    privacyPolicy: 'privacy_policy',
    comingSoon: 'coming_soon'
  };
  if (isMobileView) {
    return pages.channel ? <MobileNavbar /> : <MobileNavbar />;
  }

  const renderSidebarComponent = () => {
    if ([pages.channelDirectory, pages.following].includes(currentPage)) {
      if (isSessionValid) {
        return <SidebarSettings />;
      } else {
        return null;
      }
    }

    if (
      currentPage === pages.cart ||
      currentPage === pages.termsAndConditions ||
      currentPage === pages.privacyPolicy ||
      currentPage === pages.contact ||
      currentPage === pages.comingSoon
    ) {
      return null;
    }

    if (currentPage === pages.channel) {
      return (
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      );
    }

    if (
      [
        pages.settings,
        pages.streamManager,
        pages.channelOrders,
        pages.channelOrderDetails,
        pages.orders,
        pages.orderDetails,
        pages.orderReview,
        pages.startSelling,
        pages.sellerPayments,
        pages.products,
        pages.addProduct,
        pages.productAssets,
        pages.editProduct
      ].includes(currentPage)
    ) {
      return <SidebarSettings />;
    }

    return null;
  };

  return (
    <>
      {renderSidebarComponent()}
      <DesktopNavbar />
    </>
  );
};

Navbar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  isSessionValid: PropTypes.bool.isRequired
};
export default Navbar;
