import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clsm } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';
import Button from '../Button';
import Hamburger from './Hamburger';
import ProfileMenu from '../ProfileMenu';
import Searchbar from '../../layouts/AppLayoutWithNavbar/Navbar/Searchbar';
import { ShoppingCart, Search, Close } from '../../assets/icons';
import { useCart } from '../../contexts/Cart';
import { app as $appContent } from '../../content';

import {
  GoLive,
  ListAlt,
  Following,
  ShoppingBag,
  ListOrders,
  Paid,
  Home
} from '../../assets/icons';

const $content = $appContent.navbar;

const MobileNav = ({ containerClassName = undefined, menuClassName = '' }) => {
  const navigate = useNavigate();
  const { isMobileView, isLandscape } = useResponsiveDevice();
  const { isSessionValid, userData } = useUser();
  const { cartItemCount } = useCart();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const isSeller = userData?.isSeller;

  const navData = useMemo(() => {
    const data = [
      {
        label: $content.home,
        icon: <Home />,
        to: '/',
        visibleToAll: true
      },
      {
        label: $content.following_list,
        icon: <Following />,
        to: '/following',
        visibleToAll: true
      },
      {
        label: $content.orders,
        icon: <ListAlt />,
        to: '/orders',
        visibleToAll: true
      },
      {
        label: $content.live_stream_management,
        icon: <GoLive />,
        to: '/manager',
        visibleToSeller: true
      },
      {
        label: $content.shop_products,
        icon: <ShoppingBag />,
        to: '/products',
        visibleToSeller: true
      },
      {
        label: $content.my_channels_orders,
        icon: <ListOrders />,
        to: '/channel-orders',
        visibleToSeller: true
      },
      {
        label: $content.manage_payments,
        icon: <Paid />,
        to: '/seller-payments',
        visibleToSeller: true,
        hasDivider: isSeller
      }
    ];

    // Add the "Become a Seller" button if the user is not a seller
    if (!isSeller) {
      data.push({
        label: 'Become a Seller',
        icon: <ShoppingBag />, // You can replace this with any icon of your choice
        to: '/start-selling',
        visibleToAll: true,
        isButton: true
      });
    }

    return data.filter((item) =>
      isSeller ? item.visibleToSeller || item.visibleToAll : item.visibleToAll
    );
  }, [isSeller]);

  return (
    <nav
      className={clsm([
        'fixed',
        'flex',
        'top-0',
        'items-center',
        'justify-between',
        'w-full',
        'z-50',
        'dark:bg-black',
        'bg-white',
        'h-16',
        'px-6'
      ])}
    >
      <div
        className={clsm([
          'flex',
          'items-center',
          'justify-start',
          isProfileMenuOpen ? 'w-auto' : 'w-1/6'
        ])}
      >
        {isMobileView && (
          <ProfileMenu
            navData={navData}
            fadeBackground
            containerClassName={(isOpen) =>
              clsm([
                isOpen ? 'fixed' : 'absolute',
                'flex',
                'flex-col',
                'items-start',
                'left-4',
                'top-2',
                'h-auto',
                'w-[calc(100vw_-_40px)]',
                'max-h-[650px]',
                'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_32px))]',
                isLandscape && [
                  'md:max-w-[400px]',
                  'touch-screen-device:max-w-[400px]'
                ],
                typeof containerClassName === 'function'
                  ? containerClassName(isOpen)
                  : containerClassName
              ])
            }
            menuClassName={clsm(
              ['w-full', 'h-full', 'origin-top-left'],
              menuClassName
            )}
            onToggle={(isOpen) => setIsProfileMenuOpen(isOpen)}
          >
            {({ isOpen, toggle, toggleRef }) => (
              <Button
                data-testid="floating-menu-toggle"
                className={clsm([
                  'w-12',
                  'h-12',
                  'min-w-[48px]',
                  'min-h-[48px]',
                  'bg-white',
                  'dark:bg-black',
                  'hover:bg-lightMode-gray-hover',
                  'focus:bg-lightMode-gray'
                ])}
                onClick={() => {
                  if (isSessionValid) {
                    toggle();
                  } else {
                    navigate('/login');
                  }
                }}
                variant="secondary"
                ref={toggleRef}
              >
                <Hamburger isOpen={isOpen} />
              </Button>
            )}
          </ProfileMenu>
        )}
      </div>
      <div
        className={clsm([
          'flex',
          'relative',
          'items-center',
          'justify-center',
          'w-4/6',
          isProfileMenuOpen ? 'sm:ml-14 xs:ml-14 md:ml-24 lg:ml-28' : ''
        ])}
      >
        {showSearchBar ? (
          <>
            <Searchbar className="w-full" />

            <Button
              variant="icon"
              className="dark:fill:white"
              onClick={() => setShowSearchBar(false)}
            >
              <Close className="w-4 h-4" />
            </Button>
          </>
        ) : (
          <>
            <div className="flex items-center">
              <Button
                to="/"
                type="nav"
                variant="primaryText"
                className="flex flex-col shadow-none hover:bg-transparent focus:bg-transparent mb-0.5 flex-shrink-0 pl-2 pr-2 mr-12 dark:hover:bg-transparent"
              >
                <img
                  className="h-10 w-auto pr-0 justify-center items-center flex-shrink-0 flex-none dark:hidden"
                  src="/logo-ls.svg"
                  alt="logo light"
                />
                <img
                  className="h-10 w-auto pr-0 justify-center relative items-center flex-shrink-0 flex-none hidden dark:block"
                  src="/logo-ls-dark.svg"
                  alt="logo dark"
                />
              </Button>
            </div>
            <Button
              variant="icon"
              to="/search"
              disableHover={true}
              disableFocus={true}
              className="relative flex items-center justify-center w-11 h-11 rounded-2xl bg-white text-black dark:bg-black ml-1"
              onClick={() => setShowSearchBar(true)}
            >
              <Search className="w-6 h-6 fill-current text-black dark:text-white" />
            </Button>
          </>
        )}
      </div>

      <div className="flex items-center justify-end w-1/6">
        <Button
          type="nav"
          variant="icon"
          to="/cart"
          disableHover={true}
          disableFocus={true}
          className="relative flex items-center justify-center w-11 h-11 rounded-2xl bg-white text-black dark:fill-white dark:bg-black"
        >
          <div>
            <ShoppingCart className="w-6 h-6" />
          </div>
          {isSessionValid && cartItemCount > 0 && (
            <span className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
              {cartItemCount}
            </span>
          )}
        </Button>
      </div>
    </nav>
  );
};

MobileNav.propTypes = {
  containerClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  menuClassName: PropTypes.string
};

export default MobileNav;
