import PropTypes from 'prop-types';

import { clsm, noop } from '../../utils';
import { ListAlt } from '../../assets/icons';
import Button from '../../components/Button';

const DataUnavailable = ({
  className = '',
  noDataText = '',
  hasError = false,
  tryAgainFn = noop,
  tryAgainText = ''
}) => (
  <div className={clsm([className, 'space-y-8'])}>
    <div
      className={clsm([
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'space-y-2',
        'h-full',
        '-mt-32'
      ])}
    >
      <ListAlt
        className={clsm([
          '[&>path]:fill-gray-500',
          '[&>path]:dark:fill-white',
          'h-8',
          'w-8'
        ])}
      />
      <h3 className={clsm(['text-gray-700', 'dark:text-white', 'font-semibold', 'text-base'])}>{noDataText}</h3>
      <p className="text-gray-500 dark:text-gray-300 max-w-lg text text-center">{hasError ? 'An error occurred while fetching data.' : 'Your order history page provides an overview of all your past purchases, including details and status.'}</p>
    </div>
    {hasError && (
      <Button
        onClick={tryAgainFn}
        variant="secondary"
        className="bg-lightMode-gray"
      >
        {tryAgainText}
      </Button>
    )}
  </div>
);

DataUnavailable.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  noDataText: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string
};

export default DataUnavailable;
