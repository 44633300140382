import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaPinterestP
} from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';

// import CallToActionMidPage from '../../../pages/ChannelDirectory/CallToActionMidPage';

const Footer = () => {
  return (
    <div className="w-full mx-auto bg-[#1a1333] dark:bg-black rounded-xl shadow-xl mb-4">
      <div
        className="backdrop-blur-md bg-black/5 rounded-2xl py-12"
        aria-label="Footer"
      >
        {/* <CallToActionMidPage /> */}
        <div className="container max-w-7xl mx-auto px-8 py-12 relative z-10">
          <div className="flex sm:flex-col flex-row justify-between items-start">
            {/* Logo Section */}
            <section className="sm:w-full w-1/3 sm:mb-6 mb-0 sm:flex sm:flex-col sm:items-center sm:text-center">
              <Link
                to="/"
                className="flex items-center justify-start sm:justify-center"
                aria-label="Homepage"
              >
                <img
                  className="w-40 mt-1 h-auto flex-shrink-0"
                  src="/logo-ls-white.svg"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
              {/* <p className="mt-4 text-gray-200 p-1 text-sm transition-opacity duration-300 hover:opacity-80 sm:text-center">
                Live Shopping
              </p> */}
            </section>

            {/* Navigation Section */}
            <nav
              className="sm:w-full w-2/3 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 gap-8 sm:mt-8 mt-0 sm:flex sm:flex-col sm:items-center"
              aria-label="Footer Navigation"
            >
              <FooterSection
                title="Account"
                links={[
                  { to: '/', text: 'Home' },
                  // { to: '/login', text: 'Login' },
                  // { to: '/register', text: 'Register' },
                  {
                    to: 'https://help.livescapeshops.com/en/articles/9918987-become-a-seller',
                    text: 'Become a Seller'
                  }
                ]}
              />

              <FooterSection
                title="Support"
                links={[
                  {
                    to: 'https://help.livescapeshops.com/en',
                    text: 'Help Center'
                  },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/collections/10495283-faq',
                  //   text: 'FAQs'
                  // },
                  {
                    to: 'https://help.livescapeshops.com/en/articles/9919014-contact-us',
                    text: 'Contact Us'
                  },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/articles/9919485-shipping-information',
                  //   text: 'Shipping',
                  // },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/articles/9919497-returns-exchanges',
                  //   text: 'Returns',
                  // },
                ]}
              />

              <FooterSection
                title="Company"
                links={[
                  {
                    to: 'https://help.livescapeshops.com/en/articles/9918295-about-us',
                    text: 'About Us'
                  },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/articles/9918948-careers',
                  //   text: 'Careers'
                  // },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/articles/9919421-press-contact',
                  //   text: 'Press'
                  // },
                  // {
                  //   to: 'https://help.livescapeshops.com/en/collections/10495284-blog',
                  //   text: 'Blog'
                  // },
                  { to: '/privacy', text: 'Privacy' },
                  { to: '/terms', text: 'Terms' }
                ]}
              />

              {/* Updated FooterSocialSection */}
              <FooterSocialSection
                title="Follow Us"
                links={[
                  {
                    href: 'https://www.instagram.com/livescapeshops',
                    icon: <FaInstagram className="w-6 h-6" />
                  },
                  {
                    href: 'https://www.tiktok.com/@livescapeshops',
                    icon: <SiTiktok className="w-6 h-6" />
                  },
                  {
                    href: 'https://www.youtube.com/@LivescapeShops',
                    icon: <FaYoutube className="w-6 h-6" />
                  },
                  {
                    href: 'https://www.facebook.com/LivescapeShopsOfficial/',
                    icon: <FaFacebookF className="w-6 h-6" />
                  },
                  {
                    href: 'https://www.pinterest.com/livescapeshops',
                    icon: <FaPinterestP className="w-6 h-6" />
                  }
                ]}
              />
            </nav>
          </div>

          {/* Bottom Footer Section */}
          <div className="mt-10 pt-8 sm:text-center  border-t border-gray-50/20">
            <div className="text-sm text-gray-300 ">
              <p>© 2024 Livescape Shops. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterSection = ({ title, links }) => (
  <section className="sm:flex sm:flex-col sm:items-center">
    <h2 className="text-white text-lg font-semibold mb-4 sm:text-center">
      {title}
    </h2>
    <ul className="space-y-2 sm:flex sm:flex-col sm:items-center">
      {links.map((link, index) => (
        <li key={index}>
          <Link
            to={link.to}
            className="text-gray-300 hover:text-white transition-colors sm:text-center"
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  </section>
);

// PropTypes validation for FooterSection
FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

const FooterSocialSection = ({ title, links }) => (
  <section>
    <h2 className="text-white text-lg font-semibold mb-4 sm:text-center">
      {title}
    </h2>
    <ul className="flex space-x-4">
      {links.map((link, index) => (
        <li key={index}>
          <a
            href={link.href}
            className="text-gray-300 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.icon}
          </a>
        </li>
      ))}
    </ul>
  </section>
);

// PropTypes validation for FooterSocialSection
FooterSocialSection.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired
    })
  ).isRequired
};

export default React.memo(Footer);
