import { apiBaseUrl, authFetch } from './utils';

export const createStripeAccount = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/createStripeAccount`,
    method: 'POST',
    includeUserData: true
  });

  return response;
};

export const createStripeLoginLink = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/createStripeLoginLink`,
    method: 'POST',
    includeUserData: true
  });

  return response;
};

export const createCheckoutSession = async (cartId) => {

  const response = await authFetch({
    url: `${apiBaseUrl}/payment/createCheckoutSession`,
    method: 'POST',
    body: {cartId}
  });

  return response.result.sessionId;
};

export const createBuyNowSession = async (title, price, shippingRate, sellerId) => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/createBuyNowSession`,
    method: 'POST',
    body: {title, price, shippingRate, sellerId}
  });

  return response.result.sessionId;
};

export const hasStripeConnectId = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/hasStripeConnectId`,
    method: 'GET'
  });

  return response;
};

export const getLastPayout = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/getLastPayout`,
    method: 'GET'
  });

  return response;
}

export const getStripePublishableKey = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/getStripePublishableKey`,
    method: 'GET'
  });

  return response;
}

export const getStripeSecretKey = async () => {
  const response = await authFetch({
    url: `${apiBaseUrl}/payment/getStripeSecretKey`,
    method: 'GET'
  });

  return response;
}
