import { useRef } from 'react';
import PropTypes from 'prop-types';
import { clsm } from '../../../../../utils';

const ProductCardImage = ({ imageUrl, title, price, customClasses = '' }) => {
  const imgRef = useRef();

  const onErrorHandler = () => {
    imgRef.current.style.display = 'none';
  };

  return (
    <div
      className={clsm([
        'bg-gray-100',
        'dark:bg-gray-800',
        'relative',
        'rounded-xl',
        'w-full',
        'shadow-lg',
        'h-64',
        customClasses
      ])}
    >
      <img
        className={clsm([
          'text-[0px]',
          'rounded-xl',
          'object-cover',
          'w-full',
          'h-full'
        ])}
        src={imageUrl}
        alt={title}
        ref={imgRef}
        onError={onErrorHandler}
      />
      {price && (
        <span
          className={clsm([
            'absolute',
            'px-2.5',
            'py-0.5',
            'right-3.5',
            'rounded-3xl',
            'top-3.5',
            'font-medium',
            'text-white',
            'text-sm',
            'bg-indigo-500',
            'shadow-md'
          ])}
        >
          {price}
        </span>
      )}
    </div>
  );
};

ProductCardImage.propTypes = {
  customClasses: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ProductCardImage;
