import { useEffect, useState } from 'react';

const formatTime = (timeInMs) => {
  if (timeInMs < 1000) {
    return `${timeInMs} ms`;
  }

  let remainingTime = Math.floor(timeInMs / 1000);

  const days = Math.floor(remainingTime / 86400);
  remainingTime %= 86400;

  const hours = Math.floor(remainingTime / 3600);
  remainingTime %= 3600;

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  if (timeInMs < 60000) {
    return `${seconds}s`;
  }

  const timeParts = [];

  if (days > 0) {
    timeParts.push(`${days}d`);
  }
  if (hours > 0 || days > 0) {
    timeParts.push(hours.toString().padStart(2, '0'));
  }
  if (minutes > 0 || hours > 0 || days > 0) {
    timeParts.push(minutes.toString().padStart(2, '0'));
  }

  timeParts.push(seconds.toString().padStart(2, '0'));

  return timeParts.join(':');
};


const getDuration = (startTime) => {
  const startDate = new Date(startTime);
  const startDateInMs = startDate.getTime();
  const endDateInMs = Date.now();

  return Math.abs(endDateInMs - startDateInMs);
};

const useElapsedTime = (startTime) => {
  const [elapsedTime, setElapsedTime] = useState(formatTime(0));

  useEffect(() => {
    if (!startTime) return setElapsedTime(0);

    const updateElapsedTime = () => {
      const duration = getDuration(startTime);
      setElapsedTime(formatTime(duration));
    };
    updateElapsedTime();
    const intervalId = setInterval(updateElapsedTime, 100);

    return () => clearInterval(intervalId);
  }, [startTime]);

  return elapsedTime;
};

export default useElapsedTime;
