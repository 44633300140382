import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import EditChannelDescription from './EditChannelDescription';
import { useUser } from '../../../contexts/User';
import { channelAPI } from '../../../api';
import { useChannel } from '../../../contexts/Channel';
import Spinner from '../../../components/Spinner';

function ChannelDescription({ username = '' }) {
  const [isEditAboutOpen, setIsEditAboutOpen] = useState(false);
  const { userData } = useUser();
  const { channelData } = useChannel();
  const [description, setDescription] = useState(
    channelData ? channelData.description : ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (channelData) {
      setDescription(channelData.description);
    }
  }, [channelData]);

  const handleToggleEditAbout = () => setIsEditAboutOpen(!isEditAboutOpen);

  const handleEditAboutSubmit = async (data) => {
    setIsLoading(true);
    setError(null);
    try {
      await channelAPI.changeChannelDescription({
        description: data.description,
      });
      setDescription(data.description);
      handleToggleEditAbout();
    } catch (error) {
      console.error('Error updating about section:', error);
      setError('Failed to update description. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isOwner = userData?.username === username;

  return (
    <section className="max-w-7xl mx-auto w-full sm:px-2">
      <div className="bg-white dark:bg-black rounded-xl  overflow-hidden">
        <div className="sm:px-3 p-4">
          <div className="flex sm:flex-col flex-row sm:items-start items-center justify-between mb-6">
            <h2 className="text-2xl sm:text-base font-bold text-gray-900 dark:text-white sm:mb-4 mb-0">
              About {username}
            </h2>
            {isOwner && (
              <Button
                onClick={handleToggleEditAbout}
                variant="secondary"
                className="transition duration-200 ease-in-out transform hover:scale-105 hover:bg-gray-100 dark:hover:bg-zinc-700"
                aria-label="Edit about section"
              >
                {isEditAboutOpen ? 'Cancel' : 'Edit Description'}
              </Button>
            )}
          </div>

          {error && (
            <p className="text-red-600 dark:text-red-400 mb-4 p-3 bg-red-100 dark:bg-red-900 rounded-lg">
              {error}
            </p>
          )}

          {!isEditAboutOpen ? (
            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 sm:p-3 overflow-x-auto">
              <p className="text-lg sm:text-base text-gray-700 dark:text-gray-300 leading-relaxed break-words">
                {description || "Welcome! Follow to easily return to this shop in the future."}
              </p>
            </div>
          ) : (
            <EditChannelDescription
              isOpen={isEditAboutOpen}
              onClose={handleToggleEditAbout}
              onSubmit={handleEditAboutSubmit}
              aboutChannel={description}
              isLoading={isLoading}
              error={error}
            />
          )}

          {isLoading && (
            <div className="flex justify-center mt-6">
              <Spinner variant="light" size="lg" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

ChannelDescription.propTypes = {
  username: PropTypes.string,
};

export default ChannelDescription;
