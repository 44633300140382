import React, { useState, useEffect } from 'react';
import { getCustomerOrders } from '../../api/orders';
import Spinner from '../../components/Spinner';
import OrderHistory from './OrderHistory';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const data = await getCustomerOrders();
      if (!data) throw new Error('No data received');
      setOrders(data.orders || []);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <main className="bg-white dark:bg-black min-h-screen py-12">
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner size="large" variant="primary" />
        </div>
      ) : (
        <OrderHistory orders={orders} />
      )}
    </main>
  );
}

export default Orders;
