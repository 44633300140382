import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState
} from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { FaBoxes, FaTruck, FaClock } from 'react-icons/fa';

import { useChannel } from '../../../../contexts/Channel';
import { useUser } from '../../../../contexts/User';
import { useChat } from '../../../../contexts/Chat';
import { useFlashSale } from '../../../../contexts/StreamManagerActions/FlashSale';
import { CHAT_MESSAGE_EVENT_TYPES } from '../../../../constants';

import { clsm } from '../../../../utils';
import Spinner from '../../../../components/Spinner';
import FlashSaleContainer from './FlashSaleContainer';

import {
  createBuyNowSession,
  getStripePublishableKey
} from '../../../../api/payment';

const formatPrice = (value, currency = 'USD', locale = 'en-US') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(value);

const FlashSaleTitle = ({ title }) => (
  <h3
    className={clsm([
      'text-xl',
      'font-bold',
      'mb-3',
      'text-center',
      'text-gray-800',
      'dark:text-white',
      'line-clamp-2'
    ])}
  >
    {title}
  </h3>
);

FlashSaleTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const FlashSalePrice = ({ discountPrice, price }) => (
  <div className="flex items-center justify-center mb-4">
    <span className="text-2xl font-bold text-red-600 dark:text-red-400 mr-2">
      {discountPrice}
    </span>
    <span className="text-sm text-gray-500 line-through">{price}</span>
  </div>
);

FlashSalePrice.propTypes = {
  discountPrice: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
};

const FlashSaleImage = ({ imageUrl }) => (
  <div className="flex justify-center mb-4">
    <img
      src={imageUrl}
      alt="Flash Sale Product"
      className="object-cover w-32 h-32 rounded-lg shadow-md"
    />
  </div>
);

FlashSaleImage.propTypes = {
  imageUrl: PropTypes.string.isRequired
};

const FlashSaleButton = ({ onClick, isSoldOut, isSubmitting }) => (
  <div className="w-full">
    {isSoldOut ? (
      <div className="py-2 px-4 text-sm font-medium text-center text-red-700 bg-red-100 rounded-full dark:bg-red-900/20 dark:text-red-300">
        Sold Out
      </div>
    ) : (
      <motion.button
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.98 }}
        onClick={onClick}
        disabled={isSubmitting}
        className={clsm([
          'w-full py-2 px-4 text-sm font-semibold rounded-full transition-all duration-300 ease-in-out',
          isSubmitting
            ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
            : 'bg-gradient-to-r from-red-500 to-red-600 text-white hover:from-red-600 hover:to-red-700'
        ])}
      >
        {isSubmitting ? <Spinner variant="light" size="sm" /> : 'Buy Now'}
      </motion.button>
    )}
  </div>
);

FlashSaleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

const FlashSaleDetails = ({
  shippingRate,
  quantityLeft,
  duration,
  startTime
}) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    const endTime = startTime + duration * 1000;

    const updateTimer = () => {
      const now = Date.now();
      const remainingTime = Math.max(0, Math.floor((endTime - now) / 1000));
      setTimeLeft(remainingTime);
    };

    updateTimer();

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [duration, startTime]);

  const formatTimeLeft = () => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return `${hours > 0 ? `${hours}h ` : ''}${
      minutes > 0 ? `${minutes}m ` : ''
    }${seconds}s`;
  };

  if (timeLeft <= 0) {
    return <div classname="mt-2 pt-2">Flash Sale Ending... </div>;
  }

  return (
    <div className="flex flex-wrap items-center justify-between mt-4 text-sm text-gray-700 dark:text-gray-300">
      <div className="flex items-center mb-1 sm:w-full w-auto">
        <FaClock className="w-4 h-4 mr-1 text-red-600 dark:text-red-400" />
        <span className="font-semibold">{formatTimeLeft()}</span>
      </div>
      <div className="flex items-center mb-1 sm:w-full w-auto">
        <FaBoxes className="w-4 h-4 mr-1 text-red-600 dark:text-red-400" />
        <span className="font-semibold">{quantityLeft} Left</span>
      </div>
      <div className="flex items-center mb-1 sm:w-full w-auto">
        <FaTruck className="w-4 h-4 mr-1 text-red-600 dark:text-red-400" />
        <span className="font-semibold">{shippingRate}</span>
      </div>
    </div>
  );
};

FlashSaleDetails.propTypes = {
  shippingRate: PropTypes.string.isRequired,
  quantityLeft: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  startTime: PropTypes.number.isRequired
};

const ViewerFlashSale = ({ shouldRenderInTab }) => {
  const { SUBMIT_PURCHASE } = CHAT_MESSAGE_EVENT_TYPES;

  // Context Hooks
  const {
    actions: { sendMessage }
  } = useChat();
  const {
    isSubmitting,
    flashSaleStartTime,
    flashSaleDuration,
    dispatchFlashSaleState,
    flashSaleTitle,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount,
    flashSaleImageUrl
  } = useFlashSale();

  const { userData = {}, isSessionValid } = useUser();
  const { trackingId } = userData;
  const { channelData } = useChannel();
  const { username: channelUsername, channelArn: sellerId } = channelData || {};

  const [stripePromise, setStripePromise] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const isSoldOut = useMemo(
    () => flashSaleQuantity - flashSalePurchaseCount <= 0,
    [flashSaleQuantity, flashSalePurchaseCount]
  );

  const formattedDiscountPrice = useMemo(
    () => formatPrice(flashSaleDiscountPrice),
    [flashSaleDiscountPrice]
  );

  const formattedPrice = useMemo(
    () => formatPrice(flashSalePrice),
    [flashSalePrice]
  );

  const formattedShippingRate = useMemo(
    () =>
      flashSaleShippingRate > 0
        ? formatPrice(flashSaleShippingRate)
        : 'Free Shipping',
    [flashSaleShippingRate]
  );

  const navigateToLogin = useCallback(() => {
    navigate('/login', {
      state: { from: location, locationStoredUsername: channelUsername }
    });
  }, [location, navigate, channelUsername]);

  const flashSaleRef = useRef();

  useEffect(() => {
    if (flashSaleRef.current) {
      dispatchFlashSaleState({ flashSaleRef: flashSaleRef.current });
    }
  }, [dispatchFlashSaleState]);

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const stripeResponse = await getStripePublishableKey();
        if (stripeResponse.result.stripePublishableKey) {
          setStripePromise(
            loadStripe(stripeResponse.result.stripePublishableKey)
          );
        } else {
          console.error('Stripe publishable key not found:', stripeResponse);
        }
      } catch (error) {
        console.error('Error fetching Stripe publishable key:', error);
      }
    };

    fetchStripeKey();
  }, []);

  const submitPurchase = useCallback(async () => {
    if (isSubmitting || isSoldOut) return;

    if (isSessionValid) {
      dispatchFlashSaleState({ isSubmitting: true });

      try {
        await sendMessage(SUBMIT_PURCHASE, {
          purchaser: trackingId,
          eventType: SUBMIT_PURCHASE,
          flashSaleDuration: JSON.stringify(flashSaleDuration),
          flashSaleStartTime: JSON.stringify(flashSaleStartTime),
          flashSalePurchaseCount: JSON.stringify(flashSalePurchaseCount)
        });

        const sessionId = await createBuyNowSession(
          flashSaleTitle,
          flashSaleDiscountPrice,
          flashSaleShippingRate,
          sellerId
        );

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe Checkout error:', error);
        }
      } catch (error) {
        console.error('Error during purchase:', error);
      } finally {
        dispatchFlashSaleState({ isSubmitting: false, isPurchasing: false });
      }
    } else {
      navigateToLogin();
    }
  }, [
    isSubmitting,
    isSoldOut,
    isSessionValid,
    dispatchFlashSaleState,
    sendMessage,
    SUBMIT_PURCHASE,
    trackingId,
    flashSaleDuration,
    flashSaleStartTime,
    flashSalePurchaseCount,
    flashSaleTitle,
    flashSaleDiscountPrice,
    flashSaleShippingRate,
    sellerId,
    stripePromise,
    navigateToLogin
  ]);

  return (
    <FlashSaleContainer ref={flashSaleRef}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
        <div className="flex flex-col sm:flex-row items-center">
          {flashSaleImageUrl && (
            <div className="sm:w-1/3 mb-4 sm:mb-0 sm:mr-4">
              <FlashSaleImage imageUrl={flashSaleImageUrl} />
            </div>
          )}
          <div className="sm:w-2/3 space-y-2">
            <FlashSaleTitle title={flashSaleTitle} />
            <FlashSalePrice
              discountPrice={formattedDiscountPrice}
              price={formattedPrice}
            />
            <FlashSaleButton
              onClick={submitPurchase}
              isSoldOut={isSoldOut}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
        <FlashSaleDetails
          shippingRate={formattedShippingRate}
          quantityLeft={flashSaleQuantity - flashSalePurchaseCount}
          duration={flashSaleDuration}
          startTime={flashSaleStartTime}
        />
      </div>
    </FlashSaleContainer>
  );
};

ViewerFlashSale.propTypes = {
  shouldRenderInTab: PropTypes.bool.isRequired
};

export default ViewerFlashSale;