import { AnimatePresence } from 'framer-motion';
import { useCallback, useMemo, useRef, useState } from 'react';
import { channelAPI } from '../../../../../api';
import { CUSTOM_AVATAR_NAME } from '../../../../../helpers';
import { dashboard as $content } from '../../../../../content';
import {
  MaximumSizeExceededError,
  UnsupportedFileFormatError,
} from '../../../../../hooks/useImageUpload';
import { isS3Url } from '../../../../../utils';
import { useUser } from '../../../../../contexts/User';
import * as userAvatars from '../../../../../assets/avatars';
import IconSelect from '../../../../../components/IconSelect';
import ImageUploader from '../ImageUploader';
import SettingContainer from '../../SettingContainer';
import UploadAvatarMarker from './UploadAvatarMarker';
import useStateWithCallback from '../../../../../hooks/useStateWithCallback';

const Avatar = () => {
  const { userData, fetchUserData } = useUser();
  const { avatar, channelAssetUrls, username } = userData || {};
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(avatar);
  const [avatarUrl, setAvatarUrl] = useStateWithCallback(channelAssetUrls?.avatar || '');
  const [isAvatarUploaderOpen, setIsAvatarUploaderOpen] = useState(avatar === CUSTOM_AVATAR_NAME);
  const isAvatarUploaded = useRef(!!avatarUrl);

  const usernameInitial = username ? username.charAt(0).toUpperCase() : '';

  const userAvatarItems = useMemo(
    () => [
      [
        CUSTOM_AVATAR_NAME,
        avatarUrl,
        {
          CustomMarker: (
            <UploadAvatarMarker
              isOpen={isAvatarUploaderOpen}
              isUploaded={isAvatarUploaded.current}
            />
          ),
        },
      ],
      ...Object.entries(userAvatars).map(([key, value]) => [
        key,
        value,
        { label: usernameInitial },
      ]),
    ],
    [avatarUrl, isAvatarUploaded, isAvatarUploaderOpen, usernameInitial]
  );
  const imageUploaderRef = useRef();

  const handleChangeAvatar = useCallback(
    async ({ newSelection, previewUrl, uploadDateTime, action = 'selection' }) => {
      const isCustomAvatar = newSelection === CUSTOM_AVATAR_NAME;
      const isSameAvatarSelected = avatar === newSelection;

      if (isAvatarLoading) return;

      setIsAvatarUploaderOpen((prev) => {
        let shouldOpen = false;

        if (isCustomAvatar) shouldOpen = isAvatarUploaded.current || !prev;

        if (shouldOpen) {
          setTimeout(
            () =>
              imageUploaderRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              }),
            350
          );
        }

        return shouldOpen;
      });

      if (
        action !== 'upload' &&
        (isSameAvatarSelected || (isCustomAvatar && !isAvatarUploaded.current))
      )
        return;

      setSelectedAvatar(newSelection); // eagerly set the selected avatar
      setIsAvatarLoading(true);
      const { result, error } = await channelAPI.changeUserPreferences({
        avatar: { name: newSelection, previewUrl, uploadDateTime },
      });

      if (result) {
        let newUserData = await fetchUserData();
        while (isS3Url(newUserData.channelAssetUrls.avatar)) {
          newUserData = await fetchUserData();
        }
        newUserData = await fetchUserData();
        setAvatarUrl(newUserData.channelAssetUrls.avatar);
        setSelectedAvatar(result.avatar.name);
        if (action === 'selection')
          setMessage({ type: 'success', text: $content.notification.success.avatar_saved });
      }

      if (error) {
        setSelectedAvatar(avatar);
        setMessage({ type: 'error', text: $content.notification.error.avatar_failed_to_save });
      }

      setIsAvatarLoading(false);
    },
    [avatar, fetchUserData, isAvatarLoading, setAvatarUrl]
  );

  const onUpload = useCallback(
    ({ result, error }) => {
      if (result) {
        const { previewUrl, uploadDateTime } = result;

        isAvatarUploaded.current = true;
        handleChangeAvatar({
          action: 'upload',
          newSelection: CUSTOM_AVATAR_NAME,
          previewUrl,
          uploadDateTime,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (error) {
        switch (true) {
          case error instanceof UnsupportedFileFormatError:
            setMessage({ type: 'error', text: $content.notification.error.cant_select_file });
            break;
          case error instanceof MaximumSizeExceededError:
            setMessage({ type: 'error', text: $content.notification.error.image_exceeded_max_size });
            break;
          default:
            setMessage({ type: 'error', text: $content.notification.error.avatar_failed_to_upload });
        }
      }
    },
    [handleChangeAvatar, setMessage]
  );

  const onImageDownload = useCallback(
    () => setMessage({ type: 'success', text: $content.notification.success.avatar_uploaded }),
    [setMessage]
  );

  const onDelete = useCallback(
    ({ error }) => {
      if (error)
        return setMessage({ type: 'error', text: $content.notification.error.avatar_failed_to_delete });

      setAvatarUrl('', () => {
        isAvatarUploaded.current = false;
        const avatarNames = Object.keys(userAvatars);
        const randomAvatar = avatarNames[Math.floor(Math.random() * avatarNames.length)];

        handleChangeAvatar({ newSelection: randomAvatar, action: 'deletion' });
        setMessage({ type: 'success', text: $content.notification.success.avatar_deleted });
      });
    },
    [handleChangeAvatar, setMessage, setAvatarUrl]
  );

  return (
    <SettingContainer label={$content.settings_page.avatar}>
      {message.text && (
        <div className={`mb-4 p-4 rounded ${message.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message.text}
        </div>
      )}
      <IconSelect
        isLoading={isAvatarLoading}
        items={userAvatarItems}
        onSelect={handleChangeAvatar}
        selected={selectedAvatar}
        type="image"
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
      />
      <AnimatePresence initial={false}>
        {isAvatarUploaderOpen && (
          <ImageUploader
            ref={imageUploaderRef}
            assetType="avatar"
            className="mt-6"
            onDelete={onDelete}
            onImageDownload={onImageDownload}
            onUpload={onUpload}
            uploadUrl={avatarUrl}
          />
        )}
      </AnimatePresence>
    </SettingContainer>
  );
};

export default Avatar;
