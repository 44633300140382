export const MENU_BUTTON_CLASSES = [
  'px-5',
  'py-1',
  'text-gray-800',
  'dark:text-white',
  'hover:bg-gray-100', // Light theme hover
  'dark:hover:bg-darkMode-gray-hover',
  'flex items-center', // Ensure text and icons are aligned
  'transition-colors duration-150', // Smooth transition for hover and active states
  '[&>svg]:mr-1', // Space between icon and text
  '[&>svg]:w-6',
  '[&>svg]:h-6',
  'h-11', //Button height and how close buttons are to each other
];

export const HAIRLINE_DIVIDER_CLASSES = [
  'w-full',
  'h-0.5',
  'rounded-xl',
  'flex-shrink-0',
  'lg:portrait:mx-4',
  'bg-lightMode-gray-hover',
  'dark:bg-darkMode-gray-hover'
];
