// ButtonTheme.js

export const BUTTON_OUTLINE_CLASSES = [
  'focus:outline-none',
];

export const BUTTON_BASE_CLASSES = [
  ...BUTTON_OUTLINE_CLASSES,
  'border-none',
  'cursor-pointer',
  'disabled:cursor-not-allowed',
  'disabled:opacity-50',
  'duration-150',
  'ease-in-out',
  'flex',
  'h-11',
  'items-center',
  'justify-center',
  'min-w-[98px]',
  'px-6',
  'py-3',
  'rounded-lg',
  'select-none',
  'transition',
  'w-auto',
  'whitespace-nowrap',
  '[&>svg]:fill-current'
];

const TEXT_BASE_CLASSES = [
  'bg-transparent',
  'text-gray-900',
  'dark:text-white',
  'dark:[&>.spinner]:text-white'
];

const TEXT_BASE_HOVER_CLASSES = [
  'hover:bg-gray-100',
  'dark:hover:bg-gray-800'
];

export const BUTTON_HOVER_CLASSES = {
  primary: [
    'hover:bg-gray-800',
    'dark:hover:bg-gray-200'
  ],
  secondary: [
    'hover:bg-gray-200',
    'dark:hover:bg-gray-700'
  ],
  tertiary: [
    'hover:bg-gray-700',
    'dark:hover:bg-gray-200'
  ],
  destructive: [
    'hover:bg-red-700',
    'dark:hover:bg-red-700'
  ],
  icon: [
    'hover:bg-gray-200',
    'dark:hover:bg-gray-700'
  ],
  primaryText: [...TEXT_BASE_HOVER_CLASSES],
  secondaryText: [...TEXT_BASE_HOVER_CLASSES],
  tertiaryText: [...TEXT_BASE_HOVER_CLASSES]
};

export const BUTTON_VARIANT_CLASSES = {
  primary: [
    'bg-gray-900',
    'text-white',
    'shadow',
    'focus:bg-gray-800',
    'dark:bg-white',
    'dark:text-gray-900',
    'dark:focus:bg-gray-200',
    '[&>svg]:fill-white',
    'dark:[&>svg]:fill-gray-900'
  ],
  secondary: [
    'bg-gray-100',
    'text-gray-900',
    'focus:bg-gray-200',
    'dark:bg-gray-700',
    'dark:text-white',
    'dark:focus:bg-gray-600',
    '[&>svg]:fill-gray-900',
    'dark:[&>svg]:fill-white'
  ],
  tertiary: [
    'bg-gray-700',
    'text-white',
    'focus:bg-gray-600',
    'dark:bg-gray-100',
    'dark:text-gray-900',
    'dark:focus:bg-gray-200',
    '[&>svg]:fill-white',
    'dark:[&>svg]:fill-gray-900'
  ],
  destructive: [
    'bg-red-600',
    'text-white',
    'focus:bg-red-700',
    'dark:text-white',
    '[&>.spinner]:text-white',
    '[&>svg]:fill-white'
  ],
  icon: [
    'bg-transparent',
    'p-2.5',
    'rounded-full',
    'h-auto',
    'min-w-[auto]',
    'focus:bg-gray-200',
    'dark:focus:bg-gray-700',
    '[&>svg]:fill-current',
    '[&>svg:focus]:fill-current',
    '[&>svg:disabled]:fill-gray-400',
    'dark:[&>svg:disabled]:fill-gray-500'
  ],
  primaryText: [
    ...TEXT_BASE_CLASSES,
    '[&>.spinner]:text-gray-900',
    '[&>svg]:fill-gray-900',
    'dark:[&>.spinner]:text-white',
    'dark:[&>svg]:fill-white'
  ],
  secondaryText: [
    'bg-transparent',
    'text-gray-900',
    'dark:text-white',
    '[&>.spinner]:text-gray-900',
    '[&>svg]:fill-gray-900',
    'dark:[&>.spinner]:text-white',
    'dark:[&>svg]:fill-white'
  ],
  tertiaryText: [
    'bg-transparent',
    'text-gray-800',
    'dark:text-gray-100',
    'space-x-3',
    '!justify-start',
    'px-3',
    'py-1.5',
    'rounded-xl',
    '[&>.spinner]:text-gray-700',
    '[&>svg]:fill-gray-700',
    'dark:[&>svg]:fill-gray-100'
  ]
};

export const BUTTON_LINK_CLASSES = [
  'border-none',
  'm-0',
  'py-3',
  'px-6',
  'outline-none'
];
