import {
  apiBaseUrl,
  authFetch,
  generatePresignedPostProductImage
} from './utils';

export const createProduct = async (productData) => {

  const response = await authFetch({
    method: 'POST',
    url: `${apiBaseUrl}/product`,
    body: productData
  });

  return response;
};

export const deleteProduct = async (productId) => {
  const response = await authFetch({
    method: 'DELETE',
    url: `${apiBaseUrl}/product/${productId}`
  });

  return response;
};

export const updateProduct = async (updatedProductData) => {

  const { id, ...updateDataWithoutId } = updatedProductData;

  const response = await authFetch({
    method: 'PUT',
    url: `${apiBaseUrl}/product/${id}`,
    body: updateDataWithoutId
  });

  return response;
};


export const getProductDetails = async (productId) => {
  const { result: productDetails } = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/product/${productId}`
  });

  return productDetails;
};

export const uploadFileToS3 = async ({ productAssetId, assetType, file }) => {
  let result, error;

  try {
    const contentType = file.type;
    const { result: presignedPost, error: presignedPostError } =
      await generatePresignedPostProductImage({
        productAssetId,
        assetType,
        contentType
      });

    if (presignedPostError) throw presignedPostError;

    const formData = new FormData();
    Object.entries(presignedPost.fields).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append('Content-Type', contentType);
    formData.append('file', file);

    const response = await fetch(presignedPost.url, {
      body: formData,
      method: 'POST'
    });

    if (response.ok) {
      const versionId = response.headers.get('x-amz-version-id');
      const uploadDateTime = response.headers.get('Date');
      const previewUrl = new URL(response.headers.get('Location'));
      previewUrl.searchParams.append('versionId', versionId);

      result = { previewUrl: previewUrl.toString(), uploadDateTime };
    } else {
      throw new Error('A Presigned Post fetch error occurred');
    }
  } catch (err) {
    error = err;
  }

  if (error) console.error(error);

  return { result, error };
};

export const deleteProductAsset = async (productId, assetType) =>
  await authFetch({
    method: 'DELETE',
    url: `${apiBaseUrl}/product/asset`,
    body: { productId, assetType }
  });

export const changeProductPreferences = async (productPreferences) => {
  const response = await authFetch({
    body: productPreferences,
    method: 'PUT',
    url: `${apiBaseUrl}/product/preferences/update`
  });
  return response;
};
