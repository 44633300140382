import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

// import {
//   getPrimaryButtonBgColorClass,
//   shouldForceWhiteTextLightDark,
//   shouldForceWhiteTextLightMode,
//   amazonButtonClasses
// } from '../ProductTheme';
import {
  BUTTON_CONTAINER_BASE_CLASSES as buttonContainerBaseClasses,
  BUTTON_CONTAINER_VARIANT_CLASSES as buttonContainerVariantClasses
} from './ProductButtonTheme';
import { AmazonA } from '../../../../../assets/icons';
import { channel as $channelContent } from '../../../../../content';
import { clsm, noop } from '../../../../../utils';
import Button from '../../../../../components/Button';
import Spinner from '../../../../../components/Spinner';

const $productPopupContent = $channelContent.actions.product;

const ProductButtons = forwardRef(
  ({ variant, productUrl, addToCart, quantity }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    // const color = 'blue' || 'default';
    const isModal = variant !== 'popup';
    const isAmazonProduct = productUrl !== '';

    const productContainerClasses = [
      buttonContainerBaseClasses,
      buttonContainerVariantClasses[variant]
    ];

    // const buttonClasses = isAmazonProduct
    //   ? amazonButtonClasses
    //   : getPrimaryButtonBgColorClass(color);

    // const buttonTextClasses = isAmazonProduct
    //   ? 'text-black'
    //   : [
    //       shouldForceWhiteTextLightMode(color) && [
    //         'text-white',
    //         'dark:text-black'
    //       ],
    //       shouldForceWhiteTextLightDark(color) && [
    //         'text-white',
    //         'dark:text-white'
    //       ]
    //     ];

    const handleAddToCart = async (event) => {
      setIsLoading(true);
      event.stopPropagation();

      try {
        await addToCart();
      } catch (error) {
        console.error('Checkout error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className={clsm(productContainerClasses)}>
        {quantity < 1 ? (
          <span className="text-red-500">Out of Stock</span>
        ) : (
          <Button
            ariaLabel={`${$productPopupContent.add_to_cart}`}
            onClick={
              isAmazonProduct
                ? (event) => {
                    event.stopPropagation();
                    window.open(productUrl, '_blank');
                  }
                : handleAddToCart
            }
            variant="primary"
            className={clsm([
              // buttonClasses,
              // buttonTextClasses,
              isModal && 'w-full',
              'text-gray-100',
              '[&>svg]:fill-black',
              'dark:[&>svg]:fill-black',
              '[&>svg]:h-6',
              '[&>svg]:w-6',
              '[&>svg]:mr-2',
              'md:w-full',
              'shadow'
            ])}
            ref={ref}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner className="w-6 h-6" />
              </div>
            ) : (
              <>
                {quantity < 1 ? (
                  <span className="text-red-500">Out of Stock</span>
                ) : (
                  <>
                    {isAmazonProduct && <AmazonA />}
                    {isModal
                      ? $productPopupContent.popup.add_to_cart
                      : $productPopupContent.add_to_cart}
                  </>
                )}
              </>
            )}
          </Button>
        )}
      </div>
    );
  }
);

ProductButtons.defaultProps = {
  productUrl: '',
  addToCart: noop,
  quantity: 1
};

ProductButtons.propTypes = {
  productUrl: PropTypes.string,
  variant: PropTypes.oneOf([
    'popup',
    'productDescriptionDesktop',
    'productDescriptionMobile'
  ]).isRequired,
  addToCart: PropTypes.func,
  quantity: PropTypes.number
};

export default ProductButtons;
