import { app as $content } from '../content';
import { clsm } from '../utils';
import { FaExclamationTriangle } from 'react-icons/fa';
import Button from './Button';

const PageUnavailable = () => (
  <div
    className={clsm(
      'flex',
      'flex-col',
      'items-center',
      'justify-center',
      'text-center',
      'min-h-screen',
      'w-full',
      'bg-gradient-to-b',
      'from-lightMode-gray',
      'to-lightMode-gray-light',
      'dark:from-gray-900',
      'dark:to-black',
      'p-6'
    )}
  >
    <div className={clsm('flex', 'flex-col', 'items-center', 'space-y-6')}>
      <FaExclamationTriangle className={clsm('text-yellow-500', 'text-2xl')} />
      <h1
        className={clsm(
          'text-2xl',
          'font-semibold',
          'text-black',
          'dark:text-white'
        )}
      >
        {$content.page_unavailable}
      </h1>
      <p
        className={clsm(
          'text-lg',
          'text-gray-700',
          'dark:text-gray-300',
          'max-w-lg'
        )}
      >
        Oops! Something went wrong. Please refresh the page or go back to the
        homepage.
      </p>
      <Button type="nav" variant="primary" to="/">
        {$content.back_to_directory}
      </Button>
    </div>
  </div>
);

export default PageUnavailable;
