import PropTypes from 'prop-types';
import { clsm, noop } from '../../../utils';
import DataUnavailable from './DataUnavailable';
import Spinner from '../../../components/Spinner';

const PAGE_CENTERED_CONTENT_BASE_CLASSES = [
  'flex',
  'flex-col',
  'min-h-[50vh]',
  'items-center',
  'justify-center',
  'text-center',
  'w-full',
];

const GRID_LAYOUT_CLASSES = [
  'grid',
  'gap-6',
  'sm:grid-cols-1',
  'md:grid-cols-1',
  'lg:grid-cols-3',
  'xl:grid-cols-4',
  '2xl:grid-cols-5',
];

const GridLayout = ({
  children = [],
  className = '',
  hasError = false,
  hasData = false,
  isLoading = false,
  noDataText = '',
  title = '',
  tryAgainFn = noop,
  tryAgainText = ''
}) => (
  <section className={clsm(['flex', 'flex-col', 'grow', hasData && 'space-y-8', className])}>
    {title && <h2 className={clsm(['text-2xl', 'font-bold', 'mb-4'])}>{title}</h2>}
    {!isLoading && hasData && (
      <div className={clsm(GRID_LAYOUT_CLASSES)}>
        {children}
      </div>
    )}
    {!isLoading && !hasData && (
      <DataUnavailable
        className={clsm([...PAGE_CENTERED_CONTENT_BASE_CLASSES, 'space-y-4'])}
        noDataText={noDataText}
        hasError={hasError}
        tryAgainFn={tryAgainFn}
        tryAgainText={tryAgainText}
      />
    )}
    {isLoading && (
      <div className={clsm(PAGE_CENTERED_CONTENT_BASE_CLASSES)}>
        <Spinner size="large" className="text-blue-500 dark:text-blue-400" />
      </div>
    )}
  </section>
);

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  hasData: PropTypes.bool,
  isLoading: PropTypes.bool,
  noDataText: PropTypes.string,
  title: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string
};

export default GridLayout;
