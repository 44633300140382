import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { clsm, noop } from '../../../../../utils';
import Button from '../../../../../components/Button';
import { AmazonA } from '../../../../../assets/icons';
import { channel as $channelContent } from '../../../../../content';

const $productPopupContent = $channelContent.actions.product;

const ProductButtons = forwardRef(
  ({ openProductDetails, variant, productUrl }, ref) => {

    const isModal = variant !== 'popup';
    const isAmazonProduct = productUrl !== '';

    const productContainerClasses = clsm([
      'flex',
      'flex-col',
      variant === 'popup' && 'space-y-2 w-full',
      variant === 'productDescriptionDesktop' && 'items-center space-y-4',
      variant === 'productDescriptionMobile' && 'md:w-full md:flex-row-reverse'
    ]);

    const buttonClasses = isAmazonProduct
      ? [
          'bg-orange-600',
          'dark:hover:bg-orange-700',
          'focus:bg-orange-600',
          'focus:ring-white',
          'hover:bg-orange-500',
          'text-black'
        ]
      : [
          'bg-indigo-500',
          'focus:bg-indigo-500',
          'hover:bg-indigo-600',
          'dark:bg-indigo-400',
          'dark:focus:bg-indigo-400',
          'dark:hover:bg-indigo-500',
          'text-white'
        ];

    return (
      <div className={productContainerClasses}>
        <Button
          ariaLabel={`${$productPopupContent.buy_now}`}
          onClick={
            isAmazonProduct ? () => window.open(productUrl, '_blank') : noop
          }
          className={clsm([
            ...buttonClasses,
            'py-2',
            'px-4',
            'rounded-lg',
            'shadow',
            isModal && 'w-full',
            '[&>svg]:fill-current',
            '[&>svg]:h-6',
            '[&>svg]:w-6',
            '[&>svg]:mr-2',
            'md:w-full'
          ])}
        >
          {isAmazonProduct && <AmazonA />}
          {isModal
            ? $productPopupContent.popup.buy_now
            : $productPopupContent.buy_now}
        </Button>
        {!isAmazonProduct && (
          <Button
            variant="secondary"
            className={clsm([
              'border',
              'border-indigo-500',
              'text-indigo-500',
              'py-2',
              'px-4',
              'rounded-lg',
              'shadow',
              'md:w-full',
              variant === 'productDescriptionDesktop' && 'mt-1 w-full',
              variant === 'productDescriptionMobile' && 'md:mr-3'
            ])}
            onClick={isModal ? noop : openProductDetails}
            ref={ref}
          >
            {isModal
              ? $productPopupContent.popup.add_to_cart
              : $productPopupContent.learn_more}
          </Button>
        )}
      </div>
    );
  }
);

ProductButtons.defaultProps = {
  openProductDetails: noop,
  productUrl: ''
};

ProductButtons.propTypes = {
  openProductDetails: PropTypes.func,
  productUrl: PropTypes.string,
  variant: PropTypes.oneOf([
    'popup',
    'productDescriptionDesktop',
    'productDescriptionMobile'
  ]).isRequired
};

export default ProductButtons;
