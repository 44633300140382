import { Provider as ChannelViewProvider } from './contexts/ChannelView';
import { Provider as ProfileViewAnimationProvider } from './contexts/ProfileViewAnimation';
import { Provider as PollProvider } from '../../contexts/StreamManagerActions/Poll';
import { Provider as AuctionProvider } from '../../contexts/StreamManagerActions/Auction';
import { Provider as FlashSaleProvider } from '../../contexts/StreamManagerActions/FlashSale';
import ChannelPage from './Channel';

export default function Channel() {
  return (
    <ChannelViewProvider>
      <FlashSaleProvider>
        <AuctionProvider>
          <PollProvider>
            <ProfileViewAnimationProvider>
              <ChannelPage />
            </ProfileViewAnimationProvider>
          </PollProvider>
        </AuctionProvider>
      </FlashSaleProvider>
    </ChannelViewProvider>
  );
}
