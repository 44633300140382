import PropTypes from 'prop-types';

import { clsm, noop } from '../../../../../utils';
import { Feed } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';

const DataUnavailable = ({
  className = '',
  noDataText = '',
  hasError = false,
  tryAgainFn = noop,
  tryAgainText = '',
  isSidebarOpen = false
}) => (
  <div className={clsm([className, 'space-y-8'])}>
    <div
      className={clsm([
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        // 'opacity-70',
        'space-y-4'
      ])}
    >
     
     {!isSidebarOpen && (<Feed
        className={clsm([
          '[&>path]:fill-gray-500',
          '[&>path]:dark:fill-white',
          'h-7',
          'w-7'
        ])}
      /> 
      )}
      {isSidebarOpen && (
        <><Feed
          className={clsm([
            '[&>path]:fill-gray-500',
            '[&>path]:dark:fill-white',
            'h-7',
            'w-7'
          ])} /><h5 className={clsm(['text-gray-800', 'dark:text-white'])}>
            {noDataText}
          </h5></>
      )}
    </div>
    {hasError && (
      <Button
        onClick={tryAgainFn}
        variant="secondary"
        className="bg-lightMode-gray"
      >
        {tryAgainText}
      </Button>
    )}
  </div>
);

DataUnavailable.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  noDataText: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string,
  isSidebarOpen: PropTypes.bool.isRequired
};

export default DataUnavailable;
