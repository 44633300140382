import PropTypes from 'prop-types';

import { clsm } from '../../../../../utils';

const PageLayout = ({ children }) => {
  return (
    <div
      className={clsm([
        'bg-white',
        'dark:bg-black',
        'flex-col',
        'flex',
        'h-full',
        'items-center',
        'justify-start',
        'overflow-x-hidden',
        'overflow-y-auto',
        'no-scrollbar',
        'py-5',
        'px-2',
        'pr-4',
        'w-auto',
        'border-lightMode-gray-light',
        'dark:border-darkMode-gray-medium'
      ])}
    >
      {children}
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageLayout;
