import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

// Reusable ChevronIcon Component
const ChevronIcon = ({ isOpen }) => (
  <svg
    className={`w-5 h-5 text-indigo-600 dark:text-indigo-400 transition-transform duration-300 ${
      isOpen ? 'rotate-180' : ''
    }`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    aria-hidden="true"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
);

ChevronIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

// Reusable FAQItem Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-indigo-200 dark:border-indigo-700">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full py-4 text-left focus:outline-none"
        aria-expanded={isOpen}
        aria-controls={`faq-answer-${question}`}
      >
        <h4 className="text-lg font-medium text-indigo-900 dark:text-indigo-100">
          {question}
        </h4>
        <ChevronIcon isOpen={isOpen} />
      </button>
      <div
        id={`faq-answer-${question}`}
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <p className="py-2 text-gray-600 dark:text-gray-300">{answer}</p>
      </div>
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

// FAQSection Component
const FAQSection = () => {
  const [showAll, setShowAll] = useState(false);

  const faqs = [
    {
      question: 'What makes Livescape Shops unique?',
      answer:
        'Livescape Shops offers a curated shopping experience where boutiques and shops showcase their collections through live streams. Our platform bridges the gap between traditional retail shopping and digital convenience.'
    },
    {
      question: 'How do live sessions work?',
      answer:
        'Each live session is a shopping experience where shops present their carefully selected pieces. You can view detailed product features, ask questions about craftsmanship, and make purchases while interacting directly with the creator or curator.'
    },
    {
      question: 'What kind of shops can I discover?',
      answer:
        'Our platform features a diverse selection of boutiques and shops, from emerging designers and artisanal craftspeople to established luxury brands. Each seller brings their unique aesthetic and expertise to our community.'
    },
    {
      question: 'How personal is the shopping experience?',
      answer:
        'Every live session feels like a private shopping appointment. You can discuss fabric choices, styling options, and product details directly with shops. Many sellers also offer personalization and made-to-order options during their streams.'
    },
    // {
    //   question: 'How do you ensure quality and authenticity?',
    //   answer: (
    //     <>
    //       We carefully curate our boutique partners, ensuring each meets our standards for craftsmanship and authenticity. You can preview their credentials in our{' '}
    //       <Link
    //         to="https://help.livescapeshops.com/en/"
    //         className="text-[#9E2A2B] hover:text-[#7C1D1D] underline"
    //       >
    //         Artisan Directory
    //       </Link>{' '}
    //       and read verified customer experiences.
    //     </>
    //   )
    // },
    {
      question: 'How can I open my store on Livescape?',
      answer: (
        <>
          We welcome passionate boutiques and shops who share our commitment to quality and personal service. To learn about joining our curated marketplace, visit our{' '}
          <Link
            to="https://help.livescapeshops.com/en/articles/9918987-how-to-become-a-seller-on-livescape-shops"
            className="text-[#9E2A2B] hover:text-[#7C1D1D] underline"
          >
            Partnership Guide
          </Link>
          .
        </>
      )
    },
    {
      question: 'What about shipping and delivery?',
      answer:
        'Each retailer handles their shipping with the same care they put into their products. During checkout, you\'ll see detailed shipping options and timeline estimates. Many of our shops offer premium packaging and tracking services to ensure your pieces arrive perfectly presented.'
    }
  ];

  const displayedFaqs = showAll ? faqs : faqs.slice(0, 4);

  return (
    <section className="w-full max-w-7xl mx-auto  rounded-xl my-16">
      <div className="py-12 sm:py-16">
        <div className="max-w-6xl mx-auto px-6">
          <div className="flex flex-col items-center space-y-8">
            <h2 className="sm:text-2xl text-3xl font-bold text-indigo-900 dark:text-indigo-100 text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="w-full space-y-4">
              {displayedFaqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
            {faqs.length > 4 && (
              <div className="flex justify-center mt-8">
                <Button
                  variant="primary"
                  onClick={() => setShowAll(!showAll)}
                  className="px-6 py-3 sm:text-base text-base bg-indigo-600 dark:bg-indigo-500 text-white dark:text-gray-100 hover:bg-indigo-700 dark:hover:bg-indigo-600 font-bold rounded-full shadow-md transition-all duration-300 ease-in-out"
                >
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
