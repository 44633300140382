import PropTypes from 'prop-types';

import { LIMIT_EXCEEDED_EXCEPTION } from '../../../../constants';
import { useNotif } from '../../../../contexts/Notification';
import { userManagement as $content } from '../../../../content';
import { channelAPI } from '../../../../api';
import Button from '../../../../components/Button';

const RegisterUserRequestConfirmation = ({ username }) => {
  const { notifySuccess, notifyError } = useNotif();

  const resend = async () => {
    const { result, error } =
      await channelAPI.resendVerificationRequest(username);

    if (result) {
      notifySuccess($content.notification.success.resent_confirmation);
    }

    if (error) {
      const errorNotificationMessage =
        error?.name === LIMIT_EXCEEDED_EXCEPTION
          ? $content.notification.error.resent_confirmation_limit_error
          : $content.notification.error.resent_confirmation_error;
      notifyError(errorNotificationMessage);
    }
  };

  return (
    <div className="rounded-lg">
    <div className="w-96 dark:bg-darkMode-gray-dark p-10 sm:p-8 xs:p-8 rounded-xl max-w-lg mx-auto text-center space-y-6 shadow">
      <Button
        to="/"
        type="nav"
        className="shadow-none mb-8 bg-transparent hover:bg-transparent focus:bg-transparent dark:hover:bg-transparent dark:bg-darkMode-gray-dark"
      >
        <img
          className="w-52 h-auto dark:hidden"
          src="/logo-ls.svg"
          alt="logo light"
        />
        <img
          className="w-52 h-auto hidden dark:block dark:bg-darkMode-gray-dark"
          src="/logo-ls-dark.svg"
          alt="logo dark"
        />
      </Button>
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
        {$content.register_page.verify_your_account}
      </h2>
      <p className="text-md text-gray-500 dark:text-gray-300">
        {$content.register_page.email_link_sent}
      </p>
      <div className="flex flex-col items-center space-y-4">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          <b>{$content.did_not_receive_email}</b>
        </span>
        <Button
          onClick={resend}
          type="button"
          variant="primary"
          className="mt-2"
        >
          {$content.resend}
        </Button>
        <Button
          to="/login"
          type="nav"
          variant="primaryText"
          className="text-blue-500 hover:underline dark:text-blue-400 hover:dark:text-blue-300 transition duration-200 ease-in-out"
        >
          {$content.return_to_login}
        </Button>
      </div>
    </div>
    </div>
  );
};

RegisterUserRequestConfirmation.propTypes = {
  username: PropTypes.string.isRequired
};

export default RegisterUserRequestConfirmation;
