import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { productAPI } from '../../api';
import Button from '../../components/Button';
import Input from './formElements/Input';
import TextArea from './formElements/TextArea';
import Spinner from '../../components/Spinner';

const EditProductForm = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { getProductDetails, updateProduct } = productAPI;

  // State definitions
  const initialProductState = useMemo(() => ({
    id: '',
    productName: '',
    productDescription: '',
    price: '',
    type: 'physical',
    quantity: 1,
    shippingRate: '',
    isActive: false
  }), []);

  const [product, setProduct] = useState(initialProductState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  // Data fetching
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await getProductDetails(productId);
        response.error
          ? setMessage({ text: response.error.message, type: 'error' })
          : setProduct(response);
      } catch (error) {
        console.error('Error fetching product details:', error);
        setMessage({
          text: 'An unexpected error occurred while fetching product details.',
          type: 'error'
        });
      }
    };

    fetchProductDetails();
  }, [productId, getProductDetails]);

  // Form handlers
  const handleChange = (event, dataKey) => {
    if (initialProductState.hasOwnProperty(dataKey)) {
      setProduct({ ...product, [dataKey]: event[dataKey] });
    }
  };

  const validateProduct = useCallback(() => {
    const newErrors = {};
    
    if (!product.productName.trim()) {
      newErrors.productName = 'Product name is required.';
    } else if (product.productName.length < 3) {
      newErrors.productName =
        'Product name must be at least 3 characters long.';
    }
    if (!product.productDescription.trim()) {
      newErrors.productDescription = 'Product description is required.';
    } else if (product.productDescription.length < 3) {
      newErrors.productDescription =
        'Product description must be at least 3 characters long.';
    }
    if (product.price === '' || isNaN(product.price)) {
      newErrors.price = 'Product price is required.';
    } else if (product.price <= 0) {
      newErrors.price = 'Product price must be a positive number.';
    }
    if (!['physical', 'digital'].includes(product.type)) {
      newErrors.type =
        'Invalid product type. Must be either "physical" or "digital".';
    }
    if (isNaN(product.quantity) || product.quantity < 0) {
      newErrors.quantity = 'Product quantity must be a non-negative number.';
    }
    if (
      product.type === 'physical' &&
      (product.shippingRate === '' ||
        isNaN(product.shippingRate) ||
        product.shippingRate < 0)
    ) {
      newErrors.shippingRate =
        'Shipping rate must be a non-negative number for physical products.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [product]);

  const getUpdatedProduct = useCallback(() => {
    const updatedProduct = {};
    Object.keys(initialProductState).forEach(key => {
      updatedProduct[key] = product[key];
    });
    return updatedProduct;
  }, [initialProductState, product]);

  const handleFormSubmit = useCallback(async (isActive) => {
    if (!validateProduct()) {
      setMessage({ text: 'Please correct the errors in the form.', type: 'error' });
      return;
    }

    setIsSubmitting(true);
    try {
      const updatedProduct = { ...getUpdatedProduct(), isActive };
      const response = await updateProduct(updatedProduct);
      
      if (response.error) {
        setMessage({ text: response.error.message, type: 'error' });
      } else {
        setMessage({
          text: isActive ? 'Product updated and listed successfully.' : 'Product saved as draft.',
          type: 'success'
        });
        navigate('/products');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage({
        text: 'An unexpected error occurred while updating the product.',
        type: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [getUpdatedProduct, validateProduct, updateProduct, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormSubmit(true);
  };

  const handleSaveAsDraft = () => handleFormSubmit(false);
  const handleUpdateImages = () => navigate(`/products/${productId}/assets`);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-black pt-20 pb-16">
      <div className="max-w-lg mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-100 dark:border-gray-700">
          <div className="px-6 py-6">
            {message.text && (
              <div className={`mb-4 p-3 rounded-lg border ${
                message.type === 'error'
                  ? 'bg-red-50 border-red-200 text-red-700 dark:bg-red-900/30 dark:border-red-800'
                  : 'bg-green-50 border-green-200 text-green-700 dark:bg-green-900/30 dark:border-green-800'
              } transition-all duration-300 ease-in-out`}>
                {message.text}
              </div>
            )}

            <div className="flex items-center justify-between mb-6 border-b border-gray-200 dark:border-gray-700 pb-4">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Edit Product</h1>
              <Button
                variant="secondary"
                className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 shadow"
                onClick={() => navigate('/products')}
              >
                ← Back
              </Button>
            </div>

            {/* Form content */}
            <form onSubmit={handleSubmit} aria-labelledby="form-title" noValidate className="space-y-6">
              <div className="space-y-5">
                <Input
                  label="Product Name"
                  dataKey="productName"
                  type="text"
                  value={product.productName}
                  onChange={(event) => handleChange(event, 'productName')}
                  error={errors.productName}
                  required
                  aria-required="true"
                  placeholder="Enter the product name"
                  maxLength={100}
                  className="transition-all duration-200 focus:ring-2 focus:ring-blue-500"
                />

                <TextArea
                  label="Product Description"
                  dataKey="productDescription"
                  rows="3"
                  value={product.productDescription}
                  onChange={(event) =>
                    handleChange(event, 'productDescription')
                  }
                  error={errors.productDescription}
                  required
                  aria-required="true"
                  placeholder="Describe the product"
                  maxLength={500}
                  className="transition-all duration-200 focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    label="Product Price"
                    dataKey="price"
                    type="number"
                    value={product.price}
                    onChange={(event) => handleChange(event, 'price')}
                    error={errors.price}
                    required
                    aria-required="true"
                    max={1000000}
                    step="0.01"
                    placeholder="0.00"
                    className="transition-all duration-200"
                  />

                  {product.type === 'physical' && (
                    <Input
                      label="Shipping Rate"
                      dataKey="shippingRate"
                      type="number"
                      value={product.shippingRate}
                      onChange={(event) => handleChange(event, 'shippingRate')}
                      error={errors.shippingRate}
                      aria-required="true"
                      step="0.01"
                      placeholder="0.00"
                      className="transition-all duration-200"
                    />
                  )}
                </div>

                <Input
                  label="Product Quantity"
                  dataKey="quantity"
                  type="number"
                  value={product.quantity}
                  onChange={(event) => handleChange(event, 'quantity')}
                  error={errors.quantity}
                  required
                  aria-required="true"
                  placeholder="1"
                  className="transition-all duration-200"
                />
              </div>

              <div className="pt-6 border-t border-gray-200 dark:border-gray-700">
                <div className="flex flex-col space-y-3">
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-full py-3 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 transition-all duration-200 shadow-lg hover:shadow-xl"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner /> : 'Save and List'}
                  </Button>
                  <div className="flex space-x-3">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={handleSaveAsDraft}
                      className="w-1/2 py-2.5 shadow hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <Spinner /> : 'Save as Draft'}
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={handleUpdateImages}
                      className="w-1/2 py-2.5 shadow hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                      disabled={isSubmitting}
                    >
                      Update Images
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductForm;
