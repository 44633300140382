import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { deleteProduct } from '../../api/product';
import { useModal } from '../../contexts/Modal';
import { useNotif } from '../../contexts/Notification';

import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import { FaSearch } from 'react-icons/fa';
import Dropdown from '../../components/Dropdown';

const ProductsTable = ({
  currentItems = [],
  setProducts = () => {},
  itemsPerPage = 10
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [productStatus, setProductStatus] = useState('all');
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage);

  const navigate = useNavigate();
  const { openModal } = useModal();
  const { notifySuccess, notifyError } = useNotif();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target?.value?.toLowerCase());
    setCurrentPage(1);
  };

  const handleProductStatusChange = (event) => {
    setProductStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event) => {
    setCurrentItemsPerPage(Number(event.target.value));
    setCurrentPage(1);  // Reset to first page when changing items per page
  };

  const filteredItems = useMemo(() => {
    return currentItems.filter((item) => {
      const statusMatch =
        productStatus === 'all' || item.isActive === (productStatus === 'true');
      const searchMatch =
        (item.productName
          ? item.productName.toLowerCase().includes(searchTerm)
          : false) ||
        (item.productDescription
          ? item.productDescription.toLowerCase().includes(searchTerm)
          : false);
      return statusMatch && searchMatch;
    });
  }, [currentItems, productStatus, searchTerm]);

  const totalItems = filteredItems.length;
  const totalPages = Math.ceil(totalItems / currentItemsPerPage);

  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * currentItemsPerPage;
    return filteredItems.slice(startIndex, startIndex + currentItemsPerPage);
  }, [filteredItems, currentPage, currentItemsPerPage]);

  const productNameRenderer = useCallback(
    ({ value }) => (
      <div className="truncate font-semibold text-gray-900 dark:text-gray-100">
        {value}
      </div>
    ),
    []
  );

  const productImageRenderer = useCallback(
    ({ value }) => (
      <div className="flex justify-center items-center">
        <img
          src={value || '/no-image.png'}
          alt="Product"
          className="w-10 h-10 object-cover rounded-md border border-gray-200 dark:border-gray-700"
        />
      </div>
    ),
    []
  );

  const handleDeleteSelected = useCallback(
    (product) => {
      const confirmText = 'Confirm Delete';
      const modalMessage = `Are you sure you want to delete ${product.productName}?`;
      const onConfirm = async () => {
        try {
          setIsLoading(true);
          await deleteProduct(product.id);
          notifySuccess('Product deleted successfully!');
          const updatedItems = currentItems.filter(
            (item) => item.id !== product.id
          );
          setProducts(updatedItems);
        } catch (error) {
          notifyError('Failed to delete product. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
      openModal({
        content: { confirmText, isDestructive: true, message: modalMessage },
        onConfirm
      });
    },
    [openModal, notifySuccess, notifyError, currentItems, setProducts]
  );

  const actionCellRenderer = useCallback(
    ({ data }) => (
      <div className="flex justify-center gap-1">
        <Button
          onClick={() => navigate(`/products/${data.id}`)}
          variant="tertiary"
          size="xs"
          title="Edit Product"
        >
          Edit
        </Button>
        <Button
          onClick={() => handleDeleteSelected(data)}
          variant="destructive"
          size="xs"
          title="Delete Product"
        >
          Delete
        </Button>
      </div>
    ),
    [navigate, handleDeleteSelected]
  );

  const capitalizeFirstLetter = (string) => {
    return typeof string === 'string' && string.length > 0
      ? string.charAt(0).toUpperCase() + string.slice(1)
      : string;
  };

  const statusCellRenderer = useCallback(({ value }) => {
    const statusColorMap = {
      active: 'bg-green-100 text-green-800',
      draft: 'bg-yellow-100 text-yellow-800',
      default: 'bg-gray-100 text-gray-800'
    };

    const status =
      typeof value === 'boolean' ? (value ? 'active' : 'draft') : 'default';
    const statusClasses = statusColorMap[status] || statusColorMap.default;

    return (
      <span
        className={`px-2 py-1 text-sm font-medium rounded-full ${statusClasses}`}
      >
        {capitalizeFirstLetter(status)}
      </span>
    );
  }, []);

  const stockCellRenderer = useCallback(
    ({ value }) => (
      <div className="text-center font-medium text-gray-700 dark:text-gray-200">
        {value}
      </div>
    ),
    []
  );

  const priceCellRenderer = useCallback(
    ({ value }) => (
      <div className="text-center font-semibold text-gray-900 dark:text-gray-100">
        ${value.toFixed(2)}
      </div>
    ),
    []
  );

  const onRowDoubleClicked = (item) => navigate(`/products/${item.id}`);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () =>
    totalPages > 1 && (
      <div className="flex justify-between items-center mt-3">
        <span className="text-sm text-gray-700 dark:text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            variant="secondary"
            size="small"
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            variant="secondary"
            size="small"
          >
            Next
          </Button>
        </div>
      </div>
    );

  const renderLoadingIndicator = () =>
    isLoading && (
      <div className="flex justify-center items-center mt-6">
        <Spinner size="large" variant="dark" />
      </div>
    );

  const renderLoadError = () =>
    loadError && (
      <div className="text-red-500 text-center mt-4">
        {loadError} <Button variant="secondary">Retry</Button>
      </div>
    );

  const renderEmptyState = () => {
    const hasProducts = currentItems.length > 0;
    return (
      <div className="text-center my-20 text-gray-600 dark:text-gray-400">
        {hasProducts ? (
          <p>
            No products match your search criteria. Try adjusting your search or
            filters.
          </p>
        ) : (
          <>
            <p>You haven't added any products yet.</p>
            <Button
              type="nav"
              to="/products/new"
              variant="secondary"
              className="mt-4 w-64 mx-auto"
            >
              + Add Product
            </Button>
          </>
        )}
      </div>
    );
  };

  // Add this new line to calculate the total product count
  const totalProductCount = currentItems.length;

  return (
    <div className="bg-white dark:bg-black overflow-hidden">
      <div className="px-4">
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Products
              </h1>
              <div className="flex items-center px-3 py-1 bg-indigo-50 dark:bg-indigo-900/30 rounded-full">
                <span className="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                  {totalProductCount} total
                </span>
              </div>
            </div>
            <Button
              type="nav"
              to="/products/new"
              variant="primary"
              size="sm"
              className="px-4 py-2 shadow-sm hover:shadow-md transition-all"
            >
              + Add Product
            </Button>
          </div>

          <div className="flex flex-wrap gap-4 items-center">
            <div className="relative flex-1 min-w-[240px]">
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2.5 text-sm rounded-lg border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-800/50 dark:text-white transition-all"
                placeholder="Search products..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <FaSearch className="w-4 h-4 text-gray-400" />
              </span>
            </div>

            <Dropdown
              id="product-status"
              selected={productStatus}
              options={[
                { value: 'all', label: 'All Statuses' },
                { value: true, label: 'Active' },
                { value: false, label: 'Draft' }
              ]}
              onChange={handleProductStatusChange}
              placeholder="Select status"
              className="min-w-[160px] text-sm"
            />
            <Dropdown
              id="items-per-page"
              selected={currentItemsPerPage}
              options={[
                { value: 5, label: '5 per page' },
                { value: 10, label: '10 per page' },
                { value: 20, label: '20 per page' },
                { value: 50, label: '50 per page' },
                { value: 100, label: '100 per page' }
              ]}
              onChange={handleItemsPerPageChange}
              placeholder="Items per page"
              className="min-w-[160px] text-sm"
            />
          </div>
        </div>

        {displayedItems.length > 0 ? (
          <>
            <div className="rounded-lg shadow overflow-hidden animate-fade-in">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-100 dark:bg-gray-800">
                  <tr>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Product Name
                    </th>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Image
                    </th>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      In Stock
                    </th>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-2 text-center text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Price
                    </th>
                    <th scope="col" className="px-3 py-2 text-center text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-black divide-y divide-gray-200 dark:divide-gray-700">
                  {displayedItems.map((item) => (
                    <tr
                      key={item.id}
                      onDoubleClick={() => onRowDoubleClicked(item)}
                      className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer transition duration-150 ease-in-out"
                    >
                      <td className="px-3 py-2">
                        {productNameRenderer({ value: item.productName })}
                      </td>
                      <td className="px-3 py-2">
                        {productImageRenderer({
                          value: item.productAssets?.mainImage?.url
                        })}
                      </td>
                      <td className="px-3 py-2">
                        {stockCellRenderer({ value: item.quantity })}
                      </td>
                      <td className="px-3 py-2">
                        {statusCellRenderer({ value: item.isActive })}
                      </td>
                      <td className="px-3 py-2">
                        {priceCellRenderer({ value: item.price })}
                      </td>
                      <td className="px-3 py-2">
                        {actionCellRenderer({ data: item })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {renderPagination()}
          </>
        ) : (
          renderEmptyState()
        )}

        {renderLoadingIndicator()}
        {renderLoadError()}
      </div>
    </div>
  );
};

ProductsTable.propTypes = {
  currentItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setProducts: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number
};

export default ProductsTable;
