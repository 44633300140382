import React, { useState, useEffect } from 'react';
import { getSellerOrders } from '../../api/orders';
import ChannelOrdersTable from './ChannelOrdersTable';
import Spinner from '../../components/Spinner';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function ChannelOrders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const data = await getSellerOrders();
        if (!data) {
          throw new Error('No data received');
        }
        setOrders(data.orders || []);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();

  }, []);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
          <Spinner size="large" variant="primary" />
        </div>
      );
    } else {
      return <ChannelOrdersTable currentItems={orders} />;
    }
  };

  return (
    <div className="bg-white dark:bg-black min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="bg-white dark:bg-black rounded-lg  overflow-hidden">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default ChannelOrders;
