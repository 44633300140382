import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { FaSearch } from 'react-icons/fa';
import Dropdown from '../../components/Dropdown';

const ChannelOrdersTable = ({ currentItems = [], itemsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderStatus, setOrderStatus] = useState('all');

  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handleOrderStatusChange = (event) => {
    setOrderStatus(event.target.value);
    setCurrentPage(1);
  };

  const filteredItems = useMemo(() => {
    return currentItems.filter((item) => {
      const statusMatch =
        orderStatus === 'all' ||
        item.orderStatus.toLowerCase() === orderStatus.toLowerCase();
      const searchMatch =
        item.orderId.toLowerCase().includes(searchTerm) ||
        item.customerName.toLowerCase().includes(searchTerm);
      return statusMatch && searchMatch;
    });
  }, [currentItems, orderStatus, searchTerm]);

  const totalItems = filteredItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredItems, currentPage, itemsPerPage]);

  const orderNumberRenderer = useCallback(
    ({ value, data }) => (
      <button
        onClick={() => navigate(`/channel-orders/${data.id}`)}
        className="text-indigo-600 hover:text-indigo-900 font-medium"
      >
        {`${value}`}
      </button>
    ),
    [navigate]
  );

  const orderDateRenderer = useCallback(({ value }) => {
    const date = new Date(value);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return (
      <div className="text-gray-700 dark:text-gray-300">
        {formattedDate.replace(',', ',')}
      </div>
    );
  }, []);

  const amountValueFormatter = useCallback(
    ({ value }) => (
      <div className="text-gray-700 dark:text-gray-300 font-medium">
        ${value.toFixed(2)}
      </div>
    ),
    []
  );

  const capitalizeFirstLetter = (string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  };

  const statusCellRenderer = useCallback(({ value }) => {
    const statusColorMap = {
      processing: 'bg-yellow-100 text-yellow-800',
      shipped: 'bg-blue-100 text-blue-800',
      delivered: 'bg-green-100 text-green-800',
      cancelled: 'bg-red-100 text-red-800',
      default: 'bg-gray-100 text-gray-800',
    };
    const statusClasses =
      statusColorMap[value.toLowerCase()] || statusColorMap.default;

    return (
      <span
        className={`px-3 py-1 text-sm font-semibold rounded-full ${statusClasses}`}
      >
        {capitalizeFirstLetter(value)}
      </span>
    );
  }, []);

  const customerNameRenderer = useCallback(({ value }) => {
    return (
      <div className="text-gray-700 dark:text-gray-300 font-medium">
        {capitalizeFirstLetter(value)}
      </div>
    );
  }, []);

  const actionCellRenderer = useCallback(
    ({ data }) => (
      <div className="flex justify-center gap-2">
        <Button
          onClick={() => navigate(`/channel-orders/${data.id}`)}
          variant="primary"
          size="small"
        >
          View Details
        </Button>
      </div>
    ),
    [navigate]
  );

  const onRowDoubleClicked = (item) => navigate(`/channel-orders/${item.id}`);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () =>
    totalPages > 1 && (
      <div className="flex sm:flex-col flex-row justify-between items-center mt-6">
        <span className="text-sm text-gray-700 dark:text-gray-300 sm:mb-2 mb-0">
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            variant="secondary"
            size="small"
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            variant="secondary"
            size="small"
          >
            Next
          </Button>
        </div>
      </div>
    );

  const renderEmptyState = () => (
    <div className="text-center my-10 text-gray-600 dark:text-gray-400">
      <p className="text-base pt-10">
        No orders found. Try adjusting your search or filters.
      </p>
    </div>
  );

  return (
    <div className="bg-white dark:bg-black h-full max-w-7xl mx-auto p-6">
      <div>
        <div className="bg-white dark:bg-black space-y-4 mb-4">
          <div className="flex sm:flex-col sm:space-y-4 space-y-0 flex-row justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
              Sales History
            </h1>
            
            <div className="flex sm:flex-col flex-row gap-3 items-center sm:w-full w-auto max-w-2xl">
              <div className="relative sm:flex-1 flex-none w-72">
                <input
                  type="text"
                  className="pl-10 pr-4 py-2.5 w-full text-sm rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-800 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-200 placeholder-gray-500 dark:placeholder-gray-400"
                  placeholder="Search orders by ID or buyer"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaSearch className="w-4 h-4 text-gray-400 dark:text-gray-300" />
                </span>
              </div>
              
              <div className="sm:flex-1 flex-none w-56 sm:w-full">
                <Dropdown
                  id="order-status"
                  selected={orderStatus}
                  options={[
                    { value: 'all', label: 'All Orders' },
                    { value: 'processing', label: 'Processing' },
                    { value: 'shipped', label: 'Shipped' },
                    { value: 'delivered', label: 'Delivered' },
                    { value: 'cancelled', label: 'Cancelled' },
                  ]}
                  onChange={handleOrderStatusChange}
                  placeholder="Select status"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        {displayedItems.length > 0 ? (
          <>
            <div className="rounded-lg shadow overflow-hidden animate-fade-in">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-100 dark:bg-gray-800">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Order #
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Buyer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-center text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-center text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-black divide-y divide-gray-200 dark:divide-gray-700">
                  {displayedItems.map((item) => (
                    <tr
                      key={item.id}
                      onDoubleClick={() => onRowDoubleClicked(item)}
                      className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition duration-150 ease-in-out text-sm"
                    >
                      <td className="px-3 py-2">
                        {orderNumberRenderer({
                          value: item.orderId,
                          data: item,
                        })}
                      </td>
                      <td className="px-3 py-2">
                        {orderDateRenderer({
                          value: item.transactionDate,
                        })}
                      </td>
                      <td className="px-3 py-2">
                        {customerNameRenderer({
                          value: item.customerName,
                        })}
                      </td>
                      <td className="px-3 py-2">
                        {amountValueFormatter({
                          value: item.totalAmount,
                        })}
                      </td>
                      <td className="px-3 py-2 text-center">
                        {statusCellRenderer({ value: item.orderStatus })}
                      </td>
                      <td className="px-3 py-2 text-center">
                        {actionCellRenderer({ data: item })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {renderPagination()}
          </>
        ) : (
          renderEmptyState()
        )}
      </div>
    </div>
  );
};

ChannelOrdersTable.propTypes = {
  currentItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsPerPage: PropTypes.number,
};

export default ChannelOrdersTable;