import { Link } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../../../../../utils';
import { PROFILE_COLORS } from '../../../../../constants';

import LivePill from '../../../../../components/LivePill';
import UserAvatar from '../../../../../components/UserAvatar';

const SidebarChannelCard = ({
  avatarSrc = '',
  bannerSrc = '',
  color = 'blue',
  username = '',
  variant = 'default',
  isSidebarOpen = true
}) => {
  const [hasBannerError, setHasBannerError] = useState(false);
  const [shouldHavePointerEvents, setShouldHavePointerEvents] = useState(false);

  const shouldShowBanner = !!(!hasBannerError && bannerSrc);

  const onError = useCallback(() => {
    setHasBannerError(true);
  }, []);

  useEffect(() => {
    setShouldHavePointerEvents(true);
  }, []);

  return (
    <Link
      className={clsm([
        'flex',
        'rounded-lg',
        'relative',
        'group',
        'hover:shadow-none',
        isSidebarOpen ? 'w-full flex-col' : '',
        shouldHavePointerEvents ? 'pointer-events-auto' : 'pointer-events-none'
      ])}
      to={`/${username}`}
    >
      {isSidebarOpen ? (
        <>
          <div className="aspect-video relative rounded-lg overflow-hidden">
            {shouldShowBanner ? (
              <img
                alt=""
                src={bannerSrc}
                onError={onError}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className={`w-full h-full bg-profile-${color}-dark`} />
            )}
            {variant === 'live' && (
              <div className="absolute top-2 right-2">
                <LivePill />
              </div>
            )}
          </div>
          <div className="flex items-center p-2">
            <UserAvatar
              avatarSrc={avatarSrc}
              profileColor={color}
              className="w-8 h-8 mr-2"
              username={username}
              size="md"
            />
            <div className="flex-grow overflow-hidden">
              <h4 className="truncate text-sm font-medium text-gray-800 dark:text-gray-200">
                {username}
              </h4>
              {variant === 'live' && (
                <p className="text-xs text-red-500 font-medium">Live Now</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center w-full h-full relative ">
          <UserAvatar
            avatarSrc={avatarSrc}
            profileColor={color}
            className="w-9 h-9 "
            username={username}
            size="lg"
          />
          {variant === 'live' && (
            <div className="absolute bottom-1 right-1 w-3 h-3 bg-red-500 rounded-full border-2 border-white dark:border-gray-800" />
          )}
        </div>
      )}
    </Link>
  );
};

SidebarChannelCard.propTypes = {
  avatarSrc: PropTypes.string,
  bannerSrc: PropTypes.string,
  color: PropTypes.oneOf([...PROFILE_COLORS, 'default']),
  username: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'offline', 'live']),
  isSidebarOpen: PropTypes.bool
};

export default SidebarChannelCard;
