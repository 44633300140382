import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../../../../../../utils';
import Input from '../../../../../../components/Input';

const PriceInput = forwardRef(
  ({ dataKey, onChange, className, value, ...inputProps }, ref) => {
    const handleOnChange = ({ target }) => {
      const formattedValue = target.value.replace(/[^0-9.]/g, '');

      onChange({ [dataKey]: formattedValue }, dataKey);
    };

    return (
      <Input
        className={clsm(['dark:bg-darkMode-gray-dark', className])}
        onChange={handleOnChange}
        ref={ref}
        value={value}
        {...inputProps}
      />
    );
  }
);

PriceInput.propTypes = {
  className: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

PriceInput.defaultProps = {
  className: '',
  value: ''
};

export default PriceInput;
