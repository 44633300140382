import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../layouts/AppLayoutWithNavbar/Footer';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-darkMode-gray-dark pt-24 p-4">
      <div className="w-full max-w-3xl mx-auto p-16 sm:p-6 bg-white backdrop-blur-sm rounded-xl dark:bg-black shadow">
        <h2 className="text-3xl text-center text-gray-800 mb-12 dark:text-gray-200">
          Terms of Service
        </h2>
        <div className="overflow-y-auto">
          <div className="prose prose-xl text-gray-700 mx-auto dark:prose-dark dark:text-gray-400">
            <p>
              Welcome to Livescape Shops! In these Terms of Service ("Terms"),
              'Livescape Shops', 'we', 'us', or 'our' refer to us—including Open
              Day, LLC and its affiliates. 'You' refers to you, the user. When
              someone creates an account on Livescape Shops, they become a
              'User'. Users can be 'Buyers' or 'Sellers', depending on their
              role in transactions.
            </p>
            <br />
            <p>
              Please review the Livescape Shops Terms of Service, Privacy Policy
              (available at <Link to="/privacy">Privacy Policy</Link>), and all
              other Livescape Shops policies and rules before using our
              platform. These documents form a legal agreement between you and
              Livescape Shops. By using our services, you agree to comply with
              the Terms, Privacy Policy, and all other policies. If you do not
              agree, you are not permitted to access or use Livescape Shops.
            </p>
            <br />
            <p>
              IMPORTANT NOTICE ABOUT ARBITRATION: By agreeing to these Terms,
              you are consenting (with limited exceptions) to settle any
              disputes with Livescape Shops through binding, individual
              arbitration instead of in court. Please review the Arbitration and
              Arbitration Agreement sections below for more information.
              However, if you reside in a jurisdiction where mandatory
              arbitration of disputes is prohibited by law, the arbitration
              agreement will not apply to you. In such cases, the provisions of
              the Governing Law and Jurisdiction section will apply instead.
            </p>
            <br />
            <h3>General</h3>
            <br />
            <p>
              Livescape Shops is a platform accessible via our website (referred
              to as the "Website") that connects buyers and sellers of certain
              products (referred to as an "Item") to facilitate transactions. As
              an intermediary platform provider, Livescape Shops enables users
              to communicate and connect for the sale and purchase of Items. We
              utilize third-party companies to process payments (referred to as
              the "Payment Processor"). Both buyers and sellers must adhere to
              the Payment Processor's terms and conditions. When handling funds
              related to a listing, we do so through the Payment Processor. Open
              Day does not create, sell, or buy any Items, nor does it take
              title or possession of any Items, except for temporary possession
              as needed for verification purposes. We are not responsible for
              the shipment, handling, or performance of any Items. Buyers are
              solely responsible for purchasing and paying for Items available
              on the Website and resolving any issues related to the Items.
              Sellers are solely responsible for listing the Items, and
              Livescape Shops is not liable for any descriptions, photographs,
              or assurances of quality or performance of any Items. Except where
              prohibited by law, Livescape Shops disclaims any responsibility or
              liability related to the Items. We reserve the right to terminate
              or suspend orders, cancel purchases, or remove Item listings at
              our sole discretion. Except where prohibited by law, Livescape
              Shops will not be liable to buyers, sellers, or any third party
              for doing so.
            </p>
            <br />
            <p>
              Livescape Shops is a platform that facilitates transactions
              between buyers and sellers on the Website. However, Livescape
              Shops is not a party to any agreement between the buyer and seller
              of items, or between any users, unless Livescape Shops is the
              seller. It's important to protect yourself at all times. Use your
              best judgment and take appropriate safety precautions when
              interacting with other users, whether on the Website or in person.
              You are solely responsible for your safety and any interactions
              you have with other users.
            </p>
            <br />
            <h3>Privacy Policy</h3>
            <br />
            <p>
              Our Privacy Policy outlines the information we collect from you,
              the methods of collection, and how we use and share that
              information. For more details, please visit our{' '}
              <Link to="/privacy">Privacy Policy</Link> page.
            </p>
            <br />
            <h3>Eligibility to Use the Website</h3>
            <br />
            <p>
              You must be at least 18 years old to create an account or use the
              Website. By using the Website, you confirm that you are at least
              18 years old and meet all eligibility requirements outlined in
              these Terms. If you do not meet these requirements or do not agree
              with these Terms, you must stop using the Website immediately.
            </p>
            <br />
            <h3>Livescape Shops Users</h3>
            <br />
            <p>
              The Website was developed by individuals seeking a marketplace for
              the items you can find on the Website. Therefore, Livescape Shops
              does not prohibit any of its directors, officers, members,
              managers, employees, affiliates, successors, and assigns
              (collectively, "Livescape Shops Representatives") from using the
              Website. All Livescape Shops Representatives acting as users must
              comply with these Terms.
            </p>
            <br />
            <h3>Account Information and Right to Use the Website</h3>
            <h3>Account Creation and Profiles</h3>
            <br />
            <p>
              The Website allows users to create a Livescape Shops account
              ("Account"). To create an Account, a user must select a username
              and password, which will be considered "User Information" for the
              purposes of these Terms. Once an Account is created, if the user
              wishes to sell items, they will need to create a profile
              ("Profile") by providing the requested information. At our
              discretion, the user may then be allowed to sell items on the
              Website. All sellers must provide the following information in
              their Profile: (1) first and last name, (2) email address, (3)
              physical address, and (4) phone number. Users may also provide
              additional information as requested in their Profile and Account,
              which will also be considered "User Information". Please note that
              while Livescape Shops collects User Information, we do not verify
              or ensure that such information is correct, accurate, complete, or
              not misleading. It is a condition of your use of the Website that
              all the information you provide is current, complete, accurate,
              and not false or misleading. You agree that all information you
              provide to register with the Website is governed by our{' '}
              <Link to="/privacy">Privacy Policy</Link>, and to the maximum
              extent permitted by applicable law, you consent to all actions we
              take with respect to your information consistent with our{' '}
              <Link to="/privacy">Privacy Policy</Link>.
            </p>
            <br />
            <h3>Account and User Information Protection</h3>
            <br />
            <p>
              It is your responsibility to protect your personal data and
              maintain the confidentiality of your User Information. If you
              suspect or become aware of any unauthorized use of your Account or
              User Information, or any unauthorized breach of your Account or
              User Information, you agree to notify us immediately. You
              acknowledge that your Account and User Information are personal to
              you and agree not to allow anyone else to access the Website or
              any portion of it using your User Information. If you choose to
              share your Account or User Information with others, you do so at
              your own risk and are still responsible for all activities on your
              Account and User Information, even if such activities were not
              authorized by you. We reserve the right to disable any Account at
              any time in our sole discretion, for any reason, including if, in
              our opinion, you have violated any provision of these Terms.
            </p>
            <br />
            <h3>Unauthorized Use of Your Account</h3>
            <br />
            <p>
              Livescape Shops will not be liable for any direct or indirect loss
              that you may incur as a result of someone else using your Account
              or User Information, either with or without your knowledge. To the
              extent permitted by law, you shall be responsible for any
              expenses, including usage charges, fines, fees, civil judgments,
              and reasonable legal fees, arising from your failure to protect
              your User Information and/or promptly notify Livescape Shops about
              unauthorized use of your Account or breach of your Account
              information or password.
            </p>
            <br />
            <h3>No Guarantee of Access</h3>
            <br />
            <p>
              We reserve the right to withdraw or amend the Website, and any
              feature or material we provide as part of the Website, at our sole
              discretion without notice. Additionally, the Website may be
              upgraded and updated automatically without notice to you. We will
              not be liable if, for any reason, all or any part of the Website
              is unavailable at any time or for any period.
            </p>
            <br />
            <h3>Use and Access Restrictions</h3>
            <br />
            <p>
              We reserve the right to refuse the use of or access to the Website
              to anyone, for any reason, at any time. We may also restrict
              access to the Website, or any portion thereof, to users, including
              registered users, from time to time. We may terminate your right
              to use the Website with or without cause at any time, in our sole
              discretion, and may prevent your future use of the Website.
              Additionally, in accordance with our anti-money laundering,
              anti-terrorism, anti-fraud, and other compliance policies and
              practices, we may impose reasonable limitations and controls on
              your ability to use the Website, or on any beneficiary's ability
              to utilize the Website, if applicable. Such limitations may
              include, where good cause exists, rejecting transaction requests,
              freezing funds, or otherwise restricting your use of the Website.
              You may terminate this Agreement by simply discontinuing the use
              of the Website.
            </p>
            <br />
            <h3>Your Responsibilities Before and After Termination</h3>
            <br />
            <p>
              Livescape Shops is not obligated to retain or provide you with
              copies of your Account Information, User Information, or any other
              data. "Account Information" includes your User Information,
              transaction history, items listed for transactions, conversations
              with other users (if and when such functionality is available on
              the Website), payment account information, and similar information
              related to the Website and your usage of the Website. This list is
              illustrative and not exhaustive. Livescape Shops shall not be
              liable for any deletion, loss, corruption, or withholding of your
              Account Information or User Information. You are solely
              responsible for copying and maintaining all Account Information
              and User Information contained in your Account and Profile. If you
              terminate your Account, or if we terminate your right to use the
              Website, Livescape Shops may restrict your access to any Account
              Information, User Information, or materials that you may have used
              or stored in the Website.
            </p>
            <br />
            <h3>Use Requirements</h3>
            <br />
            <p>
              You agree that you will not (and will not encourage or enable
              another User or third party to):
            </p>
            <ul>
              <li>
                Post, upload, publish, submit or transmit any User Content that:
                (i) infringes, misappropriates or violates a third party's
                patent, copyright, trademark, trade secret, moral rights or
                other intellectual property rights, or rights of publicity or
                privacy; (ii) violates, or encourages any conduct that would
                violate, any applicable law or regulation or would give rise to
                civil liability; (iii) is fraudulent, false, misleading or
                deceptive; (iv) is defamatory, obscene, pornographic, vulgar or
                offensive; (v) promotes discrimination, bigotry, racism, hatred,
                harassment or harm against any individual or group; (vi) is
                violent or threatening or promotes violence or actions that are
                threatening to any person or entity; or (vii) promotes illegal
                or harmful activities or substances;
              </li>
              <li>
                Use the Website for any unlawful purpose or for the promotion of
                illegal activities;
              </li>
              <li>
                Use the Website to, or to attempt to, harass, abuse, or harm
                another person or group;
              </li>
              <li>
                Provide others with access to or use of your Account, or use the
                Account or any other User's Account without permission;
              </li>
              <li>
                Provide false or inaccurate information in the Website or in
                your Account, including making any false representations with
                respect to whether you are using the Website for personal,
                family or household purposes, or for business purposes;
              </li>
              <li>
                Use the Website for the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way by exposing them
                to inappropriate content, asking for personally identifiable
                information, or otherwise;
              </li>
              <li>
                Make any automated use of the Website, or take any action that
                we deem to impose or to potentially impose an unreasonable or
                disproportionately large load on the Website;
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Website or for any other unauthorized purpose without our
                prior written consent;
              </li>
              <li>
                Copy, adapt, modify, create derivative works of, distribute,
                sell, or lease any part of the Website or materials we provide
                as part of the Website;
              </li>
              <li>
                Attempt to decipher, decompile, disassemble, extract or reverse
                engineer any source code of or any software used to provide the
                Website, unless applicable laws prohibit these restrictions, or
                you have our written permission to do so;
              </li>
              <li>
                Use any software, technology, or device to scrape, spider, or
                crawl the Website or harvest or manipulate data;
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful;
              </li>
              <li>
                Bypass any measures we take to restrict access to the Website;
              </li>
              <li>
                Use the Website to solicit sales outside of the Website or to
                send any unsolicited or unauthorized advertising, promotional
                materials, email, junk mail, spam, chain letters or other form
                of solicitation;
              </li>
              <li>
                Engage in market manipulation, for example the creation and use
                of multiple accounts in an effort to create the appearance of
                increased demand for an Item;
              </li>
              <li>
                Use the Website for the distribution or sale of counterfeit,
                unauthorized, stolen, B-grade, or similar non-authentic Items;
              </li>
              <li>
                Distribute or sell counterfeit, unauthorized, stolen, B-grade,
                or similar non-authentic Items;
              </li>
              <li>
                Engage or assist in any activity that violates any law, statute,
                ordinance, by-law, regulation, or sanctions program, including
                but not limited to the U.S. Department of Treasury's Office of
                Foreign Assets Control ("OFAC") and any similar laws in any
                other jurisdiction, or that involves proceeds of any unlawful
                activity (including but not limited to money laundering,
                terrorist financing or deliberately engaging in activities
                designed to adversely affect the performance of the Website);
              </li>
              <li>
                Use the Website to participate in fundraising for a business,
                protocol, or platform, including but not limited to creating,
                listing, or buying assets that are redeemable for financial
                instruments, assets that give owners the rights to participate
                in an initial coin offering (ICO) or any securities offering, or
                assets that entitle owners to financial rewards, including but
                not limited to, DeFi (or decentralized finance) yield bonuses,
                staking bonuses, and burn discounts;
              </li>
              <li>
                Fabricate in any way any Transaction or process related thereto;
              </li>
              <li>Place misleading offers;</li>
              <li>
                Disguise or interfere in any way with the IP address of the
                computer you are using to access or use the Website or that
                otherwise prevents us from correctly identifying the IP address
                of the computer you are using to access the Website;
              </li>
              <li>
                Transmit, exchange, or otherwise support the direct or indirect
                proceeds of criminal or fraudulent activity;
              </li>
              <li>Violate any applicable law or regulation;</li>
              <li>
                Encourage or enable any other individual to do any of the
                foregoing;
              </li>
              <li>
                Otherwise interfere or attempt to interfere with the proper
                functioning of the Website.
              </li>
            </ul>
            <p>
              Livescape Shops is not obligated to monitor access to or use of
              the Website or to review or edit any content. However, we have the
              right to do so for the purpose of operating the Website, to ensure
              compliance with these Terms, and to comply with applicable law or
              other legal requirements. We reserve the right, but are not
              obligated, to remove or disable access to any content, including
              User Content (as defined below), at any time and without notice,
              including, but not limited to, if we, at our sole discretion,
              consider it objectionable or in violation of these Terms. We have
              the right to investigate violations of these Terms or conduct that
              affects the Website. We may also consult and cooperate with law
              enforcement authorities to prosecute users who violate the law.
              The sale of stolen assets, assets taken without authorization, and
              otherwise illegally obtained assets on the Website is prohibited.
              If you have reason to believe that an asset listed on the Website
              was illegally obtained, please contact us immediately using our{' '}
              <Link to="/help">help page</Link>. Listing illegally obtained
              assets may result in your listings being cancelled, your assets
              being hidden, or your Account being suspended from the Website.
              Unauthorized use may result in criminal and/or civil prosecution
              under federal, provincial or state, and local law. If you become
              aware of misuse of our Website, please contact us using our{' '}
              <Link to="/help">help page</Link>.
            </p>
            <br />
            <h3>Your Rights to Use the Website</h3>
            <br />
            <p>
              Buyers are allowed to use the Website for personal, household,
              family, or business purposes, in accordance with these Terms.
              Sellers represent and warrant that they will use the Website for
              business purposes only, in accordance with these Terms. Sellers
              are not permitted to use the Website for personal, household, or
              family purposes. Subject to these Terms, we grant you a limited,
              revocable, personal, non-sublicensable, non-transferable, and
              non-exclusive license to access and use the Website. You must use
              the Website in compliance with all applicable laws and
              regulations. If you violate any of these Terms, your right to use
              the Website will cease immediately, and you must, at our
              discretion, return or destroy any copies of the materials you have
              made. No right, title, or interest in or to the Website, or any
              content on the Website, is transferred to you. We reserve all
              rights that are not expressly granted to you under these Terms.
            </p>
            <br />
            <h3>User Interactions</h3>
            <h3>Interactions with Other Users</h3>
            <br />
            <p>
              Livescape Shops has developed the Website to enable Users to
              connect with each other for coordinating Transactions. The extent
              of User interactions and the degree of identity disclosure, as
              well as the level of facilitation by Livescape Shops, may vary
              over time. Livescape Shops does not perform criminal background or
              identity verification checks on Users, nor does it investigate the
              backgrounds of its Users.{' '}
              <strong>
                LIVES CAPE SHOPS PROVIDES NO ASSURANCE REGARDING THE BEHAVIOR,
                IDENTITY, INTENTIONS, LEGITIMACY, OR TRUTHFULNESS OF USERS.
                LIVES CAPE SHOPS RESERVES THE RIGHT TO CONDUCT—AND YOU AUTHORIZE
                LIVES CAPE SHOPS TO PERFORM—ANY CRIMINAL BACKGROUND CHECKS OR
                OTHER SCREENINGS USING AVAILABLE PUBLIC RECORDS AT ANY TIME, AND
                YOU AGREE THAT YOUR INFORMATION MAY BE USED FOR THIS PURPOSE.
              </strong>{' '}
              Livescape Shops aims to foster a respectful User experience on the
              Website. We encourage you to take precautions to ensure your
              safety when interacting with other Users, whether on the Website
              or in person, including during the use of Local Pickup Services.
              This includes not sharing your financial information, safeguarding
              your personal data, and reporting any suspicious or offensive
              behavior through the User reporting tool in the Website or by
              contacting Livescape Shops via our{' '}
              <Link to="/help">help page</Link>. Livescape Shops is not
              responsible for the behavior of any User on or off the Website.
              You agree to exercise caution in all interactions with other
              Users, both within the Website and in person, especially when
              meeting other Users to exchange a purchased Item.{' '}
              <strong>
                SUBJECT TO APPLICABLE LAW AND AS FURTHER STATED IN THESE TERMS,
                LIVES CAPE SHOPS IS NOT LIABLE FOR, AND YOU HEREBY RELEASE AND
                HOLD HARMLESS LIVES CAPE SHOPS FROM, ANY DIRECT OR INDIRECT
                LOSS, DAMAGE, HARM, INJURY, OR CLAIMS ARISING FROM OR RELATED TO
                USER INTERACTIONS, ENGAGEMENTS, OR USE OF THE WEBSITE.
              </strong>
            </p>
            <br />
            <h3>User Legal Compliance</h3>
            <br />
            <p>
              Users are required to comply with all applicable laws related to
              Transactions, including licensing, business registration, and
              other relevant obligations. It is the sole responsibility of each
              User, not Livescape Shops, to determine the applicable laws and
              ensure compliance with those laws, including obtaining necessary
              rights and licenses to buy and sell Items. You acknowledge and
              agree that certain Items, such as firearms, knives, recalled
              products, children's products, alcoholic beverages, cannabis,
              tobacco, food and drug products, medical devices, cosmetics,
              natural health products, coins, and currency, are subject to
              federal, provincial or state, and local regulations. You represent
              that you will comply with all applicable laws and regulations
              related to the use, listing, purchase, distribution, promotion,
              advertising, and sale of Items through the Website. You agree to
              defend, indemnify, and hold Livescape Shops and its affiliates
              harmless from any claims, liabilities, losses, damages, costs, and
              expenses arising from your violation of any regulations, your use
              of the Website in connection with regulated Items, or your breach
              of any regulations. Please note that while we require Users to
              comply with applicable laws, we do not verify compliance and do
              not guarantee that the Website, Users, or transactions through the
              Website comply with applicable laws. Except where prohibited by
              law, we disclaim all liability for claims related to the Website's
              compliance with laws, Users' compliance with laws, or the
              compliance of transactions through the Website with laws. Except
              where prohibited by law, you release Livescape Shops and its
              representatives from any claims related to the Website's
              compliance with laws, your compliance with laws while using the
              Website, or the compliance of transactions through the Website
              with laws.
            </p>
            <br />
            <h3>Pricing, Billing, and Fees</h3>
            <h3>Pricing</h3>
            <br />
            <p>
              Currently, creating an Account and using the Website is free.
              However, Sellers are required to pay fees related to Transactions.
              These fees are detailed in our Fee Schedule, available in our{' '}
              <Link to="https://help.livescapeshops.com/en/">Help Center</Link>, which may be updated or amended from
              time to time. Due to these potential fees, Users may need to
              provide a payment method (the "Payment Method") upon registering
              their Account. You authorize Livescape Shops and/or its Billing
              Agent to charge you in accordance with the fee structure outlined
              in the Fee Schedule. Fees and charges incurred as a result of a
              Transaction are automatically billed to your Payment Method upon
              completion of the Transaction.
            </p>
            <br />
            <h3>Transactions</h3>
            <h3>Item Transactions Generally</h3>
            <br />
            <p>
              Sellers can list an Item for sale on the Website. By listing an
              Item, Sellers make a binding offer to sell that Item at a
              specified price (the "Listing Price"). Seven days after a Buyer
              purchases the Item(s), the purchase price, minus any applicable
              fees owed by the Seller to Livescape Shops according to the Fee
              Schedule, will be transferred to the Seller via the Payment
              Processor (a "Payout Transaction"). This transfer will occur on
              the Friday following the seven-day hold period. It is your
              responsibility to ensure that all provided information is accurate
              for a proper transfer. Livescape Shops may not be able to recover
              funds transferred to an incorrect account based on the information
              provided by Users. The Seller is responsible for any fees charged
              by the Payment Processor for transfers and should review the
              Payment Processor's Terms of Service as rates are subject to
              change and are beyond Livescape Shops's control. Sellers must
              comply with the Payment Processor's terms and conditions.
            </p>
            <br />
            <h3>Seller Responsibilities for Item Transactions</h3>
            <br />
            <p>
              After a Transaction, Sellers must ship the Item following
              Livescape Shops's shipping policy, which may change and is always
              available on the Livescape Shops <Link to="https://help.livescapeshops.com/en/">Help Center</Link>.
              Sellers are responsible for any additional shipping costs. Failure
              to comply with shipping policies may result in penalties at
              Livescape Shops's sole discretion. Sellers must ensure the
              accuracy of an Item's description, including that the Item matches
              the image displayed on the Website and that the described
              condition is true and accurate. Inaccuracies in description,
              condition, or authenticity may result in penalties at Livescape
              Shops's sole discretion. Penalties for Seller-related issues may
              include, but are not limited to:
            </p>
            <ul>
              <li>Additional charges;</li>
              <li>Removal of Seller's Items from the Website;</li>
              <li>Cancellation of Seller's pending Transactions;</li>
              <li>Withholding of payments to Seller;</li>
              <li>Limitations on Seller's ability to buy and sell Items;</li>
              <li>
                Charges to Seller's Livescape Shops Account for costs incurred
                due to non-compliance with these Terms; and
              </li>
              <li>Temporary or permanent suspension of Seller's Account.</li>
            </ul>
            <p>
              Livescape Shops is not obligated to return Items that do not match
              the Seller's description, and the Seller is responsible for any
              return or disposal fees. Livescape Shops may turn over any
              counterfeit, unauthentic, stolen, unauthorized, B-grade, or
              similar Items to the authorities, and the Seller is responsible
              for any associated fees.
            </p>
            <br />
            <h3>Buyer Responsibilities for Item Transactions</h3>
            <br />
            <p>
              When a Buyer initiates a Transaction, the Buyer is responsible for
              completing the payment of the Transaction amount, including all
              additional fees and taxes. Penalties for payment-related issues
              may include, but are not limited to:
            </p>
            <ul>
              <li>Additional charges;</li>
              <li>
                Removal of any or all of the Buyer's Seller Items if the Buyer
                also acts as a Seller on the Website;
              </li>
              <li>
                Cancellation of any or all of the Buyer's pending Seller
                Transactions on the Website;
              </li>
              <li>
                Withholding of any payments due to the Buyer, whether in their
                capacity as a Buyer or a Seller;
              </li>
              <li>
                Placing limits on the Buyer's ability to buy and/or sell Items;
              </li>
              <li>
                Charges to the Buyer's Payment Method or subsequently provided
                means of payment for costs, expenses, and fees incurred by
                Livescape Shops due to the Buyer's failure to comply with these
                Terms; and
              </li>
              <li>
                Temporary or permanent suspension of the Buyer's Buyer and/or
                Seller Account.
              </li>
            </ul>
            <br />
            <h3>Livescape Shops Responsibilities for Item Transactions</h3>
            <br />
            <p>
              The Seller will ship the Item directly to the Buyer using shipping
              service provider(s). Livescape Shops, through the Payment
              Processor, will issue payment to the Seller after a seven-day hold
              and then on the next Friday after the seven-day hold. In the event
              that a Buyer disputes the Item for any reason, Livescape Shops
              may, at its sole discretion, delay the distribution of payment,
              request the Item be sent to Livescape Shops, request the Item be
              returned to the Seller, or issue a refund to the Buyer.
            </p>
            <br />
            <h3>Streaming and Selling Terms & Conditions</h3>
            <h3>User Content</h3>
            <br />
            <p>
              Select Sellers may be allowed to upload, post, transmit, or
              otherwise make available content through the Website, including,
              without limitation, text, photographs, user videos, sound
              recordings, and the musical works embodied therein, such as videos
              incorporating sound recordings from your personal music library
              and ambient noise ("User Content"), in connection with a live
              stream conducted by the Seller for the purchase of Items
              ("Transactions"). You must not post any User Content on or through
              the Website or transmit to us any User Content that you consider
              to be confidential. By submitting User Content through the
              Website, you represent that you own that User Content, or you have
              obtained all necessary permissions, consents, waivers, and
              clearances, or are otherwise authorized to submit it to the
              Website and grant the licenses to such User Content as described
              in these Terms. You or the owner of your User Content retain
              copyright, but by submitting User Content via the Website, you
              grant us a non-exclusive, royalty-free, fully transferable,
              perpetual, irrevocable, worldwide license to use, modify, adapt,
              reproduce, create derivative works of, publish, transmit,
              distribute, and authorize others to view, access, use, download,
              modify, adapt, reproduce, create derivative works of, publish, and
              transmit your User Content in any format and on any platform,
              either now known or later invented. By posting User Content to or
              through the Website, you waive any rights to prior inspection or
              approval of any marketing or promotional materials related to such
              User Content. You also waive any rights of privacy, publicity, or
              any similar rights in connection with your User Content. To the
              extent any moral or author's rights are not transferable or
              assignable, you waive and agree never to assert any such rights in
              your User Content. You further grant us a royalty-free license to
              use your name, image, voice, and likeness to identify you as the
              source of any of your User Content or in connection with our use
              of your User Content. Livescape Shops has the right to use, on the
              Website and in advertising and promotional materials, images of
              Items being sold or that have been sold through the Website. Open
              Day reserves the right to cut, crop, edit, or refuse to publish
              your User Content at our sole discretion. We also have the right,
              but not the obligation, to remove, disallow, block, or delete any
              User Content that we consider to violate these Terms or in
              response to complaints, with or without notice, without any
              liability to you. We do not guarantee the accuracy, integrity,
              appropriateness, or quality of any User Content, and under no
              circumstances will we be liable for any User Content. When you
              stream on the Website as a Seller, Livescape Shops may record and
              store a copy of your stream. By creating a stream, you consent to
              your voice and likeness being recorded and stored by Livescape
              Shops for transaction verification and fraud prevention purposes.
            </p>
            <br />
            <h3>Flash Sales and Auctions</h3>
            <p>
              Eligibility to host an "Auction" or "Flash Sale" will be
              determined at Livescape Shops's sole discretion. Livescape Shops
              is not an auction house and we are not conducting live Auctions.
            </p>
            <h3>Return Policy</h3>
            <br />
            <p>
              The Livescape Shops return policy can be found on the Livescape
              Shops <Link to="https://help.livescapeshops.com/en/">Help Center</Link>. If you experience a problem
              with your purchase or Item, and you cannot find the proper answer
              on our Help Center page, please contact us using our{' '}
              <Link to="/help">help page</Link>.
            </p>
            <br />
            <p>
              The Livescape Shops return policy can be found on the Livescape
              Shops Help Center.If you experience a problem with your purchase or Item,
              and you cannot find the proper answer on our Help Center page, please
              contact us using our help page.
            </p>
            <br />
            <h3>Taxes Generally</h3>
            <br />
            <p>
              You are solely responsible for all costs incurred while using the
              Website and for determining, collecting, reporting, and paying all
              applicable taxes. In certain jurisdictions, Livescape Shops may be
              required to remit sales taxes for your sales to the appropriate
              government agency. In these cases, Livescape Shops will collect
              and/or retain the applicable taxes and remit them to the
              government agency. As used here, "Taxes" refer to any taxes,
              duties, levies, tariffs, and other governmental charges required
              by law to be paid, collected, and/or remitted to governmental
              agencies, including municipal, provincial or state, federal, and
              national indirect or other withholding and personal or corporate
              income taxes. You are solely responsible for maintaining all
              relevant tax records and complying with any reporting requirements
              related to our Website and the sale of Items, as applicable. You
              are also responsible for ensuring the accuracy of any record
              submitted to a tax authority, including any information derived
              from the Website. We reserve the right to report any activity on
              the Website to relevant tax authorities as required by law. All
              fees and other amounts payable to Livescape Shops are exclusive of
              any applicable sales taxes, and you are responsible for paying any
              applicable sales taxes in addition to such fees or other amounts.
            </p>
            <br />
            <h3>Intellectual Property and Account Information</h3>
            <h3>Intellectual Property Ownership</h3>
            <br />
            <p>
              Livescape Shops and its licensors are the sole owners of all
              right, title, and interest in and to the Website, including all
              software and technology therein, as well as any and all
              intellectual property rights arising therefrom or existing
              therein. Except as otherwise provided herein or as authorized in
              writing by Livescape Shops, you may not modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license, frame,
              create derivative works from, transfer, or otherwise use in any
              other way for commercial or public purposes, in whole or in part,
              any information, software, products, materials, or services
              obtained from the Website. If you wish to make any use of material
              provided as part of the Website other than as set out in these
              Terms, please address your request to: legal@livescapeshops.com .
              Any use of the Website not expressly permitted by these Terms is a
              breach of these Terms and may violate copyright, trademark, and
              other laws. We reserve all rights that are not expressly granted
              to you under these Terms.
            </p>
            <br />
            <h3>Cooperation with Law Enforcement</h3>
            <br />
            <p>
              Without limiting any term or provision of these Terms, we have the
              right to fully cooperate with any law enforcement authorities or
              court order requesting or directing us to disclose the identity or
              other information of anyone posting any Items on or through the
              Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
              AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS
              RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
              DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
              SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <br />
            <h3>Copyright Complaints</h3>
            <br />
            <p>
              Livescape Shops respects the intellectual property rights of
              others and expects its users to do the same. It is Livescape
              Shops's policy, in appropriate circumstances and at its
              discretion, to disable and/or terminate the accounts of users who
              repeatedly infringe the copyrights of others. In accordance with
              the Digital Millennium Copyright Act of 1998, the text of which
              may be found on the U.S. Copyright Office website at
              http://www.copyright.gov/legislation/dmca.pdf, Livescape Shops
              will respond expeditiously to claims of copyright infringement
              committed using the Website that are reported to Livescape Shops's
              Designated Copyright Agent, identified in the sample notice below.
              If you are a copyright owner, or are authorized to act on behalf
              of one, or authorized to act under any exclusive right under
              copyright, please report alleged copyright infringements taking
              place on or through the Website by completing the following DMCA
              Notice of Alleged Infringement and delivering it to Livescape
              Shops's Designated Copyright Agent. Upon receipt of the Notice as
              described below, Livescape Shops will take whatever action, in its
              sole discretion, it deems appropriate, including removal of the
              challenged material from the Website.
            </p>
            <br />
            <h3>DMCA Notice of Alleged Infringement ("Notice")</h3>
            <br />
            <p>
              Identify the copyrighted work that you claim has been infringed,
              or - if multiple copyrighted works are covered by this Notice -
              you may provide a representative list of the copyrighted works
              that you claim have been infringed. Identify the material that you
              claim is infringing (or to be the subject of infringing activity)
              and that is to be removed or access to which is to be disabled,
              and information reasonably sufficient to permit us to locate the
              material, including at a minimum, if applicable, the URL of the
              link shown on the Website where such material may be found.
              Provide your mailing address, telephone number, and, if available,
              email address. Include both of the following statements in the
              body of the Notice: "I hereby state that I have a good faith
              belief that the disputed use of the copyrighted material is not
              authorized by the copyright owner, its agent, or the law (e.g., as
              a fair use)." "I hereby state that the information in this Notice
              is accurate and, under penalty of perjury, that I am the owner, or
              authorized to act on behalf of the owner, of the copyright or of
              an exclusive right under the copyright that is allegedly
              infringed." Provide your full legal name and your electronic or
              physical signature. Deliver this Notice, with all items completed,
              to Livescape Shops's email at legal@livescapeshops.com.
            </p>
            <br />
            <h3>Liability, Indemnification, and Disputes</h3>
            <h3>Assumption of Risk</h3>
            <br />
            <p>
              You acknowledge that interacting with others can be risky, and you
              should exercise caution in all interactions with other Users,
              whether through phone calls, electronic communication outside of
              the Website, or in-person meetings. If you interact with other
              Users on the Website, we encourage you to take appropriate
              precautions. Except where prohibited by law, we disclaim all
              liability for claims arising from your communication with another
              User on, through, or while using the Website. Except where
              prohibited by law, you release Livescape Shops and its
              representatives from any claims related to your communication with
              another User on, through, or while using the Website. If you
              discover the identity of another User and it leads to an
              interaction you consider inappropriate for any reason, we
              encourage you to contact us.
            </p>
            <br />
            <p>
              BY USING THE WEBSITE AND ENTERING INTO A TRANSACTION YOU ARE
              ACKNOWLEDGING THAT YOU ARE AWARE OF THE RISKS ASSOCIATED WITH
              USING THE WEBSITE AND ENTERING INTO A TRANSACTION.
            </p>
            <br />
            <h3>No Warranties</h3>
            <br />
            <p>
              THE WEBSITE, AND ANY CONTENT OR INFORMATION THEREIN, INCLUDING
              USER CONTENT OR ANY OTHER PRODUCT OR SERVICE PROVIDED BY LIVESCAPE
              SHOPS IS PROVIDED "AS IS," WITHOUT WARRANTY OR CONDITION OF ANY
              KIND. FOR CERTAINTY, WE DO NOT REPRESENT OR WARRANT THAT THE
              WEBSITE OR ANY TRANSACTIONS THAT OCCUR USING THE WEBSITE COMPLY
              WITH APPLICABLE LAWS. EXCEPT FOR ANY SPECIFIC WARRANTIES OR
              CONDITIONS PROVIDED OR AS OTHERWISE REQUIRED OR PERMITTED BY LAW,
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, LIVESCAPE SHOPS MAKES
              NO EXPRESS OR IMPLIED WARRANTIES OR CONDITIONS REGARDING THE
              WEBSITE, USER CONTENT, THE USE OR PERFORMANCE OF THE WEBSITE OR
              ANY OTHER PRODUCT OR SERVICE PROVIDED BY LIVESCAPE SHOPS. WITHOUT
              LIMITING THE FOREGOING, WITH RESPECT TO THE WEBSITE (INCLUDING ANY
              CONTENT AND INFORMATION THEREIN), USER CONTENT, OR ANY OTHER
              PRODUCT OR SERVICE PROVIDED BY LIVESCAPE SHOPS, LIVESCAPE SHOPS
              EXPRESSLY DISCLAIMS ALL WARRANTIES CONDITIONS OR REPRESENTATIONS,
              WHETHER EXPRESS, IMPLIED, STATUTORY OR COLLATERAL, INCLUDING
              WITHOUT LIMITATION ANY WARRANTY OR CONDITION OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR OR GENERAL PURPOSE, TITLE, SECURITY,
              ACCURACY, AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING,
              LIVESCAPE SHOPS MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO
              OR OPERATION OF THE WEBSITE, USER CONTENT, ITEM VERIFICATION,
              LIVESCAPE SHOPS HISTORICAL SALES DATA, ESTIMATED VALUE OR ANY
              OTHER PRODUCT OR SERVICE PROVIDED BY LIVESCAPE SHOPS WILL BE
              UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND
              RISK OF LOSS RESULTING FROM YOUR USE OF THE WEBSITE OR FROM ANY
              USER CONTENT, OR ANY OTHER PRODUCT OR SERVICE PROVIDED BY
              LIVESCAPE SHOPS. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT
              DISCLAIMERS OF WARRANTIES OR CONDITIONS AND IN SUCH CASES THE
              DISCLAIMERS CONTAINED HEREIN MAY NOT APPLY TO YOU. LIVESCAPE SHOPS
              IS NOT RESPONSIBLE FOR THE ACCURACY OF ANY PAYMENT INSTRUMENT
              INFORMATION, INCLUDING, BUT NOT LIMITED TO, WHETHER SUCH
              INFORMATION IS UP-TO-DATE. LIVESCAPE SHOPS HAS NO DUTY OR
              RESPONSIBILITY TO ENSURE THAT A USER HAS PROVIDED A VALID AND
              PROPER PAYMENT METHOD TO CONDUCT A TRANSACTION, OR TO DETERMINE IF
              A USER'S PAYMENT INSTRUMENT IS IN GOOD STANDING AND SUFFICIENT TO
              CONDUCT A TRANSACTION. USERS UNDERSTAND THAT THEY MAY INCUR FEES
              AND/OR CHARGES AS A RESULT OF LIVESCAPE SHOPS TRANSACTIONS UNDER
              THE TERMS OF AGREEMENTS WITH PAYMENT INSTRUMENT ISSUERS, OR
              LIVESCAPE SHOPS MAY BE UNABLE TO FACILITATE SOME TRANSACTIONS. THE
              WEBSITE MAY NOT BE AVAILABLE DUE TO ANY NUMBER OF FACTORS
              INCLUDING, BUT NOT LIMITED TO, PERIODIC SYSTEM MAINTENANCE,
              SCHEDULED OR UNSCHEDULED, ACTS OF GOD, UNAUTHORIZED ACCESS,
              VIRUSES, DENIAL OF SERVICE OR OTHER ATTACKS, TECHNICAL FAILURE OF
              THE WEBSITE AND/OR TELECOMMUNICATIONS INFRASTRUCTURE OR
              DISRUPTION, AND THEREFORE WE EXPRESSLY DISCLAIM ANY EXPRESS OR
              IMPLIED WARRANTY OR CONDITION REGARDING THE USE AND/OR
              AVAILABILITY, ACCESSIBILITY, SECURITY OR PERFORMANCE OF THE
              WEBSITE CAUSED BY SUCH FACTORS. WE DO NOT MAKE ANY REPRESENTATIONS
              OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION, MISDELIVERY OR
              FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS OR OTHER
              DATA.
            </p>
            <br />
            <h3>Disclaimers of Liability</h3>
            <br />
            <p>
              IN NO EVENT SHALL LIVESCAPE SHOPS BE LIABLE TO YOU FOR ANY
              SPECIAL, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, LOSS OF
              PROFIT, LOSS OF REVENUE, LOSS OF DATA, OR PUNITIVE DAMAGES,
              HOWEVER ARISING. LIVESCAPE SHOPS SHALL NOT BE RESPONSIBLE TO YOU
              OR ANY OTHER PARTY FOR ANY LIABILITY OR DAMAGES ARISING FROM OR IN
              RELATION TO YOUR USE OF THE WEBSITE, WHETHER ARISING FROM A
              TRANSACTION, USER CONTENT OR OTHERWISE, OR ANY OTHER PRODUCT OR
              SERVICE PROVIDED BY LIVESCAPE SHOPS; THIS DISCLAIMER INCLUDES ANY
              AND ALL LIABILITY AND DAMAGE ARISING FROM HARM OR DIRECT OR
              INDIRECT LOSS TO YOU, YOUR ITEM(S), YOUR REAL OR PERSONAL
              PROPERTY. ADDITIONALLY, LIVESCAPE SHOPS SHALL NOT BE LIABLE TO YOU
              FOR ANY DIRECT OR INDIRECT LOSSES ARISING FROM ANY SUSPENSION OR
              TERMINATION OF YOUR ACCOUNT, INABILITY TO ACCESS YOUR ACCOUNT OR
              USER CONTENT, ABILITY TO USE THE WEBSITE, OR ANY FAILURE, ERROR,
              OMISSION, INTERRUPTION, OR DEFECT OF THE WEBSITE. WE FURTHER
              EXPRESSLY DISCLAIM ALL LIABILITY FOR ANY CLAIMS ARISING FROM, IN
              CONNECTION WITH OR RELATING TO THE WEBSITE'S COMPLIANCE WITH
              APPLICABLE LAWS OR YOUR COMPLIANCE WITH APPLICABLE LAWS WHILE
              USING THE WEBSITE, INCLUDING YOUR COMPLIANCE WITH APPLICABLE
              CONSUMER PROTECTION LAWS AS A SELLER OF ITEMS TO CONSUMERS THROUGH
              THE WEBSITE. WE FURTHER EXPRESSLY DISCLAIM ALL LIABILITY OR
              RESPONSIBILITY IN CONNECTION WITH THIRD PARTY SERVICES. NOTHING
              HEREIN NOR ANY USE OF OUR WEBSITE IN CONNECTION WITH THIRD PARTY
              SERVICES CONSTITUTES OUR ENDORSEMENT, RECOMMENDATION OR ANY OTHER
              AFFILIATION OF OR WITH ANY THIRD PARTY SERVICES. LIVESCAPE SHOPS
              WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY DIRECT OR
              INDIRECT LOSS AND TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE
              LIABLE TO YOU FOR, ANY USE OF THE WEBSITE, INCLUDING BUT NOT
              LIMITED TO ANY DIRECT OR INDIRECT LOSSES, DAMAGES OR CLAIMS
              ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
              INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET
              ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) BLOCKCHAIN
              NETWORKS, CRYPTOCURRENCY WALLETS OR CORRUPT FILES; (IV)
              UNAUTHORIZED ACCESS TO THE WEBSITE; OR (V) ANY THIRD PARTY
              ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES,
              PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK. IF YOU ARE IN ANY
              WAY CONCERNED ABOUT ANOTHER USERS' SUITABILITY FOR THIS WEBSITE,
              PLEASE LET US KNOW IMMEDIATELY. SOME JURISDICTIONS DO NOT ALLOW
              THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES AND IN SUCH CASES
              SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS CONTAINED HEREIN MAY
              NOT APPLY TO YOU.
            </p>
            <br />
            <h3>User Disputes</h3>
            <br />
            <p>
              ANY DISPUTE THAT ARISES BETWEEN YOU AND ANOTHER USER IS TO BE
              RESOLVED BY YOU AND SUCH USER. LIVESCAPE SHOPS HAS NO
              RESPONSIBILITY TO RESOLVE ANY USER DISPUTE AND, EXCEPT WHERE
              PROHIBITED BY APPLICABLE LAW, SHALL NOT BE LIABLE FOR ANY DAMAGES
              ARISING FROM ANY SUCH USER DISPUTE. AS SUCH, YOU HEREBY RELEASE
              LIVESCAPE SHOPS FROM ANY AND ALL CLAIMS, DEMANDS, DAMAGES AND
              DIRECT OR INDIRECT LOSSES OF EVERY KIND ARISING FROM ANY USER
              DISPUTE.
            </p>
            <br />
            <h3>Limitation of Liability</h3>
            <br />
            <p>
              LIVESCAPE SHOPS'S LIABILITY TO YOU FOR DAMAGES FROM ANY CAUSE
              WHATSOEVER, AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN
              CONTRACT (EVEN IF A FUNDAMENTAL BREACH), TORT (INCLUDING
              NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, WILL BE LIMITED TO
              THE TOTAL FEES PAID BY YOU TO LIVESCAPE SHOPS IN THE 12 MONTHS
              IMMEDIATELY PRECEDING THE EVENT THAT GAVE RISE TO SUCH LIABILITY;
              PROVIDED THAT IF YOU HAVE PAID NO SUCH AMOUNTS, LIVESCAPE SHOPS'S
              MAXIMUM LIABILITY TO YOU SHALL BE THE GREATER OF USER'S TOTAL FEES
              TO LIVESCAPE SHOPS FOR THE 12 MONTHS PRIOR TO THE CLAIM OR $100.00
              USD. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF CERTAIN
              DAMAGES AND IN SUCH CASES SOME OR ALL OF THE EXCLUSIONS AND
              LIMITATIONS CONTAINED HEREIN MAY NOT APPLY TO YOU.
            </p>
            <br />
            <h3>Indemnification by You</h3>
            <br />
            <p>
              YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS LIVESCAPE SHOPS
              AND ITS AFFILIATES (INCLUDING ITS AND THEIR RESPECTIVE OFFICERS,
              DIRECTORS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS AND AGENTS) FROM
              AND AGAINST ANY AND ALL LIABILITIES, COSTS, EXPENSES, DIRECT OR
              INDIRECT LOSSES, OR OTHER AMOUNTS (INCLUDING REASONABLE LEGAL
              FEES) INCURRED BY LIVESCAPE SHOPS THAT ARISE FROM ANY CLAIM,
              DEMAND, OR CAUSE OF ACTION RELATING TO (1) YOUR USE OF THE
              WEBSITE, INCLUDING WITH RESPECT TO ANY TRANSACTION IN WHICH YOU
              ARE INVOLVED, (2) OUR USE OF YOUR ACCOUNT INFORMATION, (3) YOUR
              DISPUTES WITH OTHER USERS, (4) ANY OTHER USER'S ACTIONS OR
              OMISSIONS, AS THEY RELATE TO YOU, (5) YOUR BREACH OF THESE TERMS,
              (6) YOUR BREACH OF THE OTHER POLICIES, (7) YOUR MISUSE OF THE
              WEBSITE, (8) YOUR VIOLATION OF ANY LAW, (9) YOUR USER CONTENT, OR
              (10) YOUR VIOLATION OF ANY OTHER USER'S OR THIRD PARTY'S RIGHTS.
              AS STATED THROUGHOUT, EXCEPT WHERE PROHIBITED BY APPLICABLE LAW,
              LIVESCAPE SHOPS SHALL NOT BE LIABLE, AND SHALL BEAR NO LIABILITY,
              IN CONNECTION WITH ANY TRANSACTION. LIVESCAPE SHOPS DOES NOT BUY
              OR SELL ITEMS AND IS NOT A PARTY TO THE AGREEMENT OF PURCHASE AND
              SALE OF ITEMS BETWEEN BUYERS AND SELLERS. ANY TRANSACTION IN WHICH
              A LIVESCAPE SHOPS REPRESENTATIVE IS ACTING AS A USER SHALL BE
              CONSTRUED AS A USER TO USER TRANSACTION AND NOT AS A TRANSACTION
              INVOLVING LIVESCAPE SHOPS. ANY DAMAGES OR LIABILITIES THAT ARISE
              FROM OR IN CONNECTION WITH A TRANSACTION WILL BE YOUR SOLE
              RESPONSIBILITY.
            </p>
            <br />
            <h3>Limitation on Time to File Claims</h3>
            <br />
            <p>
              YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO OR ARISING OUT OF
              YOUR USE OF THE WEBSITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER
              THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
              PERMANENTLY BARRED.
            </p>
            <br />
            <h3>Force Majeure</h3>
            <br />
            <p>
              EXCEPT WHERE PROHIBITED BY APPLICABLE LAW , LIVESCAPE SHOPS SHALL
              HAVE NO LIABILITY FOR DELAYS OR FAILURE TO MEET RESPONSIBILITIES
              FROM ANY CONDITION OR EVENT THAT IS BEYOND ITS REASONABLE CONTROL,
              INCLUDING BUT NOT LIMITED TO GOVERNMENTAL ACTION, ACTS OF
              TERRORISM, ACTS OF NATURE INCLUDING EARTHQUAKES, FIRE, FLOOD, OR
              OTHER ACTS OF GOD; OR LABOR CONDITIONS. THIS LIST IS MEANT TO BE
              ILLUSTRATIVE AND IS NOT EXHAUSTIVE.
            </p>
            <br />
            <h3>
              Arbitration Agreement, Jury Trial Waiver, Class Action Waiver and
              Dispute Resolution
            </h3>
            <h3>Arbitration Agreement</h3>
            <br />
            <p>
              Except as prohibited by law and subject to any right you have to
              bring a claim against Livescape Shops in small claims court in the
              county or province in which you reside, or in Fort Worth, TX, you
              agree and acknowledge that the exclusive means for resolving any
              dispute or claim arising from the Terms or your use of the Website
              ("Dispute") shall be binding, individual arbitration and not in a
              class, representative or consolidated action or proceeding. Any
              action to enforce an arbitration decision, including any
              proceeding to confirm, modify, or vacate an arbitration decision,
              may be commenced in any court of competent jurisdiction, pursuant
              to the Governing Law and Jurisdiction section below. As limited
              exceptions, you and Livescape Shops may seek to resolve a Dispute
              in small claims court if it qualifies; and (ii) you and Livescape
              Shops each retain the right to seek injunctive, interlocutory or
              other equitable relief from a court to prevent (or enjoin) the
              infringement, passing off or misappropriation of your or our
              intellectual property rights. Subject to the foregoing, the
              arbitrator shall determine all issues of liability on the merits
              of any claim asserted by either party and may award declaratory or
              injunctive relief only in favor of the individual party seeking
              relief and only to the extent necessary to provide relief
              warranted by that party's individual claim. To the extent that you
              or we prevail on a claim and seek public injunctive relief (that
              is, injunctive relief that has the primary purpose and effect of
              prohibiting unlawful acts that threaten future injury to the
              public), the entitlement to and extent of such relief must be
              litigated in a civil court of competent jurisdiction and not in
              arbitration. The parties agree that litigation of any issues of
              public injunctive relief shall be stayed pending the outcome of
              the merits of any individual claims in arbitration. You can
              decline the provisions of this Arbitration Agreement within 30
              days of accepting these Terms by messaging us on our help page.
              Your opt out email must include your name and a statement
              manifesting your intent to opt out of the arbitration provisions
              of this Arbitration Agreement. By agreeing to arbitration under
              the Rules (as defined below), you agree that the arbitrator, and
              not any federal, provincial or state, or local court or agency,
              shall have the exclusive power to rule on the interpretation,
              applicability, and enforceability of the Arbitration Agreement, as
              well as on all substantive and procedural claims involved in the
              Dispute. All arbitration shall be conducted by a neutral
              arbitrator under the Consumer Arbitration Rules ("Rules") of the
              American Arbitration Association ("AAA"). Payment of all filing,
              administration and arbitrator fees will be governed by the AAA
              Rules, and we won't seek to recover the administration and
              arbitrator fees we are responsible for paying, unless the
              arbitrator finds your Dispute frivolous. If we prevail in
              arbitration, we'll pay all of our legal fees and costs and won't
              seek to recover them from you. If you prevail in arbitration, you
              will be entitled to an award of legal fees and expenses to the
              extent provided under applicable law. Subject to the foregoing,
              each party will be solely responsible for any and all fees
              incurred by such party in connection with the arbitration. Unless
              you exercise your right to an oral hearing, arbitration shall be
              conducted by written submissions. To begin an arbitration
              proceeding, you must send a letter requesting arbitration and
              describing your claims or disputes to Livescape Shops at the email
              address specified in the Contact Information section of these
              Terms. If we begin arbitration proceedings against you, we will
              provide you notice using your User Information. Payment of all
              filing, administration and arbitrator fees will be governed by the
              AAA's rules, except as provided in this Arbitration Agreement. The
              AAA's Rules and filing instructions are available at www.adr.org
              or by calling 1-800-778-7879. YOU AND LIVESCAPE SHOPS AGREE: (I)
              THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
              INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              PURPORTED CLASS OR REPRESENTATIVE PROCEEDING AND (II) IF THE
              PARTIES' DISPUTE IS RESOLVED THROUGH ARBITRATION, THE ARBITRATOR
              MAY NOT CONSOLIDATE ANOTHER PERSON'S CLAIMS WITH YOUR CLAIMS, AND
              MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR
              CLASS PROCEEDING ("CLASS ACTION WAIVER"). If this specific Class
              Action Waiver is found to be unenforceable, then the entirety of
              this Dispute Resolution section shall be null and void. Except as
              set forth herein, if any term or provision of this Arbitration
              Agreement is held invalid or unenforceable, such term or provision
              shall be severed from this Arbitration Agreement, and the
              remainder of the terms and provisions shall remain in full force
              and effect.
            </p>
            <br />
            <h3>Governing Law and Jurisdiction</h3>
            <br />
            <p>
              Unless otherwise prohibited by applicable law, any claim relating
              to the Website shall be governed by the laws of the State of
              Texas, without regard to any conflict of law provisions; provided,
              however, that the Arbitration Agreement above shall be governed by
              the Federal Arbitration Act. In the event that any or all of the
              arbitration provision is held to be unenforceable, any litigation
              against Livescape Shops may be commenced only in the federal or
              state courts located in Fort Worth, Texas. You hereby irrevocably
              consent to the jurisdiction of those courts for such purposes.
            </p>
            <br />
            <h3>Miscellaneous</h3>
            <h3>Contact Information</h3>
            <br />
            <p>
              Please send all feedback, comments, requests for technical
              support, and other communications relating to the Website using
              our help page. By doing so, you irrevocably grant us—Open Day,
              LLC, the official legal name of Livescape Shops—perpetual and
              unlimited permission to reproduce, distribute, create derivative
              works of, modify, publicly perform (including on a
              through-to-the-audience basis), communicate to the public, make
              available, publicly display, and otherwise use and exploit any
              feedback or comments you provide to Livescape Shops and
              derivatives thereof for any purpose and without restriction, free
              of charge and without attribution of any kind. This includes
              making, using, selling, offering for sale, importing, and
              promoting commercial products and services that incorporate or
              embody such feedback or comments, whether in whole or in part, and
              whether as provided or as modified.
            </p>
            <br />
            <h3>Enforceability</h3>
            <br />
            <p>
              Even if Livescape Shops does not require strict compliance with
              these Terms in each instance, you are still obligated to comply
              with these Terms. Our failure to enforce, at any time, any of the
              provisions, conditions, or requirements of these Terms, or the
              failure to require, at any time, performance by you of any of the
              provisions of these Terms, will in no way waive your obligation to
              comply with any of the provisions of these Terms, or our ability
              to enforce each and every such provision as written. Any and all
              waivers by Livescape Shops of any provision, condition, or
              requirement of these Terms will only be effective against
              Livescape Shops if it is in writing and signed by an authorized
              officer of Livescape Shops. No waiver by Livescape Shops of any
              term or condition set out herein shall be deemed a further or
              continuing waiver of such term or condition or a waiver of any
              other term or condition, and any failure of Livescape Shops to
              assert a right or provision under these Terms shall not constitute
              a waiver of such right or provision.
            </p>
            <br />
            <h3>
              Links to Third-Party Websites, Applications, Software, or Content
            </h3>
            <br />
            <p>
              As part of the Website, we may provide you with convenient links
              to third-party website(s) as well as content or items belonging to
              or originating from third-parties, and the Website may also
              integrate with, or be accessible through, Other Applications
              including software and services provided by third-parties
              (collectively, "Third-Party Content"). These links are provided as
              a courtesy to Users. Livescape Shops has no control over
              Third-Party Content or the promotions, materials, information,
              goods or services advertised by or available from Third-Party
              Content. Such Third-Party Content is not investigated, monitored
              or checked for accuracy, appropriateness, or completeness by
              Livescape Shops. We are not responsible for any Third-Party
              Content posted on, available through or installed from the
              Website, including the content, accuracy, offensiveness, opinions,
              reliability, privacy practices or other policies of or contained
              in the Third-Party Content. Our inclusion of, linking to, or
              permitting the use or installation of any Third-Party Content does
              not imply approval or endorsement by Livescape Shops. In addition,
              Livescape Shops disclaims liability for any direct or indirect
              loss, damage and any other consequence resulting directly or
              indirectly from or relating to your use or access of Third-Party
              Content or any information that you may provide or any transaction
              conducted with or through the Third-Party Content or the failure
              of any information, software or services posted or offered by such
              service providers or any error, omission or misrepresentation by
              such service providers or any computer virus arising from or
              system failure associated with the Third-Party Content. If you
              decide to leave the Website and access or use any Third-Party
              Content, you do so at your own risk and you should be aware that
              our terms and policies no longer govern. You should review the
              applicable terms and policies, including privacy and data
              gathering practices, of any third-party site or application to
              which you navigate from the Website. You may incur charges, such
              as message and data charges, from your wireless provider for any
              SMS messages that you send or receive in connection with your use
              of the Website. You agree that you are solely responsible for any
              such charges.
            </p>
            <br />
            <h3>Entire Agreement & Severability</h3>
            <br />
            <p>
              These Terms and our Privacy Policy constitute the sole and entire
              agreement between you and Livescape Shops regarding the Website
              and supersede all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, regarding the Website. If any provision of these Terms is
              held by an arbitrator or court or other tribunal of competent
              jurisdiction to be invalid, illegal or unenforceable for any
              reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of the Terms
              will continue in full force and effect. Our rights under these
              Terms will survive any termination of these Terms.
            </p>
            <br />
            <h3>Email and Legal Notice</h3>
            <br />
            <p>
              Communications made through the Livescape Shops e-mail and
              messaging system, if and when available, will not constitute legal
              notice to Livescape Shops in any situation where notice to
              Livescape Shops is required by contract or any law or regulation.
            </p>
            <br />
            <h3>You Consent to Receive Electronic Communications</h3>
            <br />
            <p>
              For contractual purposes, you (1) consent to receive
              communications from Livescape Shops in an electronic form via the
              email address you have submitted; and (2) agree that all Terms,
              agreements, notices, disclosures, and other communications that
              Livescape Shops provides to you electronically satisfy any legal
              requirement that such communication would satisfy if it were in
              writing. The foregoing does not affect your non-waivable rights.
              We may also use your email address, to send you other messages,
              consistent with and as described in more detail in our Privacy
              Policy located at www.livescapeshops.com/privacy. You may opt out
              of such email by sending notice of opt out to
              contact@livescapeshops.com.
            </p>
            <br />
            <h3>We May Amend the Website and these Terms</h3>
            <br />
            <p>
              We may update the Website (including its content, materials, and
              features) from time to time. Please be advised that any content
              contained on the Website may not necessarily be complete or
              up-to-date. Any of the material on the Website may be out of date
              at any given time, and we are under no obligation to update such
              material. We may revise and update these Terms from time to time
              in our sole discretion. All changes become effectively immediately
              when we post them and upon your continued use of the Website. It
              is your responsibility to check the Website from time to time, so
              you are aware of any changes. If you continue to use the Website
              after we post revised Terms, Privacy Policy, or other policy you
              signify your agreement to such revised Terms and policies.
              However, where required by law, we will notify you of material
              changes to the Terms or other policies by posting a notice in the
              Website and/or sending an email to the email address you provided
              in your Profile upon registration. For this reason, you should
              keep your contact and profile information current. If, following
              such changes, you no longer agree to these Terms or the Privacy
              Policy, you must discontinue using the Website.
            </p>
            <br />
            <h3>General Acknowledgment</h3>
            <br />
            <p>
              YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS, AND
              WILL BE BOUND BY THESE TERMS.
            </p>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfService;
