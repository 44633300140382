import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';

import Form from '../../../../components/Form';
import { channelAPI } from '../../../../api';
import { userManagement as $content } from '../../../../content';
import { useNotif } from '../../../../contexts/Notification';

const ResetPasswordConfirmation = ({ username, verificationCode }) => {
  const navigate = useNavigate();
  const { notifySuccess } = useNotif();

  const submitHandler = ({ newPassword }) => {
    return channelAPI.resetPassword(username, verificationCode, newPassword);
  };

  const onSuccess = () => {
    notifySuccess($content.reset_password_page.reset_password_success);
    navigate('/login');
  };

  return (
    <div className="rounded-lg">
      <main className="min-h-screen flex items-center justify-center ">
        <div className="bg-white dark:bg-gray-800 max-w-md w-full p-8 rounded-lg">
          <div className="flex justify-center mb-6">
            <Link to="/">
              <img
                className="w-40 h-auto dark:hidden"
                src="/logo-ls.svg"
                alt="Logo"
              />
              <img
                className="w-40 h-auto hidden dark:block dark:bg-gray-900"
                src="/logo-ls-dark.svg"
                alt="Logo"
              />
            </Link>
          </div>
          <h2 className="text-2xl font-semibold text-center text-gray-800 dark:text-white mb-6">
            {$content.reset_password_page.title}
          </h2>
          <Form
            inputsData={{
              newPassword: {
                label: 'New Password',
                type: 'password',
                confirmedBy: 'confirmNewPassword',
                required: true
              },
              confirmNewPassword: {
                label: 'Confirm New Password',
                type: 'password',
                required: true
              }
            }}
            onSuccess={onSuccess}
            submitHandler={submitHandler}
            submitText={$content.reset_password_page.reset_password}
          />
        </div>
      </main>
    </div>
  );
};

ResetPasswordConfirmation.propTypes = {
  username: PropTypes.string.isRequired,
  verificationCode: PropTypes.string.isRequired
};

export default ResetPasswordConfirmation;
