import React from 'react';
import Button from '../../components/Button';

const CallToAction = () => {
  return (
    <section className="w-full mx-auto py-16 sm:py-20">
      <div className="rounded-3xl bg-gradient-to-r from-violet-600 via-indigo-500 to-violet-600 shadow-2xl relative overflow-hidden">
        {/* Subtle background pattern */}
        <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle_at_1px_1px,#fff_1px,transparent_0)] bg-[length:24px_24px]"></div>
        
        <div className="relative p-12 sm:p-16 backdrop-blur-sm">
          <div className="flex flex-col items-center text-center max-w-3xl mx-auto">
            <h2
              className="mb-6 text-3xl sm:text-2xl font-extrabold leading-tight text-white tracking-tight"
              style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
            >
              Experience Live Boutique Shopping
            </h2>
            <p className="max-w-xl mb-8 text-base sm:text-sm text-gray-100 leading-relaxed">
              Browse exclusive collections, chat directly with boutique shops,
              and uncover unique finds—live and in real-time.
            </p>
            <Button
              type="nav"
              variant="primary"
              to="/register"
              className="px-8 py-4 text-base sm:text-lg bg-white text-purple-700 hover:bg-purple-50 font-bold rounded-full shadow-lg transition-all duration-300 ease-out hover:shadow-purple-500/25 hover:scale-105 hover:shadow-xl"
              aria-label="Join Now and Start Shopping Live"
            >
              Join Now
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(CallToAction);
