import React from 'react';
import { useCookieConsent } from '../hooks/useCookieConsent';
import { Cookie } from '../assets/icons';

function CookiePolicy() {
  const { isAccepted, acceptCookies, declineCookies } = useCookieConsent();

  if (isAccepted !== null) {
    return null;
  }

  return (
    <div
      className="fixed inset-x-0 bottom-0 p-4 bg-gray-900 bg-opacity-95 backdrop-blur-sm border-t border-gray-700 shadow-lg z-50 cookie-banner"
      role="region"
      aria-label="Cookie Policy Notification"
    >
      <div className="container mx-auto max-w-6xl flex sm:flex-col flex-row items-center justify-between sm:space-y-4 space-y-0 space-x-6 sm:space-x-0">
        <div className="flex items-center space-x-4">
          <Cookie className="w-8 h-8 text-purple-400 fill-current" />
          <p className="text-white text-sm leading-relaxed max-w-2xl">
            We use cookies to enhance your browsing experience, serve personalized ads or content,
            and analyze our traffic. By clicking "Accept", you consent to our use of cookies.
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href="/privacy"
            className="text-sm text-purple-400 hover:text-purple-300 transition-colors duration-300"
          >
            Learn more
          </a>
          <button
            className="text-sm font-medium text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-400 rounded-md px-4 py-2 transition-colors duration-300"
            onClick={acceptCookies}
          >
            Accept
          </button>
          <button
            className="text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md px-4 py-2 transition-colors duration-300"
            onClick={declineCookies}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
