import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/User';
import Spinner from '../components/Spinner';

const RequireSeller = () => {
  const location = useLocation();
  const { isSessionValid, userData } = useUser();

  if (!isSessionValid)
    return (
      <Navigate
        to={`/login${location?.search || ''}`}
        state={{ from: location }}
        replace
      />
    );

  if (!userData) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: '100%', width: '100%', marginTop: '10rem' }}
      >
        <Spinner size="large" variant="light" />
      </div>
    );
  }

  const { isSeller } = userData;

  if (isSessionValid && !isSeller)
    return <Navigate to="/start-selling" replace />;

  return <Outlet />;
};

export default RequireSeller;
