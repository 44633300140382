import { apiBaseUrl, authFetch, getCurrentSession, unauthFetch } from './utils';

export const getUserChannelData = async (username) => {
  const { result: session } = await getCurrentSession();
  const isSessionValid = !!session;
  const _fetch = isSessionValid ? authFetch : unauthFetch;

  return await _fetch({ url: `${apiBaseUrl}/channels/${username}` });
};

export const getLiveChannels = (username) =>
  unauthFetch({ url: `${apiBaseUrl}/channels?isLive=true` });

export const searchChannels = (query) =>
  unauthFetch({ url: `${apiBaseUrl}/channels/search?query=${query}` });

export const getSearchChannelData = async (queryData = {}, keyword) => {
  const encodedKeyword = encodeURIComponent(keyword);
  const qs = new URLSearchParams(queryData).toString();
  const queryString = qs ? `?${qs}` : '';

  return await authFetch({
    url: `${apiBaseUrl}/channels/${encodedKeyword}${queryString}`
  });
};