import React from 'react';
import Footer from '../../layouts/AppLayoutWithNavbar/Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen dark:bg-darkMode-gray-dark bg-gray-50 pt-24 p-4">
      <div className="w-full max-w-3xl mx-auto p-16 sm:p-8 xs:p-8 bg-white backdrop-blur-sm rounded-xl dark:bg-black shadow">
        <h2 className="text-3xl text-center text-gray-800 mb-12 dark:text-gray-200">
          Privacy Policy
        </h2>
        <div className="overflow-y-auto">
          <div className="prose prose-xl text-gray-700 mx-auto dark:prose-dark dark:text-gray-400">
            <p>
              We at Livescape Shops—including Open Day, LLC and its affiliates
              (collectively "Livescape Shops," "we," or "us")—offer a
              marketplace that connects buyers and sellers for easy and safe
              transactions. This Privacy Policy explains how we collect, use,
              and share your personal information and how you can exercise your
              privacy rights.
            </p>
            <br />
            <h3>Scope</h3>
            <br />
            <p>
              This Privacy Policy applies to all personal information we collect
              and process through our websites, social media pages, newsletters,
              and other online or offline offerings (collectively referred to as
              our "Services").
            </p>
            <br />
            <h3>Personal Information We Collect</h3>
            <br />
            <p>
              The types of personal information we collect vary based on your
              interactions with us, your use of our Services, and legal
              requirements. We gather information you provide directly, data
              automatically collected when you use our Services, and information
              from third-party services and organizations, as detailed below.
            </p>
            <br />
            <h3>Personal Information You Provide to Us</h3>
            <br />
            <p>
              We may collect the following personal information that you provide
              to us:
            </p>
            <br />
            <p>
              <strong>Account Data:</strong> When you create an account or use
              our Services, you provide personal information such as your name,
              email address, postal address, phone number, and other contact
              details. Some of this information is required to establish a
              Livescape Shops account and access our Services; without it,
              account creation is not possible. You may also choose to add
              optional details to your account, like a profile picture and a
              personal description.
            </p>
            {/*improvements to here*/}
            <br />
            <p>
              <strong>Credentials Data:</strong> For Livescape Shops account
              holders, we collect passwords and other security information for
              authentication and access. Without this information, you cannot
              create or access your Livescape Shops account.
            </p>
            <br />
            <p>
              <strong>Payment and Purchase Data:</strong> We collect personal
              information and transaction details related to your purchases or
              sales, as well as your payment information (e.g., credit card and
              account numbers, billing address). Payments made through our
              Services are processed by Stripe, our payment service provider.
              Their privacy policy is available at{' '}
              <a href="https://stripe.com/privacy">
                https://stripe.com/privacy
              </a>
              . While we do not directly collect or store payment card numbers,
              we may receive associated information such as billing details.
            </p>
            <br />
            <p>
              <strong>Additional Seller Identity Data:</strong> For sellers, we
              collect additional identification information, such as your tax
              identification number (e.g., social security number), date of
              birth, identification documents, and other details provided when
              using our Services as a seller.
            </p>
            <br />
            <p>
              <strong>Communications Data:</strong> We may collect personal
              information such as your email address, phone number, or mailing
              address when you communicate with us, for example, to request
              information about our Services, seek customer or technical
              support, apply for a job, or for other communications.
            </p>
            <br />
            <p>
              <strong>Survey Data:</strong> If you participate in our surveys,
              you may be asked to provide certain information, which may include
              personal information.
            </p>
            <br />
            <p>
              <strong>User Content Data:</strong> We and other users may collect
              personal information you submit through interactive features
              (e.g., livestreams, photographs, messaging, commenting, forums,
              blogs, and social media pages) and associated metadata (e.g.,
              creation date and location). Information shared publicly through
              these features ("User Content") is considered public and not
              covered by this Privacy Policy. Please be cautious about revealing
              identifiable information to other users.
            </p>
            <br />
            <p>
              <strong>Streaming Data:</strong> We record and store your
              streaming sessions, including your image and voice, for a limited
              period when you stream on our Services.
            </p>
            <br />
            <p>
              <strong>Event Data:</strong> We collect personal information from
              individuals who attend conferences, trade shows, and other events
              where we host or participate.
            </p>
            <br />
            <p>
              <strong>Business Development Data:</strong> We collect personal
              information from individuals and third parties for assessing and
              pursuing potential business opportunities.
            </p>
            <br />
            <p>
              <strong>Job Applicant Data:</strong> We collect information from
              job applicants who respond to our postings on our Services. This
              includes details like desired pay, education, work history, and
              visa status, submitted with your application to assess your
              qualifications. You may also choose to provide information on your
              gender, ethnicity, veteran status, disability status, and links to
              your personal website, blog, portfolio, or LinkedIn profile.
            </p>
            <br />
            <p>
              <strong>Other Information:</strong> We may collect any other
              personal information that you choose to share with us.
            </p>
            <br />
            <h3>Personal Information Collected Automatically</h3>
            <br />
            <p>
              When you use our Services, we automatically collect personal
              information about your device, browsing actions, and usage
              patterns, including:
            </p>
            <br />
            <p>
              <strong>Device Data:</strong> We collect technical data from your
              devices, such as IP address, user settings, MAC address, cookie
              identifiers, mobile carrier, mobile advertising identifiers,
              browser or device information, general location (e.g., city and
              country from your IP address), and Internet service provider.
            </p>
            <br />
            <p>
              <strong>Usage Data:</strong> We collect data about your
              interactions with our Services, such as the pages you visit, links
              clicked, content interacted with, frequency and duration of
              activities, and other usage information, including actions linked
              to your Livescape Shops account (e.g., items in your shopping
              cart, followed users). We may collect this data through our
              Services or using technologies like cookies. We'll ask for your
              consent where required by law. Additionally, we may gather
              information about you from other users, such as when you're
              tagged. For more details on our use of cookies and similar
              technologies, see the 'Cookies and Other Technologies' section
              below.
            </p>
            <br />
            <h3>Personal Information Collected from Other Sources</h3>
            <br />
            <p>
              We may also obtain personal information about you from third-party
              services and organizations. For example:
            </p>
            <br />
            <p>
              <strong>Payment Partners:</strong> We receive information such as
              payment confirmation details from our payment providers.
            </p>
            <br />
            <h3>Cookies and Other Technologies</h3>
            <br />
            <p>
              We and third parties providing content, advertising, or
              functionality on our Services may use cookies, local storage, and
              other technologies to automatically collect information during
              your use of our Services.
            </p>
            <br />
            <p>
              <strong>Cookies:</strong> Cookies are small text files placed in
              your browser that store preferences and enhance your experience.
            </p>
            <br />
            <p>We use these Technologies for various purposes:</p>
            <ul>
              <li>
                <strong>Essential Cookies:</strong> Necessary for accessing our
                Services, preventing fraudulent activity, improving security,
                and enabling essential functionality.
              </li>
              <li>
                <strong>Performance and Analytics Cookies:</strong> Collect
                information about traffic to our Services and how users use our
                Services.
              </li>
              <li>
                <strong>Functional Cookies:</strong> Enhance your experience by
                remembering your preferences, interests, or past items viewed
                when you sign into our Services.
              </li>
              <li>
                <strong>Targeting and Advertising Cookies:</strong> Used to
                deliver advertisements that are more relevant to you and your
                interests.
              </li>
            </ul>
            <br />
            <p>
              <strong>Managing Cookies:</strong> You can manage your cookie
              preferences by adjusting your browser settings to refuse cookies.
              Please note that if you choose to refuse cookies, you may not be
              able to use the full functionality of our Services.
            </p>
            <br />
            <p>
              <strong>Do Not Track Signals:</strong> Some browsers offer a 'Do
              Not Track' (DNT) signal. We currently do not respond to DNT
              signals.
            </p>
            <br />
            <h3>How We Use Your Personal Information</h3>
            <br />
            <p>
              We use your personal information for various purposes, including
              providing our Services, conducting business operations, and
              marketing our products and Services, as detailed below.
            </p>
            <br />
            <h3>Service Delivery</h3>
            <br />
            <p>
              We use your personal information to provide you with our Services,
              such as:
            </p>
            <ul>
              <li>Setting up your account for Service access;</li>
              <li>
                Sharing personal information with third parties to provide the
                Services;
              </li>
              <li>
                Providing access to specific areas, functionalities, and
                features of our Services, including content creation and
                interaction;
              </li>
              <li>
                Managing your orders, payments, returns, and exchanges,
                including sharing necessary personal data with other users for
                transaction completion (e.g., sharing your address with a seller
                for shipping);
              </li>
              <li>
                Using streaming recordings for transaction verification and
                fraud prevention, with access limited to users who purchased
                items from your stream;
              </li>
              <li>Responding to customer or technical support requests;</li>
              <li>
                Enabling our payment processor to handle your financial
                transactions for purchases.
              </li>
            </ul>
            <br />
            <h3>Administrative Purposes</h3>
            <br />
            <p>
              We use your personal information for various administrative
              purposes related to providing the Services, including:
            </p>
            <ul>
              <li>
                Communicating with you about your account, activities on our
                Services, and policy changes;
              </li>
              <li>Managing your information and Livescape Shops account;</li>
              <li>Responding to inquiries and support requests;</li>
              <li>Allowing event registration;</li>
              <li>Providing updates and support for your purchases;</li>
              <li>
                Addressing and processing queries, claims, or disputes, such as
                problems or requests for your information.
              </li>
            </ul>
            <br />
            <h3>Security, Trust, and Safety</h3>
            <br />
            <p>
              We process your personal information to maintain the security of
              the Services, detect and prevent fraud, and ensure user safety,
              including:
            </p>
            <ul>
              <li>
                Verifying your identity for certain features, such as becoming a
                seller or verified buyer, and ensuring legal age requirements;
              </li>
              <li>
                Maintaining the safety and security of the Services by verifying
                accounts, investigating suspicious activity, detecting security
                incidents, and combating malicious, deceptive, or illegal
                activity;
              </li>
              <li>
                Reviewing User Content to assist in support requests, ensure
                compliance with our terms, policies, and community guidelines,
                and prevent fraud and abuse;
              </li>
              <li>
                Identifying and addressing technical or security issues, such as
                bugs, spam, and illegal activity;
              </li>
              <li>Ensuring internal quality control and safety.</li>
            </ul>
            <br />
            <h3>Legal Obligations and Compliance</h3>
            <br />
            <p>
              We process your personal information for legal compliance and to
              enforce our agreements and rights, including:
            </p>
            <ul>
              <li>
                Complying with legal obligations, cooperating with authorities,
                courts, and regulators as required by law;
              </li>
              <li>Enforcing our terms, guidelines, and policies;</li>
              <li>
                Conducting audits related to interactions, transactions, and
                compliance activities;
              </li>
              <li>
                Addressing reports of intellectual property infringement and
                inappropriate content.
              </li>
            </ul>
            <br />
            <h3>Processing Job Applications</h3>
            <br />
            <p>
              We process your personal information for job applications with
              Livescape Shops to evaluate your application, make hiring
              decisions, communicate with you about current and future career
              opportunities (unless you opt out), manage and improve our
              recruitment process, and conduct reference and background checks
              as required or permitted by law.
            </p>
            <br />
            <h3>Marketing and Advertising</h3>
            <br />
            <p>
              We may use your personal information to send you promotional
              communications about our Services, offers, and promotions. You can
              opt out of receiving such communications at any time by following
              the unsubscribe instructions provided in the communication or by
              contacting us.
            </p>
            <br />
            <p>
              We may also use your personal information to deliver personalized
              content and advertising, and to analyze the effectiveness of
              advertising campaigns.
            </p>
            <br />
            <h3>Legal Bases for Processing Personal Information</h3>
            <br />
            <p>
              Under the General Data Protection Regulation (GDPR), we rely on
              the following legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <strong>Performance of a Contract:</strong> We process your
                personal information to perform our contract with you, such as
                providing the Services you have requested.
              </li>
              <li>
                <strong>Legitimate Interests:</strong> We process your personal
                information for our legitimate business interests, such as
                improving our Services, fraud prevention, and marketing.
              </li>
              <li>
                <strong>Consent:</strong> We process your personal information
                based on your consent where required by law.
              </li>
              <li>
                <strong>Legal Obligation:</strong> We process your personal
                information to comply with legal obligations, such as tax and
                accounting requirements.
              </li>
              <li>
                <strong>Vital Interests:</strong> We may process your personal
                information where necessary to protect your vital interests or
                those of another person.
              </li>
            </ul>
            <br />
            <h3>How We Disclose Your Personal Information</h3>
            <br />
            <p>
              We disclose your personal information to third parties for various
              business purposes, such as providing our Services, protecting us
              or others, or during major business transactions like mergers,
              sales, or asset transfers.
            </p>
            <br />
            <h3>Disclosures to Provide Our Services</h3>
            <br />
            <p>
              We may share your personal information with third parties,
              including:
            </p>
            <ul>
              <li>
                <strong>Service Providers:</strong> We share your information
                with third-party service providers who help us provide, support,
                and protect our Services. This includes providers of IT support,
                cloud hosting, payment processing, content delivery, marketing,
                analytics, and customer service.
              </li>
              <li>
                <strong>APIs/SDKs:</strong> We use third-party APIs and SDKs to
                enhance the functionality of our Services. For example, we use
                Stripe for payment processing (
                <a href="https://stripe.com/privacy">
                  https://stripe.com/privacy
                </a>
                ) and Google Analytics for analytics (
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                ).
              </li>
            </ul>
            <br />
            <h3>Disclosures to Protect Us or Others</h3>
            <br />
            <p>
              We may access, preserve, and disclose any collected personal
              information to external parties if we believe in good faith it is
              necessary to:
            </p>
            <ul>
              <li>Comply with legal processes or law enforcement requests;</li>
              <li>
                Protect the rights, property, or safety of you, us, or others;
              </li>
              <li>Enforce our policies or contracts;</li>
              <li>Collect owed amounts;</li>
              <li>
                Assist with investigations or prosecutions of suspected or
                actual illegal activity.
              </li>
            </ul>
            <br />
            <h3>
              Disclosure in the Event of Merger, Sale, or Other Asset Transfers
            </h3>
            <br />
            <p>
              In the event of a merger, acquisition, financing due diligence,
              reorganization, bankruptcy, receivership, asset sale, or service
              transition, all collected personal information may be sold or
              transferred as part of the transaction, as allowed by law and/or
              contract.
            </p>
            <br />
            <h3>Your Privacy Choices and Rights</h3>
            <br />
            <p>
              You have rights and choices regarding your information. Some
              rights are generally applicable, while others depend on specific
              circumstances and applicable laws. These rights may have
              limitations, and Livescape Shops will respond to your requests
              within the time frames required by law.
            </p>
            <br />
            <h3>Your Privacy Choices</h3>
            <br />
            <p>
              <strong>Email Communications:</strong> If you receive an unwanted
              email from us, you can use the unsubscribe link found at the
              bottom of the email to opt out of receiving future emails. Note
              that you will continue to receive transaction-related emails
              regarding products or Services you have requested. We may also
              send you certain non-promotional communications regarding us and
              our Services, and you will not be able to opt out of those
              communications unless you close your account (e.g., communications
              regarding our Services or updates to our Terms or this Privacy
              Policy).
            </p>
            <br />
            <p>
              <strong>Cookies:</strong> You can stop or restrict the placement
              of cookies on your device or remove them by adjusting your browser
              or device settings. However, changing these settings may affect
              the functionality of our Services.
            </p>
            <br />
            <h3>Your Privacy Rights</h3>
            <br />
            <p>
              Depending on your location and applicable laws, you may have the
              following rights regarding your personal information:
            </p>
            <ul>
              <li>
                <strong>Right of Access:</strong> You can request access to your
                personal information.
              </li>
              <li>
                <strong>Right to Rectification:</strong> You can request
                correction of inaccurate or incomplete personal information.
              </li>
              <li>
                <strong>Right to Erasure:</strong> You can request deletion of
                your personal information.
              </li>
              <li>
                <strong>Right to Restrict Processing:</strong> You can request
                that we restrict processing of your personal information.
              </li>
              <li>
                <strong>Right to Data Portability:</strong> You can request to
                receive your personal information in a structured, commonly
                used, and machine-readable format.
              </li>
              <li>
                <strong>Right to Object:</strong> You can object to the
                processing of your personal information for direct marketing or
                on grounds relating to your particular situation.
              </li>
              <li>
                <strong>Right to Withdraw Consent:</strong> Where we rely on
                consent, you can withdraw your consent at any time.
              </li>
              <li>
                <strong>Right to Lodge a Complaint:</strong> You have the right
                to lodge a complaint with a supervisory authority.
              </li>
            </ul>
            <br />
            <p>
              To exercise any of these rights, please contact us as described in
              the 'Contact Us' section below.
            </p>
            <br />
            <h3>Appealing Our Decision to Decline Your Request</h3>
            <br />
            <p>
              If we decline to process your request, you have the right to
              appeal our decision. Please contact us with the details of your
              request and our decision, and we will review your appeal in
              accordance with applicable laws.
            </p>
            <br />
            <h3>Security of Your Personal Information</h3>
            <br />
            <p>
              We employ physical, technical, and administrative safeguards to
              protect the information collected through our Services. We adhere
              to internal policies and procedures for handling personal
              information, including its collection, use, disclosure, storage,
              protection, and destruction. However, no system is entirely
              secure, and we cannot guarantee the complete security of our
              networks, systems, servers, devices, and databases.
            </p>
            <br />
            <p>
              <strong>Limitation of Liability:</strong> While we strive to
              protect your personal information, we cannot guarantee its
              absolute security. To the extent permitted by law, we disclaim any
              liability for any unauthorized access to or use of your personal
              information.
            </p>
            <br />
            <h3>Data Breach Procedures</h3>
            <br />
            <p>
              In the event of a data breach, we will promptly notify you and any
              applicable regulator where we are legally required to do so. We
              will take reasonable measures to mitigate the breach and prevent
              future occurrences. We will notify affected users via email or
              through our Services within [Insert Time Frame] of becoming aware
              of the breach.
            </p>
            <br />
            <h3>Retention of Personal Information</h3>
            <br />
            <p>
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </p>
            <ul>
              <li>
                <strong>Account Information:</strong> We retain your account
                information for as long as your account is active and thereafter
                as needed for legal, operational, or other legitimate purposes.
              </li>
              <li>
                <strong>Transaction Data:</strong> We retain transaction data
                for [Insert Time Period], or as required by law.
              </li>
              <li>
                <strong>Job Applicant Data:</strong> We retain job applicant
                data for [Insert Time Period] after the application process.
              </li>
            </ul>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will delete or anonymize it.
            </p>
            <br />
            <h3>Children's Information</h3>
            <br />
            <p>
              Our Services are not intended for children under the age of 13. We
              do not knowingly collect personal information from children under
              13. If you are under 13, please do not use our Services or provide
              any personal information to us.
            </p>
            <p>
              Users must be at least 18 years old to create an account and use
              our Services.
            </p>
            <p>
              If we learn that we have collected personal information from a
              child under 13 without parental consent, we will delete that
              information as quickly as possible. If you believe we might have
              any information from or about a child under 13, please contact us.
            </p>
            <br />
            <h3>Third-Party Websites and Services</h3>
            <br />
            <p>
              Our Services may contain links to third-party websites, services,
              and applications that are not operated by us. We are not
              responsible for the privacy practices of these third parties. We
              encourage you to review the privacy policies of any third-party
              websites or services before providing any personal information.
            </p>
            <br />
            <h3>Changes to Our Privacy Policy</h3>
            <br />
            <p>
              We may update this Privacy Policy from time to time. If we make
              material changes to this Privacy Policy, we will notify you by
              email or by posting a notice on our Services prior to the
              effective date of the changes. We encourage you to review this
              Privacy Policy periodically to stay informed about how we are
              protecting your information.
            </p>
            <br />
            <h3>Contact Us</h3>
            <br />
            <p>
              If you have any questions or concerns about our privacy practices
              or this Privacy Policy, or wish to exercise your rights, please
              contact us at:
            </p>
            <p>
              <strong>Email:</strong> contact@livescapeshops.com
            </p>
            {/* <p>
              <strong>Mail:</strong> [Insert Physical Address]
            </p>
            <p>
              <strong>Phone:</strong> [Insert Phone Number]
            </p> */}
            {/* <p>
              <strong>Data Controller:</strong> Open Day, LLC, [Insert Address]
            </p> */}
          </div>
        </div>
      </div>
      <div className="pt-6">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
