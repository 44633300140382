// Core imports
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// API and Utils
import { productAPI } from '../../api';
import { isS3Url } from '../../utils';
import { dashboard as $contentDashboard } from '../../content';

// Custom hooks
import useStateWithCallback from '../../hooks/useStateWithCallback';
import {
  MaximumSizeExceededError,
  UnsupportedFileFormatError
} from '../../hooks/useProductImageUpload';

// Components
import Button from '../../components/Button';
import ProductImageUploader from './formElements/ProductImageUploader';

function AddProductImagesForm() {
  const { productId } = useParams();
  const { getProductDetails, changeProductPreferences } = productAPI;

  // State management
  const [productName, setProductName] = useStateWithCallback('');
  const [productAssets, setProductAssets] = useStateWithCallback({});
  const [productAssetId, setProductAssetId] = useStateWithCallback('');
  const [mainImageUrl, setMainImageUrl] = useStateWithCallback(
    productAssets?.mainImage?.url || ''
  );
  const [additionalImage1Url, setAdditionalImage1Url] = useStateWithCallback(
    productAssets?.additionalImage1?.url || ''
  );
  const [additionalImage2Url, setAdditionalImage2Url] = useStateWithCallback(
    productAssets?.additionalImage2?.url || ''
  );
  const [message, setMessage] = useState({ type: '', text: '' });

  // Initial data fetch
  useEffect(() => {
    const fetchProductDetails = async () => {
      const productDetails = await getProductDetails(productId);
      setMainImageUrl(productDetails?.productAssets?.mainImage?.url ?? '');
      setAdditionalImage1Url(
        productDetails?.productAssets?.additionalImage1?.url ?? ''
      );
      setAdditionalImage2Url(
        productDetails?.productAssets?.additionalImage2?.url ?? ''
      );
      setProductName(productDetails.productName);
      setProductAssets(productDetails.productAssets);
      setProductAssetId(productDetails.productAssetId);
    };
    fetchProductDetails();
  }, [
    getProductDetails,
    productId,
    setMainImageUrl,
    setAdditionalImage1Url,
    setAdditionalImage2Url,
    setProductAssetId,
    setProductAssets,
    setProductName
  ]);

  // Handlers
  const onImageDownload = useCallback(() => {
    setMessage({
      type: 'success',
      text: $contentDashboard.notification.success.product_uploaded
    });
  }, []);

  const handleChangeProduct = useCallback(
    async ({ assetType, result: { previewUrl, uploadDateTime } }) => {
      const { result, error } = await changeProductPreferences({
        [assetType]: { id: productId, previewUrl, uploadDateTime }
      });

      if (!result) {
        console.error('API call did not return a result:', error);
        return;
      }

      let newProductData = await getProductDetails(productId);
      while (isS3Url(newProductData.productAssets[assetType].url)) {
        newProductData = await getProductDetails(productId);
      }

      const setters = {
        mainImage: setMainImageUrl,
        additionalImage1: setAdditionalImage1Url,
        additionalImage2: setAdditionalImage2Url
      };
      setters[assetType]?.(newProductData.productAssets[assetType].url);

      if (error) {
        setMessage({
          type: 'error',
          text: $contentDashboard.notification.error.product_failed_to_save
        });
      }
    },
    [
      changeProductPreferences,
      productId,
      getProductDetails,
      setMainImageUrl,
      setAdditionalImage1Url,
      setAdditionalImage2Url
    ]
  );

  const onUpload = useCallback(
    ({ assetType, result, error }) => {
      if (result) handleChangeProduct({ assetType, ...result });

      if (result?.error) {
        console.error('Upload error: ', result.error);
        const errorMessages = {
          [UnsupportedFileFormatError.name]:
            $contentDashboard.notification.error.cant_select_file,
          [MaximumSizeExceededError.name]:
            $contentDashboard.notification.error.image_exceeded_max_size
        };

        setMessage({
          type: 'error',
          text:
            errorMessages[result.error.constructor.name] ||
            $contentDashboard.notification.error.product_failed_to_upload
        });
      }
    },
    [handleChangeProduct]
  );

  const onDelete = useCallback(
    ({ assetType, error }) => {
      const setters = {
        mainImage: setMainImageUrl,
        additionalImage1: setAdditionalImage1Url,
        additionalImage2: setAdditionalImage2Url
      };

      setters[assetType]?.('', () => {
        getProductDetails(productId);
        setMessage({
          type: 'success',
          text: $contentDashboard.notification.success.product_deleted
        });
      });

      if (error) {
        setMessage({
          type: 'error',
          text: $contentDashboard.notification.error.product_failed_to_delete
        });
      }
    },
    [
      setMainImageUrl,
      getProductDetails,
      productId,
      setAdditionalImage1Url,
      setAdditionalImage2Url
    ]
  );

  // Render helpers
  const renderImageUploader = (title, assetType, uploadUrl) => (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
      <h3 className="text-base font-medium text-gray-900 dark:text-gray-200 mb-4">
        {title}
      </h3>
      <ProductImageUploader
        previewShape="1/1"
        assetType={assetType}
        shouldAnimate={false}
        onDelete={(error) => onDelete({ assetType, error })}
        onImageDownload={onImageDownload}
        onUpload={(result, error) => onUpload({ assetType, result, error })}
        uploadUrl={uploadUrl}
        productId={productId}
        productAssetId={productAssetId}
        value={uploadUrl}
      />
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-black pt-20 pb-16">
      <div className="max-w-lg mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-100 dark:border-gray-700">
          <div className="px-6 py-6">
            <div className="flex items-center justify-between mb-6 border-b border-gray-200 dark:border-gray-700 pb-4">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Product Images
              </h1>
              <Button
                variant="secondary"
                className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                onClick={() =>
                  (window.location.href = `/products/${productId}`)
                }
              >
                ← Back
              </Button>
            </div>

            {productName && (
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  {productName}
                </h2>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Upload images of your product. Accepted formats are JPG and
                  PNG. Maximum file size is 5MB per image.
                </p>
              </div>
            )}

            {message.text && (
              <div
                className={`mb-6 p-3 rounded-lg border ${
                  message.type === 'error'
                    ? 'bg-red-50 border-red-200 text-red-700 dark:bg-red-900/30 dark:border-red-800'
                    : 'bg-green-50 border-green-200 text-green-700 dark:bg-green-900/30 dark:border-green-800'
                } transition-all duration-300 ease-in-out`}
              >
                {message.text}
              </div>
            )}

            <div className="space-y-8">
              {renderImageUploader('Main Image', 'mainImage', mainImageUrl)}
              {renderImageUploader(
                'Additional Image 1',
                'additionalImage1',
                additionalImage1Url
              )}
              {renderImageUploader(
                'Additional Image 2',
                'additionalImage2',
                additionalImage2Url
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductImagesForm;
