const OUTER_INPUT_BASE_CLASSES = ['flex', 'relative', 'w-full', 'min-w-[90px]'];

export const OUTER_INPUT_VARIANT_CLASSES = {
  vertical: [...OUTER_INPUT_BASE_CLASSES, 'flex-col', 'justify-center'],
  horizontal: [
    ...OUTER_INPUT_BASE_CLASSES,
    'flex-grow',
    'flex-row',
    'items-center',
    'justify-start'
  ]
};

export const INNER_INPUT_VARIANT_CLASSES = [
  '[&>input:not([type="password"]),&>input:placeholder-shown]:overflow-hidden',
  'flex',
  'flex-col',
  'items-start',
  'relative',
  'w-full'
];

export const INPUT_BASE_CLASSES = [
  'appearance-none',
  'bg-gray-200',                
  'border-none',
  'dark:bg-zinc-700',        
  'dark:placeholder-zinc-400', 
  'dark:text-white', 
  'focus:outline-none',   
  'font-sans',
  'font-normal',
  'h-11',                      
  'min-w-[90px]',
  'placeholder-gray-500',       
  'px-5',
  'py-3.5',                   
  'read-only:cursor-default',
  'rounded-2xl',              
  'text-[15px]',
  'text-black',
  'w-full'
];

export const INPUT_ERROR_CLASSES = [
  'shadow-red-500',             
  'focus:shadow-outline-red'   
];

export const INPUT_TYPE_CLASSES = {
  text: [
    ...INPUT_BASE_CLASSES,
    'placeholder-shown:overflow-hidden',
    'placeholder-shown:text-ellipsis',
    'placeholder-shown:whitespace-nowrap'
  ],
  password: INPUT_BASE_CLASSES,
  number: INPUT_BASE_CLASSES
};
