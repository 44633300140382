import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa'; // Add this import

import { PROFILE_COLORS } from '../../../../constants';
import { channelAPI } from '../../../../api';
import { userManagement as $content } from '../../../../content';
import { useUser } from '../../../../contexts/User';
import * as avatars from '../../../../assets/avatars';

import Form from '../../../../components/Form';
import RegisterUserRequestConfirmation from './RegisterUserRequestConfirmation';
import Button from '../../../../components/Button'; // Update this import path

// Simplified useRandomAvatarAndColor without useCallback
const useRandomAvatarAndColor = () => {
  const getRandomAvatar = () => {
    const avatarNames = Object.keys(avatars);
    return avatarNames[Math.floor(Math.random() * avatarNames.length)];
  };

  const getRandomColor = () => {
    return PROFILE_COLORS[Math.floor(Math.random() * PROFILE_COLORS.length)];
  };

  return { getRandomAvatar, getRandomColor };
};

const RegisterUser = () => {
  const [wasRequestSent, setWasRequestSent] = useState(false);
  const [username, setUsername] = useState('');
  const { initUserResources, checkSessionStatus } = useUser();
  const { getRandomAvatar, getRandomColor } = useRandomAvatarAndColor();

  // Removed useCallback from submitRegistration
  const submitRegistration = async (formValues) => {
    const { username, email, password } = formValues;

    if (!username || !email || !password) {
      throw new Error('All fields are required.');
    }

    if (password.length < 8) {
      throw new Error('Password must be at least 8 characters long.');
    }

    const sanitizedUsername = username.trim();
    const sanitizedEmail = email.trim();
    const avatar = getRandomAvatar();
    const color = getRandomColor();

    const userData = {
      username: sanitizedUsername,
      email: sanitizedEmail,
      password,
      avatar,
      color
    };

    try {
      return await channelAPI.register(userData);
    } catch (error) {
      console.error('Registration failed:', error);
      throw new Error('Failed to register. Please try again.');
    }
  };

  // Removed useCallback from onRequestSuccess
  const onRequestSuccess = async (result, formValues) => {
    if (result.userConfirmed) {
      try {
        const { result: signInResult } = await channelAPI.signIn(formValues);

        if (signInResult) {
          await initUserResources();
          checkSessionStatus();
        }
      } catch (error) {
        console.error('Sign-in failed:', error);
        // Provide a user-friendly error message
      }
    } else {
      setUsername(formValues.username);
      setWasRequestSent(true);
    }
  };

  return (
    <div className="w-full max-w-md p-2">
      {' '}
      {/* Added p-4 for padding */}
      {wasRequestSent ? (
        <RegisterUserRequestConfirmation username={username} />
      ) : (
        <section className="space-y-8">
          <header className="mb-8">
            <Button type="nav" to="/" className="block w-full hover:bg-transparent focus:bg-transparent dark:bg-transparent dark:hover:bg-transparent" variant="icon">
              <img
                className="w-48 h-auto mx-auto dark:hidden"
                src="/logo-ls.svg"
                alt="Livescape logo light"
              />
              <img
                className="w-48 h-auto mx-auto hidden dark:block"
                src="/logo-ls-dark.svg"
                alt="Livescape logo dark"
              />
            </Button>
          </header>

          <Form
            onSuccess={onRequestSuccess}
            submitHandler={submitRegistration}
            submitText={$content.register_page.create_account}
            title={$content.register_page.title}
            className="space-y-6"
            footer={
              <div className="mt-8 space-y-4">
                <p className="text-center">
                  <span className="text-gray-600 dark:text-gray-400">
                    {$content.register_page.have_an_account}
                  </span>{' '}
                  <Link
                    to="/login"
                    className="text-blue-600 dark:text-blue-400 hover:underline font-semibold"
                  >
                    {$content.sign_in}
                  </Link>
                </p>
                <div className="text-center text-sm text-gray-500 dark:text-gray-400">
                  <Link to="/terms" className="hover:underline">
                    Terms of Service
                  </Link>
                  <span className="mx-2">·</span>
                  <Link to="/privacy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            }
            inputsData={{
              username: {
                icon: <FaUser className="text-gray-400" />,
                placeholder: 'Enter your username'
              },
              email: {
                icon: <FaEnvelope className="text-gray-400" />,
                placeholder: 'Enter your email'
              },
              password: {
                type: 'password',
                icon: <FaLock className="text-gray-400" />,
                placeholder: 'Enter your password',
                confirmedBy: 'confirmPassword',
                description: $content.register_page.password_description
              }
            }}
          />
        </section>
      )}
    </div>
  );
};

export default RegisterUser;
