import { authFetch, apiBaseUrl } from './utils';

// This function is used to add a product to the cart.
export const addProductToCart = async (productId) => {
  const response = await authFetch({
    method: 'POST',
    url: `${apiBaseUrl}/cart`,
    body: { productId }
  });

  return response;
};

// This function is used to remove a product from the cart.
export const removeProductFromCart = async (cartId, productId) => {
  const response = await authFetch({
    method: 'DELETE',
    url: `${apiBaseUrl}/cart`,
    body: { cartId, productId }
  });

  return response;
};

// This function is used to get the cart items.
export const getCartItems = async () => {
  const response = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/cart`
  });

  return response;
};

// This function is used to update the quantity of a product in the cart.
export const updateProductQuantityInCart = async (
  cartId,
  productId,
  isIncrement
) => {
  const response = await authFetch({
    method: 'PATCH',
    url: `${apiBaseUrl}/cart/update-quantity`,
    body: { cartId, productId, isIncrement }
  });

  return response;
};
