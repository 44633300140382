import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateProductQuantityInCart } from '../../api/cart';

function AdjustQuantity({ item, cartId, quantityInCart }) {
  const [quantity, setQuantity] = useState(quantityInCart || 1);

  const adjustQuantity = (isIncrement) => {
    const newQuantity = Math.max(1, Math.min(100, quantity + (isIncrement ? 1 : -1)));
    setQuantity(newQuantity);
    updateQuantity(isIncrement);
  };

  const updateQuantity = (isIncrement) => {
    updateProductQuantityInCart(cartId, item.id, isIncrement)
      .then(() => window.location.reload())
      .catch((error) => {
        console.error('Failed to update quantity:', error);
        // Handle the error appropriately
      });
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setQuantity(Number.isNaN(value) ? 1 : value);
  };

  return (
    <div className="flex items-center justify-center w-24 h-6 bg-white rounded-full">
      <button
        className="flex justify-center items-center w-6 h-6 bg-white text-gray-800 rounded-full transition-colors duration-300 ease-in-out hover:bg-gray-200 active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        onClick={() => adjustQuantity(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 12h12" />
        </svg>
      </button>
      <input
        className="w-12 text-md text-center font-medium text-gray-700 bg-transparent outline-none transition-colors duration-300 ease-in-out focus:bg-gray-50"
        value={quantity}
        onChange={handleInputChange}
        readOnly
      />
      <button
        className="flex justify-center items-center w-6 h-6 bg-white text-gray-800 rounded-full transition-colors duration-300 ease-in-out hover:bg-gray-200 active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        onClick={() => adjustQuantity(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
      </button>
    </div>
  );
}

AdjustQuantity.propTypes = {
  item: PropTypes.object.isRequired,
  cartId: PropTypes.string.isRequired,
  quantityInCart: PropTypes.number.isRequired,
};

export default AdjustQuantity;