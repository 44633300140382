import PropTypes from 'prop-types';
import { clsm, noop } from '../../utils';
import { ShoppingCart } from '../../assets/icons';
import Button from '../../components/Button';

const DataUnavailable = ({
  className = '',
  noDataText = '',
  hasError = false,
  tryAgainFn = noop,
  tryAgainText = ''
}) => (
  <div className={clsm([className, 'space-y-8'])}>
    <div className={clsm(['flex', 'flex-col', 'items-center', 'justify-center', 'space-y-2'])}>
      <ShoppingCart className={clsm(['w-8', 'h-8', '[&>path]:fill-indigo-600', '[&>path]:dark:fill-white'])} /> 
      <h3 className={clsm(['text-gray-800', 'dark:text-white', 'font-semibold', 'text-base'])}>
        {noDataText}
      </h3>
      <p className="text-gray-500 dark:text-gray-300 max-w-lg text-center">
        {hasError 
          ? 'An error occurred while fetching data.' 
          : 'Explore the wide selection of products and find the perfect items to add to your cart.'
        }
      </p>
    </div>
    {hasError && (
      <Button
        onClick={tryAgainFn}
        variant="secondary"
        className="bg-lightMode-gray"
      >
        {tryAgainText}
      </Button>
    )}
  </div>
);

DataUnavailable.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  noDataText: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string
};

export default DataUnavailable;