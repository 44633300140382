import { useMemo } from 'react';

import {
  FLASH_SALE_DATA_KEYS,
  STREAM_ACTION_NAME,
  STREAM_MANAGER_ACTION_LIMITS
} from '../../../../../constants';
import { useStreamManagerActions } from '../../../../../contexts/StreamManagerActions';
import { streamManager as $streamManagerContent } from '../../../../../content';
import Input from './formElements/Input';
import RangeSelector from './formElements/RangeSelector';
import PriceInput from './formElements/PriceInput';
import NumberInput from './formElements/NumberInput';

const FlashSale = () => {
  const {
    currentStreamManagerActionErrors,
    getStreamManagerActionData,
    updateStreamManagerActionData
  } = useStreamManagerActions();

  const {
    flashSaleTitle,
    flashSalePrice,
    flashSaleDiscountPrice,
    duration,
    flashSaleQuantity,
    flashSaleShippingRate
  } = getStreamManagerActionData(STREAM_ACTION_NAME.FLASH_SALE);

  const LIMITS = useMemo(
    () => STREAM_MANAGER_ACTION_LIMITS[STREAM_ACTION_NAME.FLASH_SALE],
    []
  );

  const contentMapper = useMemo(
    () => ({
      [STREAM_ACTION_NAME.FLASH_SALE]: {
        content: $streamManagerContent.stream_manager_actions.flash_sale,
        rangeSelector: {
          label:
            $streamManagerContent.stream_manager_actions.flash_sale.duration,
          dataKey: FLASH_SALE_DATA_KEYS.DURATION,
          min: LIMITS[FLASH_SALE_DATA_KEYS.DURATION]?.min,
          max: LIMITS[FLASH_SALE_DATA_KEYS.DURATION]?.max
        }
      }
    }),
    [LIMITS]
  );

  const updateStreamManagerActionFlashSaleData = (data) => {
    updateStreamManagerActionData({
      dataOrFn: data,
      actionName: STREAM_ACTION_NAME.FLASH_SALE
    });
  };

  const {
    content: {
      title: titleLabel,
      price: priceLabel,
      discount_price: discountPriceLabel,
      quantity: quantityLabel,
      shipping_rate: shippingLabel
    },
    rangeSelector: {
      label: rangeSelectorLabel,
      dataKey: rangeSelectorDataKey,
      min: rangeSelectorMin,
      max: rangeSelectorMax
    }
  } = contentMapper[STREAM_ACTION_NAME.FLASH_SALE];

  return (
    <>
      <Input
        dataKey={FLASH_SALE_DATA_KEYS.TITLE}
        error={currentStreamManagerActionErrors[FLASH_SALE_DATA_KEYS.TITLE]}
        label={titleLabel}
        name="streamManagerActionFormTitle"
        onChange={updateStreamManagerActionFlashSaleData}
        placeholder={titleLabel}
        value={flashSaleTitle}
      />

      <PriceInput
        dataKey={FLASH_SALE_DATA_KEYS.DISCOUNT_PRICE}
        error={
          currentStreamManagerActionErrors[FLASH_SALE_DATA_KEYS.DISCOUNT_PRICE]
        }
        label={discountPriceLabel}
        name="streamManagerActionFormDiscountPrice"
        onChange={updateStreamManagerActionFlashSaleData}
        placeholder={discountPriceLabel}
        value={flashSaleDiscountPrice}
      />
      <PriceInput
        dataKey={FLASH_SALE_DATA_KEYS.PRICE}
        error={currentStreamManagerActionErrors[FLASH_SALE_DATA_KEYS.PRICE]}
        label={priceLabel}
        name="streamManagerActionFormPrice"
        onChange={updateStreamManagerActionFlashSaleData}
        placeholder={priceLabel}
        value={flashSalePrice}
      />
      <NumberInput
        dataKey={FLASH_SALE_DATA_KEYS.QUANTITY}
        error={currentStreamManagerActionErrors[FLASH_SALE_DATA_KEYS.QUANTITY]}
        label={quantityLabel}
        name="streamManagerActionFormQuantity"
        onChange={updateStreamManagerActionFlashSaleData}
        placeholder={quantityLabel}
        value={flashSaleQuantity}
      />
      <PriceInput
        dataKey={FLASH_SALE_DATA_KEYS.SHIPPING_RATE}
        error={
          currentStreamManagerActionErrors[FLASH_SALE_DATA_KEYS.SHIPPING_RATE]
        }
        label={shippingLabel}
        name="streamManagerActionFormShipping"
        onChange={updateStreamManagerActionFlashSaleData}
        placeholder={shippingLabel}
        value={flashSaleShippingRate}
      />
      <RangeSelector
        label={rangeSelectorLabel}
        name="streamManagerActionFormDuration"
        dataKey={rangeSelectorDataKey}
        updateData={updateStreamManagerActionFlashSaleData}
        value={duration}
        min={rangeSelectorMin}
        max={rangeSelectorMax}
      />
    </>
  );
};

FlashSale.propTypes = {};

export default FlashSale;
