import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useNotif } from '../../../contexts/Notification';
import { channelAPI } from '../../../api';
import { userManagement as $content } from '../../../content';
import { useUser } from '../../../contexts/User';
import Form from '../../../components/Form';
import Button from '../../../components/Button';  // Add this import

import { FaUser, FaLock } from 'react-icons/fa'; // Add this import

// Simplify the sanitizeInput function without DOM manipulation
const escapeHtml = (unsafe) =>
  typeof unsafe === 'string'
    ? unsafe.replace(
        /[&<>"']/g,
        (match) =>
          ({
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
          }[match])
      )
    : '';

const SigninUser = () => {
  const { checkSessionStatus, initUserResources, logOutAction } = useUser();
  const { notifySuccess, notifyError } = useNotif();
  const [searchParams] = useSearchParams();

  // Directly extract and sanitize query parameters
  const verificationCode = escapeHtml(searchParams.get('code') || '');
  const username = escapeHtml(searchParams.get('username') || '');

  useEffect(() => {
    const confirmUser = async () => {
      try {
        const { result } = await channelAPI.verifyUserEmail(username, verificationCode);
        if (result) {
          notifySuccess($content.notification.success.registration_confirmed);
        } else {
          notifyError($content.notification.error.registration_not_confirmed);
        }
      } catch {
        notifyError($content.notification.error.registration_not_confirmed);
      }
    };

    if (verificationCode && username) {
      confirmUser();
    }
  }, [username, verificationCode, notifySuccess, notifyError]);

  useEffect(() => {
    if (logOutAction === 'accountDeletion') {
      notifySuccess($content.notification.success.account_deleted);
    }
  }, [logOutAction, notifySuccess]);

  const onSuccess = async () => {
    await initUserResources();
    checkSessionStatus();
  };

  return (
    <div className="w-full p-2"> {/* Added p-4 for padding */}
      <header className="mb-8">
        <Button type="nav" to="/" className="block w-full hover:bg-transparent focus:bg-transparent dark:bg-transparent dark:hover:bg-transparent" variant="icon">
          <img
            className="w-48 h-auto mx-auto dark:hidden"
            src="/logo-ls.svg"
            alt="Livescape logo light"
          />
          <img
            className="w-48 h-auto mx-auto hidden dark:block"
            src="/logo-ls-dark.svg"
            alt="Livescape logo dark"
          />
        </Button>
      </header>

      <main>
        <Form
          disableValidation
          submitHandler={channelAPI.signIn}
          submitText={$content.sign_in}
          title={$content.login_page.title}
          onSuccess={onSuccess}
          className="space-y-6"
          footer={
            <div className="mt-8 space-y-4">
              <p className="text-center">
                <span className="text-gray-600 dark:text-gray-400">{$content.login_page.new_user}</span>{' '}
                <Link to="/register" className="text-blue-600 dark:text-blue-400 hover:underline font-semibold">
                  Create an account
                </Link>
              </p>
              <div className="text-center text-sm text-gray-500 dark:text-gray-400">
                <Link to="/terms" className="hover:underline">Terms of Service</Link>
                <span className="mx-2">·</span>
                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
              </div>
            </div>
          }
          inputsData={{
            username: {
              icon: <FaUser className="text-gray-400" />,
              placeholder: 'Enter your username',
            },
            password: {
              type: 'password',
              icon: <FaLock className="text-gray-400" />,
              placeholder: 'Enter your password',
              footer: (
                <Link to="/reset" className="text-sm text-blue-600 dark:text-blue-400 hover:underline">
                  Forgot password?
                </Link>
              ),
            },
          }}
        />
      </main>
    </div>
  );
};

export default SigninUser;
