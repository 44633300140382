import { useEffect, useRef, forwardRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import { clsm } from '../../../../utils';
import {
  createAnimationProps,
  getDefaultBounceTransition
} from '../../../../helpers/animationPropsHelper';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useFlashSale } from '../../../../contexts/StreamManagerActions/FlashSale';
import { useUser } from '../../../../contexts/User';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import useResize from '../../../../hooks/useResize';
import { useLocation } from 'react-router-dom';

// Static heights based on design
const COMPOSER_HEIGHT_PX = 92;
const PURCHASE_BUTTON_HEIGHT_PX = 60;
const PROGRESS_BAR_HEIGHT_PX = 46;
const FOOTER_HEIGHT_PX = PURCHASE_BUTTON_HEIGHT_PX + PROGRESS_BAR_HEIGHT_PX;
const SPACE_BETWEEN_FLASH_SALE_AND_COMPOSER_PX = 20;

const FlashSaleContainer = forwardRef(({ children }, ref) => {
  const marginBotttomRef = useRef();
  const [height, setHeight] = useState();
  const fullHeightOfFlashSale = useRef();
  const {
    hasScrollbar,
    isPurchasing,
    hasFlashSaleEnded,
    composerRefState,
    dispatchFlashSaleState,
    isActiveFlashSale
  } = useFlashSale();

  const { isTouchscreenDevice, isLandscape, isDesktopView } =
    useResponsiveDevice();
  const { isSessionValid, userData = {} } = useUser();
  const { pathname } = useLocation();
  const color = 'gray';
  const flashSaleComponent = ref?.current;
  const composerComponent = composerRefState?.current;

  let previousHeight = window.innerHeight;

  const getScrollableContentHeight = (
    windowHeight,
    hasFlashSaleEnded,
    isPurchasing
  ) => {
    const remainingHeightToFill =
      windowHeight -
      COMPOSER_HEIGHT_PX -
      SPACE_BETWEEN_FLASH_SALE_AND_COMPOSER_PX;

    if (hasFlashSaleEnded) return remainingHeightToFill;

    // Logged out and FlashSale is active
    if (!userData?.username)
      return remainingHeightToFill - PROGRESS_BAR_HEIGHT_PX;

    return (
      remainingHeightToFill -
      (isPurchasing ? FOOTER_HEIGHT_PX : PROGRESS_BAR_HEIGHT_PX)
    );
  };

  useEffect(() => {
    marginBotttomRef.current =
      isLandscape || !isSessionValid ? 'mb-28' : 'mb-0';
  }, [isTouchscreenDevice, isLandscape, isSessionValid]);

  useEffect(() => {
    // Recalculate the height of the FlashSale if user has purchase or the FlashSale has ended
    if (
      fullHeightOfFlashSale?.current &&
      (!isPurchasing || hasFlashSaleEnded)
    ) {
      const scrollableContentHeight = getScrollableContentHeight(
        window.innerHeight,
        hasFlashSaleEnded,
        isPurchasing
      );

      const remainingHeightToFill =
        window.innerHeight -
        COMPOSER_HEIGHT_PX -
        SPACE_BETWEEN_FLASH_SALE_AND_COMPOSER_PX;
      const calculatedFlashSaleHeight =
        ref.current.scrollHeight + PROGRESS_BAR_HEIGHT_PX;

      if (calculatedFlashSaleHeight > remainingHeightToFill) {
        setHeight(scrollableContentHeight);
        dispatchFlashSaleState({ hasScrollbar: true });
      } else {
        setHeight('100%');
        dispatchFlashSaleState({ hasScrollbar: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFlashSaleEnded, isPurchasing]);

  useResize(
    useDebouncedCallback(() => {
      if (flashSaleComponent && composerComponent) {
        const flashSale = flashSaleComponent?.getBoundingClientRect();
        const composer = composerComponent?.getBoundingClientRect();
        const scrollableContentHeight = getScrollableContentHeight(
          window.innerHeight,
          hasFlashSaleEnded,
          isPurchasing
        );

        // Mounting
        if (!fullHeightOfFlashSale?.current) {
          fullHeightOfFlashSale.current = ref?.current?.scrollHeight;

          const isFlashSaleComposerOverlapping =
            flashSale.bottom > composer?.top &&
            flashSale?.top < composer?.bottom;
          const distanceY = Math.abs(flashSale?.bottom - composer?.top);
          const shouldAddScrollBar =
            isFlashSaleComposerOverlapping || distanceY < 20;

          if (shouldAddScrollBar) {
            setHeight(scrollableContentHeight);
            dispatchFlashSaleState({ hasScrollbar: true });
          }
        } else {
          // Resizing
          const isDecreasingBrowserHeight = window.innerHeight < previousHeight;

          if (isDecreasingBrowserHeight) {
            const distanceY =
              flashSale?.bottom -
              composer?.top +
              (hasScrollbar ? FOOTER_HEIGHT_PX : 0);

            if (distanceY > -20) {
              setHeight(scrollableContentHeight);
              dispatchFlashSaleState({ hasScrollbar: true });
            }
          } else {
            // Increasing browser height
            if (height === '100%') return;

            let calculatedFlashSaleHeight = scrollableContentHeight;

            if (!userData?.username) {
              calculatedFlashSaleHeight += PROGRESS_BAR_HEIGHT_PX;
            } else {
              calculatedFlashSaleHeight += isPurchasing
                ? FOOTER_HEIGHT_PX
                : PROGRESS_BAR_HEIGHT_PX;
            }

            const remainingHeightToFill =
              window.innerHeight -
              COMPOSER_HEIGHT_PX -
              SPACE_BETWEEN_FLASH_SALE_AND_COMPOSER_PX;
            const shouldRemoveScrollbar =
              (isPurchasing &&
                calculatedFlashSaleHeight > fullHeightOfFlashSale?.current) ||
              (!isPurchasing &&
                ref.current.scrollHeight + PROGRESS_BAR_HEIGHT_PX <
                  remainingHeightToFill) ||
              (hasFlashSaleEnded &&
                ref.current.scrollHeight < remainingHeightToFill);

            if (shouldRemoveScrollbar) {
              setHeight('100%');
              dispatchFlashSaleState({ hasScrollbar: false });
            } else {
              setHeight(scrollableContentHeight);
              dispatchFlashSaleState({ hasScrollbar: true });
            }
          }

          previousHeight = window.innerHeight;
        }
      }
    }, 200),
    { shouldCallOnMount: true }
  );
  const isStreamManagerPage = pathname === '/manager';
  const defaultBounceTransition = getDefaultBounceTransition(isActiveFlashSale);

  return (
    <AnimatePresence>
      <motion.div
        {...createAnimationProps({
          animations: ['fadeIn-full', 'fadeOut-full'],
          transition: 'bounce',
          customVariants: {
            hidden: {
              y: -15,
              transition: defaultBounceTransition
            },
            visible: {
              y: 0,
              transition: defaultBounceTransition
            }
          },
          options: {
            isVisible: isActiveFlashSale
          }
        })}
        className={clsm([
          'overflow-hidden',
          'rounded-xl',
          'm-5',

          'shadow',
          isDesktopView &&
            !isStreamManagerPage &&
            hasScrollbar &&
            !hasFlashSaleEnded && ['rounded-b-none', 'mb-0'],
          !isDesktopView &&
            !isStreamManagerPage &&
            `${marginBotttomRef.current}`
        ])}
      >
        <div
          style={{
            height: isDesktopView && !isStreamManagerPage && height
          }}
          ref={ref}
          className={clsm([
            isDesktopView &&
              !isStreamManagerPage &&
              hasScrollbar &&
              'overflow-y-scroll',
            'p-3',
            `bg-gray-100`,
            'dark:bg-gray-900',
            'shadow',
            `scrollbar-color-flash-sale-${color}-scrollBarThumb`
          ])}
        >
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
});

FlashSaleContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default FlashSaleContainer;
