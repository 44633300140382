import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Button from '../../../../components/Button';
import { useFlashSale } from '../../../../contexts/StreamManagerActions/FlashSale';
import { clsm } from '../../../../utils';
import FlashSaleContainer from './FlashSaleContainer';

const formatPrice = (price, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(price));

const formatNumber = (number) => new Intl.NumberFormat('en-US').format(number);

const TEXT_COLOR_CLASS = clsm(['text-gray-800', 'dark:text-gray-100']);

const FlashSaleDetailItem = React.memo(({ label, value, className }) => (
<div className={clsm(['flex', 'w-full', 'items-center', 'justify-between', 'mb-2', className])}>
  <span className={clsm(['mr-2', 'whitespace-nowrap', TEXT_COLOR_CLASS])}>
    {label}:
  </span>
  <span className={clsm(['font-semibold', 'whitespace-nowrap', TEXT_COLOR_CLASS])}>
    {value}
  </span>
</div>

));

FlashSaleDetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FlashSaleDetailItem.defaultProps = {
  className: '',
};

const FlashSaleDetails = ({
  flashSaleTitle = 'Flash Sale!',
  flashSaleDiscountPrice = 0,
  flashSalePrice = 0,
  flashSaleQuantity = 0,
  flashSaleShippingRate = 0,
  flashSalePurchaseCount = 0,
}) => (
  <motion.div
    key="StreamerFlashSaleContainer"
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
    className={clsm([
      'bg-white',
      'dark:bg-gray-800',
      'flex',
      'flex-col',
      'items-start',
      'rounded-xl',
      'w-full',
      'overflow-hidden',
      'p-6',
      'shadow-lg',
    ])}
  >
    <h2 className={clsm(['text-xl', 'font-bold', 'mb-4', TEXT_COLOR_CLASS])}>
      {flashSaleTitle}
    </h2>
    {flashSaleDiscountPrice > 0 && (
      <>
        <FlashSaleDetailItem
          label="Discount Price"
          value={formatPrice(flashSaleDiscountPrice)}
        />
        <FlashSaleDetailItem
          label="Original Price"
          value={
            <span className="line-through text-gray-500 dark:text-gray-400">
              {formatPrice(flashSalePrice)}
            </span>
          }
        />
      </>
    )}
    <FlashSaleDetailItem label="Stock" value={formatNumber(flashSaleQuantity)} />
    <FlashSaleDetailItem
      label="Shipping Rate"
      value={formatPrice(flashSaleShippingRate)}
    />
    <p className={clsm(['text-md', 'font-semibold', 'mt-6', TEXT_COLOR_CLASS])}>
      {`Proceeded to checkout: ${formatNumber(flashSalePurchaseCount)}`}
    </p>
  </motion.div>
);

FlashSaleDetails.propTypes = {
  flashSaleTitle: PropTypes.string,
  flashSaleDiscountPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flashSalePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flashSaleQuantity: PropTypes.number,
  flashSaleShippingRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flashSalePurchaseCount: PropTypes.number,
};

const StreamerFlashSale = () => {
  const flashSaleRef = useRef(null);

  const {
    dispatchFlashSaleState,
    isExpanded,
    flashSaleTitle,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount,
  } = useFlashSale();

  const handleExpandClick = useCallback(() => {
    dispatchFlashSaleState({ isExpanded: !isExpanded });
  }, [dispatchFlashSaleState, isExpanded]);

  useEffect(() => {
    if (flashSaleRef.current) {
      dispatchFlashSaleState({ flashSaleRef: flashSaleRef.current });
    }
  }, [dispatchFlashSaleState]);

  const ChevronIcon = isExpanded ? FaChevronUp : FaChevronDown;
  const buttonText = isExpanded ? 'Hide Flash Sale Details' : 'View Flash Sale Details';

  return (
    <FlashSaleContainer ref={flashSaleRef}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
        <Button
          variant="primary"
          className={clsm([
            'flex',
            'items-center',
            'justify-between',
            'w-full',
            'px-6',
            'py-4',
            'text-lg',
            'font-semibold',
            'text-white',
            'bg-red-600',
            'hover:bg-red-700',
            'focus:outline-none',
            'focus:ring-4',
            'focus:ring-red-300',
            'dark:focus:ring-red-800',
            'transition',
            'duration-300',
            'ease-in-out',
          ])}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-controls="StreamerFlashSaleContainer"
        >
          <span>{buttonText}</span>
          <ChevronIcon className="w-5 h-5 ml-2" />
        </Button>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              key="StreamerFlashSaleContainer"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="p-6"
            >
              <h2 className={clsm(['text-2xl', 'font-bold', 'mb-6', TEXT_COLOR_CLASS])}>
                {flashSaleTitle}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <FlashSaleDetailItem
                    label="Discount Price"
                    value={formatPrice(flashSaleDiscountPrice)}
                    className="text-green-600 dark:text-green-400"
                  />
                  <FlashSaleDetailItem
                    label="Original Price"
                    value={
                      <span className="line-through text-gray-500 dark:text-gray-400">
                        {formatPrice(flashSalePrice)}
                      </span>
                    }
                  />
                  <FlashSaleDetailItem
                    label="Stock"
                    value={formatNumber(flashSaleQuantity)}
                  />
                </div>
                <div className="space-y-4">
                  <FlashSaleDetailItem
                    label="Shipping Rate"
                    value={formatPrice(flashSaleShippingRate)}
                  />
                  <FlashSaleDetailItem
                    label="Proceeded to checkout"
                    value={formatNumber(flashSalePurchaseCount)}
                    className="font-semibold text-blue-600 dark:text-blue-400"
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </FlashSaleContainer>
  );
};

export default React.memo(StreamerFlashSale);
