import { useRef } from 'react';
import PropTypes from 'prop-types';

import { BREAKPOINTS } from '../../constants';
import { FORM_VARIANT_CLASSES } from './FormTheme';
import { clsm, noop } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import Button from '../../components/Button';
import Input from '../../components/Input';
import useForm from './useForm';
import useThrottledCallback from '../../hooks/useThrottledCallback';

const Form = ({
  'data-testid': dataTestId,
  className = '',
  clearFormOnSuccess = true,
  disableSubmit = noop,
  disableValidation = false,
  errorHandler = noop,
  footer = null,
  formVariant = 'vertical',
  inputsData = {},
  inputVariant = 'vertical',
  onFailure = noop,
  onSuccess = noop,
  submitBtnVariant = 'primary',
  submitHandler,
  submitText = 'Submit',
  title = '',
  validationCheck = noop,
  type = ''
}) => {
  const [formProps, isLoading, onChange, onSubmit, presubmitValidation] = useForm({
    disableValidation,
    errorHandler,
    inputsData,
    onFailure,
    onSuccess,
    submitHandler,
    validationCheck,
    type
  });

  const isFormComplete = Object.values(formProps).every(({ value }) => value);
  const { currentBreakpoint } = useResponsiveDevice();
  const isMobileView = currentBreakpoint < BREAKPOINTS.sm;
  const classes = clsm(FORM_VARIANT_CLASSES[formVariant], className);

  const initialFocusedInputValue = useRef('');
  const throttledPresubmitValidation = useThrottledCallback((name) => {
    presubmitValidation(name);
    initialFocusedInputValue.current = '';
  }, 1000);

  const handleFocus = ({ target: { value } }) => {
    initialFocusedInputValue.current = value;
  };

  const handleBlur = ({ relatedTarget, target: { name, value } }) => {
    if (relatedTarget?.type === 'submit') return;
    if (value && initialFocusedInputValue.current !== value) {
      setTimeout(() => throttledPresubmitValidation(name));
    }
  };

  const renderSubmitButton = () => (
    <div className="flex flex-col space-y-8 text-center self-stretch">
      <Button
        type="submit"
        ariaDisabled={!isFormComplete || disableSubmit(formProps)}
        isLoading={isLoading}
        variant={submitBtnVariant}
        data-testid={`${dataTestId}-submit-button`}
        customStyles={{
          marginTop: inputVariant === 'vertical' && formVariant === 'horizontal' ? '33px' : 0
        }}
      >
        {submitText}
      </Button>
      {footer && <div className="button-link">{footer}</div>}
    </div>
  );

  return (
    <form
      data-testid={dataTestId}
      className={classes}
      onSubmit={(e) => onSubmit(e, clearFormOnSuccess)}
    >
      {title && <h2 className={isMobileView ? 'h2' : ''}>{title}</h2>}
      {Object.values(formProps).map((inputProps, index, arr) => {
        const isLastInput = index === arr.length - 1;
        const hasSubmitButton = isLastInput && formVariant === 'horizontal';

        return formVariant === 'vertical' ? (
          <Input
            {...inputProps}
            key={inputProps.name}
            onBlur={!disableValidation ? handleBlur : undefined}
            onFocus={!disableValidation ? handleFocus : undefined}
            onChange={onChange}
            variant={inputVariant}
          />
        ) : (
          <div
            className={clsm(
              'flex input-format-container space-x-2',
              formVariant === 'horizontal' && 'items-end',
              !hasSubmitButton && ['mr-[108px]', 'xs:m-0']
            )}
            key={inputProps.name}
          >
            <Input
              {...inputProps}
              onBlur={!disableValidation ? handleBlur : undefined}
              onFocus={!disableValidation ? handleFocus : undefined}
              onChange={onChange}
              variant={inputVariant}
            />
            {hasSubmitButton && renderSubmitButton()}
          </div>
        );
      })}
      {formVariant === 'vertical' && renderSubmitButton()}
    </form>
  );
};

Form.propTypes = {
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  clearFormOnSuccess: PropTypes.bool,
  disableSubmit: PropTypes.func,
  disableValidation: PropTypes.bool,
  errorHandler: PropTypes.func,
  footer: PropTypes.node,
  formVariant: PropTypes.oneOf(['vertical', 'horizontal']),
  inputsData: PropTypes.object,
  inputVariant: PropTypes.oneOf(['vertical', 'horizontal']),
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  submitBtnVariant: PropTypes.oneOf(['primary', 'tertiary', 'secondary', 'destructive', 'link']),
  submitHandler: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  validationCheck: PropTypes.func
};

export default Form;
