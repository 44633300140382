import FollowingSection from './SidebarFollowingSection';
import LiveStreamsSection from './SidebarLiveStreamsSection';
import PageLayout from './SidebarPageLayout';
import withVerticalScroller from '../../../../../components/withVerticalScroller';
import PropTypes from 'prop-types';
import useCurrentPage from '../../../../../hooks/useCurrentPage';

const SidebarDirectory = ({ isSidebarOpen, toggleSidebar }) => {
  const currentPage = useCurrentPage();

  return (
    <PageLayout>
      {currentPage === 'following' ||
      currentPage === 'browse' ||
      currentPage === 'channel_directory' ||
      currentPage === 'cart' ? (
        <></>
      ) : (
        <>
          <FollowingSection
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
          <LiveStreamsSection
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </>
      )}
    </PageLayout>
  );
};

SidebarDirectory.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default withVerticalScroller(SidebarDirectory);
