import PropTypes from 'prop-types';

import { clsm } from '../../utils';
import { app as $appContent } from '../../content';

const $content = $appContent.status_bar;
const HEALTH = { STABLE: $content.stable, POOR: $content.poor };

const HealthIndicator = ({ health }) => {
  const healthColors = {
    [HEALTH.STABLE]: 'bg-darkMode-green',
    [HEALTH.POOR]: 'bg-darkMode-red',
    default: ['bg-lightMode-gray', 'dark:bg-darkMode-gray']
  };

  const color = healthColors[health] || healthColors.default;

  return <span className={clsm(['inline-block', 'w-2.5', 'h-2.5', 'rounded-full', color])} />;
};

HealthIndicator.propTypes = { health: PropTypes.string.isRequired };

export default HealthIndicator;
