import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clsm } from '../utils';
import { PROFILE_COLORS } from '../constants';
import { useResponsiveDevice } from '../contexts/ResponsiveDevice';
import LivePill from './LivePill';
import UserAvatar from './UserAvatar';

const ChannelCard = ({
  avatarSrc = '',
  bannerSrc = '',
  color = 'default',
  username = '',
  variant = 'default'
}) => {
  const navigate = useNavigate();
  const { isTouchscreenDevice } = useResponsiveDevice();
  const shouldShowBanner = !!bannerSrc;

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate(`/${username}`);
    },
    [navigate, username]
  );

  return (
    <div
      onClick={handleClick}
      onKeyPress={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleClick(event);
        }
      }}
      role="button"
      tabIndex={0}
      className={clsm([
        'flex',
        'flex-col',
        'group',
        'relative',
        'w-auto',
        'rounded-xl',
        'focus:outline-none',
        'cursor-pointer'
      ])}
    >
      <div
        className={clsm([
          'flex',
          'flex-col',
          'rounded-md',
          !isTouchscreenDevice && [
            'group-hover:opacity-85',
            'transition-opacity',
            'duration-500'
          ]
        ])}
      >
        <div
          className={clsm([
            '-mb-0.5',
            'aspect-video',
            'bg-center',
            'bg-cover',
            'rounded-md',
            'shadow',
            shouldShowBanner ? 'bg-transparent' : `bg-profile-${color}-dark`
          ])}
        >
          {shouldShowBanner && (
            <img
              alt=""
              src={bannerSrc}
              className={clsm([
                'w-full',
                'h-full',
                'object-cover',
                'rounded-md'
              ])}
            />
          )}
        </div>
        <div
          className={clsm([
            'flex',
            'items-center',
            'p-2.5',
            'rounded-b-lg',
            'space-x-3',
            'transition-colors',
            // `bg-profile-${color}`,
            !isTouchscreenDevice && [
              'group-hover:bg-opacity-90',
              'transition-all',
              'duration-300'
            ]
          ])}
        >
          <UserAvatar
            avatarSrc={avatarSrc}
            profileColor={color}
            className={clsm([
              'h-10',
              'w-10',
              'transition-transform'
              // !isTouchscreenDevice && 'group-hover:scale-105'
            ])}
            username={username}
          />
          <h3
            className={clsm([
              'truncate',
              'text-black',
              'dark:text-white',
              'font-semibold',
              'text-base'
            ])}
          >
            {username}
          </h3>
        </div>
      </div>
      {variant === 'live' && (
        <div
          className={clsm([
            'absolute',
            'right-5',
            'top-5',
            !isTouchscreenDevice && [
              'group-hover:translate-x-1',
              'group-hover:-translate-y-1',
              'transition-transform',
              'duration-300'
            ]
          ])}
        >
          <LivePill />
        </div>
      )}
    </div>
  );
};

ChannelCard.propTypes = {
  avatarSrc: PropTypes.string,
  bannerSrc: PropTypes.string,
  color: PropTypes.oneOf([...PROFILE_COLORS, 'default']),
  username: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'offline', 'live'])
};

export default ChannelCard;
