import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINTS } from '../../../../../constants';
import { clsm } from '../../../../../utils';
import { useResponsiveDevice } from '../../../../../contexts/ResponsiveDevice';
import ProductButtons from './ProductButtons';
import ProductImageCarousel from './ProductImageCarousel';
import { noop } from '../../../../../utils';
import Button from '../../../../../components/Button';

const ProductDescription = React.memo(({
  productName,
  productDescription,
  quantity,
  productAssets,
  price,
  addToCart
}) => {
  const { currentBreakpoint } = useResponsiveDevice();
  const isSmallBreakpoint = currentBreakpoint <= BREAKPOINTS.sm;

  const images = useMemo(() => [
    productAssets.mainImage?.url,
    productAssets.additionalImage1?.url,
    productAssets.additionalImage2?.url,
  ].filter(Boolean), [productAssets]);

  const [isAdding, setIsAdding] = useState(false);

  const handleAddToCart = async () => {
    setIsAdding(true);
    await addToCart();
    setIsAdding(false);
  };

  return (
    <main className={clsm([
      'flex',
      'sm:flex-col',
      'flex-row',
      'w-full',
      'justify-between',
      'h-full',
      'p-4',
      'bg-white',
      'dark:bg-darkMode-gray-medium'
    ])}>
      <section className="flex-shrink-0 sm:mr-0 mr-4">
        <ProductImageCarousel images={images} />
      </section>
      <section className={clsm([
        'flex',
        'flex-col',
        'justify-between',
        'w-full',
        // 'sm:pt-0',
        'pt-8'
      ])}>
        <article className={clsm([
          'flex',
          'flex-col',
          'justify-center',
          // 'sm:pb-0',
          'pb-8'
        ])}>
          <h1 
            className={clsm([
              'text-xl',
              'font-bold',
              'break-words',
              'dark:text-white',
              'text-black',
              'mb-4'
            ])}
          >
            {productName}
          </h1>
          <p 
            className={clsm([
              'sm:text-base',
              'text-base',
              'text-gray-600',
              'dark:text-gray-200',
              'break-words',
              'pb-4'
            ])}
          >
            {productDescription}
          </p>
          {price && (
            <div className="text-xl font-bold dark:text-white pb-4">
              ${price}
            </div>
          )}
        </article>
        <div className="mt-4">
          {isSmallBreakpoint ? (
            <Button 
              onClick={handleAddToCart} 
              variant="primary"
              className={clsm([
                'py-2', 
                'px-4', 
                'rounded', 
                'transition', 
                'duration-300', 
                isAdding ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
              ])}
              disabled={isAdding}
              aria-busy={isAdding}
            >
              {isAdding ? 'Adding...' : 'Add to Cart'}
            </Button>
          ) : (
            <ProductButtons 
              variant="productDescriptionDesktop" 
              addToCart={handleAddToCart} 
              quantity={quantity} 
              className="self-start" 
              isAdding={isAdding}
            />
          )}
        </div>
      </section>
    </main>
  );
});

ProductDescription.defaultProps = {
  productAssets: {},
  addToCart: noop,
  quantity: 1,
  price: ''
};

ProductDescription.propTypes = {
  productName: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  price: PropTypes.string,
  productAssets: PropTypes.shape({
    mainImage: PropTypes.shape({
      url: PropTypes.string
    }),
    additionalImage1: PropTypes.shape({
      url: PropTypes.string
    }),
    additionalImage2: PropTypes.shape({
      url: PropTypes.string
    }),
  }),
  addToCart: PropTypes.func.isRequired,
  quantity: PropTypes.number
};

export default ProductDescription;
