import { matchRoutes, useLocation } from 'react-router-dom';

const ROUTES = [
  { path: 'cart', name: 'cart' },
  { path: '/', name: 'channel_directory' },
  {
    path: ':username',
    name: 'channel',
    children: [{ index: true }, { path: 'profile' }]
  },
  { path: 'channel-orders', name: 'channel_orders' },
  { path: 'channel-orders/:orderId', name: 'channel_order_details' },
  { path: 'orders', name: 'orders' },
  { path: 'orders/:orderId', name: 'order_details' },
  { path: 'orders/:orderId/review', name: 'order_review' },
  { path: 'start-selling', name: 'start_selling' },
  { path: 'seller-payments', name: 'seller_payments' },

  {
    path: 'products',
    name: 'products',
    children: [
      { path: 'new', name: 'add_product' },
      { path: ':productId', name: 'edit_product' },
      { path: ':productId/assets', name: 'product_assets' }
    ]
  },
  { path: 'following', name: 'following' },
  { path: 'settings', name: 'settings' },
  { path: 'manager', name: 'stream_manager' },

  { path: 'login', name: 'login' },
  { path: 'register', name: 'register' },
  { path: 'reset', name: 'reset' },
  { path: 'terms', name: 'terms_and_conditions' },
  { path: 'privacy', name: 'privacy_policy' },
  { path: 'coming-soon', name: 'coming_soon' },
  { path: 'contact', name: 'contact' }


];

const useCurrentPage = () => {
  const location = useLocation();
  const [match] = matchRoutes(ROUTES, location) || [];

  return match?.route?.name;
};

export default useCurrentPage;
