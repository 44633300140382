import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IoInformationCircleOutline } from 'react-icons/io5';

import { useNotif } from '../../contexts/Notification';
import { useUser } from '../../contexts/User';

import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import { OpenInNew } from '../../assets/icons';

// import FeesDiscount from './FeesDiscount';
// import FeatureCards from './FeatureCards';

import { createStripeAccount, hasStripeConnectId } from '../../api/payment';

// SuccessMessage Component
const SuccessMessage = memo(() => {
  const { userData } = useUser();
  const userName = userData?.username || '';

  return (
    <section className="bg-white dark:bg-black py-8 sm:py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-xl overflow-hidden">
          <div className="p-4 sm:p-6 text-center">
            <h2 className="mb-3 text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white">
              🎉 Welcome to Livescape Shops!
            </h2>
            <p className="mb-4 text-sm text-gray-600 dark:text-gray-300">
              Congratulations! Your boutique shop is now ready for launch. As a curator 
              of unique fashion, you're joining an exclusive marketplace for distinctive 
              styles. Your store will be fully activated in just a few minutes - the 
              perfect time to start preparing your collection. Ready to showcase your 
              items to fashion-forward shoppers? <a
                      href="https://help.livescapeshops.com/en/collections/10488483-for-sellers"
                      className="text-blue-500 hover:text-blue-700 underline transition duration-200"
                    >
                      Visit our Help Center
                    </a> for tips on 
              getting started.
            </p>
            <Button
              variant="primary"
              type="nav"
              to={`/${userName}`}
              className="inline-flex items-center px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full shadow-md "
            >
              Visit Your Shop
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
});

// SellerOnboarding Component
const SellerOnboarding = memo(
  ({ isLoading, handleSellerOnboarding, hasStripeId }) => (
    <section className="py-8 sm:py-12 w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-white dark:bg-black rounded-xl  overflow-hidden">
          <div className="relative p-4 sm:p-6">
            {/* <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div> */}
            <div className="relative z-10 text-center">
              <div className="inline-block p-3 mb-4 bg-blue-50 dark:bg-blue-900/30 rounded-full">
                <svg
                  className="w-8 h-8 text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h1 className="mb-3 text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white">
                Start Your Boutique Journey
              </h1>
              <p className="mb-6 text-base text-gray-600 dark:text-gray-300 max-w-xl mx-auto">
                Join our curated marketplace exclusively for boutique retailers
                and individual sellers of unique fashion items. Enable secure
                payments to begin.
              </p>
              <div className="flex justify-center mb-6">
                {isLoading ? (
                  <div className="relative">
                    <Spinner
                      aria-label="Processing"
                      className="w-8 h-8 text-blue-500 animate-spin"
                    />
                    <span className="mt-2 block text-sm text-gray-500 dark:text-gray-400">
                      Processing...
                    </span>
                  </div>
                ) : (
                  <Button
                    onClick={handleSellerOnboarding}
                    disabled={isLoading}
                    className="group flex items-center px-6 py-3 text-sm font-semibold text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow-md"
                  >
                    {hasStripeId ? 'Complete Payment Setup' : 'Set Up Payments'}
                    <OpenInNew className="w-4 h-4 ml-2 text-white dark:text-black fill-current transition-transform duration-300 group-hover:rotate-12" />
                  </Button>
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4 text-xs text-gray-600 dark:text-gray-400">
                <div className="flex items-center">
                  <IoInformationCircleOutline className="w-4 h-4 mr-1 text-gray-400" />
                  <span>
                    Exclusively for boutique retailers and individual sellers of
                    unique fashion items.{' '}
                    <Link
                      to="/terms"
                      className="text-blue-500 hover:text-blue-700 underline transition duration-200"
                    >
                      View Terms
                    </Link>
                  </span>
                </div>
                <div className="flex items-center">
                  <IoInformationCircleOutline className="w-4 h-4 mr-1 text-gray-400" />
                  <span>
                    Need help?{' '}
                    <a
                      href="https://help.livescapeshops.com/en/collections/10488483-for-sellers"
                      className="text-blue-500 hover:text-blue-700 underline transition duration-200"
                    >
                      Visit our Help Center
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
);

SellerOnboarding.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleSellerOnboarding: PropTypes.func.isRequired,
  hasStripeId: PropTypes.bool.isRequired
};

// StartSelling Component
function StartSelling() {
  const { notifyError, notifySuccess } = useNotif();
  const [isLoading, setIsLoading] = useState(false);
  const [hasStripeId, setHasStripeId] = useState(false);
  const [isSeller, setIsSeller] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const checkStripeConnectId = async () => {
      setIsLoading(true);
      try {
        const response = await hasStripeConnectId();
        if (isMounted) {
          setHasStripeId(response?.result?.hasStripeAccount);
          setIsSeller(response?.result?.isSeller);
        }
      } catch (error) {
        notifyError('Error checking payment account status');
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    checkStripeConnectId();

    return () => {
      isMounted = false;
    };
  }, [notifyError]);

  const handleSellerOnboarding = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await createStripeAccount();
      if (response?.result?.accountLinkUrl) {
        window.location.href = response.result.accountLinkUrl;
      } else {
        notifySuccess(
          'Your payment account is already set up and ready to go!'
        );
      }
    } catch (error) {
      notifyError('Error setting up payment account. Please try again.');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 10000);
    }
  }, [notifyError, notifySuccess]);

  return (
    <div className="flex flex-col min-h-screen bg-white dark:bg-black">
      <main className="flex-grow pt-16 sm:pt-24">
        {isSeller ? (
          <SuccessMessage />
        ) : (
          <SellerOnboarding
            isLoading={isLoading}
            handleSellerOnboarding={handleSellerOnboarding}
            hasStripeId={hasStripeId}
          />
        )}

        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 space-y-16">
          <section className="w-full">
            <Suspense fallback={<SpinnerFallback />}>
              <FeatureCards />
            </Suspense>
          </section>

          <section className="w-full">
            <Suspense fallback={<SpinnerFallback />}>
              <FeesDiscount />
            </Suspense>
          </section>
        </div> */}
      </main>
    </div>
  );
}

// const SpinnerFallback = () => (
//   <div className="flex items-center justify-center h-64">
//     <Spinner className="w-12 h-12 text-blue-500 animate-spin" />
//   </div>
// );

export default StartSelling;
