import PropTypes from 'prop-types';

import { clsm, noop } from '../../utils';
import Button from '../../components/Button';

const DataUnavailable = ({
  className = '',
  noDataText = '',
  hasError = false,
  tryAgainFn = noop,
  tryAgainText = ''
}) => (
  <div
    className={clsm([
      className,
      'space-y-8',
      'bg-transparent dark:bg-transparent',
      'rounded-2xl',
      'p-4',
      'sm:p-0',
    ])}
  >
    <div className="flex flex-col items-center justify-center space-y-4 sm:space-y-4 text-center">
      <div className="bg-white dark:bg-transparent p-4 rounded-full">
        <img src="/illustration.png" alt="Logo" className="w-20 h-20 opacity-40 filter grayscale" />
      </div>
      <h2 className="text-lg sm:text-lg font-bold text-gray-800 dark:text-white opacity-60">
        {noDataText || 'No Data Available'}
      </h2>
    </div>
    {hasError && (
      <div className="flex justify-center mt-8">
        <Button
          onClick={tryAgainFn}
          variant="primary"
          className="group relative overflow-hidden rounded-full shadow-md px-8 py-3 text-lg font-semibold transition-all duration-300 transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <span className="absolute inset-0 w-full h-full transition-all duration-300 transform group-hover:translate-x-full bg-gradient-to-r from-blue-500 to-purple-500 dark:from-purple-500 dark:to-blue-500"></span>
          <span className="relative z-10 text-white">{tryAgainText || 'Try Again'}</span>
        </Button>
      </div>
    )}
  </div>
);

// ... (propTypes remain the same)

DataUnavailable.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  noDataText: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string
};

export default DataUnavailable;
