import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { channel as $channelContent } from '../content';
import { useUser } from './User';
import useContextHook from './useContextHook';
import { useChannel } from './Channel';
import { useNotif } from './Notification';
import useChatActions from '../pages/Channel/Chat/useChatConnection/useChatActions';
import {
  CHAT_LOG_LEVELS,
  MAX_RECONNECT_ATTEMPTS,
  STREAM_ACTION_NAME
} from '../constants';
import { ivsChatWebSocketRegionOrUrl } from '../api/utils';
import {
  CHAT_USER_ROLE,
  requestChatToken
} from '../pages/Channel/Chat/useChatConnection/utils';
import { ChatRoom } from 'amazon-ivs-chat-messaging';
import {
  extractChannelIdfromChannelArn,
  updateVotes,
  updateBids,
  isVotingBlocked
} from '../utils';
import { usePoll } from './StreamManagerActions/Poll';
import { useAuction } from './StreamManagerActions/Auction';
import { useFlashSale } from './StreamManagerActions/FlashSale';
import { CHAT_MESSAGE_EVENT_TYPES } from '../constants';

const {
  SEND_MESSAGE,
  START_POLL,
  END_POLL,
  SUBMIT_VOTE,
  SEND_VOTE_STATS,
  HEART_BEAT,
  START_AUCTION,
  END_AUCTION,
  HEART_BEAT_AUCTION,
  SUBMIT_BID,
  SEND_BID_STATS,
  START_FLASH_SALE,
  END_FLASH_SALE,
  HEART_BEAT_FLASH_SALE,
  SUBMIT_PURCHASE,
  SEND_PURCHASE_STATS
} = CHAT_MESSAGE_EVENT_TYPES;

const $content = $channelContent.chat;

const { INFO: info, DEBUG: debug } = CHAT_LOG_LEVELS;
const isDebug = false;

const Context = createContext(null);
Context.displayName = 'Chat';

/**
 * @typedef {Object} SenderAttributes
 * @property {string} avatar
 * @property {string} color
 * @property {string} displayName
 *
 * @typedef {Object} Sender
 * @property {SenderAttributes} Attributes
 * @property {string} UserId
 *
 * @typedef {Object} Message
 * @property {object|null=} Attributes
 * @property {string} Content
 * @property {string} Id
 * @property {string=} RequestId
 * @property {Date} SendTime
 * @property {Sender} Sender
 * @property {string} Type
 *
 * @typedef {Array<Message>} Messages
 */

const actionTypes = {
  INIT_MESSAGES: 'INIT_MESSAGES',
  ADD_MESSAGE: 'ADD_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  DELETE_MESSAGES_BY_USER_ID: 'DELETE_MESSAGES_BY_USER_ID'
};

const reducer = (messages, action) => {
  switch (action.type) {
    case actionTypes.INIT_MESSAGES: {
      return action.initialMessages || [];
    }
    case actionTypes.ADD_MESSAGE: {
      const { message: newMessage, isOwnMessage } = action;

      return [...messages, { ...newMessage, isOwnMessage }];
    }
    case actionTypes.DELETE_MESSAGE: {
      const { messageId: messageIdToDelete, deletedMessageIds } = action;
      const wasDeletedByUser =
        deletedMessageIds.current.includes(messageIdToDelete);

      const newMessages = messages.reduce(
        (acc, msg) => [
          ...acc,
          msg.id === messageIdToDelete
            ? { ...msg, isDeleted: true, wasDeletedByUser }
            : msg
        ],
        []
      );

      return newMessages;
    }
    case actionTypes.DELETE_MESSAGES_BY_USER_ID: {
      const { userId: userIdToDelete } = action;

      const newMessages = messages.filter(
        (msg) => msg.sender.attributes.channelArn !== userIdToDelete
      );

      return newMessages;
    }
    default:
      throw new Error('Unexpected action type');
  }
};

export const Provider = ({ children }) => {
  /** @type {[Messages, Function]} */
  const [messages, dispatch] = useReducer(reducer, []);

  /**
   * `sentMessageIds` and `deletedMessageIds` have to be refs to avoid redefining `addMessage` which would reset the chat connection.
   * `sentMessageIds` and `deletedMessageIds` are used to show the notifications upon message deletion.
   * The corresponding messages are flagged respectively using the `isOwnMessage` and `wasDeletedByUser` booleans which are attached to `messages` (used for rendering).
   */
  const sentMessageIds = useRef([]);
  const deletedMessageIds = useRef([]);
  const { userData, isSessionValid } = useUser();
  const { username: ownUsername } = userData || {};
  const savedMessages = useRef({});
  const { channelData, refreshChannelData } = useChannel();
  const { username: chatRoomOwnerUsername, isViewerBanned = false } =
    channelData || {};
  const { notifyError } = useNotif();
  const retryConnectionAttemptsCounterRef = useRef(0);
  const chatCapabilities = useRef([]);

  // Connection State
  const [hasConnectionError, setHasConnectionError] = useState();
  const [sendAttemptError, setSendAttemptError] = useState();
  const connection = useRef(null);
  const [room, setRoom] = useState(null);
  const isConnectionOpenRef = useRef(false);

  const isInitializingConnection = useRef(false);
  const abortControllerRef = useRef();
  const isConnecting = isInitializingConnection.current;

  // Chat Actions
  const [deletedMessage, setDeletedMessage] = useState();
  const { actions, chatUserRole, updateUserRole } = useChatActions({
    chatCapabilities,
    isConnectionOpen: isConnectionOpenRef.current,
    connection,
    setSendAttemptError
  });
  const isModerator = chatUserRole === CHAT_USER_ROLE.MODERATOR;

  // Poll Stream Action
  const {
    updatePollData,
    votes,
    hasPollEnded,
    resetPollProps,
    isActive,
    clearPollLocalStorage,
    setSelectedOption,
    selectedOption,
    showFinalResults,
    duration,
    question,
    expiry,
    startTime,
    noVotesCaptured,
    tieFound,
    savedPollData,
    saveVotesToLocalStorage,
    savePollDataToLocalStorage,
    dispatchPollState,
    endPollAndResetPollProps,
    pollCreatorId
  } = usePoll();

  const {
    updateAuctionData,
    bids,
    hasAuctionEnded,
    resetAuctionProps,
    isActiveAuction,
    clearAuctionLocalStorage,
    setSelectedOptionAuction,
    selectedOptionAuction,
    showFinalResultsAuction,
    auctionDuration,
    auctionExpiry,
    auctionStartTime,
    noBidsCaptured,
    tieFoundAuction,
    savedAuctionData,
    saveBidsToLocalStorage,
    saveAuctionDataToLocalStorage,
    dispatchAuctionState,
    endAuctionAndResetAuctionProps,
    auctionTitle,
    auctionTerms,
    auctionPrice,
    auctionReserve,
    auctionShippingRate,
    auctionBuyNow,
    auctionDescription,
    auctionBidCount,
    currentBidder,
    currentBidderUsername
  } = useAuction();

  const {
    updateFlashSaleData,
    purchases,
    hasFlashSaleEnded,
    resetFlashSaleProps,
    isActiveFlashSale,
    clearFlashSaleLocalStorage,
    selectedOptionFlashSale,
    showFinalResultsFlashSale,
    flashSaleDuration,
    flashSaleExpiry,
    flashSaleStartTime,
    noPurchasesCaptured,
    tieFoundFlashSale,
    savedFlashSaleData,
    savePurchasesToLocalStorage,
    saveFlashSaleDataToLocalStorage,
    dispatchFlashSaleState,
    endFlashSaleAndResetFlashSaleProps,
    flashSaleTitle,
    flashSaleTerms,
    flashSaleDescription,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount
  } = useFlashSale();

  const { pathname } = useLocation();

  const isStreamManagerPage = pathname === '/manager';

  const startPoll = useCallback(
    async (pollStreamActionData) => {
      const attributes = {
        eventType: START_POLL,
        pollStreamActionData: JSON.stringify(pollStreamActionData)
      };

      await actions.sendMessage(START_POLL, attributes);
      return true;
    },
    [actions]
  );

  const startAuction = useCallback(
    async (auctionStreamActionData) => {
      const attributes = {
        eventType: START_AUCTION,
        auctionStreamActionData: JSON.stringify(auctionStreamActionData)
      };

      await actions.sendMessage(START_AUCTION, attributes);
      return true;
    },
    [actions]
  );

  const startFlashSale = useCallback(
    async (flashSaleStreamActionData) => {
      const attributes = {
        eventType: START_FLASH_SALE,
        flashSaleStreamActionData: JSON.stringify(flashSaleStreamActionData)
      };

      await actions.sendMessage(START_FLASH_SALE, attributes);
      return true;
    },
    [actions]
  );

  const endPoll = useCallback(
    ({ withTimeout, timeoutDuration } = {}) => {
      const content = 'end poll';
      const attributes = { eventType: END_POLL };
      if (withTimeout) {
        setTimeout(
          () => actions.sendMessage(content, attributes),
          timeoutDuration
        );
      } else {
        actions.sendMessage(content, attributes);
      }
    },
    [actions]
  );

  const endAuction = useCallback(
    ({ withTimeout, timeoutDuration } = {}) => {
      const content = 'end auction';
      const attributes = { eventType: END_AUCTION };
      if (withTimeout) {
        setTimeout(
          () => actions.sendMessage(content, attributes),
          timeoutDuration
        );
      } else {
        actions.sendMessage(content, attributes);
      }
    },
    [actions]
  );

  const endFlashSale = useCallback(
    ({ withTimeout, timeoutDuration } = {}) => {
      const content = 'end flash sale';
      const attributes = { eventType: END_FLASH_SALE };
      if (withTimeout) {
        setTimeout(
          () => actions.sendMessage(content, attributes),
          timeoutDuration
        );
      } else {
        actions.sendMessage(content, attributes);
      }
    },
    [actions]
  );

  const sendHeartBeat = useCallback(() => {
    if (
      isModerator &&
      isActive &&
      !showFinalResults &&
      !noVotesCaptured &&
      !tieFound
    ) {
      actions.sendMessage(HEART_BEAT, {
        eventType: HEART_BEAT,
        updatedVotes: JSON.stringify(votes),
        duration: JSON.stringify(duration),
        question: JSON.stringify(question),
        expiry: JSON.stringify(expiry),
        startTime: JSON.stringify(startTime),
        voters: JSON.stringify(savedPollData?.voters || {}),
        pollCreatorId: JSON.stringify(pollCreatorId)
      });
    }
  }, [
    actions,
    duration,
    expiry,
    isActive,
    isModerator,
    noVotesCaptured,
    pollCreatorId,
    question,
    savedPollData?.voters,
    showFinalResults,
    startTime,
    tieFound,
    votes
  ]);

  const sendHeartBeatAuction = useCallback(() => {
    if (
      isModerator &&
      isActiveAuction &&
      !showFinalResultsAuction &&
      !noBidsCaptured &&
      !tieFoundAuction
    ) {
      actions.sendMessage(HEART_BEAT_AUCTION, {
        eventType: HEART_BEAT_AUCTION,
        updatedBids: JSON.stringify(bids),
        auctionDuration: JSON.stringify(auctionDuration),
        auctionExpiry: JSON.stringify(auctionExpiry),
        auctionStartTime: JSON.stringify(auctionStartTime),
        bidders: JSON.stringify(savedAuctionData?.bidders || {}),
        auctionTitle: JSON.stringify(auctionTitle),
        auctionTerms: JSON.stringify(auctionTerms),
        auctionPrice: JSON.stringify(auctionPrice),
        auctionReserve: JSON.stringify(auctionReserve),
        auctionShippingRate: JSON.stringify(auctionShippingRate),
        auctionBuyNow: JSON.stringify(auctionBuyNow),
        auctionDescription: JSON.stringify(auctionDescription),
        auctionBidCount: JSON.stringify(auctionBidCount),
        currentBidder: JSON.stringify(currentBidder),
        currentBidderUsername: JSON.stringify(currentBidderUsername)
      });
    }
  }, [
    isModerator,
    isActiveAuction,
    showFinalResultsAuction,
    noBidsCaptured,
    tieFoundAuction,
    actions,
    bids,
    auctionDuration,
    auctionExpiry,
    auctionStartTime,
    savedAuctionData?.bidders,
    auctionTitle,
    auctionTerms,
    auctionPrice,
    auctionReserve,
    auctionShippingRate,
    auctionBuyNow,
    auctionDescription,
    auctionBidCount,
    currentBidder,
    currentBidderUsername
  ]);

  const sendHeartBeatFlashSale = useCallback(() => {
    if (
      isModerator &&
      isActiveFlashSale &&
      !showFinalResultsFlashSale &&
      !noPurchasesCaptured &&
      !tieFoundFlashSale
    ) {
      actions.sendMessage(HEART_BEAT_FLASH_SALE, {
        eventType: HEART_BEAT_FLASH_SALE,
        updatedPurchases: JSON.stringify(purchases),
        flashSaleDuration: JSON.stringify(flashSaleDuration),
        flashSaleExpiry: JSON.stringify(flashSaleExpiry),
        flashSaleStartTime: JSON.stringify(flashSaleStartTime),
        purchasers: JSON.stringify(savedFlashSaleData?.purchasers || {}),
        flashSaleTitle: JSON.stringify(flashSaleTitle),
        flashSaleTerms: JSON.stringify(flashSaleTerms),
        flashSaleDescription: JSON.stringify(flashSaleDescription),
        flashSaleDiscountPrice: JSON.stringify(flashSaleDiscountPrice),
        flashSalePrice: JSON.stringify(flashSalePrice),
        flashSaleQuantity: JSON.stringify(flashSaleQuantity),
        flashSaleShippingRate: JSON.stringify(flashSaleShippingRate),
        flashSalePurchaseCount: JSON.stringify(flashSalePurchaseCount)
      });
    }
  }, [
    isModerator,
    isActiveFlashSale,
    showFinalResultsFlashSale,
    noPurchasesCaptured,
    tieFoundFlashSale,
    actions,
    purchases,
    flashSaleDuration,
    flashSaleExpiry,
    flashSaleStartTime,
    savedFlashSaleData?.purchasers,
    flashSaleTitle,
    flashSaleTerms,
    flashSaleDescription,
    flashSaleDiscountPrice,
    flashSalePrice,
    flashSaleQuantity,
    flashSaleShippingRate,
    flashSalePurchaseCount
  ]);

  useEffect(() => {
    let heartBeatIntervalId = null;
    if (isActive) {
      heartBeatIntervalId = setInterval(() => {
        sendHeartBeat();
      }, 4000);
    }

    return () => {
      if (heartBeatIntervalId !== null) {
        clearInterval(heartBeatIntervalId);
      }
    };
  }, [isActive, sendHeartBeat]);

  useEffect(() => {
    let heartBeatIntervalId = null;
    if (isActiveAuction) {
      heartBeatIntervalId = setInterval(() => {
        sendHeartBeatAuction();
      }, 4000);
    }

    return () => {
      if (heartBeatIntervalId !== null) {
        clearInterval(heartBeatIntervalId);
      }
    };
  }, [isActiveAuction, sendHeartBeatAuction]);

  useEffect(() => {
    let heartBeatIntervalId = null;
    if (isActiveFlashSale) {
      heartBeatIntervalId = setInterval(() => {
        sendHeartBeatFlashSale();
      }, 4000);
    }

    return () => {
      if (heartBeatIntervalId !== null) {
        clearInterval(heartBeatIntervalId);
      }
    };
  }, [isActiveFlashSale, sendHeartBeatFlashSale]);

  const initMessages = useCallback(() => {
    const initialMessages = savedMessages.current[chatRoomOwnerUsername] || [];

    dispatch({ type: actionTypes.INIT_MESSAGES, initialMessages });
  }, [chatRoomOwnerUsername]);

  const addMessage = useCallback(
    (message) => {
      const isOwnMessage = ownUsername === message.sender.userId;

      // Upon receiving a new message, we detect if the message was sent by the current user
      if (isOwnMessage) sentMessageIds.current.push(message.id);

      dispatch({ type: actionTypes.ADD_MESSAGE, message, isOwnMessage });
    },
    [ownUsername]
  );

  const removeMessage = useCallback((messageId) => {
    dispatch({
      type: actionTypes.DELETE_MESSAGE,
      messageId,
      deletedMessageIds
    });
  }, []);

  const removeMessageByUserId = useCallback((userId) => {
    dispatch({
      type: actionTypes.DELETE_MESSAGES_BY_USER_ID,
      userId
    });
  }, []);

  // messages local state
  const handleDeleteMessage = useCallback(
    (messageId) => {
      removeMessage(messageId);
      setDeletedMessage(messageId);
    },
    [removeMessage]
  );

  const handleUserDisconnect = useCallback(
    (bannedUsername) => {
      const bannedUserChannelId =
        extractChannelIdfromChannelArn(bannedUsername);

      if (bannedUserChannelId === userData?.trackingId.toLowerCase()) {
        // This user has been banned
        notifyError($content.notifications.error.you_have_been_banned);
        refreshChannelData();
      }
    },
    [notifyError, refreshChannelData, userData?.trackingId]
  );

  const disconnect = useCallback(() => {
    refreshChannelData();
    setRoom(null);
    connection.current = null;
    chatCapabilities.current = null;
    isInitializingConnection.current = false;
    isConnectionOpenRef.current = false;
  }, [refreshChannelData]);

  const connect = useCallback(() => {
    if (
      isViewerBanned !== false ||
      !chatRoomOwnerUsername ||
      isInitializingConnection.current
    )
      return;

    // Clean up previous connection resources
    abortControllerRef.current = new AbortController();
    if (connection.current) disconnect();

    isInitializingConnection.current = true;
    setHasConnectionError(false);

    // create a new instance of chat room
    const { signal } = abortControllerRef.current;
    const room = new ChatRoom({
      regionOrUrl: ivsChatWebSocketRegionOrUrl,
      maxReconnectAttempts: MAX_RECONNECT_ATTEMPTS,
      tokenProvider: async () => {
        const data = await requestChatToken(chatRoomOwnerUsername, signal);

        if (data?.error) {
          retryConnectionAttemptsCounterRef.current += 1;
          if (
            retryConnectionAttemptsCounterRef.current === MAX_RECONNECT_ATTEMPTS
          ) {
            isInitializingConnection.current = false;
            notifyError($content.notifications.error.error_loading_chat, {
              withTimeout: false
            });
            setHasConnectionError(true);
          }
        } else {
          chatCapabilities.current = data.capabilities;
        }

        return {
          ...data,
          ...(!data?.error && {
            sessionExpirationTime: new Date(data.sessionExpirationTime)
          })
        };
      }
    });

    room.logLevel =
      process.env.REACT_APP_STAGE === 'prod' ? info : isDebug && debug;
    room.connect();
    setRoom(room);
    connection.current = room;
    isConnectionOpenRef.current = true;
    isInitializingConnection.current = false;
  }, [chatRoomOwnerUsername, disconnect, isViewerBanned, notifyError]);

  // Initialize connection
  useEffect(() => {
    connect();

    return disconnect;
  }, [connect, disconnect]);

  useEffect(() => {
    // If chat room listeners are not available, do not continue
    if (!room || !room.addListener) {
      return;
    }

    const unsubscribeOnConnect = room.addListener('connect', () => {
      updateUserRole();
    });

    const unsubscribeOnDisconnect = room.addListener('disconnect', () => {
      isConnectionOpenRef.current = false;
      connection.current = null;
      setRoom(null);
      chatCapabilities.current = [];

      updateUserRole();
    });

    const unsubscribeOnUserDisconnect = room.addListener(
      'userDisconnect',
      (event) => {
        const { trackingId } = userData;
        const { userId: bannedUserId } = event;

        handleUserDisconnect(bannedUserId);

        const bannedUserChannelId =
          extractChannelIdfromChannelArn(bannedUserId);
        if (bannedUserChannelId !== trackingId.toLowerCase()) {
          removeMessageByUserId(bannedUserId);
        }
      }
    );

    const unsubscribeOnMessage = room.addListener('message', (message) => {
      const {
        attributes: {
          pollStreamActionData = undefined,
          auctionStreamActionData = undefined,
          flashSaleStreamActionData = undefined,
          eventType = undefined,
          voter = undefined,
          option = undefined
        }
      } = message;
      switch (eventType) {
        case HEART_BEAT:
          if ((isModerator && isStreamManagerPage) || hasPollEnded) return;

          const date = JSON.parse(message.attributes.startTime);
          const currentTime = Date.now();
          const delay = (currentTime - date) / 1000;

          updatePollData({
            duration: Number(JSON.parse(message.attributes.duration)),
            question: JSON.parse(message.attributes.question),
            votes: JSON.parse(message.attributes.updatedVotes),
            isActive: true,
            expiry: JSON.parse(message.attributes.expiry),
            startTime: JSON.parse(message.attributes.startTime),
            delay,
            pollCreatorId: JSON.parse(message.attributes.pollCreatorId)
          });

          const votersList = JSON.parse(message.attributes.voters);

          if (!selectedOption && userData?.trackingId in votersList) {
            const savedVote = votersList[userData?.trackingId];
            if (savedVote) {
              setSelectedOption(savedVote);
              dispatchPollState({ isVoting: false });
            }
          }
          break;
        case HEART_BEAT_AUCTION:
          if ((isModerator && isStreamManagerPage) || hasAuctionEnded) return;
          const dateAuction = JSON.parse(message.attributes.auctionStartTime);
          const currentTimeAuction = Date.now();
          const delayAuction = (currentTimeAuction - dateAuction) / 1000;

          updateAuctionData({
            auctionDuration: Number(
              JSON.parse(message.attributes.auctionDuration)
            ),
            bids: JSON.parse(message.attributes.updatedBids),
            isActiveAuction: true,
            auctionExpiry: JSON.parse(message.attributes.auctionExpiry),
            auctionStartTime: JSON.parse(message.attributes.auctionStartTime),
            delayAuction,
            auctionTitle: JSON.parse(message.attributes.auctionTitle),
            auctionTerms: JSON.parse(message.attributes.auctionTerms),
            auctionPrice: JSON.parse(message.attributes.auctionPrice),
            auctionReserve: JSON.parse(message.attributes.auctionReserve),
            auctionShippingRate: JSON.parse(
              message.attributes.auctionShippingRate
            ),
            auctionBuyNow: JSON.parse(message.attributes.auctionBuyNow),
            auctionDescription: JSON.parse(
              message.attributes.auctionDescription
            ),
            auctionBidCount: JSON.parse(message.attributes.auctionBidCount),
            currentBidder: JSON.parse(message.attributes.currentBidder),
            currentBidderUsername: JSON.parse(
              message.attributes.currentBidderUsername
            )
          });

          const biddersList = JSON.parse(message.attributes.bidders);

          if (!selectedOptionAuction && userData?.trackingId in biddersList) {
            const savedBid = biddersList[userData?.trackingId];
            if (savedBid) {
              setSelectedOptionAuction(savedBid);
              dispatchAuctionState({ isBidding: false });
            }
          }
          break;
        case HEART_BEAT_FLASH_SALE:
          if ((isModerator && isStreamManagerPage) || hasFlashSaleEnded) return;
          const dateFlashSale = JSON.parse(
            message.attributes.flashSaleStartTime
          );
          const currentTimeFlashSale = Date.now();
          const delayFlashSale = (currentTimeFlashSale - dateFlashSale) / 1000;

          updateFlashSaleData({
            flashSaleDuration: Number(
              JSON.parse(message.attributes.flashSaleDuration)
            ),
            purchases: JSON.parse(message.attributes.updatedPurchases),
            isActiveFlashSale: true,
            flashSaleExpiry: JSON.parse(message.attributes.flashSaleExpiry),
            flashSaleStartTime: JSON.parse(
              message.attributes.flashSaleStartTime
            ),
            delayFlashSale,
            flashSaleTerms: JSON.parse(message.attributes.flashSaleTerms),
            flashSaleDescription: JSON.parse(
              message.attributes.flashSaleDescription
            ),
            flashSaleTitle: JSON.parse(message.attributes.flashSaleTitle),
            flashSaleDiscountPrice: JSON.parse(
              message.attributes.flashSaleDiscountPrice
            ),
            flashSalePrice: JSON.parse(message.attributes.flashSalePrice),
            flashSaleQuantity: JSON.parse(message.attributes.flashSaleQuantity),
            flashSaleShippingRate: JSON.parse(
              message.attributes.flashSaleShippingRate
            ),
            flashSalePurchaseCount: JSON.parse(
              message.attributes.flashSalePurchaseCount
            )
          });

          const purchasersList = JSON.parse(message.attributes.purchasers);

          if (userData?.trackingId in purchasersList) {
            const savedPurchase = purchasersList[userData?.trackingId];
            if (savedPurchase) {
              dispatchFlashSaleState({ isPurchasing: false });
            }
          }
          break;
        case SEND_VOTE_STATS:
          const updatedVotes = JSON.parse(message.attributes.updatedVotes);
          updatePollData({ votes: updatedVotes });
          break;
        case SUBMIT_VOTE:
          const shouldBlockVote = isVotingBlocked(
            JSON.parse(message.attributes.duration),
            JSON.parse(message.attributes.startTime)
          );

          const canProcessVote =
            isModerator && pathname === '/manager' && !shouldBlockVote;

          if (canProcessVote) {
            const currentVotes = updateVotes(message, votes);
            updatePollData({ votes: currentVotes });

            saveVotesToLocalStorage(currentVotes, { [voter]: option });

            actions.sendMessage(SEND_VOTE_STATS, {
              eventType: SEND_VOTE_STATS,
              updatedVotes: JSON.stringify(currentVotes)
            });
          }
          break;
        case START_POLL:
          const {
            votes: options,
            duration,
            question,
            expiry,
            startTime,
            delay: del = 0,
            pollCreatorId
          } = JSON.parse(pollStreamActionData);

          if (isModerator && isStreamManagerPage) {
            savePollDataToLocalStorage({
              duration,
              expiry,
              startTime,
              question,
              votes: options,
              voters: {},
              isActive: true,
              name: STREAM_ACTION_NAME.POLL,
              pollCreatorId
            });
          }

          updatePollData({
            pollCreatorId,
            duration,
            question,
            votes: options,
            isActive: true,
            expiry,
            startTime,
            delay: del
          });
          break;
        case END_POLL:
          endPollAndResetPollProps();
          break;
        case SEND_BID_STATS:
          const updatedBids = JSON.parse(message.attributes.updatedBids);
          updateAuctionData({ bids: updatedBids });
          break;
        case SUBMIT_BID:
          const currentAuctionBidCount =
            JSON.parse(message.attributes.auctionBidCount) + 1;
          updateAuctionData({ auctionBidCount: currentAuctionBidCount });

          const currentPrice = JSON.parse(message.attributes.auctionPrice);
          const currentBid = JSON.parse(message.attributes.option);
          const bidderUsername = message.attributes.username;
          const currentBidderId = message.attributes.bidder;

          if (currentBid > currentPrice) {
            updateAuctionData({
              auctionPrice: JSON.parse(message.attributes.option),
              currentBidderUsername: bidderUsername,
              currentBidder: currentBidderId
            });

            const currentBids = updateBids(message, bids);

            actions.sendMessage(SEND_BID_STATS, {
              eventType: SEND_BID_STATS,
              updatedBids: JSON.stringify(currentBids)
            });
          }

          break;
        case START_AUCTION:
          const {
            bids: auctionOptions,
            duration: auctionDuration,
            expiry: auctionExpiry,
            startTime: auctionStartTime,
            delay: auctionDelay = 0,
            auctionTitle,
            auctionTerms,
            auctionPrice,
            auctionReserve,
            auctionShippingRate,
            auctionBuyNow,
            auctionDescription,
            auctionBidCount,
            currentBidder,
            currentBidderUsername
          } = JSON.parse(auctionStreamActionData);

          if (isModerator && isStreamManagerPage) {
            saveAuctionDataToLocalStorage({
              auctionDuration,
              auctionExpiry,
              auctionStartTime,
              bids: auctionOptions,
              bidders: {},
              isActiveAuction: true,
              name: STREAM_ACTION_NAME.AUCTION,
              auctionTitle,
              auctionTerms,
              auctionPrice,
              auctionReserve,
              auctionShippingRate,
              auctionBuyNow,
              auctionDescription,
              auctionBidCount,
              currentBidder,
              currentBidderUsername
            });
          }

          updateAuctionData({
            auctionDuration,
            bids: auctionOptions,
            isActiveAuction: true,
            auctionExpiry,
            auctionStartTime,
            delay: auctionDelay,
            auctionTitle,
            auctionTerms,
            auctionPrice,
            auctionReserve,
            auctionShippingRate,
            auctionBuyNow,
            auctionDescription,
            auctionBidCount,
            currentBidder,
            currentBidderUsername
          });
          break;
        case END_AUCTION:
          endAuctionAndResetAuctionProps();
          break;
        case SEND_PURCHASE_STATS:
          const updatedPurchases = JSON.parse(
            message.attributes.updatedPurchases
          );
          updateFlashSaleData({ purchases: updatedPurchases });
          break;
        case SUBMIT_PURCHASE:
          const currentFlashSalePurchaseCount =
            JSON.parse(message.attributes.flashSalePurchaseCount) + 1;
          updateFlashSaleData({
            flashSalePurchaseCount: currentFlashSalePurchaseCount
          });

          break;
        case START_FLASH_SALE:
          const {
            purchases: flashSaleOptions,
            duration: flashSaleDuration,
            expiry: flashSaleExpiry,
            startTime: flashSaleStartTime,
            delay: flashSaleDelay = 0,
            flashSaleTerms,
            flashSaleDescription,
            flashSaleTitle,
            flashSaleDiscountPrice,
            flashSalePrice,
            flashSaleQuantity,
            flashSaleShippingRate,
            flashSalePurchaseCount
          } = JSON.parse(flashSaleStreamActionData);

          if (isModerator && isStreamManagerPage) {
            saveFlashSaleDataToLocalStorage({
              flashSaleDuration,
              flashSaleExpiry,
              flashSaleStartTime,
              purchases: flashSaleOptions,
              purchasers: {},
              isActiveFlashSale: true,
              name: STREAM_ACTION_NAME.FLASH_SALE,
              flashSaleTerms,
              flashSaleDescription,
              flashSaleTitle,
              flashSaleDiscountPrice,
              flashSalePrice,
              flashSaleQuantity,
              flashSaleShippingRate,
              flashSalePurchaseCount
            });
          }

          updateFlashSaleData({
            flashSaleDuration,
            purchases: flashSaleOptions,
            isActiveFlashSale: true,
            flashSaleExpiry,
            flashSaleStartTime,
            delay: flashSaleDelay,
            flashSaleTerms,
            flashSaleDescription,
            flashSaleTitle,
            flashSaleDiscountPrice,
            flashSalePrice,
            flashSaleQuantity,
            flashSaleShippingRate,
            flashSalePurchaseCount
          });
          break;
        case END_FLASH_SALE:
          endFlashSaleAndResetFlashSaleProps();
          break;
        case SEND_MESSAGE:
          addMessage(message);
          break;
        default:
          break;
      }
    });

    const unsubscribeOnMessageDelete = room.addListener(
      'messageDelete',
      (deletedMessage) => {
        const {
          attributes: { MessageID },
          reason
        } = deletedMessage;
        handleDeleteMessage(MessageID, reason);
      }
    );

    return () => {
      unsubscribeOnConnect();
      unsubscribeOnDisconnect();
      unsubscribeOnMessage();
      unsubscribeOnMessageDelete();
      unsubscribeOnUserDisconnect();
    };
  }, [
    addMessage,
    room,
    updateUserRole,
    handleDeleteMessage,
    handleUserDisconnect,
    userData,
    removeMessageByUserId,
    updatePollData,
    resetPollProps,
    hasPollEnded,
    updateAuctionData,
    resetAuctionProps,
    hasAuctionEnded,
    updateFlashSaleData,
    resetFlashSaleProps,
    hasFlashSaleEnded,
    isModerator,
    pathname,
    votes,
    bids,
    actions,
    isActive,
    isActiveAuction,
    isActiveFlashSale,
    selectedOption,
    setSelectedOption,
    setSelectedOptionAuction,
    saveVotesToLocalStorage,
    saveBidsToLocalStorage,
    savedPollData,
    clearPollLocalStorage,
    savedAuctionData,
    clearAuctionLocalStorage,
    savedFlashSaleData,
    clearFlashSaleLocalStorage,
    isStreamManagerPage,
    savePollDataToLocalStorage,
    dispatchPollState,
    endPollAndResetPollProps,
    saveAuctionDataToLocalStorage,
    dispatchAuctionState,
    selectedOptionAuction,
    endAuctionAndResetAuctionProps,
    saveFlashSaleDataToLocalStorage,
    dispatchFlashSaleState,
    selectedOptionFlashSale,
    endFlashSaleAndResetFlashSaleProps,
    purchases,
    savePurchasesToLocalStorage,
    auctionTitle,
    auctionTerms,
    auctionPrice,
    auctionReserve,
    auctionShippingRate,
    auctionBuyNow,
    auctionDescription,
    flashSaleDiscountPrice,
    flashSaleTitle,
    flashSaleQuantity,
    flashSaleShippingRate
  ]);

  // We are saving the chat messages in local state for only the currently signed-in user's chat room,
  // and removing them from local state once the user has signed out
  useEffect(() => {
    if (isSessionValid) {
      if (
        ownUsername &&
        chatRoomOwnerUsername &&
        chatRoomOwnerUsername === ownUsername
      ) {
        savedMessages.current[ownUsername] = messages.map((message) => ({
          ...message,
          isPreloaded: true
        }));
      }
    } else {
      savedMessages.current = {};
    }
  }, [isSessionValid, messages, ownUsername, chatRoomOwnerUsername]);

  const value = useMemo(
    () => ({
      addMessage,
      deletedMessageIds,
      initMessages,
      messages,
      removeMessage,
      removeMessageByUserId,
      sentMessageIds,
      // chat Actions
      actions,
      chatUserRole,
      hasConnectionError,
      isConnecting,
      sendAttemptError,
      isModerator,
      startPoll,
      endPoll,
      startAuction,
      endAuction,
      startFlashSale,
      endFlashSale,
      deletedMessage,
      setDeletedMessage
    }),
    [
      actions,
      addMessage,
      chatUserRole,
      hasConnectionError,
      initMessages,
      isConnecting,
      messages,
      removeMessage,
      removeMessageByUserId,
      sendAttemptError,
      isModerator,
      startPoll,
      endPoll,
      startAuction,
      endAuction,
      startFlashSale,
      endFlashSale,
      deletedMessage,
      setDeletedMessage
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = { children: PropTypes.node.isRequired };

export const useChat = () => useContextHook(Context);
