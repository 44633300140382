import { memo, useEffect } from 'react';
import Button from '../../components/Button';

const HeroSection = memo(function HeroSection() {
  useEffect(() => {
    // Add preload hint for video when in browser environment
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.as = 'video';
    preloadLink.href = '/ex-shop.mp4';
    document.head.appendChild(preloadLink);

    return () => {
      if (document.head.contains(preloadLink)) {
        document.head.removeChild(preloadLink);
      }
    };
  }, []);

  return (
    <section
      className="
        relative
        w-full mt-4 sm:mt-8 mx-auto
        rounded-xl
        overflow-hidden
        backdrop-blur-sm
        border border-gray-200/10 dark:border-gray-800/10
        shadow-lg
      "
      aria-label="Hero Section"
    >
      {/* Updated background: Subtle gradient overlay */}
      <div
        className="
          absolute inset-0
          bg-white
          dark:bg-black
        "
        aria-hidden="true"
      />

      <div
        className="
          relative
          max-w-7xl mx-auto
          px-8 sm:px-4
          py-12
          flex flex-row sm:flex-col-reverse items-center gap-8
        "
      >
        {/* Left Column: Content with updated text styles */}
        <div className="w-1/2 sm:w-full text-left sm:text-center">
          <header className="flex flex-col items-start sm:items-center space-y-4 mb-8">
            <div className="flex items-center gap-3">
              <h1
                className="
                  text-4xl sm:text-xl md:text-3xl font-extrabold
                  text-gray-900 dark:text-white leading-tight tracking-tight
                  [text-shadow:0px_2px_4px_rgba(0,0,0,0.1)]
                "
              >
                Live Boutique Shopping
              </h1>
              {/* <span
                className="
                  bg-gray-600/20 text-white text-sm font-semibold
                  px-3 py-1 rounded-full shadow-lg
                "
              >
                Beta
              </span> */}
            </div>
          </header>

          <p className="text-base sm:text-sm text-gray-700 dark:text-gray-300 mb-8 max-w-lg sm:mx-0">
            Experience real-time shopping with your favorite boutiques. Chat live with sellers,
            see products up close, and enjoy personalized shopping from anywhere.
          </p>

          <div className="flex justify-start sm:justify-center gap-4 sm:flex-col">
            <Button
              type="nav"
              variant="primary"
              to="/register"
              className="
                group px-8 py-4
                bg-indigo-600 text-white font-bold text-base
                dark:bg-indigo-500 dark:text-white
                rounded-full shadow-xl
                transition-all duration-300
                hover:bg-indigo-700 dark:hover:bg-indigo-600
                focus:ring-4 focus:ring-indigo-400/50
                active:scale-95
              "
              aria-label="Start Shopping Live"
            >
              Start Shopping Live
            </Button>
            <Button
              type="nav"
              variant="secondary"
              to="/LivescapeDemo"
              className="
                group px-8 py-4
                bg-gray-900/5 dark:bg-white/5
                border-2 border-gray-900/20 dark:border-white/20
                text-gray-900 dark:text-white font-bold text-base
                rounded-full shadow-lg
                transition-all duration-300
                hover:bg-gray-900/10 dark:hover:bg-white/10
                focus:ring-4 focus:ring-gray-900/20 dark:focus:ring-white/20
                active:scale-95
              "
              aria-label="View Example Shop"
            >
              Watch Demo Stream
            </Button>
          </div>
        </div>

        {/* Right Column: Image/Video with updated overlay */}
        <div className="w-1/2 sm:w-full">
          <div className="relative aspect-video rounded-xl shadow-2xl overflow-hidden">
            <div
              aria-hidden="true"
              className="
                absolute inset-0 rounded-xl
                bg-gradient-to-t from-black/60 via-black/30 to-transparent
                ring-1 ring-inset ring-gray-900/10 dark:ring-white/10
                z-10
              "
            />
            <img
              src="/hero-pink-bg.jpeg"
              className="w-full h-full object-cover rounded-xl"
              loading="eager"
              alt="Live boutique shopping experience"
              width="640"
              height="360"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default HeroSection;
