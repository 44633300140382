import { apiBaseUrl, unauthFetch, authFetch } from './utils';

export const getProducts = async () => {
  try {
    const { result, error } = await authFetch({
      url: `${apiBaseUrl}/products`,
      method: 'GET'
    });
    if (error) {
      throw new Error('Failed to fetch channel products: ' + error.message);
    }
    return result;
  } catch (error) {
    console.error('Error in getChannelProducts:', error);
    throw error; // Rethrow the error to ensure it is handled by the caller
  }
};

export const getActiveProducts = async (channelArn) => {
  try {
    const encodedChannelArn = encodeURIComponent(channelArn);
    const { result, error } = await unauthFetch({
      url: `${apiBaseUrl}/products/active?channelArn=${encodedChannelArn}`,
      method: 'GET'
    });
    if (error) {
      throw new Error('Failed to fetch channel products: ' + error.message);
    }
    return result;
  } catch (error) {
    console.error('Error in getChannelProducts:', error);
    throw error; // Rethrow the error to ensure it is handled by the caller
  }
};