import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { productAPI } from '../../api';
import Button from '../../components/Button';
import Input from './formElements/Input';
import TextArea from './formElements/TextArea';
import Spinner from '../../components/Spinner';

const initialProductState = {
  id: uuidv4(),
  productName: '',
  productDescription: '',
  productAssetId: uuidv4(),
  productAssets: {},
  price: '',
  type: 'physical',
  quantity: 1,
  shippingRate: '',
  isActive: false
};

const AddProductForm = () => {
  const [product, setProduct] = useState(initialProductState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const { createProduct } = productAPI;

  useEffect(() => {
    document.querySelector('input[name="productName"]')?.focus();
  }, []);

  const handleChange = (event, dataKey) => {
    setProduct(prev => ({ ...prev, [dataKey]: event[dataKey] }));
  };

  const validateProduct = useCallback(() => {
    const newErrors = {};
    const { productName, productDescription, price, type, quantity, shippingRate } = product;

    if (!productName.trim() || productName.length < 3 || productName.length > 100) {
      newErrors.productName = 'Product name must be between 3 and 100 characters.';
    }

    if (!productDescription.trim() || productDescription.length < 3 || productDescription.length > 500) {
      newErrors.productDescription = 'Product description must be between 3 and 500 characters.';
    }

    if (price === '' || isNaN(price) || price <= 0) {
      newErrors.price = 'Product price must be a positive number.';
    }

    if (!['physical', 'digital'].includes(type)) {
      newErrors.type = 'Invalid product type. Must be either "physical" or "digital".';
    }

    if (isNaN(quantity) || quantity < 0) {
      newErrors.quantity = 'Product quantity must be a non-negative number.';
    }

    if (type === 'physical' && (shippingRate === '' || isNaN(shippingRate) || shippingRate < 0)) {
      newErrors.shippingRate = 'Shipping rate must be a non-negative number for physical products.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [product]);

  const handleFormSubmit = useCallback(async (isActive) => {
    if (!validateProduct()) {
      setMessage({ text: 'Please correct the errors in the form.', type: 'error' });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await createProduct({ ...product, isActive });
      if (response.error) {
        setMessage({ text: response.error.message, type: 'error' });
      } else {
        const messageText = isActive
          ? 'Product created and listed successfully.'
          : 'Product saved. You can now add images.';
        setMessage({ text: messageText, type: 'success' });
        window.location.href = isActive ? '/products' : `/products/${response.result.id}/assets`;
      }
    } catch (error) {
      console.error('Error creating product:', error);
      setMessage({
        text: 'An unexpected error occurred while creating the product.',
        type: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [product, validateProduct, createProduct]);

  const handleSaveAsDraft = () => handleFormSubmit(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormSubmit(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-black pt-20 pb-16">
      <div className="max-w-lg mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-100 dark:border-gray-700">
          <div className="px-6 py-6">
            {message.text && (
              <div
                className={`mb-4 p-3 rounded-lg border ${
                  message.type === 'error'
                    ? 'bg-red-50 border-red-200 text-red-700 dark:bg-red-900/30 dark:border-red-800'
                    : 'bg-green-50 border-green-200 text-green-700 dark:bg-green-900/30 dark:border-green-800'
                } transition-all duration-300 ease-in-out`}
              >
                {message.text}
              </div>
            )}

            <div className="flex items-center justify-between mb-6 border-b border-gray-200 dark:border-gray-700 pb-4">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Create Product
              </h1>
              <Button
                variant="secondary"
                className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 shadow"
                onClick={() => {
                  window.location.href = '/products';
                }}
              >
                ← Back
              </Button>
            </div>

            <form
              onSubmit={handleSubmit}
              aria-labelledby="form-title"
              noValidate
              className="space-y-6"
            >
              <div className="space-y-5">
                <Input
                  label="Product Name"
                  dataKey="productName"
                  type="text"
                  value={product.productName}
                  onChange={(event) => handleChange(event, 'productName')}
                  error={errors.productName}
                  required
                  aria-required="true"
                  placeholder="Enter the product name"
                  maxLength={100}
                  className="transition-all duration-200 focus:ring-2 focus:ring-blue-500"
                />

                <TextArea
                  label="Product Description"
                  dataKey="productDescription"
                  rows="3"
                  value={product.productDescription}
                  onChange={(event) =>
                    handleChange(event, 'productDescription')
                  }
                  error={errors.productDescription}
                  required
                  aria-required="true"
                  placeholder="Describe the product"
                  maxLength={500}
                  className="transition-all duration-200 focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    label="Product Price"
                    dataKey="price"
                    type="number"
                    value={product.price}
                    onChange={(event) => handleChange(event, 'price')}
                    error={errors.price}
                    required
                    aria-required="true"
                    max={1000000}
                    step="0.01"
                    placeholder="0.00"
                    className="transition-all duration-200"
                  />

                  {product.type === 'physical' && (
                    <Input
                      label="Shipping Rate"
                      dataKey="shippingRate"
                      type="number"
                      value={product.shippingRate}
                      onChange={(event) => handleChange(event, 'shippingRate')}
                      error={errors.shippingRate}
                      aria-required="true"
                      step="0.01"
                      placeholder="0.00"
                      className="transition-all duration-200"
                    />
                  )}
                </div>

                <Input
                  label="Product Quantity"
                  dataKey="quantity"
                  type="number"
                  value={product.quantity}
                  onChange={(event) => handleChange(event, 'quantity')}
                  error={errors.quantity}
                  required
                  aria-required="true"
                  placeholder="1"
                  className="transition-all duration-200"
                />
              </div>

              <div className="pt-6 border-t border-gray-200 dark:border-gray-700">
                <div className="flex flex-col space-y-3">
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-full py-3 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 transition-all duration-200 shadow hover:shadow-xl"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner /> : 'Save and List'}
                  </Button>
                  <div className="flex space-x-3">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={handleSaveAsDraft}
                      className="w-1/2 py-2.5 shadow hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <Spinner /> : 'Save as Draft'}
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleFormSubmit(false)}
                      className="w-1/2 py-2.5 shadow hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <Spinner /> : 'Add Images'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductForm;
