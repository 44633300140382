import LiveStreamsSection from './LiveStreamsSection';
import PageLayout from './PageLayout';
import withVerticalScroller from '../../components/withVerticalScroller';
import HeroSection from './HeroSection';
import { useUser } from '../../contexts/User';
import WhyChooseUs from './WhyChooseUs';
import Footer from '../../layouts/AppLayoutWithNavbar/Footer';
import CallToActionMidPage from './CallToActionMidPage';
import FAQSection from './FAQSection';
import CookiePolicy from '../../components/CookiePolicy';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
// import Banner from './Banner';

const ChannelDirectory = () => {
  const { isSessionValid } = useUser();
  const { isMobileView } = useResponsiveDevice();

  return (
    <PageLayout>
      <div className="space-y-16 animate-fadeIn">
        {!isSessionValid && (
          <div className="relative z-10">
            <HeroSection />
          </div>
        )}
        <div className="relative z-10">
          <LiveStreamsSection />
        </div>
        {!isSessionValid && (
          <>
            <div className="relative z-10">
              <WhyChooseUs />
              <FAQSection />
              <CallToActionMidPage />
              <Footer />
            </div>
          </>
        )}
        {!isMobileView && <CookiePolicy />}
      </div>
    </PageLayout>
  );
};

export default withVerticalScroller(ChannelDirectory);
