import { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { BREAKPOINTS, USER_MANAGEMENT_THEME_COLOR } from '../../constants';
import { clsm, compose } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';
import FullScreenLoader from '../../components/FullScreenLoader';
import Notification from '../../components/Notification';
import useScrollToTop from '../../hooks/useScrollToTop';
import useThemeColor from '../../hooks/useThemeColor';
import withSessionLoader from '../../components/withSessionLoader';
import withVerticalScroller from '../../components/withVerticalScroller';

const UserManagement = () => {
  const {
    hasErrorCreatingResources,
    initUserResources,
    isCreatingResources,
    isSessionValid
  } = useUser();

  const { currentBreakpoint, mainRef } = useResponsiveDevice();
  const isResponsiveView = currentBreakpoint < BREAKPOINTS.lg;
  const location = useLocation();
  const locationRef = useRef({});
  const from = locationRef.current?.from?.pathname || '/';

  useScrollToTop({ isResponsiveView });
  useThemeColor(USER_MANAGEMENT_THEME_COLOR);

  useEffect(() => {
    locationRef.current = location.state;
  }, [location.state]);

  if (isSessionValid && !isCreatingResources && !hasErrorCreatingResources) {
    return <Navigate to={from} state={locationRef.current} replace />;
  }

  return (
    <main
      id="main-user-container"
      className={clsm([
        'flex flex-col justify-center min-h-screen w-full relative scroll-smooth',
        'bg-gray-50 dark:bg-black',
        'overflow-x-hidden overflow-y-auto supports-overlay:overflow-y-overlay'
      ])}
      ref={mainRef}
    >
      <Notification />
      {isCreatingResources || hasErrorCreatingResources ? (
        <FullScreenLoader
          hasError={hasErrorCreatingResources}
          mobileBreakpoint={BREAKPOINTS.lg}
          onClick={initUserResources}
        />
      ) : (
        <div
          className={clsm([
            'flex flex-col items-center justify-center w-full h-full',
            'px-4 py-8 sm:px-0 sm:py-0',
          ])}
        >
          <div className="bg-white dark:bg-black p-8 rounded-xl shadow-2xl w-full max-w-md">
            <Outlet />
          </div>
        </div>
      )}
    </main>
  );
};

export default compose(
  (component) => withSessionLoader(component, BREAKPOINTS.lg),
  withVerticalScroller
)(UserManagement);
