import PropTypes from 'prop-types';

import { clsm, noop } from '../../../../../utils';

import Button from '../../../../../components/Button';
import { ChevronLeft, ChevronRight } from '../../../../../assets/icons';

const GridLayout = ({
  children = [],
  className = '',
  hasError = false,
  hasData = false,
  isLoading = false,
  noDataText = '',
  title = '',
  tryAgainFn = noop,
  tryAgainText = '',
  isSidebarOpen = false,
  toggleSidebar = noop
}) => {
  return (
    <section
      className={clsm([
        'flex-col',
        'flex',
        'grow',
        'w-full',
        hasData && 'space-y-4',
        className
      ])}
    >
      <div className="flex justify-between items-center mb-4">
        {isSidebarOpen && (
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{title}</h3>
        )}
        <Button
          className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
          variant="icon"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <ChevronLeft className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
        </Button>
      </div>

      {!isLoading && hasData && (
        <div className={clsm([
          'grid',
          'gap-3',
          isSidebarOpen ? 'grid-cols-1' : 'grid-cols-1',
        ])}>
          {children}
        </div>
      )}
      
      {/* ... (rest of the component remains the same) */}
    </section>
  );
};

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  hasData: PropTypes.bool,
  isLoading: PropTypes.bool,
  noDataText: PropTypes.string,
  title: PropTypes.string,
  tryAgainFn: PropTypes.func,
  tryAgainText: PropTypes.string,
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default GridLayout;
