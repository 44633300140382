import { authFetch, apiBaseUrl } from './utils';

// This function is used to get the carts for the user.
export const getCarts = async () => {
  const response = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/carts` // Assuming the backend endpoint for fetching multiple carts is /carts
  });

  return response;
};

// This function is used to get the cart items.
export const getCartItems = async () => {
  const response = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/carts`
  });

  return response;
};

// This function is used to get the cart items.
export const getCartsItemCount = async () => {
  const response = await authFetch({
    method: 'GET',
    url: `${apiBaseUrl}/carts/count`
  });

  return response;
};
