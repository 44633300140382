import PropTypes from 'prop-types';
import { clsm } from '../../utils';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';

const PageLayout = ({ children }) => {
  const { isMobileView } = useResponsiveDevice();
  const { isSessionValid } = useUser();

  return (
    <div
      className={clsm([
        'min-h-screen',
        'flex flex-col',
        'bg-gray-50 dark:bg-black',
        'transition-colors duration-300',
        'overflow-x-hidden',
        isMobileView ? 'px-4 py-6' : 'px-8 py-10',
        'sm:px-6 sm:py-8',
        'md:px-12 md:py-12',
        'lg:px-16 lg:py-16',
        'xl:px-24 xl:py-20',
        isMobileView && !isSessionValid && 'pb-32',
      ])}
    >
      <main className={clsm(["flex-grow w-full  mx-auto"])}>
        {children}
      </main>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageLayout;
