import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ProductImageCarousel = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // Check if images array is empty and use default image
  const displayImages = images.length > 0 ? images : ['/no-image.png'];

  return (
    <div className="relative max-w-xs mx-auto overflow-hidden rounded-lg shadow-lg bg-white" style={{ height: '320px' }}>
      <div
        className="flex transition-transform ease-out duration-300"
        style={{ transform: `translateX(-${current * 100}%)`, height: '320px' }}
      >
        {displayImages.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Slide ${index}`}
            className="min-w-full block object-cover"
            style={{ height: '320px', width: '320px' }}  // Ensure all images have the same dimensions
          />
        ))}
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex justify-center p-4">
        {displayImages.map((_, index) => (
          <span
            key={index}
            className={`inline-block h-2 w-2 mx-1 rounded-full ${
              current === index ? 'bg-indigo-600' : 'bg-white border border-indigo-600'
            }`}
            aria-hidden="true"
          ></span>
        ))}
      </div>
      {displayImages.length > 1 && (
        <>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-4 transform -translate-y-1/2 p-3 rounded-full bg-gray-500 bg-opacity-20 text-white cursor-pointer hover:bg-opacity-70"
            aria-label="Previous slide"
          >
            &#10094;
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-4 transform -translate-y-1/2 p-3 rounded-full bg-gray-500 bg-opacity-20 text-white cursor-pointer hover:bg-opacity-70"
            aria-label="Next slide"
          >
            &#10095;
          </button>
        </>
      )}
    </div>
  );
};

ProductImageCarousel.propTypes = {
  images: PropTypes.array.isRequired
};

export default ProductImageCarousel;
