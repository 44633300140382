import { clsm } from '../../../../utils';

import PropTypes from 'prop-types';
import SidebarDirectory from './SidebarDirectory';


const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div
      className={clsm(
        [
          'fixed',
          'flex',
          'flex-col',
          'h-auto',
          'top-0',
          'bottom-0',
          'left-0',
          'pt-16',
          'pb-4',
          'no-scrollbar',
          'overflow-y-auto',
          'overflow-x-hidden',
          'dark:bg-black',
          'bg-white',
          'supports-overlay:overflow-y-overlay',
          'transition-all',
          'duration-300',
          'ease-in-out'
        ],
        isSidebarOpen
          ? ['w-64', 'px-4'] // is open
          : ['w-20', 'px-1'] // is closed
      )}
    >     
      <SidebarDirectory isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
    </div>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default Sidebar;
