import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { clsm } from '../../../utils';

const MS_PER_MINUTE = 60000;
const MS_PER_SECOND = 1000;

const CountdownTimer = ({ color = 'default', duration = 10, startTime = Date.now() }) => {
  const durationInMs = duration * 1000;
  const [timeLeft, setTimeLeft] = useState(
    durationInMs - (Date.now() - startTime)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      let remainingTimeInMs = durationInMs - (now - startTime);

      if (remainingTimeInMs <= 0) {
        remainingTimeInMs = 0;
        clearInterval(intervalId);
      }

      setTimeLeft(remainingTimeInMs);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [durationInMs, startTime]);

  const formattedTimeLeft = useMemo(() => {
    const minutes = Math.floor(timeLeft / MS_PER_MINUTE);
    const seconds = Math.floor((timeLeft % MS_PER_MINUTE) / MS_PER_SECOND);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, [timeLeft]);

  // Calculate the percentage of time left
  const percentage = useMemo(() => (timeLeft / durationInMs) * 100, [timeLeft, durationInMs]);

  // Determine the color based on the time left
  const timerColor = useMemo(() => {
    if (percentage > 66) return 'text-green-500';
    if (percentage > 33) return 'text-yellow-500';
    return 'text-red-500';
  }, [percentage]);

  return (
    <div className="relative flex items-center justify-center">
      <svg className="w-14 h-14 transform -rotate-90" viewBox="0 0 100 100">
        <circle
          className="text-gray-300"
          stroke="currentColor"
          strokeWidth="10"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
        />
        <circle
          className={clsm([
            'transition-colors duration-500 ease-linear',
            timerColor,
          ])}
          stroke="currentColor"
          strokeWidth="10"
          strokeDasharray="282.6"
          strokeDashoffset={(282.6 * (100 - percentage)) / 100}
          strokeLinecap="round"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
        />
      </svg>
      <div
        className={clsm([
          'absolute',
          'flex',
          'items-center',
          'justify-center',
          'text-black',
          'dark:text-white',
          'text-base',
          'font-semibold',
        ])}
      >
        <span>{formattedTimeLeft}</span>
      </div>
    </div>
  );
};

CountdownTimer.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
  startTime: PropTypes.number,
};

export default CountdownTimer;
