// AnimatedBidItems.jsx

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { FaCrown, FaDollarSign } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';
import { useChannel } from '../../../../contexts/Channel';
import { useUser } from '../../../../contexts/User';
import BidItem from './BidItem';
import {
  createBuyNowSession,
  getStripePublishableKey,
} from '../../../../api/payment';

const AnimatedBidItems = ({
  textColor = 'text-gray-800',
  radioBoxControls = {},
  showBidPercentage = true,
}) => {
  const { channelData } = useChannel();
  const { color } = channelData || {};
  const {
    bids,
    totalBids,
    noBidsCaptured,
    highestCountOption,
    hasAuctionEnded,
    showFinalResults,
    auctionTitle,
    currentBidderUsername,
    currentBidder,
    isActiveAuction,
    auctionPrice,
    auctionShippingRate,
  } = useAuction();
  const { userData } = useUser();
  const { trackingId = '' } = userData || {};

  const [stripePromise, setStripePromise] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // Fetch Stripe publishable key on mount
  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const stripeResponse = await getStripePublishableKey();
        const publishableKey = stripeResponse.result?.stripePublishableKey;

        if (publishableKey) {
          setStripePromise(loadStripe(publishableKey));
        } else {
          console.error('Stripe publishable key not found:', stripeResponse);
        }
      } catch (error) {
        console.error('Error fetching Stripe publishable key:', error);
      }
    };

    fetchStripeKey();
  }, []);

  const submitPurchase = useCallback(async () => {
    if (isDisabled || currentBidder !== trackingId) return;

    setIsDisabled(true);

    try {
      const sessionId = await createBuyNowSession(
        auctionTitle,
        auctionPrice,
        auctionShippingRate,
        channelData.channelArn
      );

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Stripe Checkout error:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      // Prevent rapid re-clicking
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
    }
  }, [
    isDisabled,
    currentBidder,
    trackingId,
    auctionTitle,
    auctionPrice,
    auctionShippingRate,
    channelData.channelArn,
    stripePromise,
  ]);

  const renderAuctionResults = (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="flex flex-col items-center justify-center py-6 px-6 h-full w-full bg-white dark:bg-gray-800 rounded-lg shadow-md space-y-4"
    >
      <FaCrown className="text-yellow-500 w-12 h-12" />
      <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
        Auction Winner
      </h2>

      <span
        className={
          currentBidderUsername
            ? 'text-xl font-semibold text-green-600 dark:text-green-400'
            : 'text-xl font-semibold text-red-500'
        }
      >
        {currentBidderUsername || 'No Winner'}
      </span>

      {currentBidder === trackingId && (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button"
          className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md transition-colors hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          aria-label="Buy Now"
          onClick={submitPurchase}
          disabled={isDisabled}
        >
          {isDisabled ? (
            'Processing...'
          ) : (
            <>
              <FaDollarSign className="inline-block mr-2" />
              Buy Now
            </>
          )}
        </motion.button>
      )}
    </motion.section>
  );

  const showBidAndProgress = !hasAuctionEnded && !showFinalResults;

  if (!showBidAndProgress && isActiveAuction) {
    return renderAuctionResults;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-3"
    >
      <AnimatePresence>
        {bids.map(({ option, count, key }, index) => {
          const isHighestCount = option === highestCountOption.option;
          const percentage = count ? Math.ceil((count / totalBids) * 100) : 0;

          return (
            <motion.div
              key={key}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <BidItem
                isHighestCount={isHighestCount}
                option={option}
                count={count}
                percentage={percentage}
                showBidPercentage={showBidPercentage}
                color={color}
                textColor={textColor}
                radioBoxControls={radioBoxControls}
                inputAndLabelId={`${option}-${index}`}
                noBidsCaptured={noBidsCaptured}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </motion.div>
  );
};

AnimatedBidItems.propTypes = {
  textColor: PropTypes.string,
  showBidPercentage: PropTypes.bool,
  radioBoxControls: PropTypes.object,
};

export default AnimatedBidItems;