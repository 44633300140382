import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useChannel } from '../../../contexts/Channel';
import { clsm } from '../../../utils';
import { useProfileViewAnimation } from '../contexts/ProfileViewAnimation';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import Spinner from '../../../components/Spinner';

const StreamInfo = forwardRef(
  (
    { message, icon, playerProfileViewAnimationProps, isVisible, type },
    ref
  ) => {
    const { isProfileViewExpanded, shouldAnimateProfileView } =
      useProfileViewAnimation();
    const { isDefaultResponsiveView } = useResponsiveDevice();
    const { channelData } = useChannel();
    const { channelAssetUrls } = channelData || {};

    // Compute class names
    const containerClasses = clsm([
      'absolute',
      'flex',
      isVisible ? 'flex' : 'hidden',
      'items-center',
      'justify-center',
      'flex-col',
      'space-y-6',
      'aspect-video',
      'transition-all',
      'pointer-events-none',
      'z-10',
      'h-full',
      shouldAnimateProfileView.current ? 'duration-400' : 'duration-0',
      isProfileViewExpanded &&
        (isDefaultResponsiveView ? 'w-9/10 h-auto' : 'w-7/10 h-auto'),
    ]);

    // Animation variants for smoother transitions
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    };

    const backgroundStyle = channelAssetUrls?.banner
      ? { 
          backgroundImage: `url(${channelAssetUrls.banner})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      : { backgroundColor: 'rgba(17, 24, 39, 0.9)' };

    return (
      <motion.div
        {...playerProfileViewAnimationProps}
        className={containerClasses}
        ref={ref}
        initial="hidden"
        animate={isVisible ? 'visible' : 'hidden'}
        variants={variants}
        transition={{ duration: 0.3 }}
        style={backgroundStyle}
      >
        <div className="flex flex-col items-center justify-center bg-transparent p-8 rounded-xl max-w-md w-full">
          {(type === 'spinner' || !message) && (
            <Spinner className="w-12 h-12 mb-4 text-white" />
          )}
          {icon && type !== 'spinner' && message && (
            <div className="mb-4 text-white fill-current text-4xl sm:text-2xl [&>svg]:text-white [&>svg]:fill-white">
              {icon}
            </div>
          )}
          {message && (
            <h2 className="text-center text-xl sm:text-lg font-semibold text-white">
              {message}
            </h2>
          )}
        </div>
      </motion.div>
    );
  }
);

StreamInfo.propTypes = {
  icon: PropTypes.node,
  isVisible: PropTypes.bool,
  message: PropTypes.string,
  playerProfileViewAnimationProps: PropTypes.object.isRequired,
  type: PropTypes.string,
};

StreamInfo.defaultProps = {
  icon: null,
  isVisible: false,
  message: '',
  type: '',
};

export default StreamInfo;
