import React from 'react';
import PropTypes from 'prop-types';
import { CommunityConnect, Deal, EngagingChat } from '../../assets/icons';

const FeatureCard = ({ icon, title, description }) => (
  <article className="group h-full">
    <div className="flex h-full flex-col items-center p-8 text-center rounded-xl 
      bg-white/5 dark:bg-black/5 backdrop-blur-sm
      border border-gray-200/10 dark:border-gray-800/10
      transition-all duration-300 hover:bg-white/10 dark:hover:bg-black/10
      shadow-xl">
      <div className="mb-6 flex h-20 w-20 sm:h-16 sm:w-16 items-center justify-center rounded-full bg-indigo-600/20">
        {React.cloneElement(icon, {
          className: 'w-12 h-12 text-indigo-500 dark:text-indigo-400',
          'aria-label': `${title} Icon`
        })}
      </div>
      <h3 className="mb-4 text-lg font-bold text-gray-900 dark:text-white [text-shadow:0px_2px_4px_rgba(0,0,0,0.1)]">{title}</h3>
      <p className="text-gray-700 dark:text-gray-300 flex-grow">{description}</p>
    </div>
  </article>
);

FeatureCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const WhyChooseUs = () => {
  const features = [
    {
      icon: <EngagingChat />,
      title: 'Live Shopping',
      description: 'Experience live shopping sessions with boutiques showcasing their carefully selected collections.'
    },
    {
      icon: <CommunityConnect />,
      title: 'Personal Connection',
      description:
        'Connect directly with boutiques who share the stories behind their unique pieces.'
    },
    {
      icon: <Deal />,
      title: 'Exclusive Access',
      description: 'Enjoy product demonstration session and early access to new collections during special live events.'
    }
  ];

  return (
    <section
      className="relative mx-auto w-full mt-16 py-16 rounded-2xl 
        bg-white/5 dark:bg-black/5 backdrop-blur-sm
        border border-gray-200/10 dark:border-gray-800/10 shadow-lg"
      aria-labelledby="why-choose-us-title"
    >
      <div
        className="absolute inset-0 rounded-2xl
          bg-white
          dark:bg-black"
        aria-hidden="true"
      />
      <div className="relative z-10 mx-auto max-w-7xl px-6">
        <header className="mb-16 text-center">
          <h2
            id="why-choose-us-title"
            className="text-3xl sm:text-2xl font-bold text-gray-900 dark:text-white [text-shadow:0px_2px_4px_rgba(0,0,0,0.1)]"
          >
            A Curated Experience
          </h2>
        </header>
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 items-stretch">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
