import { clsm } from '../../../../utils';

import SidebarNavigation from './SidebarNavigation';

const SidebarSettings = () => {
  return (
    <div
      className={clsm(
        [
          'fixed',
          'flex',
          'flex-col',
          'items-center',
          'justify-between',
          'h-auto',
          'top-0',
          'bottom-0',
          'left-0',
          'w-16',
          'pt-7',
          'pb-4',
          'no-scrollbar',
          'overflow-y-auto',
          'overflow-x-hidden',
          'supports-overlay:overflow-y-overlay',
          'bg-white',
          'dark:bg-black',
          // 'shadow-md',
          'transition-all',
          'duration-300',
          'ease-in-out',
        ],
        ['w-60', 'pt-16', 'pb-0', 'pl-0', 'lg:portrait:w-40', 'lg:portrait:pl-8']
      )}
    >
      <SidebarNavigation />
    </div>
  );
};

export default SidebarSettings;
