// useCookieConsent.js
import { useState, useEffect } from 'react';

export function useCookieConsent() {
  const [isAccepted, setIsAccepted] = useState(null);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent !== null) {
      setIsAccepted(cookieConsent === 'true');
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsAccepted(true);
  };

  const declineCookies = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsAccepted(false);
  };

  return { isAccepted, acceptCookies, declineCookies };
}
