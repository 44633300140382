import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getProducts } from '../../api/products';
import ProductsTable from './ProductsTable';
import Spinner from '../../components/Spinner';

const ErrorMessage = ({ error }) => (
  <div className="text-center text-red-500">
    <p>
      We encountered an error while fetching products. Please try again later.
    </p>
    <p>Error details: {error}</p>
  </div>
);

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired
};

const LoadingSpinner = () => (
  <div className="flex flex-col justify-center items-center h-96">
    <Spinner />
    <p className="mt-4 text-gray-600">Loading products, please wait...</p>
  </div>
);

const Products = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      const data = await getProducts();
      if (!data) throw new Error('No data received');
      setProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const renderContent = () => {
    if (isLoading) return <LoadingSpinner />;
    if (error) return <ErrorMessage error={error} />;
    return <ProductsTable currentItems={products} setProducts={setProducts} />;
  };

  return (
    <main className="bg-white dark:bg-black min-h-screen py-24">
      <div className="max-w-7xl mx-auto sm:px-4 md:px-6 px-8">
        <section className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
          {renderContent()}
        </section>
      </div>
    </main>
  );
};

export default React.memo(Products);
