// BidItem.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { CheckCircle } from '../../../../assets/icons';
import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';
import { useUser } from '../../../../contexts/User';
import { clsm } from '../../../../utils';

const BidItem = ({
  option,
  textColor,
  inputAndLabelId,
  percentage,
  isHighestCount
}) => {
  const {
    selectedOptionAuction,
    setSelectedOptionAuction,
    isBidding,
    shouldRenderRadioInput,
    isSubmitting,
  } = useAuction();
  const { isSessionValid } = useUser();

  const isSelected = selectedOptionAuction === option;

  return (
    <motion.div
      className={clsm([
        'relative',
        'w-full',
        'bg-white',
        'dark:bg-gray-800',
        'rounded-lg',
        'shadow-sm',
        'overflow-hidden',
        'cursor-pointer',
        'transition-all',
        'duration-200',
        isSelected && 'ring-2 ring-blue-500',
        isSelected && 'border-l-4 border-purple-500'
      ])}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (isSubmitting) return;
        setSelectedOptionAuction(option);
      }}
      onKeyPress={(e) => {
        if (isSubmitting) return;
        if (e.key === 'Enter' || e.key === ' ') {
          setSelectedOptionAuction(option);
        }
      }}
    >
      {/* Progress Bar */}
      {percentage > 0 && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 0.5 }}
          className="absolute top-0 left-0 h-full bg-blue-100 dark:bg-blue-900 opacity-30"
        />
      )}

      {/* Content */}
      <div className="relative flex items-center justify-between p-3">
        {/* Option Text */}
        <div className="flex items-center space-x-3">
          {shouldRenderRadioInput && (
            <input
              id={inputAndLabelId}
              type="radio"
              name="bid-option"
              checked={isSelected}
              onChange={() => setSelectedOptionAuction(option)}
              className="form-radio h-4 w-4 text-blue-600"
            />
          )}
          <label
            htmlFor={inputAndLabelId}
            className={clsm([
              'text-sm',
              'font-medium',
              `text-${textColor}`,
            ])}
          >
            {option}
          </label>
        </div>

        {/* Percentage or Check Icon */}
        <div className="flex items-center space-x-2">
          {percentage > 0 && (
            <span className="text-xs font-medium text-gray-600 dark:text-gray-300">
              {percentage}%
            </span>
          )}
          {isSessionValid && isSelected && !isBidding && (
            <CheckCircle className="w-5 h-5 text-green-500" />
          )}
        </div>
      </div>
    </motion.div>
  );
};

BidItem.propTypes = {
  option: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  inputAndLabelId: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  isHighestCount: PropTypes.bool.isRequired
};

export default BidItem;
