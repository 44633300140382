import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clsm } from '../../../../utils';
import StreamerAuction from './StreamerAuction';
import ViewerAuction from './ViewerAuction';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useChat } from '../../../../contexts/Chat';
import { useAuction } from '../../../../contexts/StreamManagerActions/Auction';

const Auction = ({ shouldRenderInTab = 'false' }) => {
  const { pathname } = useLocation();
  const { isActiveAuction } = useAuction();
  const { isModerator } = useChat();
  const { isDesktopView, isLandscape } = useResponsiveDevice();
  const isStreamManagerPage = pathname === '/manager';

  return (
    <div
      className={clsm([
        !isStreamManagerPage && [
          'no-scrollbar',
          'overflow-y-auto',
          'supports-overlay:overflow-y-overlay',
          !isDesktopView && ['pb-20', 'h-full']
        ],
        'w-full',
        'absolute',
        isLandscape && 'mb-[110px]',
        !isActiveAuction ? 'z-0' : 'z-50'
      ])}
    >
      {isModerator && isStreamManagerPage && <StreamerAuction />}
      {!isStreamManagerPage && (
        <ViewerAuction shouldRenderInTab={shouldRenderInTab} />
      )}
    </div>
  );
};

Auction.propTypes = {
  shouldRenderInTab: PropTypes.bool
};

export default Auction;
