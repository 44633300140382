import { motion } from 'framer-motion';
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { clsm } from '../../../utils';
import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import { usePlayerContext } from '../contexts/Player';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import Button from '../../../components/Button';
import ProgressBar from './ProgressBar';
import { 
  IoCheckmarkCircle, 
  IoCloseCircle,
  IoHelpCircle,
  IoTimeOutline,
  IoTrophyOutline 
} from 'react-icons/io5';

const defaultQuizAnswerHeight = 42;
// const correctAnswerClasses = 'bg-green-500 text-white';
// const incorrectAnswerClasses = 'bg-red-500 text-white';
const defaultSlideUpVariant = { /* your variant settings */ };
const defaultViewerStreamActionTransition = { /* your transition settings */ };

// Enhanced animation variants with more sophisticated timing
const answerVariants = {
  initial: { 
    opacity: 0, 
    y: 40, 
    scale: 0.95,
    filter: 'blur(5px)'
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    filter: 'blur(0px)',
    transition: {
      duration: 0.5,
      delay: index * 0.15,
      ease: [0.25, 0.1, 0.25, 1]
    }
  }),
  exit: { 
    opacity: 0,
    scale: 0.9,
    y: 20,
    transition: { duration: 0.3 }
  }
};

const QuizCard = ({
  answers = [],
  correctAnswerIndex = 0,
  duration = 10,
  question,
  setCurrentViewerAction,
  shouldRenderActionInTab = false,
  shouldShowStream = false,
  startTime
}) => {
  const [answerHeight, setAnswerHeight] = useState(defaultQuizAnswerHeight);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [chosenAnswerIndex, setChosenAnswerIndex] = useState(null);
  const { isMobileView } = useResponsiveDevice();
  const { isOverlayVisible } = usePlayerContext();
  const quizButtonArrRef = useRef([]);
  const [timeLeft, setTimeLeft] = useState(duration);

  // Add confetti effect for correct answer
  const showConfetti = () => {
    if (chosenAnswerIndex === correctAnswerIndex) {
      // Add your preferred confetti library implementation here
    }
  };

  const selectAnswer = (index) => {
    setIsAnswerSelected(true);
    setChosenAnswerIndex(index);
    showConfetti();
    setTimeout(() => setCurrentViewerAction(null), 2000);
  };

  useLayoutEffect(() => {
    quizButtonArrRef.current.forEach((quizButton) => {
      if (quizButton && quizButton.clientHeight > answerHeight) {
        setAnswerHeight(quizButton.clientHeight);
      }
    });
  }, [answerHeight]);

  // Add timer effect
  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = Math.max(0, Math.ceil(duration - ((Date.now() - startTime) / 1000)));
      setTimeLeft(remaining);
      
      if (remaining <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, startTime]);

  return (
    <div
      className={clsm([
        'absolute',
        'flex',
        'flex-col',
        'h-full',
        'no-scrollbar',
        'overflow-x-hidden',
        'overflow-y-auto',
        'p-5',
        'supports-overlay:overflow-y-overlay',
        'transition-[margin]',
        'w-full',
        'mb-4',
        !shouldRenderActionInTab && [
          '-translate-x-2/4',
          'bottom-0',
          'h-auto',
          'justify-end',
          'left-1/2',
          'max-w-[640px]',
          'min-w-[300px]', // Adjusted for better responsiveness
          'w-full'
        ],
        isOverlayVisible &&
          shouldShowStream &&
          !shouldRenderActionInTab &&
          'mb-20',
        isMobileView && 'pb-20'
      ])}
    >
      <motion.div
        {...createAnimationProps({
          animations: ['fadeIn-full'],
          customVariants: defaultSlideUpVariant,
          transition: defaultViewerStreamActionTransition,
          options: { shouldAnimate: !shouldRenderActionInTab }
        })}
        className={clsm([
          'bg-gradient-to-br',
          'from-white',
          'via-gray-50',
          'to-gray-100',
          'flex',
          'flex-col',
          'items-start',
          'rounded-[2rem]',
          'shadow-2xl',
          'shadow-gray-200/60',
          'w-full',
          'border',
          'border-gray-200/80',
          'overflow-hidden',
          // 'backdrop-blur-sm',
          'relative', // Added for pseudo-elements
          'before:absolute',
          'before:inset-0',
          'before:bg-gradient-to-b',
          'before:from-white/40',
          'before:to-transparent',
          'before:pointer-events-none',
        ])}
      >
        <div className="relative w-full">
          <div className="absolute top-0 left-0 w-full h-1.5">
            <ProgressBar 
              duration={duration} 
              startTime={startTime}
              className={clsm([
                'rounded-none',
                'bg-gradient-to-r',
                'from-blue-500',
                'to-purple-500'
              ])}
            />
          </div>
          
          <div className="absolute left-4 top-4 flex items-center text-gray-400">
            <IoTimeOutline className="w-5 h-5" />
            <span className="ml-2 text-sm font-medium">
              {timeLeft}s
            </span>
          </div>

          <h3 className={clsm([
            'flex',
            'px-8',
            'py-8',
            'pt-12', // Increased to accommodate timer
            'w-full',
            'justify-center',
            'break-words',
            'text-gray-800',
            'text-2xl',
            'font-bold',
            'text-center',
            'border-b',
            'border-gray-200/60',
            'bg-white/50',
            // 'backdrop-blur-sm',
            'leading-tight'
          ])}>
            <span className="inline-flex items-center ">
              <span className="mr-3 text-blue-500">
                <IoHelpCircle className="w-7 h-7" />
              </span>
              {question}
            </span>
          </h3>
        </div>

        <div className={clsm([
          'flex',
          'flex-col',
          'p-8',
          'space-y-3',
          'w-full'
        ])}>
          {answers.map((answer, index) => (
            <motion.div
              key={`answer-${index}`}
              variants={answerVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              custom={index}
              className="relative"
            >
              <Button
                ariaLabel={`Answer ${index + 1}`}
                customStyles={{
                  minHeight: `${answerHeight}px`
                }}
                className={clsm([
                  'group',
                  'bg-white',
                  'text-gray-700',
                  'text-left',
                  'justify-start',
                  // 'items-start',
                  'hover:bg-blue-50',
                  'hover:text-blue-700',
                  'hover:border-blue-200',
                  'focus:bg-blue-100',
                  'focus:border-blue-300',
                  'focus:ring-2',
                  'focus:ring-blue-200',
                  'focus:ring-offset-2',
                  'active:scale-[0.98]',
                  'rounded-xl',
                  'transition-all',
                  'duration-200',
                  'px-6',
                  'py-4',
                  'w-full',
                  'border-2',
                  'border-gray-200',
                  'font-medium',
                  'shadow-sm',
                  'hover:shadow-md',
                  'relative',
                  'overflow-hidden',
                  !isAnswerSelected && [
                    'hover:translate-y-[-2px]',
                    'after:absolute',
                    'after:inset-0',
                    'after:bg-gradient-to-r',
                    'after:from-transparent',
                    'after:to-transparent',
                    'after:opacity-0',
                    'after:transition-opacity',
                    'hover:after:opacity-100',
                  ],
                  isAnswerSelected && chosenAnswerIndex === index && (
                    index === correctAnswerIndex
                      ? [
                          'bg-green-50',
                          'text-green-700',
                          'border-green-300',
                          'shadow-green-100',
                          'animate-success',
                          'ring-2',
                          'ring-green-200',
                          'ring-offset-2'
                        ]
                      : [
                          'bg-red-50',
                          'text-red-700',
                          'border-red-300',
                          'shadow-red-100',
                          'animate-error',
                          'ring-2',
                          'ring-red-200',
                          'ring-offset-2'
                        ]
                  ),
                  isAnswerSelected && chosenAnswerIndex !== index && [
                    'opacity-40',
                    'scale-95',
                    'pointer-events-none',
                    'transition-all',
                    'duration-300'
                  ]
                ])}
                onClick={() => selectAnswer(index)}
                isDisabled={isAnswerSelected}
                ref={(el) => (quizButtonArrRef.current[index] = el)}
              >
                <span className="relative z-10 flex items-center">
                  <span className={clsm([
                    'mr-4',
                    'flex',
                    'items-center',
                    'justify-center',
                    'w-8',
                    'h-8',
                    'text-sm',
                    'rounded-lg',
                    'border-2',
                    'border-current',
                    'font-bold',
                    'transition-colors',
                    'group-hover:bg-blue-100/50'
                  ])}>
                    {String.fromCharCode(65 + index)}
                  </span>
                  <span className="flex-1 text-left">{answer}</span>
                  {isAnswerSelected && chosenAnswerIndex === index && (
                    <span className="ml-3 flex items-center">
                      {index === correctAnswerIndex ? (
                        <>
                          <IoCheckmarkCircle className="w-6 h-6 text-green-500 mr-2" />
                          <IoTrophyOutline className="w-5 h-5 text-yellow-500 animate-bounce" />
                        </>
                      ) : (
                        <IoCloseCircle className="w-6 h-6 text-red-500" />
                      )}
                    </span>
                  )}
                </span>
              </Button>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

QuizCard.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string),
  correctAnswerIndex: PropTypes.number,
  duration: PropTypes.number,
  question: PropTypes.string.isRequired,
  setCurrentViewerAction: PropTypes.func.isRequired,
  shouldRenderActionInTab: PropTypes.bool,
  shouldShowStream: PropTypes.bool,
  startTime: PropTypes.number.isRequired
};

export default QuizCard;
