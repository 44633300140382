import { useRef, useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { Link } from 'react-router-dom';

import { channelAPI } from '../../../api';
import { clsm } from '../../../utils';
import { CreateVideo } from '../../../assets/icons';
import { dashboard as $content } from '../../../content';
import {
  INPUT_BUTTON_GROUP_CLASSES,
  SETTINGS_SECTION_CLASSES
} from '../SettingsTheme';
import { useModal } from '../../../contexts/Modal';
import { useNotif } from '../../../contexts/Notification';
import { useSettingsOrientation } from '../Settings';
import { useUser } from '../../../contexts/User';
import Button from '../../../components/Button';
import CopyTextInput from '../CopyTextInput';
import Input from '../../../components/Input';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';

const StreamSettings = () => {
  const { isDesktopView } = useResponsiveDevice();

  const resetStreamKeyButtonRef = useRef();
  const [isResetStreamKeyLoading, setIsResetStreamKeyLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotif();
  const { openModal } = useModal();
  const { userData, fetchUserData } = useUser();
  const settingsFormOrientation = useSettingsOrientation();

  const copyStreamKey = () => {
    copyToClipboard(userData.streamKeyValue);
    notifySuccess($content.notification.success.stream_key_copied);
  };

  const handleResetStreamKey = () => {
    if (isResetStreamKeyLoading) return;

    const resetStreamKey = async () => {
      setIsResetStreamKeyLoading(true);
      const { result, error } = await channelAPI.resetStreamKey();

      if (result) {
        await fetchUserData();
        notifySuccess($content.notification.success.stream_key_reset);
      }
      if (error)
        notifyError($content.notification.error.reset_stream_key_failed);

      setIsResetStreamKeyLoading(false);
    };

    openModal({
      content: {
        confirmText: $content.modal.reset_stream_key_modal.reset_stream_key,
        isDestructive: true,
        message: $content.modal.reset_stream_key_modal.confirm_intent_message,
        subMessage: $content.modal.reset_stream_key_modal.stream_will_terminate
      },
      lastFocusedElement: resetStreamKeyButtonRef,
      onConfirm: resetStreamKey
    });
  };

  return (
    <div className={clsm(['space-y-6 ', SETTINGS_SECTION_CLASSES])}>
      <div className="bg-white dark:bg-black shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {$content.settings_page.stream_settings}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
            <p>
              Stream directly from your browser or use{' '}
              <Link
                to="https://obsproject.com"
                className="text-blue-600 dark:text-blue-400 underline hover:text-blue-800 dark:hover:text-blue-600"
              >
                OBS (Open Broadcaster Software)
              </Link>{' '}
              with the stream URL and key provided below.
            </p>
          </div>
          <div className="mt-5 space-y-6 ">
          <span
        className={clsm(
          INPUT_BUTTON_GROUP_CLASSES,
          'xs:flex-wrap',
          'xs:mb-[10px]',
          'xs:space-y-2.5'
        )}
      >
        <Input
          label={$content.settings_page.stream_key}
          name="streamKeyValue"
          placeholder={$content.settings_page.stream_key}
          readOnly
          type="password"
          value={userData.streamKeyValue}
          variant={settingsFormOrientation}
        />
        <Button
          ariaLabel={`Reset ${$content.settings_page.stream_key} value`}
          className="xs:order-1"
          isLoading={isResetStreamKeyLoading}
          onClick={handleResetStreamKey}
          ref={resetStreamKeyButtonRef}
          variant="destructive"
        >
          {$content.settings_page.reset}
        </Button>
        <Button
          ariaLabel={`Copy ${$content.settings_page.stream_key} value`}
          onClick={copyStreamKey}
          variant="tertiary"
        >
          {$content.settings_page.copy}
        </Button>
      </span>

            <CopyTextInput
              label={$content.settings_page.ingest_server_url}
              name="ingestServerUrl"
              value={userData.ingestServerUrl}
              successMessage={
                $content.notification.success.ingest_server_url_copied
              }
            />
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-black shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            Go Live
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
            <p>Start streaming directly from your browser.</p>
          </div>
          <div className="mt-5">
            <Button
              type="nav"
              to="/manager"
              state={{
                isWebBroadcastContainerOpen: true,
                streamManagerSelectedTab: isDesktopView ? 0 : 1
              }}
              className="sm:w-full w-60"
              variant="primary"
            >
              <CreateVideo className="w-5 h-5 mr-4 text-white dark:text-black fill-current" />
              {$content.settings_page.go_live_from_web}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamSettings;
