import { useMemo } from 'react';
import {
  AUCTION_DATA_KEYS,
  STREAM_ACTION_NAME,
  STREAM_MANAGER_ACTION_LIMITS
} from '../../../../../constants';
import { useStreamManagerActions } from '../../../../../contexts/StreamManagerActions';
import { streamManager as $streamManagerContent } from '../../../../../content';
import PriceInput from './formElements/PriceInput';
import Input from './formElements/Input';
import RangeSelector from './formElements/RangeSelector';

const Auction = () => {
  const {
    currentStreamManagerActionErrors,
    getStreamManagerActionData,
    updateStreamManagerActionData
  } = useStreamManagerActions();

  const { auctionTitle, auctionPrice, duration, auctionShippingRate } =
    getStreamManagerActionData(STREAM_ACTION_NAME.AUCTION);

  const LIMITS = useMemo(
    () => STREAM_MANAGER_ACTION_LIMITS[STREAM_ACTION_NAME.AUCTION],
    []
  );

  const contentMapper = useMemo(
    () => ({
      [STREAM_ACTION_NAME.AUCTION]: {
        content: $streamManagerContent.stream_manager_actions.auction,
        rangeSelector: {
          label: $streamManagerContent.stream_manager_actions.auction.duration,
          dataKey: AUCTION_DATA_KEYS.DURATION,
          min: LIMITS[AUCTION_DATA_KEYS.DURATION]?.min,
          max: LIMITS[AUCTION_DATA_KEYS.DURATION]?.max
        }
      }
    }),
    [LIMITS]
  );

  const updateStreamManagerActionAuctionData = (data) => {
    updateStreamManagerActionData({
      dataOrFn: data,
      actionName: STREAM_ACTION_NAME.AUCTION
    });
  };

  const {
    content: {
      title: titleLabel,
      price: priceLabel,
      shipping_rate: shippingRateLabel
    },
    rangeSelector: {
      label: rangeSelectorLabel,
      dataKey: rangeSelectorDataKey,
      min: rangeSelectorMin,
      max: rangeSelectorMax
    }
  } = contentMapper[STREAM_ACTION_NAME.AUCTION];

  return (
    <>
      <Input
        dataKey={AUCTION_DATA_KEYS.TITLE}
        error={currentStreamManagerActionErrors[AUCTION_DATA_KEYS.TITLE]}
        label={titleLabel}
        name="streamManagerActionFormTitle"
        onChange={updateStreamManagerActionAuctionData}
        placeholder={titleLabel}
        value={auctionTitle}
      />
      <PriceInput
        dataKey={AUCTION_DATA_KEYS.PRICE}
        error={currentStreamManagerActionErrors[AUCTION_DATA_KEYS.PRICE]}
        label={priceLabel}
        name="streamManagerActionFormPrice"
        onChange={updateStreamManagerActionAuctionData}
        placeholder={priceLabel}
        value={auctionPrice}
      />
      <PriceInput
        dataKey={AUCTION_DATA_KEYS.SHIPPING_RATE}
        error={
          currentStreamManagerActionErrors[AUCTION_DATA_KEYS.SHIPPING_RATE]
        }
        label={shippingRateLabel}
        name="streamManagerActionFormShippingRate"
        onChange={updateStreamManagerActionAuctionData}
        placeholder={shippingRateLabel}
        value={auctionShippingRate}
      />
      <RangeSelector
        label={rangeSelectorLabel}
        name="streamManagerActionFormDuration"
        dataKey={rangeSelectorDataKey}
        updateData={updateStreamManagerActionAuctionData}
        value={duration}
        min={rangeSelectorMin}
        max={rangeSelectorMax}
      />
    </>
  );
};

Auction.propTypes = {};

export default Auction;
